"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRegistrationType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var UserRegistrationType_Factory = /** @class */ (function () {
    function UserRegistrationType_Factory() {
    }
    UserRegistrationType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Type: model.Type,
            TypeCode: model.TypeCode,
        };
        return result;
    };
    UserRegistrationType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Type: model.Type,
            TypeCode: model.TypeCode,
        };
        return result;
    };
    return UserRegistrationType_Factory;
}());
exports.UserRegistrationType_Factory = UserRegistrationType_Factory;
