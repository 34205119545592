"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreScreenResponse_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PreScreenResponse_Factory = /** @class */ (function () {
    function PreScreenResponse_Factory() {
    }
    PreScreenResponse_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdvisedToSelfIsolate: model.AdvisedToSelfIsolate,
            AppointmentId: model.AppointmentId,
            AwaitingResults: model.AwaitingResults,
            Contact: model.Contact,
            Cough: model.Cough,
            Fever: model.Fever,
            LossOfTasteSmell: model.LossOfTasteSmell,
            OtherFluSymptoms: model.OtherFluSymptoms,
            Over60: model.Over60,
            Over70: model.Over70,
            PatientId: model.PatientId,
            PositiveLast7Days: model.PositiveLast7Days,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RiskCondition: model.RiskCondition,
            ShortnessOfBreath: model.ShortnessOfBreath,
            Temperature: model.Temperature,
            TemperatureScale: model.TemperatureScale,
            Travel: model.Travel,
            UserId: model.UserId,
        };
        return result;
    };
    PreScreenResponse_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdvisedToSelfIsolate: model.AdvisedToSelfIsolate,
            AppointmentId: model.AppointmentId,
            AwaitingResults: model.AwaitingResults,
            Contact: model.Contact,
            Cough: model.Cough,
            Fever: model.Fever,
            LossOfTasteSmell: model.LossOfTasteSmell,
            OtherFluSymptoms: model.OtherFluSymptoms,
            Over60: model.Over60,
            Over70: model.Over70,
            PatientId: model.PatientId,
            PositiveLast7Days: model.PositiveLast7Days,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RiskCondition: model.RiskCondition,
            ShortnessOfBreath: model.ShortnessOfBreath,
            Temperature: model.Temperature,
            TemperatureScale: model.TemperatureScale,
            Travel: model.Travel,
            UserId: model.UserId,
        };
        return result;
    };
    return PreScreenResponse_Factory;
}());
exports.PreScreenResponse_Factory = PreScreenResponse_Factory;
