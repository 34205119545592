"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceAdjustment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BalanceAdjustment_Factory = /** @class */ (function () {
    function BalanceAdjustment_Factory() {
    }
    BalanceAdjustment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdjustmentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AdjustmentDate),
            Amount: model.Amount,
            ApplyToToday: model.ApplyToToday,
            AppointmentId: model.AppointmentId,
            Comments: model.Comments,
            ContractAdjustmentAmount: model.ContractAdjustmentAmount,
            ContractAdjustmentTypeId: model.ContractAdjustmentTypeId,
            ContractId: model.ContractId,
            ContractScheduleId: model.ContractScheduleId,
            DiscountSubTypeId: model.DiscountSubTypeId,
            DiscountTypeId: model.DiscountTypeId,
            InsuranceClaimId: model.InsuranceClaimId,
            LocationId: model.LocationId,
            PatientId: model.PatientId,
            ProviderId: model.ProviderId,
            ServiceLocationId: model.ServiceLocationId,
            WriteOffSubTypeId: model.WriteOffSubTypeId,
            WriteOffTypeId: model.WriteOffTypeId,
        };
        return result;
    };
    BalanceAdjustment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdjustmentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AdjustmentDate),
            Amount: model.Amount,
            ApplyToToday: model.ApplyToToday,
            AppointmentId: model.AppointmentId,
            Comments: model.Comments,
            ContractAdjustmentAmount: model.ContractAdjustmentAmount,
            ContractAdjustmentTypeId: model.ContractAdjustmentTypeId,
            ContractId: model.ContractId,
            ContractScheduleId: model.ContractScheduleId,
            DiscountSubTypeId: model.DiscountSubTypeId,
            DiscountTypeId: model.DiscountTypeId,
            InsuranceClaimId: model.InsuranceClaimId,
            LocationId: model.LocationId,
            PatientId: model.PatientId,
            ProviderId: model.ProviderId,
            ServiceLocationId: model.ServiceLocationId,
            WriteOffSubTypeId: model.WriteOffSubTypeId,
            WriteOffTypeId: model.WriteOffTypeId,
        };
        return result;
    };
    return BalanceAdjustment_Factory;
}());
exports.BalanceAdjustment_Factory = BalanceAdjustment_Factory;
