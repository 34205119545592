"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSInterchange_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var NHSClaim_1 = require("./NHSClaim");
var NHSMailbox_1 = require("./NHSMailbox");
var NHSInterchange_Factory = /** @class */ (function () {
    function NHSInterchange_Factory() {
    }
    NHSInterchange_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimsProviders: model.ClaimsProviders && model.ClaimsProviders.map(function (i) { return User_1.User_Factory.CreateIncoming(i); }),
            Error: model.Error,
            InterchangeNo: model.InterchangeNo,
            MailboxId: model.MailboxId,
            NHSClaims: model.NHSClaims && model.NHSClaims.map(function (i) { return NHSClaim_1.NHSClaim_Factory.CreateIncoming(i); }),
            NHSMailbox: NHSMailbox_1.NHSMailbox_Factory.CreateIncoming(model.NHSMailbox),
            NumberOfClaims: model.NumberOfClaims,
            ProcessingState: model.ProcessingState,
            ReceivedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ReceivedDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SentBy: model.SentBy,
            SentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.SentDate),
            User: User_1.User_Factory.CreateIncoming(model.User),
        };
        return result;
    };
    NHSInterchange_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimsProviders: model.ClaimsProviders && model.ClaimsProviders.map(function (i) { return User_1.User_Factory.CreateOutgoing(i); }),
            Error: model.Error,
            InterchangeNo: model.InterchangeNo,
            MailboxId: model.MailboxId,
            NHSClaims: model.NHSClaims && model.NHSClaims.map(function (i) { return NHSClaim_1.NHSClaim_Factory.CreateOutgoing(i); }),
            NHSMailbox: NHSMailbox_1.NHSMailbox_Factory.CreateOutgoing(model.NHSMailbox),
            NumberOfClaims: model.NumberOfClaims,
            ProcessingState: model.ProcessingState,
            ReceivedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ReceivedDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SentBy: model.SentBy,
            SentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.SentDate),
            User: User_1.User_Factory.CreateOutgoing(model.User),
        };
        return result;
    };
    return NHSInterchange_Factory;
}());
exports.NHSInterchange_Factory = NHSInterchange_Factory;
