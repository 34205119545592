"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduleViewChair_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Chair_1 = require("./Chair");
var RecordStatus_1 = require("./RecordStatus");
var ScheduleViewChair_Factory = /** @class */ (function () {
    function ScheduleViewChair_Factory() {
    }
    ScheduleViewChair_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Chair: Chair_1.Chair_Factory.CreateIncoming(model.Chair),
            ChairId: model.ChairId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
            ScheduleViewId: model.ScheduleViewId,
            ShowOrder: model.ShowOrder,
        };
        return result;
    };
    ScheduleViewChair_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Chair: Chair_1.Chair_Factory.CreateOutgoing(model.Chair),
            ChairId: model.ChairId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
            ScheduleViewId: model.ScheduleViewId,
            ShowOrder: model.ShowOrder,
        };
        return result;
    };
    return ScheduleViewChair_Factory;
}());
exports.ScheduleViewChair_Factory = ScheduleViewChair_Factory;
