"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPaymentMerchant_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EPaymentMerchant_Factory = /** @class */ (function () {
    function EPaymentMerchant_Factory() {
    }
    EPaymentMerchant_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EPaymentsVersion: model.EPaymentsVersion,
            ExternalMerchantId: model.ExternalMerchantId,
            MerchantID: model.MerchantID,
            MerchantName: model.MerchantName,
            TsysMerchantId: model.TsysMerchantId,
            V3MerchantId: model.V3MerchantId,
        };
        return result;
    };
    EPaymentMerchant_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EPaymentsVersion: model.EPaymentsVersion,
            ExternalMerchantId: model.ExternalMerchantId,
            MerchantID: model.MerchantID,
            MerchantName: model.MerchantName,
            TsysMerchantId: model.TsysMerchantId,
            V3MerchantId: model.V3MerchantId,
        };
        return result;
    };
    return EPaymentMerchant_Factory;
}());
exports.EPaymentMerchant_Factory = EPaymentMerchant_Factory;
