"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickbooksLocationDefaultMapping_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var QuickbooksLocationDefaultMapping_Factory = /** @class */ (function () {
    function QuickbooksLocationDefaultMapping_Factory() {
    }
    QuickbooksLocationDefaultMapping_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DepositToAccount: model.DepositToAccount,
            DeposLineItemDetails: model.DeposLineItemDetails,
            InheritItemizeByPatient: model.InheritItemizeByPatient,
            InheritTransactionsFrom: model.InheritTransactionsFrom,
            ItemizeByPatient: model.ItemizeByPatient,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            QBOClass: model.QBOClass,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransactionsFrom: Date_Factory_1.Date_Factory.CreateIncoming(model.TransactionsFrom),
        };
        return result;
    };
    QuickbooksLocationDefaultMapping_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DepositToAccount: model.DepositToAccount,
            DeposLineItemDetails: model.DeposLineItemDetails,
            InheritItemizeByPatient: model.InheritItemizeByPatient,
            InheritTransactionsFrom: model.InheritTransactionsFrom,
            ItemizeByPatient: model.ItemizeByPatient,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            QBOClass: model.QBOClass,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransactionsFrom: Date_Factory_1.Date_Factory.CreateOutgoing(model.TransactionsFrom),
        };
        return result;
    };
    return QuickbooksLocationDefaultMapping_Factory;
}());
exports.QuickbooksLocationDefaultMapping_Factory = QuickbooksLocationDefaultMapping_Factory;
