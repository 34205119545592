"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProviderFinancialActivity_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ProviderFinancialActivity_Factory = /** @class */ (function () {
    function ProviderFinancialActivity_Factory() {
    }
    ProviderFinancialActivity_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Collections: model.Collections,
            Month: model.Month,
            PatientIds: model.PatientIds && model.PatientIds.map(function (i) { return i; }),
            Productions: model.Productions,
            ProviderId: model.ProviderId,
            Year: model.Year,
        };
        return result;
    };
    ProviderFinancialActivity_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Collections: model.Collections,
            Month: model.Month,
            PatientIds: model.PatientIds && model.PatientIds.map(function (i) { return i; }),
            Productions: model.Productions,
            ProviderId: model.ProviderId,
            Year: model.Year,
        };
        return result;
    };
    return ProviderFinancialActivity_Factory;
}());
exports.ProviderFinancialActivity_Factory = ProviderFinancialActivity_Factory;
