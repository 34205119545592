"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsToothChartElementTooth_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Tooth_1 = require("./Tooth");
var EFormsToothChartElementTooth_Factory = /** @class */ (function () {
    function EFormsToothChartElementTooth_Factory() {
    }
    EFormsToothChartElementTooth_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DataElementId: model.DataElementId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Tooth: Tooth_1.Tooth_Factory.CreateIncoming(model.Tooth),
            ToothCode: model.ToothCode,
        };
        return result;
    };
    EFormsToothChartElementTooth_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DataElementId: model.DataElementId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Tooth: Tooth_1.Tooth_Factory.CreateOutgoing(model.Tooth),
            ToothCode: model.ToothCode,
        };
        return result;
    };
    return EFormsToothChartElementTooth_Factory;
}());
exports.EFormsToothChartElementTooth_Factory = EFormsToothChartElementTooth_Factory;
