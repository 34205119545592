"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentNote_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Appointment_1 = require("./Appointment");
var AppointmentNoteType_1 = require("./AppointmentNoteType");
var Note_1 = require("./Note");
var PatientWatch_1 = require("./PatientWatch");
var RecordStatus_1 = require("./RecordStatus");
var AppointmentNote_Factory = /** @class */ (function () {
    function AppointmentNote_Factory() {
    }
    AppointmentNote_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Appointment: Appointment_1.Appointment_Factory.CreateIncoming(model.Appointment),
            AppointmentId: model.AppointmentId,
            AppointmentNoteType: model.AppointmentNoteType,
            AppointmentNoteType1: AppointmentNoteType_1.AppointmentNoteType_Factory.CreateIncoming(model.AppointmentNoteType1),
            FeeChangeAmount: model.FeeChangeAmount,
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateIncoming(model.NoteTakenOn),
            PatientWatch: PatientWatch_1.PatientWatch_Factory.CreateIncoming(model.PatientWatch),
            PatientWatchId: model.PatientWatchId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
        };
        return result;
    };
    AppointmentNote_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Appointment: Appointment_1.Appointment_Factory.CreateOutgoing(model.Appointment),
            AppointmentId: model.AppointmentId,
            AppointmentNoteType: model.AppointmentNoteType,
            AppointmentNoteType1: AppointmentNoteType_1.AppointmentNoteType_Factory.CreateOutgoing(model.AppointmentNoteType1),
            FeeChangeAmount: model.FeeChangeAmount,
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.NoteTakenOn),
            PatientWatch: PatientWatch_1.PatientWatch_Factory.CreateOutgoing(model.PatientWatch),
            PatientWatchId: model.PatientWatchId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
        };
        return result;
    };
    return AppointmentNote_Factory;
}());
exports.AppointmentNote_Factory = AppointmentNote_Factory;
