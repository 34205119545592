"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientPaymentMethod_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EPaymentMerchant_1 = require("./EPaymentMerchant");
var Patient_1 = require("./Patient");
var PatientResponsibleParty_1 = require("./PatientResponsibleParty");
var ScheduledPayment_1 = require("./ScheduledPayment");
var PatientPaymentMethod_Factory = /** @class */ (function () {
    function PatientPaymentMethod_Factory() {
    }
    PatientPaymentMethod_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ACHAccountNumber: model.ACHAccountNumber,
            CardHolder: model.CardHolder,
            CardNumber: model.CardNumber,
            EPaymentMerchant: EPaymentMerchant_1.EPaymentMerchant_Factory.CreateIncoming(model.EPaymentMerchant),
            ExpirationDate: model.ExpirationDate,
            MerchantId: model.MerchantId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PatientResponsibleParty: PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateIncoming(model.PatientResponsibleParty),
            PatientResponsiblePartyId: model.PatientResponsiblePartyId,
            PatientResponsiblePartyName: model.PatientResponsiblePartyName,
            PaymentType: model.PaymentType,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateIncoming(i); }),
            Status: model.Status,
            V3GuarantorId: model.V3GuarantorId,
            V3MerchantId: model.V3MerchantId,
            V3TokenId: model.V3TokenId,
            VaultId: model.VaultId,
            Vendor: model.Vendor,
        };
        return result;
    };
    PatientPaymentMethod_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ACHAccountNumber: model.ACHAccountNumber,
            CardHolder: model.CardHolder,
            CardNumber: model.CardNumber,
            EPaymentMerchant: EPaymentMerchant_1.EPaymentMerchant_Factory.CreateOutgoing(model.EPaymentMerchant),
            ExpirationDate: model.ExpirationDate,
            MerchantId: model.MerchantId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PatientResponsibleParty: PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateOutgoing(model.PatientResponsibleParty),
            PatientResponsiblePartyId: model.PatientResponsiblePartyId,
            PatientResponsiblePartyName: model.PatientResponsiblePartyName,
            PaymentType: model.PaymentType,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateOutgoing(i); }),
            Status: model.Status,
            V3GuarantorId: model.V3GuarantorId,
            V3MerchantId: model.V3MerchantId,
            V3TokenId: model.V3TokenId,
            VaultId: model.VaultId,
            Vendor: model.Vendor,
        };
        return result;
    };
    return PatientPaymentMethod_Factory;
}());
exports.PatientPaymentMethod_Factory = PatientPaymentMethod_Factory;
