"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsurancePolicy_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceEligibilityType_1 = require("./InsuranceEligibilityType");
var InsurancePlan_1 = require("./InsurancePlan");
var InsurancePolicyCoverageType_1 = require("./InsurancePolicyCoverageType");
var Patient_1 = require("./Patient");
var Person_1 = require("./Person");
var RelationshipToPolicyHolderType_1 = require("./RelationshipToPolicyHolderType");
var InsurancePolicy_Factory = /** @class */ (function () {
    function InsurancePolicy_Factory() {
    }
    InsurancePolicy_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BenefitsAreAssigned: model.BenefitsAreAssigned,
            CoverageTypeCode: model.CoverageTypeCode,
            FamilyBenefitsRemaining: model.FamilyBenefitsRemaining,
            FamilyDeductibleRemaining: model.FamilyDeductibleRemaining,
            IndividualBenefitsRemaining: model.IndividualBenefitsRemaining,
            IndividualDeductibleRemaining: model.IndividualDeductibleRemaining,
            InsuranceEligibilityType: InsuranceEligibilityType_1.InsuranceEligibilityType_Factory.CreateIncoming(model.InsuranceEligibilityType),
            InsuranceEligibilityTypeChangedOn: Date_Factory_1.Date_Factory.CreateIncoming(model.InsuranceEligibilityTypeChangedOn),
            InsuranceEligibilityTypeCode: model.InsuranceEligibilityTypeCode,
            InsurancePlan: InsurancePlan_1.InsurancePlan_Factory.CreateIncoming(model.InsurancePlan),
            InsurancePlanId: model.InsurancePlanId,
            InsurancePolicyCoverageType: InsurancePolicyCoverageType_1.InsurancePolicyCoverageType_Factory.CreateIncoming(model.InsurancePolicyCoverageType),
            InsuredId: model.InsuredId,
            IsValid: model.IsValid,
            LastCoverageAccess: Date_Factory_1.Date_Factory.CreateIncoming(model.LastCoverageAccess),
            OnPlanSinceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.OnPlanSinceDate),
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PolicyHolderPerson: Person_1.Person_Factory.CreateIncoming(model.PolicyHolderPerson),
            PolicyHolderPersonId: model.PolicyHolderPersonId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RelationshipToPolicyHolderType: RelationshipToPolicyHolderType_1.RelationshipToPolicyHolderType_Factory.CreateIncoming(model.RelationshipToPolicyHolderType),
            RelationshipToPolicyHolderTypeCode: model.RelationshipToPolicyHolderTypeCode,
        };
        return result;
    };
    InsurancePolicy_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BenefitsAreAssigned: model.BenefitsAreAssigned,
            CoverageTypeCode: model.CoverageTypeCode,
            FamilyBenefitsRemaining: model.FamilyBenefitsRemaining,
            FamilyDeductibleRemaining: model.FamilyDeductibleRemaining,
            IndividualBenefitsRemaining: model.IndividualBenefitsRemaining,
            IndividualDeductibleRemaining: model.IndividualDeductibleRemaining,
            InsuranceEligibilityType: InsuranceEligibilityType_1.InsuranceEligibilityType_Factory.CreateOutgoing(model.InsuranceEligibilityType),
            InsuranceEligibilityTypeChangedOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.InsuranceEligibilityTypeChangedOn),
            InsuranceEligibilityTypeCode: model.InsuranceEligibilityTypeCode,
            InsurancePlan: InsurancePlan_1.InsurancePlan_Factory.CreateOutgoing(model.InsurancePlan),
            InsurancePlanId: model.InsurancePlanId,
            InsurancePolicyCoverageType: InsurancePolicyCoverageType_1.InsurancePolicyCoverageType_Factory.CreateOutgoing(model.InsurancePolicyCoverageType),
            InsuredId: model.InsuredId,
            IsValid: model.IsValid,
            LastCoverageAccess: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastCoverageAccess),
            OnPlanSinceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.OnPlanSinceDate),
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PolicyHolderPerson: Person_1.Person_Factory.CreateOutgoing(model.PolicyHolderPerson),
            PolicyHolderPersonId: model.PolicyHolderPersonId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RelationshipToPolicyHolderType: RelationshipToPolicyHolderType_1.RelationshipToPolicyHolderType_Factory.CreateOutgoing(model.RelationshipToPolicyHolderType),
            RelationshipToPolicyHolderTypeCode: model.RelationshipToPolicyHolderTypeCode,
        };
        return result;
    };
    return InsurancePolicy_Factory;
}());
exports.InsurancePolicy_Factory = InsurancePolicy_Factory;
