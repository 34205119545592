"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCourseDetailOrtho_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClaim_1 = require("./NHSClaim");
var TreatmentPlan_1 = require("./TreatmentPlan");
var NHSCourseDetailOrtho_Factory = /** @class */ (function () {
    function NHSCourseDetailOrtho_Factory() {
    }
    NHSCourseDetailOrtho_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ApplianceFittingDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ApplianceFittingDate),
            AssessmentComplete: model.AssessmentComplete,
            AssessmentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AssessmentDate),
            AssessmentResult: model.AssessmentResult,
            CommissionerApproval: model.CommissionerApproval,
            EndAestheticIndex: model.EndAestheticIndex,
            EndDentalHealthIndex: model.EndDentalHealthIndex,
            NHSClaims: model.NHSClaims && model.NHSClaims.map(function (i) { return NHSClaim_1.NHSClaim_Factory.CreateIncoming(i); }),
            PARScoresCalculated: model.PARScoresCalculated,
            PostTreatmentPARScore: model.PostTreatmentPARScore,
            PreTreatmentPARScore: model.PreTreatmentPARScore,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartAestheticIndex: model.StartAestheticIndex,
            StartDentalHealthIndex: model.StartDentalHealthIndex,
            TreatmentEnd: model.TreatmentEnd,
            TreatmentEndDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TreatmentEndDate),
            TreatmentFinished: model.TreatmentFinished,
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateIncoming(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
            TreatmentReferralDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TreatmentReferralDate),
            TreatmentRefusalReason: model.TreatmentRefusalReason,
        };
        return result;
    };
    NHSCourseDetailOrtho_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ApplianceFittingDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ApplianceFittingDate),
            AssessmentComplete: model.AssessmentComplete,
            AssessmentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AssessmentDate),
            AssessmentResult: model.AssessmentResult,
            CommissionerApproval: model.CommissionerApproval,
            EndAestheticIndex: model.EndAestheticIndex,
            EndDentalHealthIndex: model.EndDentalHealthIndex,
            NHSClaims: model.NHSClaims && model.NHSClaims.map(function (i) { return NHSClaim_1.NHSClaim_Factory.CreateOutgoing(i); }),
            PARScoresCalculated: model.PARScoresCalculated,
            PostTreatmentPARScore: model.PostTreatmentPARScore,
            PreTreatmentPARScore: model.PreTreatmentPARScore,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartAestheticIndex: model.StartAestheticIndex,
            StartDentalHealthIndex: model.StartDentalHealthIndex,
            TreatmentEnd: model.TreatmentEnd,
            TreatmentEndDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TreatmentEndDate),
            TreatmentFinished: model.TreatmentFinished,
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateOutgoing(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
            TreatmentReferralDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TreatmentReferralDate),
            TreatmentRefusalReason: model.TreatmentRefusalReason,
        };
        return result;
    };
    return NHSCourseDetailOrtho_Factory;
}());
exports.NHSCourseDetailOrtho_Factory = NHSCourseDetailOrtho_Factory;
