"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalNote_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Note_1 = require("./Note");
var Tooth_1 = require("./Tooth");
var ClinicalNote_Factory = /** @class */ (function () {
    function ClinicalNote_Factory() {
    }
    ClinicalNote_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            ClinicalEventId: model.ClinicalEventId,
            ClinicalNoteType: model.ClinicalNoteType,
            EffectiveDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EffectiveDate, { ignoreTimePortion: true }),
            FDIToothCode: model.FDIToothCode,
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            NoteId: model.NoteId,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Tooth: Tooth_1.Tooth_Factory.CreateIncoming(model.Tooth),
        };
        return result;
    };
    ClinicalNote_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            ClinicalEventId: model.ClinicalEventId,
            ClinicalNoteType: model.ClinicalNoteType,
            EffectiveDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EffectiveDate, { ignoreTimePortion: true }),
            FDIToothCode: model.FDIToothCode,
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            NoteId: model.NoteId,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Tooth: Tooth_1.Tooth_Factory.CreateOutgoing(model.Tooth),
        };
        return result;
    };
    return ClinicalNote_Factory;
}());
exports.ClinicalNote_Factory = ClinicalNote_Factory;
