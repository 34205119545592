import React from "react";
import { Callout, Intent } from "@blueprintjs/core";
import Wait from "./Wait";
import ModalWait from "./ModalWait";
import usePromiseState from "./usePromiseState";



type RenderFunc<T> = (result: T) => React.ReactElement;

interface IPromiseWaitProps<T, TProps={promiseResult: T}> {
  promise: Promise<T>; // | undefined;
  useModalWait?: boolean;
  waitMessage?: string;
  errorMessage?: string;
  //render?: RenderFunc<T>;
  children?: RenderFunc<T>;
  mapComponentProps?: (promiseResult: T) => TProps;
  component?: React.ComponentType<TProps>;
  waitProps?: { showBorder?: boolean;  style?: React.CSSProperties; }
}


function defaultMapComponentToProps<T, TProps={promiseResult: T}>(result: T) {
  return { promiseResult: result } as unknown as TProps;
};

// Waits for a promise to fullfill.
// Shows `Wait` component while busy.
// Shows error message if promise rejects.
// Renders child render function, or specified coponent when promise completes.

const PromiseWait = <T, TProps={promiseResult: T}>({
  promise,
  mapComponentProps = defaultMapComponentToProps,
  component: Component,
  useModalWait = true,
  children,
  waitProps,
  waitMessage = "Please Wait",
  errorMessage
}: IPromiseWaitProps<T, TProps>): (React.ReactElement | null) => {

  const [ isBusy, result, error ] = usePromiseState<T>(promise);

  if (!Component && !children)  {
    throw new Error("Either `component` or `children` must be provided.")
  }

  if (isBusy) {
    const WaitComponent = useModalWait ? ModalWait : Wait;
    return  <WaitComponent isOpen={true} rightOrBottomText={waitMessage} {...waitProps} />;
  }

  if (error) {
    console.error("Promise rejection.", error);
    return (
      <div className="centered">
        <Callout
          title="Oops! Something went wrong :("
          intent={Intent.DANGER}
          className="elevated"
          style={{maxWidth: 400}}
        >
          {errorMessage || error.message || "An error has occured."}
        </Callout>
      </div>)
  }

  if (Component) {
    const componentProps = mapComponentProps(result!);
    return <Component {...componentProps!} />
  }

  return children!(result!);
};

PromiseWait.mapAsIs = function <T>(promiseResult: T) { return promiseResult };

export default PromiseWait;


