"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCourseClaimMessageInfo_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClaimMessageType_1 = require("./NHSClaimMessageType");
var NHSCourseClaimMessageInfo_Factory = /** @class */ (function () {
    function NHSCourseClaimMessageInfo_Factory() {
    }
    NHSCourseClaimMessageInfo_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimMessageType: model.ClaimMessageType,
            CompletedWorkChargeDescription: model.CompletedWorkChargeDescription,
            DerivedActivityBand: model.DerivedActivityBand,
            DerivedChargeBand: model.DerivedChargeBand,
            NHSActivityBandDescription: model.NHSActivityBandDescription,
            NHSClaimMessageType: NHSClaimMessageType_1.NHSClaimMessageType_Factory.CreateIncoming(model.NHSClaimMessageType),
            NHSCourseDetailsID: model.NHSCourseDetailsID,
            NHSUdaAmount: model.NHSUdaAmount,
            OverrideActivityBand: model.OverrideActivityBand,
            OverrideChargeBand: model.OverrideChargeBand,
            PatientChargeAmount: model.PatientChargeAmount,
            PatientChargeAmountCompletedOnly: model.PatientChargeAmountCompletedOnly,
            PatientChargedSoFar: model.PatientChargedSoFar,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferredActivityBand: model.ReferredActivityBand,
            TStamp: model.TStamp,
            UserActivityBand: model.UserActivityBand,
            UserChargeBand: model.UserChargeBand,
        };
        return result;
    };
    NHSCourseClaimMessageInfo_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimMessageType: model.ClaimMessageType,
            CompletedWorkChargeDescription: model.CompletedWorkChargeDescription,
            DerivedActivityBand: model.DerivedActivityBand,
            DerivedChargeBand: model.DerivedChargeBand,
            NHSActivityBandDescription: model.NHSActivityBandDescription,
            NHSClaimMessageType: NHSClaimMessageType_1.NHSClaimMessageType_Factory.CreateOutgoing(model.NHSClaimMessageType),
            NHSCourseDetailsID: model.NHSCourseDetailsID,
            NHSUdaAmount: model.NHSUdaAmount,
            OverrideActivityBand: model.OverrideActivityBand,
            OverrideChargeBand: model.OverrideChargeBand,
            PatientChargeAmount: model.PatientChargeAmount,
            PatientChargeAmountCompletedOnly: model.PatientChargeAmountCompletedOnly,
            PatientChargedSoFar: model.PatientChargedSoFar,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferredActivityBand: model.ReferredActivityBand,
            TStamp: model.TStamp,
            UserActivityBand: model.UserActivityBand,
            UserChargeBand: model.UserChargeBand,
        };
        return result;
    };
    return NHSCourseClaimMessageInfo_Factory;
}());
exports.NHSCourseClaimMessageInfo_Factory = NHSCourseClaimMessageInfo_Factory;
