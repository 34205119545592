"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSSchedule_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSContract_1 = require("./NHSContract");
var NHSSchedulePayment_1 = require("./NHSSchedulePayment");
var User_1 = require("./User");
var NHSSchedule_Factory = /** @class */ (function () {
    function NHSSchedule_Factory() {
    }
    NHSSchedule_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ContractId: model.ContractId,
            NHSContract: NHSContract_1.NHSContract_Factory.CreateIncoming(model.NHSContract),
            NHSSchedulePayments: model.NHSSchedulePayments && model.NHSSchedulePayments.map(function (i) { return NHSSchedulePayment_1.NHSSchedulePayment_Factory.CreateIncoming(i); }),
            PerformerId: model.PerformerId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduleId: model.ScheduleId,
            User: User_1.User_Factory.CreateIncoming(model.User),
        };
        return result;
    };
    NHSSchedule_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ContractId: model.ContractId,
            NHSContract: NHSContract_1.NHSContract_Factory.CreateOutgoing(model.NHSContract),
            NHSSchedulePayments: model.NHSSchedulePayments && model.NHSSchedulePayments.map(function (i) { return NHSSchedulePayment_1.NHSSchedulePayment_Factory.CreateOutgoing(i); }),
            PerformerId: model.PerformerId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduleId: model.ScheduleId,
            User: User_1.User_Factory.CreateOutgoing(model.User),
        };
        return result;
    };
    return NHSSchedule_Factory;
}());
exports.NHSSchedule_Factory = NHSSchedule_Factory;
