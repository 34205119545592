"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionAdjustment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var TransactionAdjustment_Factory = /** @class */ (function () {
    function TransactionAdjustment_Factory() {
    }
    TransactionAdjustment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdjustmentComments: model.AdjustmentComments,
            Amount: model.Amount,
            IsExternalServiceLocationId: model.IsExternalServiceLocationId,
            LocationId: model.LocationId,
            NSFFee: model.NSFFee,
            PatientId: model.PatientId,
            ProviderId: model.ProviderId,
            Reference: model.Reference,
            ServiceLocationId: model.ServiceLocationId,
            TransactionComments: model.TransactionComments,
            TransactionId: model.TransactionId,
            TransactionSubTypeId: model.TransactionSubTypeId,
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    TransactionAdjustment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdjustmentComments: model.AdjustmentComments,
            Amount: model.Amount,
            IsExternalServiceLocationId: model.IsExternalServiceLocationId,
            LocationId: model.LocationId,
            NSFFee: model.NSFFee,
            PatientId: model.PatientId,
            ProviderId: model.ProviderId,
            Reference: model.Reference,
            ServiceLocationId: model.ServiceLocationId,
            TransactionComments: model.TransactionComments,
            TransactionId: model.TransactionId,
            TransactionSubTypeId: model.TransactionSubTypeId,
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    return TransactionAdjustment_Factory;
}());
exports.TransactionAdjustment_Factory = TransactionAdjustment_Factory;
