"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsurancePayer_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var InsurancePayerDetail_1 = require("./InsurancePayerDetail");
var InsurancePayerType_1 = require("./InsurancePayerType");
var PhoneNumber_1 = require("./PhoneNumber");
var InsurancePayer_Factory = /** @class */ (function () {
    function InsurancePayer_Factory() {
    }
    InsurancePayer_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Address: Address_1.Address_Factory.CreateIncoming(model.Address),
            AddressId: model.AddressId,
            ContactName: model.ContactName,
            Details: InsurancePayerDetail_1.InsurancePayerDetail_Factory.CreateIncoming(model.Details),
            GlobalDBEServicesPayerId: model.GlobalDBEServicesPayerId,
            InsurancePayerType: InsurancePayerType_1.InsurancePayerType_Factory.CreateIncoming(model.InsurancePayerType),
            IsElectronicClaimStatusActive: model.IsElectronicClaimStatusActive,
            IsElectronicEligibilityActive: model.IsElectronicEligibilityActive,
            IsPayerStatusActive: model.IsPayerStatusActive,
            Notes: model.Notes,
            PayerIdName: model.PayerIdName,
            PayerName: model.PayerName,
            PayerTypeCode: model.PayerTypeCode,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(model.PhoneNumber),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(i); }),
            PrimaryPhoneNumberId: model.PrimaryPhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            URL: model.URL,
        };
        return result;
    };
    InsurancePayer_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Address: Address_1.Address_Factory.CreateOutgoing(model.Address),
            AddressId: model.AddressId,
            ContactName: model.ContactName,
            Details: InsurancePayerDetail_1.InsurancePayerDetail_Factory.CreateOutgoing(model.Details),
            GlobalDBEServicesPayerId: model.GlobalDBEServicesPayerId,
            InsurancePayerType: InsurancePayerType_1.InsurancePayerType_Factory.CreateOutgoing(model.InsurancePayerType),
            IsElectronicClaimStatusActive: model.IsElectronicClaimStatusActive,
            IsElectronicEligibilityActive: model.IsElectronicEligibilityActive,
            IsPayerStatusActive: model.IsPayerStatusActive,
            Notes: model.Notes,
            PayerIdName: model.PayerIdName,
            PayerName: model.PayerName,
            PayerTypeCode: model.PayerTypeCode,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(model.PhoneNumber),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(i); }),
            PrimaryPhoneNumberId: model.PrimaryPhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            URL: model.URL,
        };
        return result;
    };
    return InsurancePayer_Factory;
}());
exports.InsurancePayer_Factory = InsurancePayer_Factory;
