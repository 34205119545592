"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientWatch_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AppointmentNote_1 = require("./AppointmentNote");
var ClinicalEventWatchDetail_1 = require("./ClinicalEventWatchDetail");
var PatientWatch_Factory = /** @class */ (function () {
    function PatientWatch_Factory() {
    }
    PatientWatch_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentNotes: model.AppointmentNotes && model.AppointmentNotes.map(function (i) { return AppointmentNote_1.AppointmentNote_Factory.CreateIncoming(i); }),
            ClinicalEventWatchDetails: model.ClinicalEventWatchDetails && model.ClinicalEventWatchDetails.map(function (i) { return ClinicalEventWatchDetail_1.ClinicalEventWatchDetail_Factory.CreateIncoming(i); }),
            Description: model.Description,
            DoctorId: model.DoctorId,
            IsCritical: model.IsCritical,
            IsRemoved: model.IsRemoved,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ToothCode: model.ToothCode,
            UserId: model.UserId,
        };
        return result;
    };
    PatientWatch_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentNotes: model.AppointmentNotes && model.AppointmentNotes.map(function (i) { return AppointmentNote_1.AppointmentNote_Factory.CreateOutgoing(i); }),
            ClinicalEventWatchDetails: model.ClinicalEventWatchDetails && model.ClinicalEventWatchDetails.map(function (i) { return ClinicalEventWatchDetail_1.ClinicalEventWatchDetail_Factory.CreateOutgoing(i); }),
            Description: model.Description,
            DoctorId: model.DoctorId,
            IsCritical: model.IsCritical,
            IsRemoved: model.IsRemoved,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ToothCode: model.ToothCode,
            UserId: model.UserId,
        };
        return result;
    };
    return PatientWatch_Factory;
}());
exports.PatientWatch_Factory = PatientWatch_Factory;
