"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserWorkingDay_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var RecordStatus_1 = require("./RecordStatus");
var User_1 = require("./User");
var UserWorkingDayChair_1 = require("./UserWorkingDayChair");
var UserWorkingDayChairTime_1 = require("./UserWorkingDayChairTime");
var UserWorkingDayTime_1 = require("./UserWorkingDayTime");
var UserWorkingDay_Factory = /** @class */ (function () {
    function UserWorkingDay_Factory() {
    }
    UserWorkingDay_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            IsClosed: model.IsClosed,
            IsForOverriddenDate: model.IsForOverriddenDate,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            OverriddenDate: Date_Factory_1.Date_Factory.CreateIncoming(model.OverriddenDate, { ignoreTimePortion: true }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
            UseDefaultOfficeHours: model.UseDefaultOfficeHours,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
            UserWorkingDayChairs: model.UserWorkingDayChairs && model.UserWorkingDayChairs.map(function (i) { return UserWorkingDayChair_1.UserWorkingDayChair_Factory.CreateIncoming(i); }),
            UserWorkingDayChairTimes: model.UserWorkingDayChairTimes && model.UserWorkingDayChairTimes.map(function (i) { return UserWorkingDayChairTime_1.UserWorkingDayChairTime_Factory.CreateIncoming(i); }),
            UserWorkingDayTimes: model.UserWorkingDayTimes && model.UserWorkingDayTimes.map(function (i) { return UserWorkingDayTime_1.UserWorkingDayTime_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    UserWorkingDay_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            IsClosed: model.IsClosed,
            IsForOverriddenDate: model.IsForOverriddenDate,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            OverriddenDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.OverriddenDate, { ignoreTimePortion: true }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
            UseDefaultOfficeHours: model.UseDefaultOfficeHours,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
            UserWorkingDayChairs: model.UserWorkingDayChairs && model.UserWorkingDayChairs.map(function (i) { return UserWorkingDayChair_1.UserWorkingDayChair_Factory.CreateOutgoing(i); }),
            UserWorkingDayChairTimes: model.UserWorkingDayChairTimes && model.UserWorkingDayChairTimes.map(function (i) { return UserWorkingDayChairTime_1.UserWorkingDayChairTime_Factory.CreateOutgoing(i); }),
            UserWorkingDayTimes: model.UserWorkingDayTimes && model.UserWorkingDayTimes.map(function (i) { return UserWorkingDayTime_1.UserWorkingDayTime_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return UserWorkingDay_Factory;
}());
exports.UserWorkingDay_Factory = UserWorkingDay_Factory;
