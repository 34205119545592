"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCatDetails_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCatDetails_Factory = /** @class */ (function () {
    function NHSCatDetails_Factory() {
    }
    NHSCatDetails_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {};
        return result;
    };
    NHSCatDetails_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {};
        return result;
    };
    return NHSCatDetails_Factory;
}());
exports.NHSCatDetails_Factory = NHSCatDetails_Factory;
