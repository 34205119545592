"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToothSurfaceNumberType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ToothSurfaceNumberType_Factory = /** @class */ (function () {
    function ToothSurfaceNumberType_Factory() {
    }
    ToothSurfaceNumberType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Count: model.Count,
            CountOrGreater: model.CountOrGreater,
            Description: model.Description,
        };
        return result;
    };
    ToothSurfaceNumberType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Count: model.Count,
            CountOrGreater: model.CountOrGreater,
            Description: model.Description,
        };
        return result;
    };
    return ToothSurfaceNumberType_Factory;
}());
exports.ToothSurfaceNumberType_Factory = ToothSurfaceNumberType_Factory;
