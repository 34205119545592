"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientAllergy_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AllergyType_1 = require("./AllergyType");
var PatientAllergyVersion_1 = require("./PatientAllergyVersion");
var PatientAllergy_Factory = /** @class */ (function () {
    function PatientAllergy_Factory() {
    }
    PatientAllergy_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AllergyType: AllergyType_1.AllergyType_Factory.CreateIncoming(model.AllergyType),
            AllergyTypeId: model.AllergyTypeId,
            PatientAllergyVersions: model.PatientAllergyVersions && model.PatientAllergyVersions.map(function (i) { return PatientAllergyVersion_1.PatientAllergyVersion_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientAllergy_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AllergyType: AllergyType_1.AllergyType_Factory.CreateOutgoing(model.AllergyType),
            AllergyTypeId: model.AllergyTypeId,
            PatientAllergyVersions: model.PatientAllergyVersions && model.PatientAllergyVersions.map(function (i) { return PatientAllergyVersion_1.PatientAllergyVersion_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientAllergy_Factory;
}());
exports.PatientAllergy_Factory = PatientAllergy_Factory;
