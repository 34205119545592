"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreatmentPlanNote_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Note_1 = require("./Note");
var TreatmentPlanNoteType_1 = require("./TreatmentPlanNoteType");
var TreatmentPlanNote_Factory = /** @class */ (function () {
    function TreatmentPlanNote_Factory() {
    }
    TreatmentPlanNote_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            FeeChangeAmount: model.FeeChangeAmount,
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateIncoming(model.NoteTakenOn),
            NoteTypeCode: model.NoteTypeCode,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TreatmentPlanNoteType: TreatmentPlanNoteType_1.TreatmentPlanNoteType_Factory.CreateIncoming(model.TreatmentPlanNoteType),
        };
        return result;
    };
    TreatmentPlanNote_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            FeeChangeAmount: model.FeeChangeAmount,
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.NoteTakenOn),
            NoteTypeCode: model.NoteTypeCode,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TreatmentPlanNoteType: TreatmentPlanNoteType_1.TreatmentPlanNoteType_Factory.CreateOutgoing(model.TreatmentPlanNoteType),
        };
        return result;
    };
    return TreatmentPlanNote_Factory;
}());
exports.TreatmentPlanNote_Factory = TreatmentPlanNote_Factory;
