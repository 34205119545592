"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceLocationTransaction_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var ServiceLocation_1 = require("./ServiceLocation");
var Transaction_1 = require("./Transaction");
var ServiceLocationTransaction_Factory = /** @class */ (function () {
    function ServiceLocationTransaction_Factory() {
    }
    ServiceLocationTransaction_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            PracticeLocation: Location_1.Location_Factory.CreateIncoming(model.PracticeLocation),
            PracticeLocationId: model.PracticeLocationId,
            RecordId: model.RecordId,
            ServiceLocation: ServiceLocation_1.ServiceLocation_Factory.CreateIncoming(model.ServiceLocation),
            ServiceLocationId: model.ServiceLocationId,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    ServiceLocationTransaction_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            PracticeLocation: Location_1.Location_Factory.CreateOutgoing(model.PracticeLocation),
            PracticeLocationId: model.PracticeLocationId,
            RecordId: model.RecordId,
            ServiceLocation: ServiceLocation_1.ServiceLocation_Factory.CreateOutgoing(model.ServiceLocation),
            ServiceLocationId: model.ServiceLocationId,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return ServiceLocationTransaction_Factory;
}());
exports.ServiceLocationTransaction_Factory = ServiceLocationTransaction_Factory;
