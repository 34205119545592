"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstateReferralAuditLog_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var EstateReferral_1 = require("./EstateReferral");
var EstateReferralRejectionReason_1 = require("./EstateReferralRejectionReason");
var EnterpriseLocation_1 = require("./EnterpriseLocation");
var Location_1 = require("./Location");
var Patient_1 = require("./Patient");
var PatientProcedure_1 = require("./PatientProcedure");
var EstateReferralAuditLog_Factory = /** @class */ (function () {
    function EstateReferralAuditLog_Factory() {
    }
    EstateReferralAuditLog_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            Doctor: User_1.User_Factory.CreateIncoming(model.Doctor),
            DoctorId: model.DoctorId,
            EnterpriseConsultationSubjectLinkId: model.EnterpriseConsultationSubjectLinkId,
            EstateReferral: EstateReferral_1.EstateReferral_Factory.CreateIncoming(model.EstateReferral),
            EstateReferralId: model.EstateReferralId,
            EstateReferralRejectionReason: EstateReferralRejectionReason_1.EstateReferralRejectionReason_Factory.CreateIncoming(model.EstateReferralRejectionReason),
            EstateReferralRejectionReasonId: model.EstateReferralRejectionReasonId,
            IsUrgent: model.IsUrgent,
            NotesToDoctor: model.NotesToDoctor,
            NotesToReceptionist: model.NotesToReceptionist,
            OriginatingEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateIncoming(model.OriginatingEnterpriseLocation),
            OriginatingEnterpriseLocationId: model.OriginatingEnterpriseLocationId,
            OriginatingLocation: Location_1.Location_Factory.CreateIncoming(model.OriginatingLocation),
            OriginatingLocationId: model.OriginatingLocationId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralDateRange: model.ReferralDateRange,
            ReferralDateUnit: model.ReferralDateUnit,
            ReferralEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateIncoming(model.ReferralEnterpriseLocation),
            ReferralEnterpriseLocationId: model.ReferralEnterpriseLocationId,
            ReferralLocation: Location_1.Location_Factory.CreateIncoming(model.ReferralLocation),
            ReferralLocationId: model.ReferralLocationId,
            ReferralNumberOfDateUnits: model.ReferralNumberOfDateUnits,
            ReferralStatus: model.ReferralStatus,
            ReferralToSummary: model.ReferralToSummary,
            ReferredPatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(model.ReferredPatientProcedure),
            ReferredPatientProcedureId: model.ReferredPatientProcedureId,
            SummaryLinkedObjectsJson: model.SummaryLinkedObjectsJson,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    EstateReferralAuditLog_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            Doctor: User_1.User_Factory.CreateOutgoing(model.Doctor),
            DoctorId: model.DoctorId,
            EnterpriseConsultationSubjectLinkId: model.EnterpriseConsultationSubjectLinkId,
            EstateReferral: EstateReferral_1.EstateReferral_Factory.CreateOutgoing(model.EstateReferral),
            EstateReferralId: model.EstateReferralId,
            EstateReferralRejectionReason: EstateReferralRejectionReason_1.EstateReferralRejectionReason_Factory.CreateOutgoing(model.EstateReferralRejectionReason),
            EstateReferralRejectionReasonId: model.EstateReferralRejectionReasonId,
            IsUrgent: model.IsUrgent,
            NotesToDoctor: model.NotesToDoctor,
            NotesToReceptionist: model.NotesToReceptionist,
            OriginatingEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateOutgoing(model.OriginatingEnterpriseLocation),
            OriginatingEnterpriseLocationId: model.OriginatingEnterpriseLocationId,
            OriginatingLocation: Location_1.Location_Factory.CreateOutgoing(model.OriginatingLocation),
            OriginatingLocationId: model.OriginatingLocationId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralDateRange: model.ReferralDateRange,
            ReferralDateUnit: model.ReferralDateUnit,
            ReferralEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateOutgoing(model.ReferralEnterpriseLocation),
            ReferralEnterpriseLocationId: model.ReferralEnterpriseLocationId,
            ReferralLocation: Location_1.Location_Factory.CreateOutgoing(model.ReferralLocation),
            ReferralLocationId: model.ReferralLocationId,
            ReferralNumberOfDateUnits: model.ReferralNumberOfDateUnits,
            ReferralStatus: model.ReferralStatus,
            ReferralToSummary: model.ReferralToSummary,
            ReferredPatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(model.ReferredPatientProcedure),
            ReferredPatientProcedureId: model.ReferredPatientProcedureId,
            SummaryLinkedObjectsJson: model.SummaryLinkedObjectsJson,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return EstateReferralAuditLog_Factory;
}());
exports.EstateReferralAuditLog_Factory = EstateReferralAuditLog_Factory;
