"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracItemOption_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracLab_1 = require("./LabtracLab");
var LabtracItemOption_Factory = /** @class */ (function () {
    function LabtracItemOption_Factory() {
    }
    LabtracItemOption_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ExternalId: model.ExternalId,
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateIncoming(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    LabtracItemOption_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ExternalId: model.ExternalId,
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateOutgoing(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return LabtracItemOption_Factory;
}());
exports.LabtracItemOption_Factory = LabtracItemOption_Factory;
