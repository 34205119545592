"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImplantDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ImplantBrand_1 = require("./ImplantBrand");
var ImplantBrandSize_1 = require("./ImplantBrandSize");
var ImplantDetailVersion_1 = require("./ImplantDetailVersion");
var ImplantManufacturer_1 = require("./ImplantManufacturer");
var PatientProcedure_1 = require("./PatientProcedure");
var Referral_1 = require("./Referral");
var ImplantDetail_Factory = /** @class */ (function () {
    function ImplantDetail_Factory() {
    }
    ImplantDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ActiveReferralId: model.ActiveReferralId,
            ImplantBrand: ImplantBrand_1.ImplantBrand_Factory.CreateIncoming(model.ImplantBrand),
            ImplantBrandId: model.ImplantBrandId,
            ImplantBrandSize: ImplantBrandSize_1.ImplantBrandSize_Factory.CreateIncoming(model.ImplantBrandSize),
            ImplantDetailVersions: model.ImplantDetailVersions && model.ImplantDetailVersions.map(function (i) { return ImplantDetailVersion_1.ImplantDetailVersion_Factory.CreateIncoming(i); }),
            ImplantManufactureId: model.ImplantManufactureId,
            ImplantManufacturer: ImplantManufacturer_1.ImplantManufacturer_Factory.CreateIncoming(model.ImplantManufacturer),
            ImplantRecordId: model.ImplantRecordId,
            ImplantSizeId: model.ImplantSizeId,
            InsertDate: Date_Factory_1.Date_Factory.CreateIncoming(model.InsertDate),
            LatestVersionNumber: model.LatestVersionNumber,
            Lot: model.Lot,
            Note: model.Note,
            PatientId: model.PatientId,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referral: Referral_1.Referral_Factory.CreateIncoming(model.Referral),
            RemovalDate: Date_Factory_1.Date_Factory.CreateIncoming(model.RemovalDate),
            UserId: model.UserId,
        };
        return result;
    };
    ImplantDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ActiveReferralId: model.ActiveReferralId,
            ImplantBrand: ImplantBrand_1.ImplantBrand_Factory.CreateOutgoing(model.ImplantBrand),
            ImplantBrandId: model.ImplantBrandId,
            ImplantBrandSize: ImplantBrandSize_1.ImplantBrandSize_Factory.CreateOutgoing(model.ImplantBrandSize),
            ImplantDetailVersions: model.ImplantDetailVersions && model.ImplantDetailVersions.map(function (i) { return ImplantDetailVersion_1.ImplantDetailVersion_Factory.CreateOutgoing(i); }),
            ImplantManufactureId: model.ImplantManufactureId,
            ImplantManufacturer: ImplantManufacturer_1.ImplantManufacturer_Factory.CreateOutgoing(model.ImplantManufacturer),
            ImplantRecordId: model.ImplantRecordId,
            ImplantSizeId: model.ImplantSizeId,
            InsertDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.InsertDate),
            LatestVersionNumber: model.LatestVersionNumber,
            Lot: model.Lot,
            Note: model.Note,
            PatientId: model.PatientId,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referral: Referral_1.Referral_Factory.CreateOutgoing(model.Referral),
            RemovalDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.RemovalDate),
            UserId: model.UserId,
        };
        return result;
    };
    return ImplantDetail_Factory;
}());
exports.ImplantDetail_Factory = ImplantDetail_Factory;
