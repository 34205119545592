"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Note_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NoteVersion_1 = require("./NoteVersion");
var PatientConcern_1 = require("./PatientConcern");
var PatientNoteType_1 = require("./PatientNoteType");
var RecordStatus_1 = require("./RecordStatus");
var Note_Factory = /** @class */ (function () {
    function Note_Factory() {
    }
    Note_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CreatedFromTemplateId: model.CreatedFromTemplateId,
            LatestVersion: model.LatestVersion,
            NoteVersions: model.NoteVersions && model.NoteVersions.map(function (i) { return NoteVersion_1.NoteVersion_Factory.CreateIncoming(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            PatientNoteSubTypeId: model.PatientNoteSubTypeId,
            PatientNoteType: PatientNoteType_1.PatientNoteType_Factory.CreateIncoming(model.PatientNoteType),
            PatientNoteTypeId: model.PatientNoteTypeId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
        };
        return result;
    };
    Note_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CreatedFromTemplateId: model.CreatedFromTemplateId,
            LatestVersion: model.LatestVersion,
            NoteVersions: model.NoteVersions && model.NoteVersions.map(function (i) { return NoteVersion_1.NoteVersion_Factory.CreateOutgoing(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            PatientNoteSubTypeId: model.PatientNoteSubTypeId,
            PatientNoteType: PatientNoteType_1.PatientNoteType_Factory.CreateOutgoing(model.PatientNoteType),
            PatientNoteTypeId: model.PatientNoteTypeId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
        };
        return result;
    };
    return Note_Factory;
}());
exports.Note_Factory = Note_Factory;
