"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaimBatchSubmission_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var InsuranceClaimBatchSubmission_Factory = /** @class */ (function () {
    function InsuranceClaimBatchSubmission_Factory() {
    }
    InsuranceClaimBatchSubmission_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BatchNumber: model.BatchNumber,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    InsuranceClaimBatchSubmission_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BatchNumber: model.BatchNumber,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return InsuranceClaimBatchSubmission_Factory;
}());
exports.InsuranceClaimBatchSubmission_Factory = InsuranceClaimBatchSubmission_Factory;
