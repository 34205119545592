"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralSource_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CorrespondenceTemplateFormatType_1 = require("./CorrespondenceTemplateFormatType");
var Person_1 = require("./Person");
var ReferralSourceImplantBrand_1 = require("./ReferralSourceImplantBrand");
var Lab_1 = require("./Lab");
var ReferralSourcePracticeName_1 = require("./ReferralSourcePracticeName");
var ReferralSourceSpecialty_1 = require("./ReferralSourceSpecialty");
var ReferralSourceTransactions_1 = require("./ReferralSourceTransactions");
var Tag_1 = require("./Tag");
var ReferralSource_Factory = /** @class */ (function () {
    function ReferralSource_Factory() {
    }
    ReferralSource_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalNotes: model.ClinicalNotes,
            CorrespondenceTemplateFormatType: CorrespondenceTemplateFormatType_1.CorrespondenceTemplateFormatType_Factory.CreateIncoming(model.CorrespondenceTemplateFormatType),
            CorrespondenceTypePref: model.CorrespondenceTypePref,
            DateOfBirthDay: model.DateOfBirthDay,
            DateOfBirthMonth: model.DateOfBirthMonth,
            FamilyDescription: model.FamilyDescription,
            GeneralNotes: model.GeneralNotes,
            NPI: model.NPI,
            Person: Person_1.Person_Factory.CreateIncoming(model.Person),
            PersonId: model.PersonId,
            PreferredImplantBrands: model.PreferredImplantBrands && model.PreferredImplantBrands.map(function (i) { return ReferralSourceImplantBrand_1.ReferralSourceImplantBrand_Factory.CreateIncoming(i); }),
            PreferredLabs: model.PreferredLabs && model.PreferredLabs.map(function (i) { return Lab_1.Lab_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralSourcePracticeName: ReferralSourcePracticeName_1.ReferralSourcePracticeName_Factory.CreateIncoming(model.ReferralSourcePracticeName),
            ReferralSourcePracticeNameId: model.ReferralSourcePracticeNameId,
            ReferralSourceSpecialty: ReferralSourceSpecialty_1.ReferralSourceSpecialty_Factory.CreateIncoming(model.ReferralSourceSpecialty),
            ReferralSourceTransactions: model.ReferralSourceTransactions && model.ReferralSourceTransactions.map(function (i) { return ReferralSourceTransactions_1.ReferralSourceTransactions_Factory.CreateIncoming(i); }),
            SpecialtyId: model.SpecialtyId,
            Tags: model.Tags && model.Tags.map(function (i) { return Tag_1.Tag_Factory.CreateIncoming(i); }),
            UPN: model.UPN,
        };
        return result;
    };
    ReferralSource_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalNotes: model.ClinicalNotes,
            CorrespondenceTemplateFormatType: CorrespondenceTemplateFormatType_1.CorrespondenceTemplateFormatType_Factory.CreateOutgoing(model.CorrespondenceTemplateFormatType),
            CorrespondenceTypePref: model.CorrespondenceTypePref,
            DateOfBirthDay: model.DateOfBirthDay,
            DateOfBirthMonth: model.DateOfBirthMonth,
            FamilyDescription: model.FamilyDescription,
            GeneralNotes: model.GeneralNotes,
            NPI: model.NPI,
            Person: Person_1.Person_Factory.CreateOutgoing(model.Person),
            PersonId: model.PersonId,
            PreferredImplantBrands: model.PreferredImplantBrands && model.PreferredImplantBrands.map(function (i) { return ReferralSourceImplantBrand_1.ReferralSourceImplantBrand_Factory.CreateOutgoing(i); }),
            PreferredLabs: model.PreferredLabs && model.PreferredLabs.map(function (i) { return Lab_1.Lab_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralSourcePracticeName: ReferralSourcePracticeName_1.ReferralSourcePracticeName_Factory.CreateOutgoing(model.ReferralSourcePracticeName),
            ReferralSourcePracticeNameId: model.ReferralSourcePracticeNameId,
            ReferralSourceSpecialty: ReferralSourceSpecialty_1.ReferralSourceSpecialty_Factory.CreateOutgoing(model.ReferralSourceSpecialty),
            ReferralSourceTransactions: model.ReferralSourceTransactions && model.ReferralSourceTransactions.map(function (i) { return ReferralSourceTransactions_1.ReferralSourceTransactions_Factory.CreateOutgoing(i); }),
            SpecialtyId: model.SpecialtyId,
            Tags: model.Tags && model.Tags.map(function (i) { return Tag_1.Tag_Factory.CreateOutgoing(i); }),
            UPN: model.UPN,
        };
        return result;
    };
    return ReferralSource_Factory;
}());
exports.ReferralSource_Factory = ReferralSource_Factory;
