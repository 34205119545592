"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionPendingSplit_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Patient_1 = require("./Patient");
var Transaction_1 = require("./Transaction");
var TransactionPendingSplit_Factory = /** @class */ (function () {
    function TransactionPendingSplit_Factory() {
    }
    TransactionPendingSplit_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Transaction: Transaction_1.Transaction_Factory.CreateIncoming(model.Transaction),
            TransactionId: model.TransactionId,
        };
        return result;
    };
    TransactionPendingSplit_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Transaction: Transaction_1.Transaction_Factory.CreateOutgoing(model.Transaction),
            TransactionId: model.TransactionId,
        };
        return result;
    };
    return TransactionPendingSplit_Factory;
}());
exports.TransactionPendingSplit_Factory = TransactionPendingSplit_Factory;
