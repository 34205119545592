"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracDoctor_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracAddress_1 = require("./LabtracAddress");
var LabtracCase_1 = require("./LabtracCase");
var LabtracLab_1 = require("./LabtracLab");
var User_1 = require("./User");
var LabtracDoctor_Factory = /** @class */ (function () {
    function LabtracDoctor_Factory() {
    }
    LabtracDoctor_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppReference: model.AppReference,
            Cod: model.Cod,
            DoctorId: model.DoctorId,
            Email: model.Email,
            ExternalId: model.ExternalId,
            FirstName: model.FirstName,
            LabReference: model.LabReference,
            LabtracAddresses: model.LabtracAddresses && model.LabtracAddresses.map(function (i) { return LabtracAddress_1.LabtracAddress_Factory.CreateIncoming(i); }),
            LabtracCases: model.LabtracCases && model.LabtracCases.map(function (i) { return LabtracCase_1.LabtracCase_Factory.CreateIncoming(i); }),
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateIncoming(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            LastName: model.LastName,
            OnlineStatus: model.OnlineStatus,
            OnlineStatusNotes: model.OnlineStatusNotes,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    LabtracDoctor_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppReference: model.AppReference,
            Cod: model.Cod,
            DoctorId: model.DoctorId,
            Email: model.Email,
            ExternalId: model.ExternalId,
            FirstName: model.FirstName,
            LabReference: model.LabReference,
            LabtracAddresses: model.LabtracAddresses && model.LabtracAddresses.map(function (i) { return LabtracAddress_1.LabtracAddress_Factory.CreateOutgoing(i); }),
            LabtracCases: model.LabtracCases && model.LabtracCases.map(function (i) { return LabtracCase_1.LabtracCase_Factory.CreateOutgoing(i); }),
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateOutgoing(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            LastName: model.LastName,
            OnlineStatus: model.OnlineStatus,
            OnlineStatusNotes: model.OnlineStatusNotes,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return LabtracDoctor_Factory;
}());
exports.LabtracDoctor_Factory = LabtracDoctor_Factory;
