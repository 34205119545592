"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkStatementBatch_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BulkStatementBatchGuarantor_1 = require("./BulkStatementBatchGuarantor");
var BulkStatementBatchPatient_1 = require("./BulkStatementBatchPatient");
var User_1 = require("./User");
var Location_1 = require("./Location");
var BulkStatementBatch_Factory = /** @class */ (function () {
    function BulkStatementBatch_Factory() {
    }
    BulkStatementBatch_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BulkStatementBatchGuarantors: model.BulkStatementBatchGuarantors && model.BulkStatementBatchGuarantors.map(function (i) { return BulkStatementBatchGuarantor_1.BulkStatementBatchGuarantor_Factory.CreateIncoming(i); }),
            BulkStatementBatchPatients: model.BulkStatementBatchPatients && model.BulkStatementBatchPatients.map(function (i) { return BulkStatementBatchPatient_1.BulkStatementBatchPatient_Factory.CreateIncoming(i); }),
            DueDate: Date_Factory_1.Date_Factory.CreateIncoming(model.DueDate),
            EmailedCount: model.EmailedCount,
            FileId: model.FileId,
            GeneratedByUser: User_1.User_Factory.CreateIncoming(model.GeneratedByUser),
            GeneratedByUserId: model.GeneratedByUserId,
            GenerationDate: Date_Factory_1.Date_Factory.CreateIncoming(model.GenerationDate, { ignoreTimePortion: true }),
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            Notes: model.Notes,
            PrintedByUser: User_1.User_Factory.CreateIncoming(model.PrintedByUser),
            PrintedByUserId: model.PrintedByUserId,
            PrintedCount: model.PrintedCount,
            PrintedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.PrintedDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StatementCount: model.StatementCount,
            TotalAmount: model.TotalAmount,
            TypeId: model.TypeId,
        };
        return result;
    };
    BulkStatementBatch_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BulkStatementBatchGuarantors: model.BulkStatementBatchGuarantors && model.BulkStatementBatchGuarantors.map(function (i) { return BulkStatementBatchGuarantor_1.BulkStatementBatchGuarantor_Factory.CreateOutgoing(i); }),
            BulkStatementBatchPatients: model.BulkStatementBatchPatients && model.BulkStatementBatchPatients.map(function (i) { return BulkStatementBatchPatient_1.BulkStatementBatchPatient_Factory.CreateOutgoing(i); }),
            DueDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.DueDate),
            EmailedCount: model.EmailedCount,
            FileId: model.FileId,
            GeneratedByUser: User_1.User_Factory.CreateOutgoing(model.GeneratedByUser),
            GeneratedByUserId: model.GeneratedByUserId,
            GenerationDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.GenerationDate, { ignoreTimePortion: true }),
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            Notes: model.Notes,
            PrintedByUser: User_1.User_Factory.CreateOutgoing(model.PrintedByUser),
            PrintedByUserId: model.PrintedByUserId,
            PrintedCount: model.PrintedCount,
            PrintedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.PrintedDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StatementCount: model.StatementCount,
            TotalAmount: model.TotalAmount,
            TypeId: model.TypeId,
        };
        return result;
    };
    return BulkStatementBatch_Factory;
}());
exports.BulkStatementBatch_Factory = BulkStatementBatch_Factory;
