import { useEffect } from "react";
import { ISignalDispatcher, SignalCallback } from "../../utils/signalDispatcher";

export * from "./HubManager";
export * from "./IHubManager";
//export * from "./TestHub";
export * from "./FormsHub";

export function useHub<T>(signal: ISignalDispatcher<T>, callback: SignalCallback<T>) {
  useEffect(() => {
    let signalHandler = callback;

    signal.addListener(signalHandler);

    function cleanup() {
      signal.removeListener(signalHandler);
    }

    return cleanup;
  }, [signal, callback]);
}
