
export const ssoClaimTypes = {
  email: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
  name: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name", // sso user id
  accountVersion: "urn:csd-sso/accountVersion"
}


export enum ErrorCodes {
  invalidRequest = "invalid_request" as any,
  invalidClient = "invalid_client"  as any,
  invalidGrant = "invalid_grant"  as any,
  unauthorizedClient = "unauthorized_client" as any,
  unsupportedGrantType = "unsupported_grant_type" as any,
  invalidScope = "invalid_scope" as any
}

export interface IAuthClaim {
    type: string;
    value: string;
}

export interface IAuthTokenInfo {
    token_type: string;
    expires_in: number;
    claims: IAuthClaim[];
}


export interface IToken {
  tokenType: string;
  token: string;
  expiresOn: Date;
}

export interface ITokens {
  accessToken: IToken;
  refreshToken: IToken;
  state?: any;
}

export interface IAuthTokens extends ITokens {
  claims: IAuthClaim[];
}

export interface IAuthSuccessResponse {
  token_type: string;
  access_token: string;
  expires_in: string;
  refresh_token: string;
  refresh_expires_in: string;
  state?: any;
  //[key: string]: any;
}

export interface IAuthRequest {
  grant_type: string;
  username?: string;
  password?: string;
  refresh_token?: string;
  scope?: string;
}

export interface IAuthFailureResponse {
  error: ErrorCodes;
  error_description?: string;
  error_uri?: string;
}


