"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSContractPeriodMonth_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSContractPeriodMonth_Factory = /** @class */ (function () {
    function NHSContractPeriodMonth_Factory() {
    }
    NHSContractPeriodMonth_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Month: Date_Factory_1.Date_Factory.CreateIncoming(model.Month, { ignoreTimePortion: true }),
            PeriodId: model.PeriodId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    NHSContractPeriodMonth_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Month: Date_Factory_1.Date_Factory.CreateOutgoing(model.Month, { ignoreTimePortion: true }),
            PeriodId: model.PeriodId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return NHSContractPeriodMonth_Factory;
}());
exports.NHSContractPeriodMonth_Factory = NHSContractPeriodMonth_Factory;
