import { IFieldPartBase, IFormTemplateJson } from "../models";
import { FormPartType } from "../models/baseTypes";
import { traverse } from "../../../../utils/jsonUtils";
import { IPrefillData } from "../../../../stores/formsStore";
import { IEFormsTemplate } from "csd.phoenix.models";
import { ensureObjectPathsExit } from "../../../../utils/jsonUtils";


export type IFieldPartDef = IFieldPartBase & { type: FormPartType };

function isTemplateFieldPartDefinition(node: any): node is IFieldPartDef {
  return node && typeof node === 'object' && typeof node.type === 'string' && typeof node.binding === 'string';
}

export function* getTemplateFieldBindings(templateJson: IFormTemplateJson) {
  console.assert(templateJson && Array.isArray(templateJson.parts));
  const parts = templateJson.parts;
  const iterator = traverse(parts);
  for (const item of iterator) {
    if (isTemplateFieldPartDefinition(item.value)) {
      yield item.value;
    }
  }
}

export function augmentFormData(data: IPrefillData, template: IEFormsTemplate) {
  const templateDef = JSON.parse(template.JSONTemplateBody);
  const fieldBindings = Array.from(getTemplateFieldBindings(templateDef));
  const augmentedPrefillData = ensureObjectPathsExit(data, fieldBindings.map(fpb => fpb.binding));
  return augmentedPrefillData;
}

