"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationBulkStatementSetting_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var LocationBulkStatementSetting_Factory = /** @class */ (function () {
    function LocationBulkStatementSetting_Factory() {
    }
    LocationBulkStatementSetting_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AccountType: model.AccountType,
            AmountDueTypeCode: model.AmountDueTypeCode,
            DueDateDays: model.DueDateDays,
            GenerateByGuarantor: model.GenerateByGuarantor,
            LastPayment: model.LastPayment,
            LastPaymentDays: model.LastPaymentDays,
            LastSent: model.LastSent,
            LastSentDays: model.LastSentDays,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            MinimumBalance: model.MinimumBalance,
            MinimumBalanceAmount: model.MinimumBalanceAmount,
            OutstandingClaim: model.OutstandingClaim,
            OutstandingClaimDays: model.OutstandingClaimDays,
            PatientDueAmount: model.PatientDueAmount,
            PatientStatusType: model.PatientStatusType,
            RecordId: model.RecordId,
            TransactionHistoryDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TransactionHistoryDate, { ignoreTimePortion: true }),
            TransactionHistoryDays: model.TransactionHistoryDays,
            TransactionHistoryTypeCode: model.TransactionHistoryTypeCode,
            TransactionSortAsc: model.TransactionSortAsc,
        };
        return result;
    };
    LocationBulkStatementSetting_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AccountType: model.AccountType,
            AmountDueTypeCode: model.AmountDueTypeCode,
            DueDateDays: model.DueDateDays,
            GenerateByGuarantor: model.GenerateByGuarantor,
            LastPayment: model.LastPayment,
            LastPaymentDays: model.LastPaymentDays,
            LastSent: model.LastSent,
            LastSentDays: model.LastSentDays,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            MinimumBalance: model.MinimumBalance,
            MinimumBalanceAmount: model.MinimumBalanceAmount,
            OutstandingClaim: model.OutstandingClaim,
            OutstandingClaimDays: model.OutstandingClaimDays,
            PatientDueAmount: model.PatientDueAmount,
            PatientStatusType: model.PatientStatusType,
            RecordId: model.RecordId,
            TransactionHistoryDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TransactionHistoryDate, { ignoreTimePortion: true }),
            TransactionHistoryDays: model.TransactionHistoryDays,
            TransactionHistoryTypeCode: model.TransactionHistoryTypeCode,
            TransactionSortAsc: model.TransactionSortAsc,
        };
        return result;
    };
    return LocationBulkStatementSetting_Factory;
}());
exports.LocationBulkStatementSetting_Factory = LocationBulkStatementSetting_Factory;
