"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaimAttachmentFile_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceClaimAttachmentFileType_1 = require("./InsuranceClaimAttachmentFileType");
var InsuranceClaimAttachmentFile_Factory = /** @class */ (function () {
    function InsuranceClaimAttachmentFile_Factory() {
    }
    InsuranceClaimAttachmentFile_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AttachedBoxFileId: model.AttachedBoxFileId,
            AttachmentType: InsuranceClaimAttachmentFileType_1.InsuranceClaimAttachmentFileType_Factory.CreateIncoming(model.AttachmentType),
            AttachmentTypeCode: model.AttachmentTypeCode,
            ImageCode: model.ImageCode,
            ImageOrientation: model.ImageOrientation,
            InsuranceClaimAttachmentId: model.InsuranceClaimAttachmentId,
            Name: model.Name,
            OriginalBoxFileId: model.OriginalBoxFileId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    InsuranceClaimAttachmentFile_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AttachedBoxFileId: model.AttachedBoxFileId,
            AttachmentType: InsuranceClaimAttachmentFileType_1.InsuranceClaimAttachmentFileType_Factory.CreateOutgoing(model.AttachmentType),
            AttachmentTypeCode: model.AttachmentTypeCode,
            ImageCode: model.ImageCode,
            ImageOrientation: model.ImageOrientation,
            InsuranceClaimAttachmentId: model.InsuranceClaimAttachmentId,
            Name: model.Name,
            OriginalBoxFileId: model.OriginalBoxFileId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return InsuranceClaimAttachmentFile_Factory;
}());
exports.InsuranceClaimAttachmentFile_Factory = InsuranceClaimAttachmentFile_Factory;
