"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSTransmissionFile_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSInterchange_1 = require("./NHSInterchange");
var NHSTransmissionFile_Factory = /** @class */ (function () {
    function NHSTransmissionFile_Factory() {
    }
    NHSTransmissionFile_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Destination: model.Destination,
            Direction: model.Direction,
            File: model.File,
            FileNumber: model.FileNumber,
            InterchangeId: model.InterchangeId,
            NHSInterchange: NHSInterchange_1.NHSInterchange_Factory.CreateIncoming(model.NHSInterchange),
            Origin: model.Origin,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReleaseDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ReleaseDate),
        };
        return result;
    };
    NHSTransmissionFile_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Destination: model.Destination,
            Direction: model.Direction,
            File: model.File,
            FileNumber: model.FileNumber,
            InterchangeId: model.InterchangeId,
            NHSInterchange: NHSInterchange_1.NHSInterchange_Factory.CreateOutgoing(model.NHSInterchange),
            Origin: model.Origin,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReleaseDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ReleaseDate),
        };
        return result;
    };
    return NHSTransmissionFile_Factory;
}());
exports.NHSTransmissionFile_Factory = NHSTransmissionFile_Factory;
