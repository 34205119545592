"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAdditionalInfoKSA_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var UserAdditionalInfoKSA_Factory = /** @class */ (function () {
    function UserAdditionalInfoKSA_Factory() {
    }
    UserAdditionalInfoKSA_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClientIdentifierId: model.ClientIdentifierId,
            FirstNameAr: model.FirstNameAr,
            HealthId: model.HealthId,
            IdType: model.IdType,
            LastNameAr: model.LastNameAr,
            MaritalStatus: model.MaritalStatus,
            MaritalStatusCode: model.MaritalStatusCode,
            MiddleNamesAr: model.MiddleNamesAr,
            Nationality: model.Nationality,
            NationalityCode: model.NationalityCode,
            Occupation: model.Occupation,
            PlaceOfBirth: model.PlaceOfBirth,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            Religion: model.Religion,
            ResyncRequired: model.ResyncRequired,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserRecordId: model.UserRecordId,
        };
        return result;
    };
    UserAdditionalInfoKSA_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClientIdentifierId: model.ClientIdentifierId,
            FirstNameAr: model.FirstNameAr,
            HealthId: model.HealthId,
            IdType: model.IdType,
            LastNameAr: model.LastNameAr,
            MaritalStatus: model.MaritalStatus,
            MaritalStatusCode: model.MaritalStatusCode,
            MiddleNamesAr: model.MiddleNamesAr,
            Nationality: model.Nationality,
            NationalityCode: model.NationalityCode,
            Occupation: model.Occupation,
            PlaceOfBirth: model.PlaceOfBirth,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            Religion: model.Religion,
            ResyncRequired: model.ResyncRequired,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserRecordId: model.UserRecordId,
        };
        return result;
    };
    return UserAdditionalInfoKSA_Factory;
}());
exports.UserAdditionalInfoKSA_Factory = UserAdditionalInfoKSA_Factory;
