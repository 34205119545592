"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSActivityBandValue_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSActivityBand_1 = require("./NHSActivityBand");
var NHSActivityValueSet_1 = require("./NHSActivityValueSet");
var NHSActivityBandValue_Factory = /** @class */ (function () {
    function NHSActivityBandValue_Factory() {
    }
    NHSActivityBandValue_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            MaxAgeMonths: model.MaxAgeMonths,
            MinAgeMonths: model.MinAgeMonths,
            NHSActivityBand: NHSActivityBand_1.NHSActivityBand_Factory.CreateIncoming(model.NHSActivityBand),
            NHSActivityBandID: model.NHSActivityBandID,
            NHSActivityBandValueID: model.NHSActivityBandValueID,
            NHSActivityValueSet: NHSActivityValueSet_1.NHSActivityValueSet_Factory.CreateIncoming(model.NHSActivityValueSet),
            NHSActivityValueSetID: model.NHSActivityValueSetID,
            Units: model.Units,
        };
        return result;
    };
    NHSActivityBandValue_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            MaxAgeMonths: model.MaxAgeMonths,
            MinAgeMonths: model.MinAgeMonths,
            NHSActivityBand: NHSActivityBand_1.NHSActivityBand_Factory.CreateOutgoing(model.NHSActivityBand),
            NHSActivityBandID: model.NHSActivityBandID,
            NHSActivityBandValueID: model.NHSActivityBandValueID,
            NHSActivityValueSet: NHSActivityValueSet_1.NHSActivityValueSet_Factory.CreateOutgoing(model.NHSActivityValueSet),
            NHSActivityValueSetID: model.NHSActivityValueSetID,
            Units: model.Units,
        };
        return result;
    };
    return NHSActivityBandValue_Factory;
}());
exports.NHSActivityBandValue_Factory = NHSActivityBandValue_Factory;
