import "./di/config";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { configure as mobx } from "mobx";

mobx({
  enforceActions: "observed",
  reactionRequiresObservable: true,
  computedRequiresReaction: true,  // Experimental
  observableRequiresReaction: true // Experimental
});

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
