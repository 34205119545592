"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrthoContractChargeSchedule_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Transaction_1 = require("./Transaction");
var CDTCode_1 = require("./CDTCode");
var OrthoContract_1 = require("./OrthoContract");
var OrthoContractFrequency_1 = require("./OrthoContractFrequency");
var InsurancePolicy_1 = require("./InsurancePolicy");
var OrthoContractAdditionalCDTCode_1 = require("./OrthoContractAdditionalCDTCode");
var InsuranceClaim_1 = require("./InsuranceClaim");
var ScheduledPayment_1 = require("./ScheduledPayment");
var OrthoContractChargeSchedule_Factory = /** @class */ (function () {
    function OrthoContractChargeSchedule_Factory() {
    }
    OrthoContractChargeSchedule_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            AmountCharged: model.AmountCharged,
            AmountFinanced: model.AmountFinanced,
            AmountRemaining: model.AmountRemaining,
            CDTCodeId: model.CDTCodeId,
            ChargeAmount: model.ChargeAmount,
            ChargeCount: model.ChargeCount,
            ChargeLastDayOfMonth: model.ChargeLastDayOfMonth,
            ChargeTransactions: model.ChargeTransactions && model.ChargeTransactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            ContinuationCDTCode: CDTCode_1.CDTCode_Factory.CreateIncoming(model.ContinuationCDTCode),
            Contract: OrthoContract_1.OrthoContract_Factory.CreateIncoming(model.Contract),
            ContractFrequency: OrthoContractFrequency_1.OrthoContractFrequency_Factory.CreateIncoming(model.ContractFrequency),
            ContractFrequencyCode: model.ContractFrequencyCode,
            ContractId: model.ContractId,
            COTClaimsNotRequired: model.COTClaimsNotRequired,
            DownpaymentAmount: model.DownpaymentAmount,
            DownpaymentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.DownpaymentDate, { ignoreTimePortion: true }),
            DownpaymentTransactions: model.DownpaymentTransactions && model.DownpaymentTransactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            DueDay: model.DueDay,
            FinalChargeAmount: model.FinalChargeAmount,
            FinalChargeDate: Date_Factory_1.Date_Factory.CreateIncoming(model.FinalChargeDate, { ignoreTimePortion: true }),
            InsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateIncoming(model.InsurancePolicy),
            InsurancePolicyId: model.InsurancePolicyId,
            LastContinuationDate: Date_Factory_1.Date_Factory.CreateIncoming(model.LastContinuationDate),
            MostRecentChargeDate: Date_Factory_1.Date_Factory.CreateIncoming(model.MostRecentChargeDate, { ignoreTimePortion: true }),
            NextChargeDate: Date_Factory_1.Date_Factory.CreateIncoming(model.NextChargeDate, { ignoreTimePortion: true }),
            NextChargeDateSaved: Date_Factory_1.Date_Factory.CreateIncoming(model.NextChargeDateSaved, { ignoreTimePortion: true }),
            OrthoContractAdditionalCDTCodes: model.OrthoContractAdditionalCDTCodes && model.OrthoContractAdditionalCDTCodes.map(function (i) { return OrthoContractAdditionalCDTCode_1.OrthoContractAdditionalCDTCode_Factory.CreateIncoming(i); }),
            PreAuthClaim: InsuranceClaim_1.InsuranceClaim_Factory.CreateIncoming(model.PreAuthClaim),
            PreAuthClaimId: model.PreAuthClaimId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RemainingChargeCount: model.RemainingChargeCount,
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    OrthoContractChargeSchedule_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            AmountCharged: model.AmountCharged,
            AmountFinanced: model.AmountFinanced,
            AmountRemaining: model.AmountRemaining,
            CDTCodeId: model.CDTCodeId,
            ChargeAmount: model.ChargeAmount,
            ChargeCount: model.ChargeCount,
            ChargeLastDayOfMonth: model.ChargeLastDayOfMonth,
            ChargeTransactions: model.ChargeTransactions && model.ChargeTransactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            ContinuationCDTCode: CDTCode_1.CDTCode_Factory.CreateOutgoing(model.ContinuationCDTCode),
            Contract: OrthoContract_1.OrthoContract_Factory.CreateOutgoing(model.Contract),
            ContractFrequency: OrthoContractFrequency_1.OrthoContractFrequency_Factory.CreateOutgoing(model.ContractFrequency),
            ContractFrequencyCode: model.ContractFrequencyCode,
            ContractId: model.ContractId,
            COTClaimsNotRequired: model.COTClaimsNotRequired,
            DownpaymentAmount: model.DownpaymentAmount,
            DownpaymentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.DownpaymentDate, { ignoreTimePortion: true }),
            DownpaymentTransactions: model.DownpaymentTransactions && model.DownpaymentTransactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            DueDay: model.DueDay,
            FinalChargeAmount: model.FinalChargeAmount,
            FinalChargeDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.FinalChargeDate, { ignoreTimePortion: true }),
            InsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateOutgoing(model.InsurancePolicy),
            InsurancePolicyId: model.InsurancePolicyId,
            LastContinuationDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastContinuationDate),
            MostRecentChargeDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.MostRecentChargeDate, { ignoreTimePortion: true }),
            NextChargeDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.NextChargeDate, { ignoreTimePortion: true }),
            NextChargeDateSaved: Date_Factory_1.Date_Factory.CreateOutgoing(model.NextChargeDateSaved, { ignoreTimePortion: true }),
            OrthoContractAdditionalCDTCodes: model.OrthoContractAdditionalCDTCodes && model.OrthoContractAdditionalCDTCodes.map(function (i) { return OrthoContractAdditionalCDTCode_1.OrthoContractAdditionalCDTCode_Factory.CreateOutgoing(i); }),
            PreAuthClaim: InsuranceClaim_1.InsuranceClaim_Factory.CreateOutgoing(model.PreAuthClaim),
            PreAuthClaimId: model.PreAuthClaimId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RemainingChargeCount: model.RemainingChargeCount,
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return OrthoContractChargeSchedule_Factory;
}());
exports.OrthoContractChargeSchedule_Factory = OrthoContractChargeSchedule_Factory;
