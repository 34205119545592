"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReferralSource_1 = require("./ReferralSource");
var Tag_Factory = /** @class */ (function () {
    function Tag_Factory() {
    }
    Tag_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            RecordId: model.RecordId,
            ReferralSources: model.ReferralSources && model.ReferralSources.map(function (i) { return ReferralSource_1.ReferralSource_Factory.CreateIncoming(i); }),
            TagValue: model.TagValue,
        };
        return result;
    };
    Tag_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            RecordId: model.RecordId,
            ReferralSources: model.ReferralSources && model.ReferralSources.map(function (i) { return ReferralSource_1.ReferralSource_Factory.CreateOutgoing(i); }),
            TagValue: model.TagValue,
        };
        return result;
    };
    return Tag_Factory;
}());
exports.Tag_Factory = Tag_Factory;
