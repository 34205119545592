"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImplantRecord_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ImplantDetail_1 = require("./ImplantDetail");
var ImplantRecord_Factory = /** @class */ (function () {
    function ImplantRecord_Factory() {
    }
    ImplantRecord_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            EffectiveDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EffectiveDate),
            ImplantDetails: model.ImplantDetails && model.ImplantDetails.map(function (i) { return ImplantDetail_1.ImplantDetail_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    ImplantRecord_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            EffectiveDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EffectiveDate),
            ImplantDetails: model.ImplantDetails && model.ImplantDetails.map(function (i) { return ImplantDetail_1.ImplantDetail_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    return ImplantRecord_Factory;
}());
exports.ImplantRecord_Factory = ImplantRecord_Factory;
