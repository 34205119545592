"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCourseDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSFP17TriageDetail_1 = require("./NHSFP17TriageDetail");
var Location_1 = require("./Location");
var NHSCaseMixRecording_1 = require("./NHSCaseMixRecording");
var NHSClaim_1 = require("./NHSClaim");
var NHSContract_1 = require("./NHSContract");
var NHSCourseClaimMessageInfo_1 = require("./NHSCourseClaimMessageInfo");
var NHSCourseSnapshot_1 = require("./NHSCourseSnapshot");
var NHSExemptCode_1 = require("./NHSExemptCode");
var Transaction_1 = require("./Transaction");
var NHSTreatmentArrangement_1 = require("./NHSTreatmentArrangement");
var TreatmentPlan_1 = require("./TreatmentPlan");
var NHSTriageDetail_1 = require("./NHSTriageDetail");
var User_1 = require("./User");
var NHSCourseDetail_Factory = /** @class */ (function () {
    function NHSCourseDetail_Factory() {
    }
    NHSCourseDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceType: model.AcceptanceType,
            ActivityClaimed: model.ActivityClaimed,
            AdviceOnlyIC: model.AdviceOnlyIC,
            AllChargesRaised: model.AllChargesRaised,
            ArrestOfBleeding: model.ArrestOfBleeding,
            AssessmentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AssessmentDate),
            BabyBorn: Date_Factory_1.Date_Factory.CreateIncoming(model.BabyBorn),
            BestPracticePrevention: model.BestPracticePrevention,
            BPEId: model.BPEId,
            BriefInterventionAlcohol: model.BriefInterventionAlcohol,
            BriefInterventionTobacco: model.BriefInterventionTobacco,
            CariesTreatmentOffered: model.CariesTreatmentOffered,
            CertificateNumber: model.CertificateNumber,
            ChargeBasisDescription: model.ChargeBasisDescription,
            ChargeForReferralTreatment: model.ChargeForReferralTreatment,
            ContinuationNHSCourseDetail: NHSCourseDetail_Factory.CreateIncoming(model.ContinuationNHSCourseDetail),
            ContinuationNHSCourseDetails: model.ContinuationNHSCourseDetails && model.ContinuationNHSCourseDetails.map(function (i) { return NHSCourseDetail_Factory.CreateIncoming(i); }),
            ContinuationOfCourse: model.ContinuationOfCourse,
            ConvertedClaimChargedSoFar: model.ConvertedClaimChargedSoFar,
            CustomOcclusalHard: model.CustomOcclusalHard,
            CustomOcclusalSoft: model.CustomOcclusalSoft,
            DateActivityClaimed: Date_Factory_1.Date_Factory.CreateIncoming(model.DateActivityClaimed),
            DCPUserId: model.DCPUserId,
            DentureRepairs: model.DentureRepairs,
            DietaryChangesAgreed: model.DietaryChangesAgreed,
            DisabilityHandicap: model.DisabilityHandicap,
            Empty: model.Empty,
            Examination: model.Examination,
            ExemptCode: model.ExemptCode,
            ExemptionCheck: model.ExemptionCheck,
            ExemptText: model.ExemptText,
            ExemptUntil: Date_Factory_1.Date_Factory.CreateIncoming(model.ExemptUntil),
            FlexibleCommissioning: model.FlexibleCommissioning,
            FluorideToothpasteAdvice: model.FluorideToothpasteAdvice,
            FP17TriageDetail: NHSFP17TriageDetail_1.NHSFP17TriageDetail_Factory.CreateIncoming(model.FP17TriageDetail),
            FreeExamRuleApplied: model.FreeExamRuleApplied,
            FTR: model.FTR,
            GuaranteedItems: model.GuaranteedItems,
            HasReferredTreatment: model.HasReferredTreatment,
            InterDentalCleaningAids: model.InterDentalCleaningAids,
            IsConvertedClaim: model.IsConvertedClaim,
            IsOrthoCourse: model.IsOrthoCourse,
            IssueOfPrescription: model.IssueOfPrescription,
            IsTraumaCourse: model.IsTraumaCourse,
            IsUnbandedCourse: model.IsUnbandedCourse,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            NHSCaseMixRecordings: model.NHSCaseMixRecordings && model.NHSCaseMixRecordings.map(function (i) { return NHSCaseMixRecording_1.NHSCaseMixRecording_Factory.CreateIncoming(i); }),
            NHSClaims: model.NHSClaims && model.NHSClaims.map(function (i) { return NHSClaim_1.NHSClaim_Factory.CreateIncoming(i); }),
            NHSContract: NHSContract_1.NHSContract_Factory.CreateIncoming(model.NHSContract),
            NHSContractID: model.NHSContractID,
            NHSCourseClaimMessageInfoObjects: model.NHSCourseClaimMessageInfoObjects && model.NHSCourseClaimMessageInfoObjects.map(function (i) { return NHSCourseClaimMessageInfo_1.NHSCourseClaimMessageInfo_Factory.CreateIncoming(i); }),
            NHSCourseSnapshots: model.NHSCourseSnapshots && model.NHSCourseSnapshots.map(function (i) { return NHSCourseSnapshot_1.NHSCourseSnapshot_Factory.CreateIncoming(i); }),
            NHSExemptCode: NHSExemptCode_1.NHSExemptCode_Factory.CreateIncoming(model.NHSExemptCode),
            NICEPeriod: model.NICEPeriod,
            OldSurname: model.OldSurname,
            OralHygieneImprovementPlan: model.OralHygieneImprovementPlan,
            OwnerUserId: model.OwnerUserId,
            PartialExemptAmount: model.PartialExemptAmount,
            PatientPresentedWith: model.PatientPresentedWith,
            PerioTreatmentOffered: model.PerioTreatmentOffered,
            PhasedTreatment: model.PhasedTreatment,
            PIAnalgesics: model.PIAnalgesics,
            PIAntibiotic: model.PIAntibiotic,
            PIAntifungals: model.PIAntifungals,
            PIAnxiolysis: model.PIAnxiolysis,
            PIArtificialSaliva: model.PIArtificialSaliva,
            PIHighFluorideToothpaste: model.PIHighFluorideToothpaste,
            PIHygieneMouthwash: model.PIHygieneMouthwash,
            PIMedicineMouthwash: model.PIMedicineMouthwash,
            PlaqueRetentiveFactorRemoval: model.PlaqueRetentiveFactorRemoval,
            PlaqueScore: model.PlaqueScore,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referral: model.Referral,
            RefusedTreatment: model.RefusedTreatment,
            RemovalOfSutures: model.RemovalOfSutures,
            RepairOfAppliance: model.RepairOfAppliance,
            RepairOfBridges: model.RepairOfBridges,
            ReplacementAppliance: model.ReplacementAppliance,
            SurveyEmailAddress: model.SurveyEmailAddress,
            SurveyMobileNumber: model.SurveyMobileNumber,
            ToothbrushingAdvice: model.ToothbrushingAdvice,
            ToothCount: model.ToothCount,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            TreatmentArrangement: NHSTreatmentArrangement_1.NHSTreatmentArrangement_Factory.CreateIncoming(model.TreatmentArrangement),
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateIncoming(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
            TriageDetail: NHSTriageDetail_1.NHSTriageDetail_Factory.CreateIncoming(model.TriageDetail),
            TwoMonthRuleApplied: model.TwoMonthRuleApplied,
            Twomonthruleoverride: model.Twomonthruleoverride,
            TwoReplacementAppliances: model.TwoReplacementAppliances,
            UnitsNotClaimable: model.UnitsNotClaimable,
            UntreatedDecayedTeeth: model.UntreatedDecayedTeeth,
            Urgent: model.Urgent,
            User: User_1.User_Factory.CreateIncoming(model.User),
            VirtualConsultation: model.VirtualConsultation,
            WelshDecay: model.WelshDecay,
            WelshDental: model.WelshDental,
            WelshMedical: model.WelshMedical,
            WelshOther: model.WelshOther,
            WelshPerio: model.WelshPerio,
            WelshSocial: model.WelshSocial,
        };
        return result;
    };
    NHSCourseDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceType: model.AcceptanceType,
            ActivityClaimed: model.ActivityClaimed,
            AdviceOnlyIC: model.AdviceOnlyIC,
            AllChargesRaised: model.AllChargesRaised,
            ArrestOfBleeding: model.ArrestOfBleeding,
            AssessmentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AssessmentDate),
            BabyBorn: Date_Factory_1.Date_Factory.CreateOutgoing(model.BabyBorn),
            BestPracticePrevention: model.BestPracticePrevention,
            BPEId: model.BPEId,
            BriefInterventionAlcohol: model.BriefInterventionAlcohol,
            BriefInterventionTobacco: model.BriefInterventionTobacco,
            CariesTreatmentOffered: model.CariesTreatmentOffered,
            CertificateNumber: model.CertificateNumber,
            ChargeBasisDescription: model.ChargeBasisDescription,
            ChargeForReferralTreatment: model.ChargeForReferralTreatment,
            ContinuationNHSCourseDetail: NHSCourseDetail_Factory.CreateOutgoing(model.ContinuationNHSCourseDetail),
            ContinuationNHSCourseDetails: model.ContinuationNHSCourseDetails && model.ContinuationNHSCourseDetails.map(function (i) { return NHSCourseDetail_Factory.CreateOutgoing(i); }),
            ContinuationOfCourse: model.ContinuationOfCourse,
            ConvertedClaimChargedSoFar: model.ConvertedClaimChargedSoFar,
            CustomOcclusalHard: model.CustomOcclusalHard,
            CustomOcclusalSoft: model.CustomOcclusalSoft,
            DateActivityClaimed: Date_Factory_1.Date_Factory.CreateOutgoing(model.DateActivityClaimed),
            DCPUserId: model.DCPUserId,
            DentureRepairs: model.DentureRepairs,
            DietaryChangesAgreed: model.DietaryChangesAgreed,
            DisabilityHandicap: model.DisabilityHandicap,
            Empty: model.Empty,
            Examination: model.Examination,
            ExemptCode: model.ExemptCode,
            ExemptionCheck: model.ExemptionCheck,
            ExemptText: model.ExemptText,
            ExemptUntil: Date_Factory_1.Date_Factory.CreateOutgoing(model.ExemptUntil),
            FlexibleCommissioning: model.FlexibleCommissioning,
            FluorideToothpasteAdvice: model.FluorideToothpasteAdvice,
            FP17TriageDetail: NHSFP17TriageDetail_1.NHSFP17TriageDetail_Factory.CreateOutgoing(model.FP17TriageDetail),
            FreeExamRuleApplied: model.FreeExamRuleApplied,
            FTR: model.FTR,
            GuaranteedItems: model.GuaranteedItems,
            HasReferredTreatment: model.HasReferredTreatment,
            InterDentalCleaningAids: model.InterDentalCleaningAids,
            IsConvertedClaim: model.IsConvertedClaim,
            IsOrthoCourse: model.IsOrthoCourse,
            IssueOfPrescription: model.IssueOfPrescription,
            IsTraumaCourse: model.IsTraumaCourse,
            IsUnbandedCourse: model.IsUnbandedCourse,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            NHSCaseMixRecordings: model.NHSCaseMixRecordings && model.NHSCaseMixRecordings.map(function (i) { return NHSCaseMixRecording_1.NHSCaseMixRecording_Factory.CreateOutgoing(i); }),
            NHSClaims: model.NHSClaims && model.NHSClaims.map(function (i) { return NHSClaim_1.NHSClaim_Factory.CreateOutgoing(i); }),
            NHSContract: NHSContract_1.NHSContract_Factory.CreateOutgoing(model.NHSContract),
            NHSContractID: model.NHSContractID,
            NHSCourseClaimMessageInfoObjects: model.NHSCourseClaimMessageInfoObjects && model.NHSCourseClaimMessageInfoObjects.map(function (i) { return NHSCourseClaimMessageInfo_1.NHSCourseClaimMessageInfo_Factory.CreateOutgoing(i); }),
            NHSCourseSnapshots: model.NHSCourseSnapshots && model.NHSCourseSnapshots.map(function (i) { return NHSCourseSnapshot_1.NHSCourseSnapshot_Factory.CreateOutgoing(i); }),
            NHSExemptCode: NHSExemptCode_1.NHSExemptCode_Factory.CreateOutgoing(model.NHSExemptCode),
            NICEPeriod: model.NICEPeriod,
            OldSurname: model.OldSurname,
            OralHygieneImprovementPlan: model.OralHygieneImprovementPlan,
            OwnerUserId: model.OwnerUserId,
            PartialExemptAmount: model.PartialExemptAmount,
            PatientPresentedWith: model.PatientPresentedWith,
            PerioTreatmentOffered: model.PerioTreatmentOffered,
            PhasedTreatment: model.PhasedTreatment,
            PIAnalgesics: model.PIAnalgesics,
            PIAntibiotic: model.PIAntibiotic,
            PIAntifungals: model.PIAntifungals,
            PIAnxiolysis: model.PIAnxiolysis,
            PIArtificialSaliva: model.PIArtificialSaliva,
            PIHighFluorideToothpaste: model.PIHighFluorideToothpaste,
            PIHygieneMouthwash: model.PIHygieneMouthwash,
            PIMedicineMouthwash: model.PIMedicineMouthwash,
            PlaqueRetentiveFactorRemoval: model.PlaqueRetentiveFactorRemoval,
            PlaqueScore: model.PlaqueScore,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referral: model.Referral,
            RefusedTreatment: model.RefusedTreatment,
            RemovalOfSutures: model.RemovalOfSutures,
            RepairOfAppliance: model.RepairOfAppliance,
            RepairOfBridges: model.RepairOfBridges,
            ReplacementAppliance: model.ReplacementAppliance,
            SurveyEmailAddress: model.SurveyEmailAddress,
            SurveyMobileNumber: model.SurveyMobileNumber,
            ToothbrushingAdvice: model.ToothbrushingAdvice,
            ToothCount: model.ToothCount,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            TreatmentArrangement: NHSTreatmentArrangement_1.NHSTreatmentArrangement_Factory.CreateOutgoing(model.TreatmentArrangement),
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateOutgoing(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
            TriageDetail: NHSTriageDetail_1.NHSTriageDetail_Factory.CreateOutgoing(model.TriageDetail),
            TwoMonthRuleApplied: model.TwoMonthRuleApplied,
            Twomonthruleoverride: model.Twomonthruleoverride,
            TwoReplacementAppliances: model.TwoReplacementAppliances,
            UnitsNotClaimable: model.UnitsNotClaimable,
            UntreatedDecayedTeeth: model.UntreatedDecayedTeeth,
            Urgent: model.Urgent,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            VirtualConsultation: model.VirtualConsultation,
            WelshDecay: model.WelshDecay,
            WelshDental: model.WelshDental,
            WelshMedical: model.WelshMedical,
            WelshOther: model.WelshOther,
            WelshPerio: model.WelshPerio,
            WelshSocial: model.WelshSocial,
        };
        return result;
    };
    return NHSCourseDetail_Factory;
}());
exports.NHSCourseDetail_Factory = NHSCourseDetail_Factory;
