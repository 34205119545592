import { RecordStatusCodes } from "csd.phoenix.models";
import { IEFormsTemplate, EFormsTemplate_Factory } from "csd.phoenix.models/EFormsTemplate";
import { IEFormsInstance } from "csd.phoenix.models/EFormsInstance";
import { loadConfig } from "../../services/appConfigService";
import http from "../../services/api/http";
import AsyncLruCache from "../../utils/AsyncLruCache";
import { EMPTY_GUID } from "../../utils/objectUtils";
import { getLocale } from '../../i18n';
import { EFormsTemplateSourceTypes } from "csd.phoenix.models/EFormsTemplateSourceTypes";



const fifteenMinutes = 1000 * 60 * 15;

export interface IEFormsTemplateLight extends Omit<IEFormsTemplate, "JSONTemplateBody"> {
}

export interface IEFormsInstanceLight extends Omit<IEFormsInstance, "JSONBody" | "OriginalJSONBody"> {
}


const devTemplateName = "[Local Dev Template]";

const devTemplateJsonPlaceholder = JSON.stringify(
  {
    "name": "DevTemplate",
    "locale": getLocale(),
    "parts": [
      {
        "type": "content",
        "content": ["Create and update local template file (components/shared/form/dynamicForm/data/_DevTemplate_.json) to see changes here."]
      }
    ]
  }
);

export async function getLocalTemplateJson(templateName: string) {
  let json: string | undefined = undefined;

  const { useLocalTemplateJson } = await loadConfig();
  if ((useLocalTemplateJson || templateName === devTemplateName) && process.env.NODE_ENV !== "production") {
    let fileName;
    switch (templateName.toLocaleLowerCase()) {
      case devTemplateName.toLocaleLowerCase():
        fileName = "_DevTemplate_";
        break;

      case "patient registration":
        fileName = "NewPatient";
        break;

      case "hipaa privacy":
        fileName = "HipaaPrivacy";
        break;
    }

    try {
      const jsonStr = await import("../../components/shared/form/dynamicForm/data/" + fileName + ".json");
      json = JSON.stringify(jsonStr);
    } catch (error) {
      json = devTemplateJsonPlaceholder;
    }

  }

  if (json) {
    console.info("-----> Using local JSON for: " + templateName);
  }

  return json;
}

//#region Template List
async function loadTemplateList() {
  const { apiUrl } = await loadConfig();
  //console.info("Loading template list.")
  const url = apiUrl
    .segment(`eforms/templates`)
    .param({ includeInactive: true }) // We must include `inactive` since we may need those to show old/archived items.
    .toString();
  let { data } = await http.get<IEFormsTemplate[]>(url);
  data = data.map(t => EFormsTemplate_Factory.CreateIncoming(t)!)
  return data;
}

const templateListCache = new AsyncLruCache<"_", IEFormsTemplateLight[]>({ maxItems: 1000, expireAfter: fifteenMinutes, promiseFactory: loadTemplateList })

export const getTemplateList = () => templateListCache.get("_");

export const getActiveTemplateList = () => getTemplateList().then(templates => templates.filter(t => t.RecordStatus === RecordStatusCodes.Active));

export const getTemplate = (templateId: string) => getTemplateList().then(templates => templates.find(t => t.RecordId === templateId));
//#endregion



let _devTemplate: IEFormsTemplate;

async function getLocalDevTemplate() {
  if (_devTemplate) {
    return _devTemplate;
  }

  const json = (await getLocalTemplateJson(devTemplateName))!;

  const template: IEFormsTemplate = {
    TemplateName: devTemplateName,
    RecordId: EMPTY_GUID,
    RecordCreated: new Date(),
    RecordLastUpdated: new Date(),
    RecordStatus: 1,
    CreatedBy: "cb2b9ecb-b87f-443a-8ba1-012753c6d242",
    IsInUse: false,
    IsPublic: true,
    JSONTemplateBody: json,
    UICulture: JSON.parse(json)["UICulture"] as string,
    Ordinal: 1,
    IsWriteBack: false,
    TenantLocaleId: JSON.parse(json)["TenantLocaleId"] as number,
    SourceTypeId: EFormsTemplateSourceTypes.PatientForms
  };

  return (_devTemplate = template);
}


//#region Private Template Detail
async function loadPrivateTemplateDetails(templateId: string) {
  if (templateId === EMPTY_GUID) {
    return getLocalDevTemplate();
  }

  console.info(`Loading private  template details. Template: ${templateId} `);
  const { apiUrl } = await loadConfig();
  const url = apiUrl
    .segment(`eforms/templates/${templateId}`)
    .toString();

  let { data } = await http.get<IEFormsTemplate>(url);
  data = EFormsTemplate_Factory.CreateIncoming(data)!;
  data.JSONTemplateBody = (await getLocalTemplateJson(data.TemplateName)) || data.JSONTemplateBody;
  return data;
}

const privateTemplateDetailsCache = new AsyncLruCache<string, IEFormsTemplate>(
  {
    maxItems: 1000,
    expireAfter: fifteenMinutes,
    promiseFactory: loadPrivateTemplateDetails,

  })

export const getPrivateTemplateDetails = async (templateId: string) => privateTemplateDetailsCache.get(templateId);
//#endregion


//#region Public Template List
async function loadPublicTemplateList(tenantInstallationId: string) {
  const { apiUrl } = await loadConfig();
  console.info("Loading public template list.")
  const url = apiUrl
    .segment(`eforms/external/templates`)
    .param({ tenantInstallationId })
    .toString();
  let { data } = await http.get<IEFormsTemplate[]>(url);
  const templates = data.map(t => EFormsTemplate_Factory.CreateIncoming(t)!);
  const map = new Map<string, IEFormsTemplateLight>(templates.map(t => [t.RecordId!, t]));
  return map;
}

const publicTemplateListCache = new AsyncLruCache<string, Map<string, IEFormsTemplateLight>>(
  {
    maxItems: 1,
    expireAfter: fifteenMinutes,
    promiseFactory: loadPublicTemplateList
  })

export const getPublicTemplateList = (tenantInstallationId: string) =>
  publicTemplateListCache.get(tenantInstallationId).then(map => [...map.values()]);
//#endregion



//#region Public Template Detail
async function loadPublicTemplateDetails(complexKey: string) {
  const [tenantInstallationId, templateId] = complexKey.split("|");
  if (templateId === EMPTY_GUID) {
    return getLocalDevTemplate();
  }

  console.info(`Loading public template details. Tenant: ${tenantInstallationId}, template: ${templateId} `);
  const { apiUrl } = await loadConfig();
  const url = apiUrl
    .segment(`eforms/external/templates/${templateId}`)
    .param({ tenantInstallationId })
    .toString();

  let { data } = await http.get<IEFormsTemplate>(url);
  data = EFormsTemplate_Factory.CreateIncoming(data)!;
  data.JSONTemplateBody = (await getLocalTemplateJson(data.TemplateName)) || data.JSONTemplateBody;
  return data;
}

const publicTemplateDetailsCache = new AsyncLruCache<string, IEFormsTemplate>(
  {
    maxItems: 1000,
    expireAfter: fifteenMinutes,
    promiseFactory: loadPublicTemplateDetails,
  })

export const getPublicTemplateDetails = async (tenantInstallationId: string, templateId: string) =>
  publicTemplateDetailsCache.get(`${tenantInstallationId}|${templateId}`);
//#endregion


//#region Save Template
export async function saveTemplate(template: IEFormsTemplate) {
  //console.info("Saving template Name: " + template.RecordId, JSON.parse(template.JSONTemplateBody));
  const { apiUrl } = await loadConfig();
  const url = apiUrl
    .segment(`eforms/templates`)
    .toString();

  let { data } = await http.post<IEFormsTemplate>(url, template);

  data = EFormsTemplate_Factory.CreateIncoming(data)!;
  // Caching the JSON.
  privateTemplateDetailsCache.setExplicitly(data.RecordId!, data);

  // Caching the Non-JSON fields.
  const { JSONTemplateBody, ...newTemplate } = data;

  var templateList = await templateListCache.get("_");

  //console.log("Before templateList", templateList);

  // If the recordid is there then update that item.
  // If the not then add the item.
  const index = templateList.map(x => x.RecordId).indexOf(newTemplate.RecordId);
  index >= 0 ? templateList.splice(index, 1, newTemplate) : templateList.push(newTemplate);
  //console.log("After templateList", templateList);

  // if (newTemplate.IsPublic)
  // {
  //   const map = await publicTemplateListCache.get(tiid);
  //   map && map.set(newTemplate.RecordId!, newTemplate);
  // }

  return data;
}


//#endregion

