"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewCharge_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceClaim_1 = require("./InsuranceClaim");
var InsurancePolicy_1 = require("./InsurancePolicy");
var NHSCourseChargesOutstanding_1 = require("./NHSCourseChargesOutstanding");
var NewChargeProcedure_1 = require("./NewChargeProcedure");
var NewCharge_Factory = /** @class */ (function () {
    function NewCharge_Factory() {
    }
    NewCharge_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            AppointmentId: model.AppointmentId,
            InsuranceClaims: model.InsuranceClaims && model.InsuranceClaims.map(function (i) { return InsuranceClaim_1.InsuranceClaim_Factory.CreateIncoming(i); }),
            InsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateIncoming(model.InsurancePolicy),
            NHSCharges: model.NHSCharges && model.NHSCharges.map(function (i) { return NHSCourseChargesOutstanding_1.NHSCourseChargesOutstanding_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            Procedures: model.Procedures && model.Procedures.map(function (i) { return NewChargeProcedure_1.NewChargeProcedure_Factory.CreateIncoming(i); }),
            Reference: model.Reference,
        };
        return result;
    };
    NewCharge_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            AppointmentId: model.AppointmentId,
            InsuranceClaims: model.InsuranceClaims && model.InsuranceClaims.map(function (i) { return InsuranceClaim_1.InsuranceClaim_Factory.CreateOutgoing(i); }),
            InsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateOutgoing(model.InsurancePolicy),
            NHSCharges: model.NHSCharges && model.NHSCharges.map(function (i) { return NHSCourseChargesOutstanding_1.NHSCourseChargesOutstanding_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            Procedures: model.Procedures && model.Procedures.map(function (i) { return NewChargeProcedure_1.NewChargeProcedure_Factory.CreateOutgoing(i); }),
            Reference: model.Reference,
        };
        return result;
    };
    return NewCharge_Factory;
}());
exports.NewCharge_Factory = NewCharge_Factory;
