"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompletedMedicalQuestionnaire_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CompletedMedicalQuestionnaireAnswer_1 = require("./CompletedMedicalQuestionnaireAnswer");
var MedicalQuestionnaire_1 = require("./MedicalQuestionnaire");
var User_1 = require("./User");
var CompletedMedicalQuestionnaire_Factory = /** @class */ (function () {
    function CompletedMedicalQuestionnaire_Factory() {
    }
    CompletedMedicalQuestionnaire_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdditionalNotes: model.AdditionalNotes,
            CompletedByUserId: model.CompletedByUserId,
            CompletedMedicalQuestionnaireAnswers: model.CompletedMedicalQuestionnaireAnswers && model.CompletedMedicalQuestionnaireAnswers.map(function (i) { return CompletedMedicalQuestionnaireAnswer_1.CompletedMedicalQuestionnaireAnswer_Factory.CreateIncoming(i); }),
            Converted: model.Converted,
            MedicalQuestionnaire: MedicalQuestionnaire_1.MedicalQuestionnaire_Factory.CreateIncoming(model.MedicalQuestionnaire),
            MedicalQuestionnaireId: model.MedicalQuestionnaireId,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
        };
        return result;
    };
    CompletedMedicalQuestionnaire_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdditionalNotes: model.AdditionalNotes,
            CompletedByUserId: model.CompletedByUserId,
            CompletedMedicalQuestionnaireAnswers: model.CompletedMedicalQuestionnaireAnswers && model.CompletedMedicalQuestionnaireAnswers.map(function (i) { return CompletedMedicalQuestionnaireAnswer_1.CompletedMedicalQuestionnaireAnswer_Factory.CreateOutgoing(i); }),
            Converted: model.Converted,
            MedicalQuestionnaire: MedicalQuestionnaire_1.MedicalQuestionnaire_Factory.CreateOutgoing(model.MedicalQuestionnaire),
            MedicalQuestionnaireId: model.MedicalQuestionnaireId,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
        };
        return result;
    };
    return CompletedMedicalQuestionnaire_Factory;
}());
exports.CompletedMedicalQuestionnaire_Factory = CompletedMedicalQuestionnaire_Factory;
