"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Person_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var CorrespondenceLetter_1 = require("./CorrespondenceLetter");
var EthnicityType_1 = require("./EthnicityType");
var GenderType_1 = require("./GenderType");
var InsurancePolicy_1 = require("./InsurancePolicy");
var PatientResponsibleParty_1 = require("./PatientResponsibleParty");
var Patient_1 = require("./Patient");
var PeopleAdditionalInfoKSA_1 = require("./PeopleAdditionalInfoKSA");
var PhoneNumber_1 = require("./PhoneNumber");
var SalutationType_1 = require("./SalutationType");
var Person_Factory = /** @class */ (function () {
    function Person_Factory() {
    }
    Person_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AbbreviatedName: model.AbbreviatedName,
            AdditionalId: model.AdditionalId,
            Addresses: model.Addresses && model.Addresses.map(function (i) { return Address_1.Address_Factory.CreateIncoming(i); }),
            Birthdate: Date_Factory_1.Date_Factory.CreateIncoming(model.Birthdate, { ignoreTimePortion: true }),
            CorrespondenceLetters: model.CorrespondenceLetters && model.CorrespondenceLetters.map(function (i) { return CorrespondenceLetter_1.CorrespondenceLetter_Factory.CreateIncoming(i); }),
            EmailAddress: model.EmailAddress,
            EthnicityType: EthnicityType_1.EthnicityType_Factory.CreateIncoming(model.EthnicityType),
            EthnicityTypeCode: model.EthnicityTypeCode,
            FirstName: model.FirstName,
            FullName: model.FullName,
            Gender: model.Gender,
            GenderType: GenderType_1.GenderType_Factory.CreateIncoming(model.GenderType),
            InsurancePolicies: model.InsurancePolicies && model.InsurancePolicies.map(function (i) { return InsurancePolicy_1.InsurancePolicy_Factory.CreateIncoming(i); }),
            LastName: model.LastName,
            MiddleName: model.MiddleName,
            Nickname: model.Nickname,
            PatientResponsibleParties: model.PatientResponsibleParties && model.PatientResponsibleParties.map(function (i) { return PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateIncoming(i); }),
            Patients: model.Patients && model.Patients.map(function (i) { return Patient_1.Patient_Factory.CreateIncoming(i); }),
            PeopleAdditionalInfoKSA: PeopleAdditionalInfoKSA_1.PeopleAdditionalInfoKSA_Factory.CreateIncoming(model.PeopleAdditionalInfoKSA),
            PeopleShardId: model.PeopleShardId,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(model.PhoneNumber),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(i); }),
            PrimaryPhoneNumberId: model.PrimaryPhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Salutation: model.Salutation,
            SalutationType: SalutationType_1.SalutationType_Factory.CreateIncoming(model.SalutationType),
            SmsOptOut: model.SmsOptOut,
            SSN: model.SSN,
            Title: model.Title,
        };
        return result;
    };
    Person_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AbbreviatedName: model.AbbreviatedName,
            AdditionalId: model.AdditionalId,
            Addresses: model.Addresses && model.Addresses.map(function (i) { return Address_1.Address_Factory.CreateOutgoing(i); }),
            Birthdate: Date_Factory_1.Date_Factory.CreateOutgoing(model.Birthdate, { ignoreTimePortion: true }),
            CorrespondenceLetters: model.CorrespondenceLetters && model.CorrespondenceLetters.map(function (i) { return CorrespondenceLetter_1.CorrespondenceLetter_Factory.CreateOutgoing(i); }),
            EmailAddress: model.EmailAddress,
            EthnicityType: EthnicityType_1.EthnicityType_Factory.CreateOutgoing(model.EthnicityType),
            EthnicityTypeCode: model.EthnicityTypeCode,
            FirstName: model.FirstName,
            FullName: model.FullName,
            Gender: model.Gender,
            GenderType: GenderType_1.GenderType_Factory.CreateOutgoing(model.GenderType),
            InsurancePolicies: model.InsurancePolicies && model.InsurancePolicies.map(function (i) { return InsurancePolicy_1.InsurancePolicy_Factory.CreateOutgoing(i); }),
            LastName: model.LastName,
            MiddleName: model.MiddleName,
            Nickname: model.Nickname,
            PatientResponsibleParties: model.PatientResponsibleParties && model.PatientResponsibleParties.map(function (i) { return PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateOutgoing(i); }),
            Patients: model.Patients && model.Patients.map(function (i) { return Patient_1.Patient_Factory.CreateOutgoing(i); }),
            PeopleAdditionalInfoKSA: PeopleAdditionalInfoKSA_1.PeopleAdditionalInfoKSA_Factory.CreateOutgoing(model.PeopleAdditionalInfoKSA),
            PeopleShardId: model.PeopleShardId,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(model.PhoneNumber),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(i); }),
            PrimaryPhoneNumberId: model.PrimaryPhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Salutation: model.Salutation,
            SalutationType: SalutationType_1.SalutationType_Factory.CreateOutgoing(model.SalutationType),
            SmsOptOut: model.SmsOptOut,
            SSN: model.SSN,
            Title: model.Title,
        };
        return result;
    };
    return Person_Factory;
}());
exports.Person_Factory = Person_Factory;
