"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracCase_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracCaseEnclosure_1 = require("./LabtracCaseEnclosure");
var LabtracCaseMaterial_1 = require("./LabtracCaseMaterial");
var LabtracCaseProduct_1 = require("./LabtracCaseProduct");
var LabtracDoctor_1 = require("./LabtracDoctor");
var LabtracDocument_1 = require("./LabtracDocument");
var LabtracMessage_1 = require("./LabtracMessage");
var Patient_1 = require("./Patient");
var LabtracCase_Factory = /** @class */ (function () {
    function LabtracCase_Factory() {
    }
    LabtracCase_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ActualArrivalDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ActualArrivalDate),
            AppReference: model.AppReference,
            EstimatedArrivalDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EstimatedArrivalDate),
            EstimatedShipDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EstimatedShipDate),
            ExternalId: model.ExternalId,
            LabCaseReference: model.LabCaseReference,
            LabOrderStatus: model.LabOrderStatus,
            LabOrderStatusNotes: model.LabOrderStatusNotes,
            LabReference: model.LabReference,
            LabtracCaseEnclosures: model.LabtracCaseEnclosures && model.LabtracCaseEnclosures.map(function (i) { return LabtracCaseEnclosure_1.LabtracCaseEnclosure_Factory.CreateIncoming(i); }),
            LabtracCaseMaterials: model.LabtracCaseMaterials && model.LabtracCaseMaterials.map(function (i) { return LabtracCaseMaterial_1.LabtracCaseMaterial_Factory.CreateIncoming(i); }),
            LabtracCaseProducts: model.LabtracCaseProducts && model.LabtracCaseProducts.map(function (i) { return LabtracCaseProduct_1.LabtracCaseProduct_Factory.CreateIncoming(i); }),
            LabtracCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.LabtracCreated),
            LabtracDoctor: LabtracDoctor_1.LabtracDoctor_Factory.CreateIncoming(model.LabtracDoctor),
            LabtracDoctorId: model.LabtracDoctorId,
            LabtracDocuments: model.LabtracDocuments && model.LabtracDocuments.map(function (i) { return LabtracDocument_1.LabtracDocument_Factory.CreateIncoming(i); }),
            LabtracMessages: model.LabtracMessages && model.LabtracMessages.map(function (i) { return LabtracMessage_1.LabtracMessage_Factory.CreateIncoming(i); }),
            OnlineStatus: model.OnlineStatus,
            OnlineStatusNotes: model.OnlineStatusNotes,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientFirstName: model.PatientFirstName,
            PatientId: model.PatientId,
            PatientLastName: model.PatientLastName,
            PracticeRequiredDate: Date_Factory_1.Date_Factory.CreateIncoming(model.PracticeRequiredDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RxUploadCaseStatus: model.RxUploadCaseStatus,
            ShippingCost: model.ShippingCost,
            ShippingMethodId: model.ShippingMethodId,
            TrackingId: model.TrackingId,
        };
        return result;
    };
    LabtracCase_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ActualArrivalDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ActualArrivalDate),
            AppReference: model.AppReference,
            EstimatedArrivalDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EstimatedArrivalDate),
            EstimatedShipDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EstimatedShipDate),
            ExternalId: model.ExternalId,
            LabCaseReference: model.LabCaseReference,
            LabOrderStatus: model.LabOrderStatus,
            LabOrderStatusNotes: model.LabOrderStatusNotes,
            LabReference: model.LabReference,
            LabtracCaseEnclosures: model.LabtracCaseEnclosures && model.LabtracCaseEnclosures.map(function (i) { return LabtracCaseEnclosure_1.LabtracCaseEnclosure_Factory.CreateOutgoing(i); }),
            LabtracCaseMaterials: model.LabtracCaseMaterials && model.LabtracCaseMaterials.map(function (i) { return LabtracCaseMaterial_1.LabtracCaseMaterial_Factory.CreateOutgoing(i); }),
            LabtracCaseProducts: model.LabtracCaseProducts && model.LabtracCaseProducts.map(function (i) { return LabtracCaseProduct_1.LabtracCaseProduct_Factory.CreateOutgoing(i); }),
            LabtracCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.LabtracCreated),
            LabtracDoctor: LabtracDoctor_1.LabtracDoctor_Factory.CreateOutgoing(model.LabtracDoctor),
            LabtracDoctorId: model.LabtracDoctorId,
            LabtracDocuments: model.LabtracDocuments && model.LabtracDocuments.map(function (i) { return LabtracDocument_1.LabtracDocument_Factory.CreateOutgoing(i); }),
            LabtracMessages: model.LabtracMessages && model.LabtracMessages.map(function (i) { return LabtracMessage_1.LabtracMessage_Factory.CreateOutgoing(i); }),
            OnlineStatus: model.OnlineStatus,
            OnlineStatusNotes: model.OnlineStatusNotes,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientFirstName: model.PatientFirstName,
            PatientId: model.PatientId,
            PatientLastName: model.PatientLastName,
            PracticeRequiredDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.PracticeRequiredDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RxUploadCaseStatus: model.RxUploadCaseStatus,
            ShippingCost: model.ShippingCost,
            ShippingMethodId: model.ShippingMethodId,
            TrackingId: model.TrackingId,
        };
        return result;
    };
    return LabtracCase_Factory;
}());
exports.LabtracCase_Factory = LabtracCase_Factory;
