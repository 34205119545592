"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteTemplate_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NoteTemplateCategory_1 = require("./NoteTemplateCategory");
var NoteTemplateFavourite_1 = require("./NoteTemplateFavourite");
var NoteTemplate_Factory = /** @class */ (function () {
    function NoteTemplate_Factory() {
    }
    NoteTemplate_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Category: NoteTemplateCategory_1.NoteTemplateCategory_Factory.CreateIncoming(model.Category),
            CategoryId: model.CategoryId,
            CreatedByUserId: model.CreatedByUserId,
            IsSystemDefault: model.IsSystemDefault,
            Name: model.Name,
            NoteContent: model.NoteContent,
            NoteContentTypeCode: model.NoteContentTypeCode,
            NoteTemplateFavourites: model.NoteTemplateFavourites && model.NoteTemplateFavourites.map(function (i) { return NoteTemplateFavourite_1.NoteTemplateFavourite_Factory.CreateIncoming(i); }),
            PreviousVersionId: model.PreviousVersionId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequiresFeatureTypeCode: model.RequiresFeatureTypeCode,
        };
        return result;
    };
    NoteTemplate_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Category: NoteTemplateCategory_1.NoteTemplateCategory_Factory.CreateOutgoing(model.Category),
            CategoryId: model.CategoryId,
            CreatedByUserId: model.CreatedByUserId,
            IsSystemDefault: model.IsSystemDefault,
            Name: model.Name,
            NoteContent: model.NoteContent,
            NoteContentTypeCode: model.NoteContentTypeCode,
            NoteTemplateFavourites: model.NoteTemplateFavourites && model.NoteTemplateFavourites.map(function (i) { return NoteTemplateFavourite_1.NoteTemplateFavourite_Factory.CreateOutgoing(i); }),
            PreviousVersionId: model.PreviousVersionId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequiresFeatureTypeCode: model.RequiresFeatureTypeCode,
        };
        return result;
    };
    return NoteTemplate_Factory;
}());
exports.NoteTemplate_Factory = NoteTemplate_Factory;
