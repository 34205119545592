"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeLock_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var TimeLocksChair_1 = require("./TimeLocksChair");
var TimeLock_Factory = /** @class */ (function () {
    function TimeLock_Factory() {
    }
    TimeLock_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            DurationInMinutes: model.DurationInMinutes,
            EndTime: Date_Factory_1.Date_Factory.CreateIncoming(model.EndTime),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartTime: Date_Factory_1.Date_Factory.CreateIncoming(model.StartTime),
            TimeLocksChairs: model.TimeLocksChairs && model.TimeLocksChairs.map(function (i) { return TimeLocksChair_1.TimeLocksChair_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    TimeLock_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            DurationInMinutes: model.DurationInMinutes,
            EndTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.EndTime),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartTime),
            TimeLocksChairs: model.TimeLocksChairs && model.TimeLocksChairs.map(function (i) { return TimeLocksChair_1.TimeLocksChair_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return TimeLock_Factory;
}());
exports.TimeLock_Factory = TimeLock_Factory;
