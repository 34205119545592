"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantSetting_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var TenantSetting_Factory = /** @class */ (function () {
    function TenantSetting_Factory() {
    }
    TenantSetting_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Key: model.Key,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Value: model.Value,
        };
        return result;
    };
    TenantSetting_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Key: model.Key,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Value: model.Value,
        };
        return result;
    };
    return TenantSetting_Factory;
}());
exports.TenantSetting_Factory = TenantSetting_Factory;
