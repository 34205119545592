"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSChargeValueSet_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSChargeBandValue_1 = require("./NHSChargeBandValue");
var NHSRegion_1 = require("./NHSRegion");
var NHSChargeValueSet_Factory = /** @class */ (function () {
    function NHSChargeValueSet_Factory() {
    }
    NHSChargeValueSet_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            NHSChargeBandValues: model.NHSChargeBandValues && model.NHSChargeBandValues.map(function (i) { return NHSChargeBandValue_1.NHSChargeBandValue_Factory.CreateIncoming(i); }),
            NHSChargeValueSetID: model.NHSChargeValueSetID,
            NHSRegion: NHSRegion_1.NHSRegion_Factory.CreateIncoming(model.NHSRegion),
            NHSRegionID: model.NHSRegionID,
            ReplacementMultiplier: model.ReplacementMultiplier,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
        };
        return result;
    };
    NHSChargeValueSet_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            NHSChargeBandValues: model.NHSChargeBandValues && model.NHSChargeBandValues.map(function (i) { return NHSChargeBandValue_1.NHSChargeBandValue_Factory.CreateOutgoing(i); }),
            NHSChargeValueSetID: model.NHSChargeValueSetID,
            NHSRegion: NHSRegion_1.NHSRegion_Factory.CreateOutgoing(model.NHSRegion),
            NHSRegionID: model.NHSRegionID,
            ReplacementMultiplier: model.ReplacementMultiplier,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
        };
        return result;
    };
    return NHSChargeValueSet_Factory;
}());
exports.NHSChargeValueSet_Factory = NHSChargeValueSet_Factory;
