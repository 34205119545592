"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreatmentPlan_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEvent_1 = require("./ClinicalEvent");
var NHSCourseDetail_1 = require("./NHSCourseDetail");
var NHSCourseDetailOrtho_1 = require("./NHSCourseDetailOrtho");
var NHSPatientDeclaration_1 = require("./NHSPatientDeclaration");
var Patient_1 = require("./Patient");
var PatientProcedureGroup_1 = require("./PatientProcedureGroup");
var PatientProcedure_1 = require("./PatientProcedure");
var User_1 = require("./User");
var TreatmentPlanProcedureGroup_1 = require("./TreatmentPlanProcedureGroup");
var TreatmentPlanStatus_1 = require("./TreatmentPlanStatus");
var TreatmentPlan_Factory = /** @class */ (function () {
    function TreatmentPlan_Factory() {
    }
    TreatmentPlan_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AcceptanceDate),
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            CompletionDate: Date_Factory_1.Date_Factory.CreateIncoming(model.CompletionDate),
            CreatedByUserId: model.CreatedByUserId,
            EnterpriseLocationId: model.EnterpriseLocationId,
            NHSCourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateIncoming(model.NHSCourseDetail),
            NHSCourseDetailOrtho: NHSCourseDetailOrtho_1.NHSCourseDetailOrtho_Factory.CreateIncoming(model.NHSCourseDetailOrtho),
            NHSPatientDeclarations: model.NHSPatientDeclarations && model.NHSPatientDeclarations.map(function (i) { return NHSPatientDeclaration_1.NHSPatientDeclaration_Factory.CreateIncoming(i); }),
            Notes: model.Notes,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PatientNotes: model.PatientNotes,
            PatientProcedureGroups: model.PatientProcedureGroups && model.PatientProcedureGroups.map(function (i) { return PatientProcedureGroup_1.PatientProcedureGroup_Factory.CreateIncoming(i); }),
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(i); }),
            PlanName: model.PlanName,
            PlanProvider: User_1.User_Factory.CreateIncoming(model.PlanProvider),
            PlanProviderId: model.PlanProviderId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SignatureDate: Date_Factory_1.Date_Factory.CreateIncoming(model.SignatureDate),
            SignatureFileId: model.SignatureFileId,
            TreatmentPlanProcedureGroups: model.TreatmentPlanProcedureGroups && model.TreatmentPlanProcedureGroups.map(function (i) { return TreatmentPlanProcedureGroup_1.TreatmentPlanProcedureGroup_Factory.CreateIncoming(i); }),
            TreatmentPlanStatus: TreatmentPlanStatus_1.TreatmentPlanStatus_Factory.CreateIncoming(model.TreatmentPlanStatus),
            TreatmentPlanStatusCode: model.TreatmentPlanStatusCode,
        };
        return result;
    };
    TreatmentPlan_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AcceptanceDate),
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            CompletionDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.CompletionDate),
            CreatedByUserId: model.CreatedByUserId,
            EnterpriseLocationId: model.EnterpriseLocationId,
            NHSCourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateOutgoing(model.NHSCourseDetail),
            NHSCourseDetailOrtho: NHSCourseDetailOrtho_1.NHSCourseDetailOrtho_Factory.CreateOutgoing(model.NHSCourseDetailOrtho),
            NHSPatientDeclarations: model.NHSPatientDeclarations && model.NHSPatientDeclarations.map(function (i) { return NHSPatientDeclaration_1.NHSPatientDeclaration_Factory.CreateOutgoing(i); }),
            Notes: model.Notes,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PatientNotes: model.PatientNotes,
            PatientProcedureGroups: model.PatientProcedureGroups && model.PatientProcedureGroups.map(function (i) { return PatientProcedureGroup_1.PatientProcedureGroup_Factory.CreateOutgoing(i); }),
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(i); }),
            PlanName: model.PlanName,
            PlanProvider: User_1.User_Factory.CreateOutgoing(model.PlanProvider),
            PlanProviderId: model.PlanProviderId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SignatureDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.SignatureDate),
            SignatureFileId: model.SignatureFileId,
            TreatmentPlanProcedureGroups: model.TreatmentPlanProcedureGroups && model.TreatmentPlanProcedureGroups.map(function (i) { return TreatmentPlanProcedureGroup_1.TreatmentPlanProcedureGroup_Factory.CreateOutgoing(i); }),
            TreatmentPlanStatus: TreatmentPlanStatus_1.TreatmentPlanStatus_Factory.CreateOutgoing(model.TreatmentPlanStatus),
            TreatmentPlanStatusCode: model.TreatmentPlanStatusCode,
        };
        return result;
    };
    return TreatmentPlan_Factory;
}());
exports.TreatmentPlan_Factory = TreatmentPlan_Factory;
