"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsInstance_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEvent_1 = require("./ClinicalEvent");
var EFormsInstanceDetail_1 = require("./EFormsInstanceDetail");
var EFormsSourceType_1 = require("./EFormsSourceType");
var EFormsStatusTracking_1 = require("./EFormsStatusTracking");
var EFormsTemplate_1 = require("./EFormsTemplate");
var EFormsInstance_Factory = /** @class */ (function () {
    function EFormsInstance_Factory() {
    }
    EFormsInstance_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            InstanceDetail: EFormsInstanceDetail_1.EFormsInstanceDetail_Factory.CreateIncoming(model.InstanceDetail),
            IsRead: model.IsRead,
            JSONBody: model.JSONBody,
            LatestStatusCode: model.LatestStatusCode,
            LocationId: model.LocationId,
            OriginalJSONBody: model.OriginalJSONBody,
            PatientId: model.PatientId,
            PatientName: model.PatientName,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SourceType: EFormsSourceType_1.EFormsSourceType_Factory.CreateIncoming(model.SourceType),
            SourceTypeId: model.SourceTypeId,
            StatusTrackings: model.StatusTrackings && model.StatusTrackings.map(function (i) { return EFormsStatusTracking_1.EFormsStatusTracking_Factory.CreateIncoming(i); }),
            Template: EFormsTemplate_1.EFormsTemplate_Factory.CreateIncoming(model.Template),
            TemplateId: model.TemplateId,
        };
        return result;
    };
    EFormsInstance_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            InstanceDetail: EFormsInstanceDetail_1.EFormsInstanceDetail_Factory.CreateOutgoing(model.InstanceDetail),
            IsRead: model.IsRead,
            JSONBody: model.JSONBody,
            LatestStatusCode: model.LatestStatusCode,
            LocationId: model.LocationId,
            OriginalJSONBody: model.OriginalJSONBody,
            PatientId: model.PatientId,
            PatientName: model.PatientName,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SourceType: EFormsSourceType_1.EFormsSourceType_Factory.CreateOutgoing(model.SourceType),
            SourceTypeId: model.SourceTypeId,
            StatusTrackings: model.StatusTrackings && model.StatusTrackings.map(function (i) { return EFormsStatusTracking_1.EFormsStatusTracking_Factory.CreateOutgoing(i); }),
            Template: EFormsTemplate_1.EFormsTemplate_Factory.CreateOutgoing(model.Template),
            TemplateId: model.TemplateId,
        };
        return result;
    };
    return EFormsInstance_Factory;
}());
exports.EFormsInstance_Factory = EFormsInstance_Factory;
