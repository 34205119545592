"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientProcedureGroup_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEventConditionCode_1 = require("./ClinicalEventConditionCode");
var PatientProcedure_1 = require("./PatientProcedure");
var TreatmentPlan_1 = require("./TreatmentPlan");
var PatientProcedureGroup_Factory = /** @class */ (function () {
    function PatientProcedureGroup_Factory() {
    }
    PatientProcedureGroup_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AddedByUserId: model.AddedByUserId,
            ClinicalEventConditionCode: model.ClinicalEventConditionCode,
            ConditionCode: ClinicalEventConditionCode_1.ClinicalEventConditionCode_Factory.CreateIncoming(model.ConditionCode),
            PatientId: model.PatientId,
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateIncoming(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
        };
        return result;
    };
    PatientProcedureGroup_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AddedByUserId: model.AddedByUserId,
            ClinicalEventConditionCode: model.ClinicalEventConditionCode,
            ConditionCode: ClinicalEventConditionCode_1.ClinicalEventConditionCode_Factory.CreateOutgoing(model.ConditionCode),
            PatientId: model.PatientId,
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateOutgoing(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
        };
        return result;
    };
    return PatientProcedureGroup_Factory;
}());
exports.PatientProcedureGroup_Factory = PatientProcedureGroup_Factory;
