"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduleNote_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ScheduleNote_Factory = /** @class */ (function () {
    function ScheduleNote_Factory() {
    }
    ScheduleNote_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            LocationId: model.LocationId,
            Note: model.Note,
            ScheduleDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ScheduleDate, { ignoreTimePortion: true }),
        };
        return result;
    };
    ScheduleNote_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            LocationId: model.LocationId,
            Note: model.Note,
            ScheduleDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ScheduleDate, { ignoreTimePortion: true }),
        };
        return result;
    };
    return ScheduleNote_Factory;
}());
exports.ScheduleNote_Factory = ScheduleNote_Factory;
