import { loadConfig } from "../../services/appConfigService";
import http, { ContentTypes, HttpError } from "../../services/api/http";
import Axios from "axios";



//#region Logo image
export async function uploadImage(image: File, isLogo: boolean) {
  const { apiUrl } = await loadConfig();
  //console.info(`Loading template instances.`)
  const url = apiUrl.segment(`eforms/attachments/image`)
    .param({isLogo})
    .toString();

  const formData = new FormData();
  formData.append("image", image);

  await http.post(url, formData, { headers: {'Content-Type': ContentTypes.MultipartFormData} })
  //console.log(response);
}



export async function getLogoImageUrl() {
  const { apiUrl } = await loadConfig();
  const url = apiUrl.segment(`eforms/attachments/logo`).toString();

  try {
    const { data: { LogoUrl } } = await http.get<{LogoUrl: string}>(url);
    return LogoUrl;
  }
  catch(e) {
    if (e instanceof HttpError && e.statusCode === 404) {
      return null;
    }
    //console.log("Code: ", e.response.status)
    throw e;
  }
}


export async function GetLogoImage() {
  const url = await getLogoImageUrl();
  if (!url) {
    return {};
  }

  const { data } = await Axios.get<Blob>(url, {responseType: 'blob'});
  return {url, data};
}

export async function getLogoImageAsFile(): Promise<File | null> {
  let {url, data: blob} = await GetLogoImage();
  if (!blob) {
    return null;
  }

  // Convert `Blob` to `File`
  let imageType;
  if (blob.type === "application/octet-stream") {
    // Try to figure out the image type from the file extension
    const lastPeriod = url!.lastIndexOf(".");
    if (lastPeriod < 0)  {
      throw new Error("Can't determine logo image type.")
    }
    imageType = url!.substr(lastPeriod +1 ).toLocaleLowerCase();
    const mimeType = "image/" + (imageType === "jpg" ? "jpeg" : imageType);
    blob = new Blob([blob], {type: mimeType});
  }
  else if (blob.type === "image/png" || blob.type === "image/jpeg" || blob.type === "image/gif") {
    const mimeTypePrefix = "image/";
    imageType = blob.type.substr(mimeTypePrefix.length);
    if (imageType === "jpeg") {
      imageType = "jpg";
    }
  }
  else {
    throw new Error("Unexpected logo image type: " + blob.type);
  }

  // // NOTE: Edge (pre-Chromium) doesn't support `File` constructor, so we'll fake it :(
  // if (!!window.navigator.msSaveBlob) {
  //   const file = new Blob([blob], {type: blob.type}) as any;
  //   file.name = "logo." + imageType;
  //   file.lastModified = new Date();
  //   return file;
  // }

  const file = new File([blob], "logo." + imageType);
  return file;
}
//#endregion

