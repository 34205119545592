"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoftTissueRegion_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ArchType_1 = require("./ArchType");
var SoftTissueRegion_Factory = /** @class */ (function () {
    function SoftTissueRegion_Factory() {
    }
    SoftTissueRegion_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ArchType: ArchType_1.ArchType_Factory.CreateIncoming(model.ArchType),
            ArchTypeCode: model.ArchTypeCode,
            Code: model.Code,
            Description: model.Description,
        };
        return result;
    };
    SoftTissueRegion_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ArchType: ArchType_1.ArchType_Factory.CreateOutgoing(model.ArchType),
            ArchTypeCode: model.ArchTypeCode,
            Code: model.Code,
            Description: model.Description,
        };
        return result;
    };
    return SoftTissueRegion_Factory;
}());
exports.SoftTissueRegion_Factory = SoftTissueRegion_Factory;
