"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordStatusCodes_DisplayOrders = exports.RecordStatusCodes_Descriptions_English = exports.RecordStatusCodes_Descriptions = exports.RecordStatusCodes_translateDescriptionBack = exports.RecordStatusCodes_translateDescriptions = exports.RecordStatusCodes = void 0;
var RecordStatusCodes;
(function (RecordStatusCodes) {
    RecordStatusCodes[RecordStatusCodes["Active"] = 0] = "Active";
    RecordStatusCodes[RecordStatusCodes["Inactive"] = 1] = "Inactive";
})(RecordStatusCodes = exports.RecordStatusCodes || (exports.RecordStatusCodes = {}));
function RecordStatusCodes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.RecordStatusCodes_Descriptions);
    allKeys.forEach(function (key) {
        exports.RecordStatusCodes_Descriptions[key] = translationFunction(exports.RecordStatusCodes_Descriptions[key]);
    });
}
exports.RecordStatusCodes_translateDescriptions = RecordStatusCodes_translateDescriptions;
function RecordStatusCodes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.RecordStatusCodes_Descriptions);
    allKeys.forEach(function (key) {
        exports.RecordStatusCodes_Descriptions[key] = exports.RecordStatusCodes_Descriptions_English[key];
    });
}
exports.RecordStatusCodes_translateDescriptionBack = RecordStatusCodes_translateDescriptionBack;
var _RecordStatusCodes_Descriptions = {};
_RecordStatusCodes_Descriptions.Active = _RecordStatusCodes_Descriptions[0] = "Active";
_RecordStatusCodes_Descriptions.Inactive = _RecordStatusCodes_Descriptions[1] = "Inactive";
var _RecordStatusCodes_Descriptions_English = {};
_RecordStatusCodes_Descriptions_English.Active = _RecordStatusCodes_Descriptions_English[0] = "Active";
_RecordStatusCodes_Descriptions_English.Inactive = _RecordStatusCodes_Descriptions_English[1] = "Inactive";
exports.RecordStatusCodes_Descriptions = _RecordStatusCodes_Descriptions;
exports.RecordStatusCodes_Descriptions_English = _RecordStatusCodes_Descriptions_English;
var _RecordStatusCodes_DisplayOrders = {};
_RecordStatusCodes_DisplayOrders.Active = _RecordStatusCodes_DisplayOrders[0] = 0;
_RecordStatusCodes_DisplayOrders.Inactive = _RecordStatusCodes_DisplayOrders[1] = 1;
exports.RecordStatusCodes_DisplayOrders = _RecordStatusCodes_DisplayOrders;
