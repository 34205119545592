"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserReportFavourite_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SavedReport_1 = require("./SavedReport");
var ReportType_1 = require("./ReportType");
var User_1 = require("./User");
var UserReportFavourite_Factory = /** @class */ (function () {
    function UserReportFavourite_Factory() {
    }
    UserReportFavourite_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SavedId: model.SavedId,
            SavedReport: SavedReport_1.SavedReport_Factory.CreateIncoming(model.SavedReport),
            Type: ReportType_1.ReportType_Factory.CreateIncoming(model.Type),
            TypeCode: model.TypeCode,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    UserReportFavourite_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SavedId: model.SavedId,
            SavedReport: SavedReport_1.SavedReport_Factory.CreateOutgoing(model.SavedReport),
            Type: ReportType_1.ReportType_Factory.CreateOutgoing(model.Type),
            TypeCode: model.TypeCode,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return UserReportFavourite_Factory;
}());
exports.UserReportFavourite_Factory = UserReportFavourite_Factory;
