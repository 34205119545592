"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientHistory_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientHistory_Factory = /** @class */ (function () {
    function PatientHistory_Factory() {
    }
    PatientHistory_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            LastSeenDate: Date_Factory_1.Date_Factory.CreateIncoming(model.LastSeenDate),
            PatientId: model.PatientId,
            PatientSinceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.PatientSinceDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientHistory_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            LastSeenDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastSeenDate),
            PatientId: model.PatientId,
            PatientSinceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.PatientSinceDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientHistory_Factory;
}());
exports.PatientHistory_Factory = PatientHistory_Factory;
