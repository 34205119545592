"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcedureGrouping_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Procedure_1 = require("./Procedure");
var ProcedureGrouping_Factory = /** @class */ (function () {
    function ProcedureGrouping_Factory() {
    }
    ProcedureGrouping_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            GroupProcedureId: model.GroupProcedureId,
            Procedure: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure),
            Procedure1: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure1),
            ProcedureId: model.ProcedureId,
            Qty: model.Qty,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    ProcedureGrouping_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            GroupProcedureId: model.GroupProcedureId,
            Procedure: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure),
            Procedure1: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure1),
            ProcedureId: model.ProcedureId,
            Qty: model.Qty,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return ProcedureGrouping_Factory;
}());
exports.ProcedureGrouping_Factory = ProcedureGrouping_Factory;
