"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSTriageDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCourseDetail_1 = require("./NHSCourseDetail");
var NHSTriageDetail_Factory = /** @class */ (function () {
    function NHSTriageDetail_Factory() {
    }
    NHSTriageDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdviceGiven: model.AdviceGiven,
            AdvisedAnalgesics: model.AdvisedAnalgesics,
            CallBackIfSymptomsWorsen: model.CallBackIfSymptomsWorsen,
            ContactDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ContactDate, { ignoreTimePortion: true }),
            ContactTimeInMins: model.ContactTimeInMins,
            CourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateIncoming(model.CourseDetail),
            DCPCarriedOutTriage: model.DCPCarriedOutTriage,
            FaceToFaceAppointmentFTA: model.FaceToFaceAppointmentFTA,
            FollowUpCallRequired: model.FollowUpCallRequired,
            NHSCourseDetailsId: model.NHSCourseDetailsId,
            PatientGroup: model.PatientGroup,
            PrimaryReason: model.PrimaryReason,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferredToLocalUDCReason: model.ReferredToLocalUDCReason,
            RemotePrescriptionAnalgesics: model.RemotePrescriptionAnalgesics,
            RemotePrescriptionAntibiotics: model.RemotePrescriptionAntibiotics,
            TriageViaVideo: model.TriageViaVideo,
        };
        return result;
    };
    NHSTriageDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdviceGiven: model.AdviceGiven,
            AdvisedAnalgesics: model.AdvisedAnalgesics,
            CallBackIfSymptomsWorsen: model.CallBackIfSymptomsWorsen,
            ContactDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ContactDate, { ignoreTimePortion: true }),
            ContactTimeInMins: model.ContactTimeInMins,
            CourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateOutgoing(model.CourseDetail),
            DCPCarriedOutTriage: model.DCPCarriedOutTriage,
            FaceToFaceAppointmentFTA: model.FaceToFaceAppointmentFTA,
            FollowUpCallRequired: model.FollowUpCallRequired,
            NHSCourseDetailsId: model.NHSCourseDetailsId,
            PatientGroup: model.PatientGroup,
            PrimaryReason: model.PrimaryReason,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferredToLocalUDCReason: model.ReferredToLocalUDCReason,
            RemotePrescriptionAnalgesics: model.RemotePrescriptionAnalgesics,
            RemotePrescriptionAntibiotics: model.RemotePrescriptionAntibiotics,
            TriageViaVideo: model.TriageViaVideo,
        };
        return result;
    };
    return NHSTriageDetail_Factory;
}());
exports.NHSTriageDetail_Factory = NHSTriageDetail_Factory;
