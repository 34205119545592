"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaim_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var InsuranceClaimType_1 = require("./InsuranceClaimType");
var InsuranceClaimAttachment_1 = require("./InsuranceClaimAttachment");
var InsuranceClaimBatchSubmission_1 = require("./InsuranceClaimBatchSubmission");
var InsuranceClaimContinuationItem_1 = require("./InsuranceClaimContinuationItem");
var InsuranceClaimItem_1 = require("./InsuranceClaimItem");
var InsuranceClaimNote_1 = require("./InsuranceClaimNote");
var InsuranceClaimStatusCode_1 = require("./InsuranceClaimStatusCode");
var InsurancePolicy_1 = require("./InsurancePolicy");
var Location_1 = require("./Location");
var MedicalClaimAdditionalInfo_1 = require("./MedicalClaimAdditionalInfo");
var Patient_1 = require("./Patient");
var Transaction_1 = require("./Transaction");
var ServiceLocationInsuranceClaim_1 = require("./ServiceLocationInsuranceClaim");
var InsuranceClaim_Factory = /** @class */ (function () {
    function InsuranceClaim_Factory() {
    }
    InsuranceClaim_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AmtClaimed: model.AmtClaimed,
            AmtPaid: model.AmtPaid,
            AppliancePlacementDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AppliancePlacementDate),
            BenefitsUsed: model.BenefitsUsed,
            BillingProvider: User_1.User_Factory.CreateIncoming(model.BillingProvider),
            BillingProviderId: model.BillingProviderId,
            ClaimCoverageOrder: model.ClaimCoverageOrder,
            ClaimDetail: model.ClaimDetail,
            ClaimNumber: model.ClaimNumber,
            ClaimStatusCode: model.ClaimStatusCode,
            ClaimType: InsuranceClaimType_1.InsuranceClaimType_Factory.CreateIncoming(model.ClaimType),
            ClaimTypeCode: model.ClaimTypeCode,
            DeductibleUsed: model.DeductibleUsed,
            IncludedCodes: model.IncludedCodes,
            InsuranceAmount: model.InsuranceAmount,
            InsuranceClaimAttachment: InsuranceClaimAttachment_1.InsuranceClaimAttachment_Factory.CreateIncoming(model.InsuranceClaimAttachment),
            InsuranceClaimBatchSubmission: InsuranceClaimBatchSubmission_1.InsuranceClaimBatchSubmission_Factory.CreateIncoming(model.InsuranceClaimBatchSubmission),
            InsuranceClaimBatchSubmissionId: model.InsuranceClaimBatchSubmissionId,
            InsuranceClaimContinuationItems: model.InsuranceClaimContinuationItems && model.InsuranceClaimContinuationItems.map(function (i) { return InsuranceClaimContinuationItem_1.InsuranceClaimContinuationItem_Factory.CreateIncoming(i); }),
            InsuranceClaimItems: model.InsuranceClaimItems && model.InsuranceClaimItems.map(function (i) { return InsuranceClaimItem_1.InsuranceClaimItem_Factory.CreateIncoming(i); }),
            InsuranceClaimNotes: model.InsuranceClaimNotes && model.InsuranceClaimNotes.map(function (i) { return InsuranceClaimNote_1.InsuranceClaimNote_Factory.CreateIncoming(i); }),
            InsuranceClaimStatusCode: InsuranceClaimStatusCode_1.InsuranceClaimStatusCode_Factory.CreateIncoming(model.InsuranceClaimStatusCode),
            InsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateIncoming(model.InsurancePolicy),
            InsurancePolicyId: model.InsurancePolicyId,
            InsuranceWriteOffAmount: model.InsuranceWriteOffAmount,
            IsBilledByProvider: model.IsBilledByProvider,
            IsOrthodonticsTreatment: model.IsOrthodonticsTreatment,
            IsTerminalState: model.IsTerminalState,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            MedicalClaimAdditionalInfo: MedicalClaimAdditionalInfo_1.MedicalClaimAdditionalInfo_Factory.CreateIncoming(model.MedicalClaimAdditionalInfo),
            MonthsOfTxRemaining: model.MonthsOfTxRemaining,
            Note: model.Note,
            OrthoContractId: model.OrthoContractId,
            OrthoContractSource: model.OrthoContractSource,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientAmount: model.PatientAmount,
            PatientControlNumber: model.PatientControlNumber,
            PatientId: model.PatientId,
            PayerName: model.PayerName,
            Payments: model.Payments && model.Payments.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            PlanName: model.PlanName,
            PreAuthorizationNumber: model.PreAuthorizationNumber,
            PrecedingClaim: InsuranceClaim_Factory.CreateIncoming(model.PrecedingClaim),
            PrecedingClaimId: model.PrecedingClaimId,
            PriorPlacementDate: Date_Factory_1.Date_Factory.CreateIncoming(model.PriorPlacementDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Remarks: model.Remarks,
            ReplacementOfProsthesis: model.ReplacementOfProsthesis,
            ServiceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ServiceDate),
            ServiceLocationInsuranceClaim: ServiceLocationInsuranceClaim_1.ServiceLocationInsuranceClaim_Factory.CreateIncoming(model.ServiceLocationInsuranceClaim),
            ServiceLocationInsuranceClaimId: model.ServiceLocationInsuranceClaimId,
            SubsequentClaims: model.SubsequentClaims && model.SubsequentClaims.map(function (i) { return InsuranceClaim_Factory.CreateIncoming(i); }),
            SuppressPatientShareWarning: model.SuppressPatientShareWarning,
        };
        return result;
    };
    InsuranceClaim_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AmtClaimed: model.AmtClaimed,
            AmtPaid: model.AmtPaid,
            AppliancePlacementDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AppliancePlacementDate),
            BenefitsUsed: model.BenefitsUsed,
            BillingProvider: User_1.User_Factory.CreateOutgoing(model.BillingProvider),
            BillingProviderId: model.BillingProviderId,
            ClaimCoverageOrder: model.ClaimCoverageOrder,
            ClaimDetail: model.ClaimDetail,
            ClaimNumber: model.ClaimNumber,
            ClaimStatusCode: model.ClaimStatusCode,
            ClaimType: InsuranceClaimType_1.InsuranceClaimType_Factory.CreateOutgoing(model.ClaimType),
            ClaimTypeCode: model.ClaimTypeCode,
            DeductibleUsed: model.DeductibleUsed,
            IncludedCodes: model.IncludedCodes,
            InsuranceAmount: model.InsuranceAmount,
            InsuranceClaimAttachment: InsuranceClaimAttachment_1.InsuranceClaimAttachment_Factory.CreateOutgoing(model.InsuranceClaimAttachment),
            InsuranceClaimBatchSubmission: InsuranceClaimBatchSubmission_1.InsuranceClaimBatchSubmission_Factory.CreateOutgoing(model.InsuranceClaimBatchSubmission),
            InsuranceClaimBatchSubmissionId: model.InsuranceClaimBatchSubmissionId,
            InsuranceClaimContinuationItems: model.InsuranceClaimContinuationItems && model.InsuranceClaimContinuationItems.map(function (i) { return InsuranceClaimContinuationItem_1.InsuranceClaimContinuationItem_Factory.CreateOutgoing(i); }),
            InsuranceClaimItems: model.InsuranceClaimItems && model.InsuranceClaimItems.map(function (i) { return InsuranceClaimItem_1.InsuranceClaimItem_Factory.CreateOutgoing(i); }),
            InsuranceClaimNotes: model.InsuranceClaimNotes && model.InsuranceClaimNotes.map(function (i) { return InsuranceClaimNote_1.InsuranceClaimNote_Factory.CreateOutgoing(i); }),
            InsuranceClaimStatusCode: InsuranceClaimStatusCode_1.InsuranceClaimStatusCode_Factory.CreateOutgoing(model.InsuranceClaimStatusCode),
            InsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateOutgoing(model.InsurancePolicy),
            InsurancePolicyId: model.InsurancePolicyId,
            InsuranceWriteOffAmount: model.InsuranceWriteOffAmount,
            IsBilledByProvider: model.IsBilledByProvider,
            IsOrthodonticsTreatment: model.IsOrthodonticsTreatment,
            IsTerminalState: model.IsTerminalState,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            MedicalClaimAdditionalInfo: MedicalClaimAdditionalInfo_1.MedicalClaimAdditionalInfo_Factory.CreateOutgoing(model.MedicalClaimAdditionalInfo),
            MonthsOfTxRemaining: model.MonthsOfTxRemaining,
            Note: model.Note,
            OrthoContractId: model.OrthoContractId,
            OrthoContractSource: model.OrthoContractSource,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientAmount: model.PatientAmount,
            PatientControlNumber: model.PatientControlNumber,
            PatientId: model.PatientId,
            PayerName: model.PayerName,
            Payments: model.Payments && model.Payments.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            PlanName: model.PlanName,
            PreAuthorizationNumber: model.PreAuthorizationNumber,
            PrecedingClaim: InsuranceClaim_Factory.CreateOutgoing(model.PrecedingClaim),
            PrecedingClaimId: model.PrecedingClaimId,
            PriorPlacementDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.PriorPlacementDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Remarks: model.Remarks,
            ReplacementOfProsthesis: model.ReplacementOfProsthesis,
            ServiceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ServiceDate),
            ServiceLocationInsuranceClaim: ServiceLocationInsuranceClaim_1.ServiceLocationInsuranceClaim_Factory.CreateOutgoing(model.ServiceLocationInsuranceClaim),
            ServiceLocationInsuranceClaimId: model.ServiceLocationInsuranceClaimId,
            SubsequentClaims: model.SubsequentClaims && model.SubsequentClaims.map(function (i) { return InsuranceClaim_Factory.CreateOutgoing(i); }),
            SuppressPatientShareWarning: model.SuppressPatientShareWarning,
        };
        return result;
    };
    return InsuranceClaim_Factory;
}());
exports.InsuranceClaim_Factory = InsuranceClaim_Factory;
