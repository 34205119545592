"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsMedicalQuestionnairePrefill_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsMedicalQuestionnaireAnswerPrefill_1 = require("./EFormsMedicalQuestionnaireAnswerPrefill");
var EFormsMedicalQuestionnairePrefill_Factory = /** @class */ (function () {
    function EFormsMedicalQuestionnairePrefill_Factory() {
    }
    EFormsMedicalQuestionnairePrefill_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            QuestionnaireId: model.QuestionnaireId,
            Questions: model.Questions && model.Questions.map(function (i) { return EFormsMedicalQuestionnaireAnswerPrefill_1.EFormsMedicalQuestionnaireAnswerPrefill_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    EFormsMedicalQuestionnairePrefill_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            QuestionnaireId: model.QuestionnaireId,
            Questions: model.Questions && model.Questions.map(function (i) { return EFormsMedicalQuestionnaireAnswerPrefill_1.EFormsMedicalQuestionnaireAnswerPrefill_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return EFormsMedicalQuestionnairePrefill_Factory;
}());
exports.EFormsMedicalQuestionnairePrefill_Factory = EFormsMedicalQuestionnairePrefill_Factory;
