"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BPEExam_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEvent_1 = require("./ClinicalEvent");
var BPEExam_Factory = /** @class */ (function () {
    function BPEExam_Factory() {
    }
    BPEExam_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            ExamDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ExamDate),
            LA: model.LA,
            LL: model.LL,
            LR: model.LR,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UA: model.UA,
            UL: model.UL,
            UR: model.UR,
        };
        return result;
    };
    BPEExam_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            ExamDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ExamDate),
            LA: model.LA,
            LL: model.LL,
            LR: model.LR,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UA: model.UA,
            UL: model.UL,
            UR: model.UR,
        };
        return result;
    };
    return BPEExam_Factory;
}());
exports.BPEExam_Factory = BPEExam_Factory;
