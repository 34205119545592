"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeopleAdditionalInfoKSA_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Person_1 = require("./Person");
var PeopleAdditionalInfoKSA_Factory = /** @class */ (function () {
    function PeopleAdditionalInfoKSA_Factory() {
    }
    PeopleAdditionalInfoKSA_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ArabicFullName: model.ArabicFullName,
            Citizenship: model.Citizenship,
            ClientIdentifierId: model.ClientIdentifierId,
            FirstNameAr: model.FirstNameAr,
            HealthId: model.HealthId,
            IdType: model.IdType,
            LastNameAr: model.LastNameAr,
            MaritalStatus: model.MaritalStatus,
            MaritalStatusCode: model.MaritalStatusCode,
            MiddleNamesAr: model.MiddleNamesAr,
            Nationality: model.Nationality,
            NationalityCode: model.NationalityCode,
            Occupation: model.Occupation,
            Person: Person_1.Person_Factory.CreateIncoming(model.Person),
            PersonRecordId: model.PersonRecordId,
            PlaceOfBirth: model.PlaceOfBirth,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            Religion: model.Religion,
            ResyncRequired: model.ResyncRequired,
        };
        return result;
    };
    PeopleAdditionalInfoKSA_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ArabicFullName: model.ArabicFullName,
            Citizenship: model.Citizenship,
            ClientIdentifierId: model.ClientIdentifierId,
            FirstNameAr: model.FirstNameAr,
            HealthId: model.HealthId,
            IdType: model.IdType,
            LastNameAr: model.LastNameAr,
            MaritalStatus: model.MaritalStatus,
            MaritalStatusCode: model.MaritalStatusCode,
            MiddleNamesAr: model.MiddleNamesAr,
            Nationality: model.Nationality,
            NationalityCode: model.NationalityCode,
            Occupation: model.Occupation,
            Person: Person_1.Person_Factory.CreateOutgoing(model.Person),
            PersonRecordId: model.PersonRecordId,
            PlaceOfBirth: model.PlaceOfBirth,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            Religion: model.Religion,
            ResyncRequired: model.ResyncRequired,
        };
        return result;
    };
    return PeopleAdditionalInfoKSA_Factory;
}());
exports.PeopleAdditionalInfoKSA_Factory = PeopleAdditionalInfoKSA_Factory;
