"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerioExam_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EnterpriseConsultationSubjectLink_1 = require("./EnterpriseConsultationSubjectLink");
var InsuranceClaimAttachment_1 = require("./InsuranceClaimAttachment");
var Note_1 = require("./Note");
var PerioRecord_1 = require("./PerioRecord");
var PerioExam_Factory = /** @class */ (function () {
    function PerioExam_Factory() {
    }
    PerioExam_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateIncoming(i); }),
            EnterpriseLocationId: model.EnterpriseLocationId,
            InsuranceClaimAttachments: model.InsuranceClaimAttachments && model.InsuranceClaimAttachments.map(function (i) { return InsuranceClaimAttachment_1.InsuranceClaimAttachment_Factory.CreateIncoming(i); }),
            LocationId: model.LocationId,
            Notes: model.Notes && model.Notes.map(function (i) { return Note_1.Note_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            PerioRecords: model.PerioRecords && model.PerioRecords.map(function (i) { return PerioRecord_1.PerioRecord_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    PerioExam_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateOutgoing(i); }),
            EnterpriseLocationId: model.EnterpriseLocationId,
            InsuranceClaimAttachments: model.InsuranceClaimAttachments && model.InsuranceClaimAttachments.map(function (i) { return InsuranceClaimAttachment_1.InsuranceClaimAttachment_Factory.CreateOutgoing(i); }),
            LocationId: model.LocationId,
            Notes: model.Notes && model.Notes.map(function (i) { return Note_1.Note_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            PerioRecords: model.PerioRecords && model.PerioRecords.map(function (i) { return PerioRecord_1.PerioRecord_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    return PerioExam_Factory;
}());
exports.PerioExam_Factory = PerioExam_Factory;
