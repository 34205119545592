import { publicRoutes } from "../../routePaths";
import { IAuthRequest, IToken } from './models';
import { getTokensFromStorage, removeTokensFromStorage, getCurrentTenantSelection } from "./storage";
import { performAuth } from "./ssoAuth";


function hasExpired(token: IToken | null, clockSkewInSeconds = 300) {
  const now = new Date();
  return (!token || (token.expiresOn.valueOf() - now.valueOf()) < (clockSkewInSeconds * 1000));
}

export function haveValidAccessToken(clockSkewInSeconds = 300) {
  const tokens = getTokensFromStorage();
  return !hasExpired(tokens && tokens.accessToken, clockSkewInSeconds);
}

export function isLoggedIn(clockSkewInSeconds = 120) {
  return haveValidAccessToken(clockSkewInSeconds) && !!getCurrentTenantSelection();
}


export async function tryRestoreSession() {
  let tokens = getTokensFromStorage();

  if (tokens) {
    if (!hasExpired(tokens.accessToken)) {
      console.info("All good. Auth session hasn't expired.")
      return tokens;
    }

    removeTokensFromStorage();
    console.info("Attempting to restore auth session using refresh token.");

    // Access token has expired. How about the refresh token?
    if (!hasExpired(tokens.refreshToken, 60)) {
      try {
        const newTokens = await renewSession(tokens.refreshToken);
        console.info("Auth session renewed :)");
        return newTokens;
      }
      catch(e) {
        console.error("Auth session renewal attempt failed :(", e);
        // Fall through
      }
    }
  }

  console.warn("Can't renew auth session. No valid/unexpired tokens.")
  return null
}


export function login(username : string, password : string) {
  let authRequest: IAuthRequest = {
    grant_type: 'password',
    username: username,
    password: password
  };

  return performAuth(authRequest);
}


function renewSession(refreshToken: IToken) {
  const authRequest : IAuthRequest = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken.token
  };

  return performAuth(authRequest);
}


export function logout(sessionExpired = false) {
  sessionStorage.clear();
  const destUrl = sessionExpired ? publicRoutes.login.sessionExpired : publicRoutes.login.signIn;
  window.location.replace(destUrl);
}
