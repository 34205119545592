"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeZone_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var TimeZone_Factory = /** @class */ (function () {
    function TimeZone_Factory() {
    }
    TimeZone_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            TimeZone1: model.TimeZone1,
            TimeZoneDisplay: model.TimeZoneDisplay,
            TimeZoneId: model.TimeZoneId,
            TimeZoneSortOrder: model.TimeZoneSortOrder,
            WindowsTimeZone: model.WindowsTimeZone,
        };
        return result;
    };
    TimeZone_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            TimeZone1: model.TimeZone1,
            TimeZoneDisplay: model.TimeZoneDisplay,
            TimeZoneId: model.TimeZoneId,
            TimeZoneSortOrder: model.TimeZoneSortOrder,
            WindowsTimeZone: model.WindowsTimeZone,
        };
        return result;
    };
    return TimeZone_Factory;
}());
exports.TimeZone_Factory = TimeZone_Factory;
