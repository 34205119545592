"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsStatusCodes_DisplayOrders = exports.EFormsStatusCodes_Descriptions_English = exports.EFormsStatusCodes_Descriptions = exports.EFormsStatusCodes_translateDescriptionBack = exports.EFormsStatusCodes_translateDescriptions = exports.EFormsStatusCodes = void 0;
var EFormsStatusCodes;
(function (EFormsStatusCodes) {
    EFormsStatusCodes[EFormsStatusCodes["Submitted"] = 1] = "Submitted";
    EFormsStatusCodes[EFormsStatusCodes["Assigned"] = 2] = "Assigned";
    EFormsStatusCodes[EFormsStatusCodes["Rejected"] = 3] = "Rejected";
    EFormsStatusCodes[EFormsStatusCodes["Saved"] = 4] = "Saved";
    EFormsStatusCodes[EFormsStatusCodes["Archived"] = 5] = "Archived";
    EFormsStatusCodes[EFormsStatusCodes["OnHold"] = 6] = "OnHold";
})(EFormsStatusCodes = exports.EFormsStatusCodes || (exports.EFormsStatusCodes = {}));
function EFormsStatusCodes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EFormsStatusCodes_Descriptions);
    allKeys.forEach(function (key) {
        exports.EFormsStatusCodes_Descriptions[key] = translationFunction(exports.EFormsStatusCodes_Descriptions[key]);
    });
}
exports.EFormsStatusCodes_translateDescriptions = EFormsStatusCodes_translateDescriptions;
function EFormsStatusCodes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EFormsStatusCodes_Descriptions);
    allKeys.forEach(function (key) {
        exports.EFormsStatusCodes_Descriptions[key] = exports.EFormsStatusCodes_Descriptions_English[key];
    });
}
exports.EFormsStatusCodes_translateDescriptionBack = EFormsStatusCodes_translateDescriptionBack;
var _EFormsStatusCodes_Descriptions = {};
_EFormsStatusCodes_Descriptions.Submitted = _EFormsStatusCodes_Descriptions[1] = "Externally submitted w/o patient id or submitted after filled out in office. Awaiting approval.";
_EFormsStatusCodes_Descriptions.Assigned = _EFormsStatusCodes_Descriptions[2] = "Internally assigned to patient with patient id.";
_EFormsStatusCodes_Descriptions.Rejected = _EFormsStatusCodes_Descriptions[3] = "Rejected by office personnel.";
_EFormsStatusCodes_Descriptions.Saved = _EFormsStatusCodes_Descriptions[4] = "Approved and saved by office personnel.";
_EFormsStatusCodes_Descriptions.Archived = _EFormsStatusCodes_Descriptions[5] = "Used for forms that are no longer current or expired.";
_EFormsStatusCodes_Descriptions.OnHold = _EFormsStatusCodes_Descriptions[6] = "Used for forms that are may need to be updated by a patient.";
var _EFormsStatusCodes_Descriptions_English = {};
_EFormsStatusCodes_Descriptions_English.Submitted = _EFormsStatusCodes_Descriptions_English[1] = "Externally submitted w/o patient id or submitted after filled out in office. Awaiting approval.";
_EFormsStatusCodes_Descriptions_English.Assigned = _EFormsStatusCodes_Descriptions_English[2] = "Internally assigned to patient with patient id.";
_EFormsStatusCodes_Descriptions_English.Rejected = _EFormsStatusCodes_Descriptions_English[3] = "Rejected by office personnel.";
_EFormsStatusCodes_Descriptions_English.Saved = _EFormsStatusCodes_Descriptions_English[4] = "Approved and saved by office personnel.";
_EFormsStatusCodes_Descriptions_English.Archived = _EFormsStatusCodes_Descriptions_English[5] = "Used for forms that are no longer current or expired.";
_EFormsStatusCodes_Descriptions_English.OnHold = _EFormsStatusCodes_Descriptions_English[6] = "Used for forms that are may need to be updated by a patient.";
exports.EFormsStatusCodes_Descriptions = _EFormsStatusCodes_Descriptions;
exports.EFormsStatusCodes_Descriptions_English = _EFormsStatusCodes_Descriptions_English;
var _EFormsStatusCodes_DisplayOrders = {};
_EFormsStatusCodes_DisplayOrders.Submitted = _EFormsStatusCodes_DisplayOrders[1] = 1;
_EFormsStatusCodes_DisplayOrders.Assigned = _EFormsStatusCodes_DisplayOrders[2] = 2;
_EFormsStatusCodes_DisplayOrders.Rejected = _EFormsStatusCodes_DisplayOrders[3] = 3;
_EFormsStatusCodes_DisplayOrders.Saved = _EFormsStatusCodes_DisplayOrders[4] = 4;
_EFormsStatusCodes_DisplayOrders.Archived = _EFormsStatusCodes_DisplayOrders[5] = 5;
_EFormsStatusCodes_DisplayOrders.OnHold = _EFormsStatusCodes_DisplayOrders[6] = 6;
exports.EFormsStatusCodes_DisplayOrders = _EFormsStatusCodes_DisplayOrders;
