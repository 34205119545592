"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurgeryRecording_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SurgeryDrugRecording_1 = require("./SurgeryDrugRecording");
var SurgeryEventRecording_1 = require("./SurgeryEventRecording");
var SurgeryGasRecording_1 = require("./SurgeryGasRecording");
var VitalsRecord_1 = require("./VitalsRecord");
var SurgeryRecording_Factory = /** @class */ (function () {
    function SurgeryRecording_Factory() {
    }
    SurgeryRecording_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DrugRecording: SurgeryDrugRecording_1.SurgeryDrugRecording_Factory.CreateIncoming(model.DrugRecording),
            DrugRecordingId: model.DrugRecordingId,
            EventRecording: SurgeryEventRecording_1.SurgeryEventRecording_Factory.CreateIncoming(model.EventRecording),
            EventRecordingId: model.EventRecordingId,
            GasRecording: SurgeryGasRecording_1.SurgeryGasRecording_Factory.CreateIncoming(model.GasRecording),
            GasRecordingId: model.GasRecordingId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SurgeryVitalsId: model.SurgeryVitalsId,
            Time: Date_Factory_1.Date_Factory.CreateIncoming(model.Time),
            VitalsRecord: VitalsRecord_1.VitalsRecord_Factory.CreateIncoming(model.VitalsRecord),
            VitalsRecordId: model.VitalsRecordId,
        };
        return result;
    };
    SurgeryRecording_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DrugRecording: SurgeryDrugRecording_1.SurgeryDrugRecording_Factory.CreateOutgoing(model.DrugRecording),
            DrugRecordingId: model.DrugRecordingId,
            EventRecording: SurgeryEventRecording_1.SurgeryEventRecording_Factory.CreateOutgoing(model.EventRecording),
            EventRecordingId: model.EventRecordingId,
            GasRecording: SurgeryGasRecording_1.SurgeryGasRecording_Factory.CreateOutgoing(model.GasRecording),
            GasRecordingId: model.GasRecordingId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SurgeryVitalsId: model.SurgeryVitalsId,
            Time: Date_Factory_1.Date_Factory.CreateOutgoing(model.Time),
            VitalsRecord: VitalsRecord_1.VitalsRecord_Factory.CreateOutgoing(model.VitalsRecord),
            VitalsRecordId: model.VitalsRecordId,
        };
        return result;
    };
    return SurgeryRecording_Factory;
}());
exports.SurgeryRecording_Factory = SurgeryRecording_Factory;
