"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientWaitingListNoteType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientWaitingListNoteType_Factory = /** @class */ (function () {
    function PatientWaitingListNoteType_Factory() {
    }
    PatientWaitingListNoteType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            PatientWaitingListNoteType1: model.PatientWaitingListNoteType1,
        };
        return result;
    };
    PatientWaitingListNoteType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            PatientWaitingListNoteType1: model.PatientWaitingListNoteType1,
        };
        return result;
    };
    return PatientWaitingListNoteType_Factory;
}());
exports.PatientWaitingListNoteType_Factory = PatientWaitingListNoteType_Factory;
