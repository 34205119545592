"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmsApptReminder_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SmsMessage_1 = require("./SmsMessage");
var SmsApptReminder_Factory = /** @class */ (function () {
    function SmsApptReminder_Factory() {
    }
    SmsApptReminder_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            CreationReason: model.CreationReason,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            SmsMessage: SmsMessage_1.SmsMessage_Factory.CreateIncoming(model.SmsMessage),
            SmsMessageId: model.SmsMessageId,
        };
        return result;
    };
    SmsApptReminder_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            CreationReason: model.CreationReason,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            SmsMessage: SmsMessage_1.SmsMessage_Factory.CreateOutgoing(model.SmsMessage),
            SmsMessageId: model.SmsMessageId,
        };
        return result;
    };
    return SmsApptReminder_Factory;
}());
exports.SmsApptReminder_Factory = SmsApptReminder_Factory;
