"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientArchiveRecord_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ArchiveAccessRequest_1 = require("./ArchiveAccessRequest");
var Note_1 = require("./Note");
var Patient_1 = require("./Patient");
var PatientArchiveRecord_Factory = /** @class */ (function () {
    function PatientArchiveRecord_Factory() {
    }
    PatientArchiveRecord_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ArchiveAccessRequests: model.ArchiveAccessRequests && model.ArchiveAccessRequests.map(function (i) { return ArchiveAccessRequest_1.ArchiveAccessRequest_Factory.CreateIncoming(i); }),
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            NoteId: model.NoteId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
        };
        return result;
    };
    PatientArchiveRecord_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ArchiveAccessRequests: model.ArchiveAccessRequests && model.ArchiveAccessRequests.map(function (i) { return ArchiveAccessRequest_1.ArchiveAccessRequest_Factory.CreateOutgoing(i); }),
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            NoteId: model.NoteId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
        };
        return result;
    };
    return PatientArchiveRecord_Factory;
}());
exports.PatientArchiveRecord_Factory = PatientArchiveRecord_Factory;
