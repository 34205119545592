"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RxTemplate_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var RxDosage_1 = require("./RxDosage");
var RxDrug_1 = require("./RxDrug");
var RxFormulation_1 = require("./RxFormulation");
var RxSig_1 = require("./RxSig");
var RxTemplate_Factory = /** @class */ (function () {
    function RxTemplate_Factory() {
    }
    RxTemplate_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Controlled: model.Controlled,
            Days: model.Days,
            IsActive: model.IsActive,
            Name: model.Name,
            Quantity: model.Quantity,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Refills: model.Refills,
            RxDosage: RxDosage_1.RxDosage_Factory.CreateIncoming(model.RxDosage),
            RxDosageId: model.RxDosageId,
            RxDrug: RxDrug_1.RxDrug_Factory.CreateIncoming(model.RxDrug),
            RxDrugId: model.RxDrugId,
            RxFormulation: RxFormulation_1.RxFormulation_Factory.CreateIncoming(model.RxFormulation),
            RxFormulationId: model.RxFormulationId,
            RxSig: RxSig_1.RxSig_Factory.CreateIncoming(model.RxSig),
            RxSigId: model.RxSigId,
            SubAllowed: model.SubAllowed,
        };
        return result;
    };
    RxTemplate_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Controlled: model.Controlled,
            Days: model.Days,
            IsActive: model.IsActive,
            Name: model.Name,
            Quantity: model.Quantity,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Refills: model.Refills,
            RxDosage: RxDosage_1.RxDosage_Factory.CreateOutgoing(model.RxDosage),
            RxDosageId: model.RxDosageId,
            RxDrug: RxDrug_1.RxDrug_Factory.CreateOutgoing(model.RxDrug),
            RxDrugId: model.RxDrugId,
            RxFormulation: RxFormulation_1.RxFormulation_Factory.CreateOutgoing(model.RxFormulation),
            RxFormulationId: model.RxFormulationId,
            RxSig: RxSig_1.RxSig_Factory.CreateOutgoing(model.RxSig),
            RxSigId: model.RxSigId,
            SubAllowed: model.SubAllowed,
        };
        return result;
    };
    return RxTemplate_Factory;
}());
exports.RxTemplate_Factory = RxTemplate_Factory;
