"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientContactTypeCodes_DisplayOrders = exports.PatientContactTypeCodes_Descriptions_English = exports.PatientContactTypeCodes_Descriptions = exports.PatientContactTypeCodes_translateDescriptionBack = exports.PatientContactTypeCodes_translateDescriptions = exports.PatientContactTypeCodes = void 0;
var PatientContactTypeCodes;
(function (PatientContactTypeCodes) {
    PatientContactTypeCodes[PatientContactTypeCodes["Unknown"] = 0] = "Unknown";
    PatientContactTypeCodes[PatientContactTypeCodes["Phone"] = 1] = "Phone";
    PatientContactTypeCodes[PatientContactTypeCodes["Text"] = 2] = "Text";
    PatientContactTypeCodes[PatientContactTypeCodes["Email"] = 3] = "Email";
    PatientContactTypeCodes[PatientContactTypeCodes["Letter"] = 4] = "Letter";
})(PatientContactTypeCodes = exports.PatientContactTypeCodes || (exports.PatientContactTypeCodes = {}));
function PatientContactTypeCodes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.PatientContactTypeCodes_Descriptions);
    allKeys.forEach(function (key) {
        exports.PatientContactTypeCodes_Descriptions[key] = translationFunction(exports.PatientContactTypeCodes_Descriptions[key]);
    });
}
exports.PatientContactTypeCodes_translateDescriptions = PatientContactTypeCodes_translateDescriptions;
function PatientContactTypeCodes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.PatientContactTypeCodes_Descriptions);
    allKeys.forEach(function (key) {
        exports.PatientContactTypeCodes_Descriptions[key] = exports.PatientContactTypeCodes_Descriptions_English[key];
    });
}
exports.PatientContactTypeCodes_translateDescriptionBack = PatientContactTypeCodes_translateDescriptionBack;
var _PatientContactTypeCodes_Descriptions = {};
_PatientContactTypeCodes_Descriptions.Unknown = _PatientContactTypeCodes_Descriptions[0] = "Unknown";
_PatientContactTypeCodes_Descriptions.Phone = _PatientContactTypeCodes_Descriptions[1] = "Phone";
_PatientContactTypeCodes_Descriptions.Text = _PatientContactTypeCodes_Descriptions[2] = "Text";
_PatientContactTypeCodes_Descriptions.Email = _PatientContactTypeCodes_Descriptions[3] = "Email";
_PatientContactTypeCodes_Descriptions.Letter = _PatientContactTypeCodes_Descriptions[4] = "Letter";
var _PatientContactTypeCodes_Descriptions_English = {};
_PatientContactTypeCodes_Descriptions_English.Unknown = _PatientContactTypeCodes_Descriptions_English[0] = "Unknown";
_PatientContactTypeCodes_Descriptions_English.Phone = _PatientContactTypeCodes_Descriptions_English[1] = "Phone";
_PatientContactTypeCodes_Descriptions_English.Text = _PatientContactTypeCodes_Descriptions_English[2] = "Text";
_PatientContactTypeCodes_Descriptions_English.Email = _PatientContactTypeCodes_Descriptions_English[3] = "Email";
_PatientContactTypeCodes_Descriptions_English.Letter = _PatientContactTypeCodes_Descriptions_English[4] = "Letter";
exports.PatientContactTypeCodes_Descriptions = _PatientContactTypeCodes_Descriptions;
exports.PatientContactTypeCodes_Descriptions_English = _PatientContactTypeCodes_Descriptions_English;
var _PatientContactTypeCodes_DisplayOrders = {};
_PatientContactTypeCodes_DisplayOrders.Unknown = _PatientContactTypeCodes_DisplayOrders[0] = 0;
_PatientContactTypeCodes_DisplayOrders.Phone = _PatientContactTypeCodes_DisplayOrders[1] = 1;
_PatientContactTypeCodes_DisplayOrders.Text = _PatientContactTypeCodes_DisplayOrders[2] = 2;
_PatientContactTypeCodes_DisplayOrders.Email = _PatientContactTypeCodes_DisplayOrders[3] = 3;
_PatientContactTypeCodes_DisplayOrders.Letter = _PatientContactTypeCodes_DisplayOrders[4] = 4;
exports.PatientContactTypeCodes_DisplayOrders = _PatientContactTypeCodes_DisplayOrders;
