"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientERxDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientERxDetail_Factory = /** @class */ (function () {
    function PatientERxDetail_Factory() {
    }
    PatientERxDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            eRxFacilityId: model.eRxFacilityId,
            eRxPatientId: model.eRxPatientId,
            LastERxSyncDate: Date_Factory_1.Date_Factory.CreateIncoming(model.LastERxSyncDate, { ignoreTimePortion: true }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientERxDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            eRxFacilityId: model.eRxFacilityId,
            eRxPatientId: model.eRxPatientId,
            LastERxSyncDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastERxSyncDate, { ignoreTimePortion: true }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientERxDetail_Factory;
}());
exports.PatientERxDetail_Factory = PatientERxDetail_Factory;
