"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkInsurancePaymentClaim_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BulkInsurancePayment_1 = require("./BulkInsurancePayment");
var InsuranceClaim_1 = require("./InsuranceClaim");
var PatientProcedure_1 = require("./PatientProcedure");
var Transaction_1 = require("./Transaction");
var BulkInsurancePaymentClaim_Factory = /** @class */ (function () {
    function BulkInsurancePaymentClaim_Factory() {
    }
    BulkInsurancePaymentClaim_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AcceptanceDate),
            AdjustmentAmount: model.AdjustmentAmount,
            Amount: model.Amount,
            BulkInsurancePayment: BulkInsurancePayment_1.BulkInsurancePayment_Factory.CreateIncoming(model.BulkInsurancePayment),
            BulkPaymentId: model.BulkPaymentId,
            InsuranceClaim: InsuranceClaim_1.InsuranceClaim_Factory.CreateIncoming(model.InsuranceClaim),
            InsuranceClaimId: model.InsuranceClaimId,
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(i); }),
            PaymentComment: model.PaymentComment,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SubsequentPolicyId: model.SubsequentPolicyId,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            WriteOffAmount: model.WriteOffAmount,
            WriteOffComments: model.WriteOffComments,
        };
        return result;
    };
    BulkInsurancePaymentClaim_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AcceptanceDate),
            AdjustmentAmount: model.AdjustmentAmount,
            Amount: model.Amount,
            BulkInsurancePayment: BulkInsurancePayment_1.BulkInsurancePayment_Factory.CreateOutgoing(model.BulkInsurancePayment),
            BulkPaymentId: model.BulkPaymentId,
            InsuranceClaim: InsuranceClaim_1.InsuranceClaim_Factory.CreateOutgoing(model.InsuranceClaim),
            InsuranceClaimId: model.InsuranceClaimId,
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(i); }),
            PaymentComment: model.PaymentComment,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SubsequentPolicyId: model.SubsequentPolicyId,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            WriteOffAmount: model.WriteOffAmount,
            WriteOffComments: model.WriteOffComments,
        };
        return result;
    };
    return BulkInsurancePaymentClaim_Factory;
}());
exports.BulkInsurancePaymentClaim_Factory = BulkInsurancePaymentClaim_Factory;
