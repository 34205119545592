"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionSubType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var OrthoContract_1 = require("./OrthoContract");
var Transaction_1 = require("./Transaction");
var TransactionType_1 = require("./TransactionType");
var TransactionSubType_Factory = /** @class */ (function () {
    function TransactionSubType_Factory() {
    }
    TransactionSubType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Category: model.Category,
            CategoryId: model.CategoryId,
            Description: model.Description,
            NotUserEditable: model.NotUserEditable,
            OrthoContracts: model.OrthoContracts && model.OrthoContracts.map(function (i) { return OrthoContract_1.OrthoContract_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            TransactionType: TransactionType_1.TransactionType_Factory.CreateIncoming(model.TransactionType),
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    TransactionSubType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Category: model.Category,
            CategoryId: model.CategoryId,
            Description: model.Description,
            NotUserEditable: model.NotUserEditable,
            OrthoContracts: model.OrthoContracts && model.OrthoContracts.map(function (i) { return OrthoContract_1.OrthoContract_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            TransactionType: TransactionType_1.TransactionType_Factory.CreateOutgoing(model.TransactionType),
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    return TransactionSubType_Factory;
}());
exports.TransactionSubType_Factory = TransactionSubType_Factory;
