"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalAlertType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MedicalAlertType_Factory = /** @class */ (function () {
    function MedicalAlertType_Factory() {
    }
    MedicalAlertType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            LinkedAllergyTypeId: model.LinkedAllergyTypeId,
            LinkedMedicationTypeId: model.LinkedMedicationTypeId,
            MedicalAlertType1: model.MedicalAlertType1,
            MedicalAlertTypeCode: model.MedicalAlertTypeCode,
            MedicalQuestionId: model.MedicalQuestionId,
            NotUserEditable: model.NotUserEditable,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    MedicalAlertType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            LinkedAllergyTypeId: model.LinkedAllergyTypeId,
            LinkedMedicationTypeId: model.LinkedMedicationTypeId,
            MedicalAlertType1: model.MedicalAlertType1,
            MedicalAlertTypeCode: model.MedicalAlertTypeCode,
            MedicalQuestionId: model.MedicalQuestionId,
            NotUserEditable: model.NotUserEditable,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return MedicalAlertType_Factory;
}());
exports.MedicalAlertType_Factory = MedicalAlertType_Factory;
