"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PracticeEmailAccountType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PracticeEmailAccountType_Factory = /** @class */ (function () {
    function PracticeEmailAccountType_Factory() {
    }
    PracticeEmailAccountType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            PracticeEmailAccountType1: model.PracticeEmailAccountType1,
            PracticeEmailAccountTypeCode: model.PracticeEmailAccountTypeCode,
        };
        return result;
    };
    PracticeEmailAccountType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            PracticeEmailAccountType1: model.PracticeEmailAccountType1,
            PracticeEmailAccountTypeCode: model.PracticeEmailAccountTypeCode,
        };
        return result;
    };
    return PracticeEmailAccountType_Factory;
}());
exports.PracticeEmailAccountType_Factory = PracticeEmailAccountType_Factory;
