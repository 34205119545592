"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSActivityValueSet_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSActivityBandValue_1 = require("./NHSActivityBandValue");
var NHSRegion_1 = require("./NHSRegion");
var NHSActivityValueSet_Factory = /** @class */ (function () {
    function NHSActivityValueSet_Factory() {
    }
    NHSActivityValueSet_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            NHSActivityBandValues: model.NHSActivityBandValues && model.NHSActivityBandValues.map(function (i) { return NHSActivityBandValue_1.NHSActivityBandValue_Factory.CreateIncoming(i); }),
            NHSActivityValueSetID: model.NHSActivityValueSetID,
            NHSRegion: NHSRegion_1.NHSRegion_Factory.CreateIncoming(model.NHSRegion),
            NHSRegionID: model.NHSRegionID,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
        };
        return result;
    };
    NHSActivityValueSet_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            NHSActivityBandValues: model.NHSActivityBandValues && model.NHSActivityBandValues.map(function (i) { return NHSActivityBandValue_1.NHSActivityBandValue_Factory.CreateOutgoing(i); }),
            NHSActivityValueSetID: model.NHSActivityValueSetID,
            NHSRegion: NHSRegion_1.NHSRegion_Factory.CreateOutgoing(model.NHSRegion),
            NHSRegionID: model.NHSRegionID,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
        };
        return result;
    };
    return NHSActivityValueSet_Factory;
}());
exports.NHSActivityValueSet_Factory = NHSActivityValueSet_Factory;
