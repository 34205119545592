"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickbooksLocationPaymentMapping_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var TransactionType_1 = require("./TransactionType");
var QuickbooksLocationPaymentMapping_Factory = /** @class */ (function () {
    function QuickbooksLocationPaymentMapping_Factory() {
    }
    QuickbooksLocationPaymentMapping_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DepositToAccount: model.DepositToAccount,
            DeposLineItemDetails: model.DeposLineItemDetails,
            IncludeTransactionType: model.IncludeTransactionType,
            InheritIncludeTransactionType: model.InheritIncludeTransactionType,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            QBOClass: model.QBOClass,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransactionType: TransactionType_1.TransactionType_Factory.CreateIncoming(model.TransactionType),
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    QuickbooksLocationPaymentMapping_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DepositToAccount: model.DepositToAccount,
            DeposLineItemDetails: model.DeposLineItemDetails,
            IncludeTransactionType: model.IncludeTransactionType,
            InheritIncludeTransactionType: model.InheritIncludeTransactionType,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            QBOClass: model.QBOClass,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransactionType: TransactionType_1.TransactionType_Factory.CreateOutgoing(model.TransactionType),
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    return QuickbooksLocationPaymentMapping_Factory;
}());
exports.QuickbooksLocationPaymentMapping_Factory = QuickbooksLocationPaymentMapping_Factory;
