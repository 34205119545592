"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Appointment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AppointmentAuditLog_1 = require("./AppointmentAuditLog");
var AppointmentNote_1 = require("./AppointmentNote");
var AppointmentStatusCode_1 = require("./AppointmentStatusCode");
var AppointmentType_1 = require("./AppointmentType");
var Chair_1 = require("./Chair");
var Location_1 = require("./Location");
var MawidRequest_1 = require("./MawidRequest");
var Note_1 = require("./Note");
var Patient_1 = require("./Patient");
var PatientConcern_1 = require("./PatientConcern");
var PatientProcedure_1 = require("./PatientProcedure");
var RecordStatus_1 = require("./RecordStatus");
var Transaction_1 = require("./Transaction");
var User_1 = require("./User");
var Appointment_Factory = /** @class */ (function () {
    function Appointment_Factory() {
    }
    Appointment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Appointment1: Appointment_Factory.CreateIncoming(model.Appointment1),
            AppointmentAuditLogs: model.AppointmentAuditLogs && model.AppointmentAuditLogs.map(function (i) { return AppointmentAuditLog_1.AppointmentAuditLog_Factory.CreateIncoming(i); }),
            AppointmentNotes: model.AppointmentNotes && model.AppointmentNotes.map(function (i) { return AppointmentNote_1.AppointmentNote_Factory.CreateIncoming(i); }),
            AppointmentStatusCode: AppointmentStatusCode_1.AppointmentStatusCode_Factory.CreateIncoming(model.AppointmentStatusCode),
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateIncoming(model.AppointmentType),
            AppointmentTypeId: model.AppointmentTypeId,
            AssistantId: model.AssistantId,
            Chair: Chair_1.Chair_Factory.CreateIncoming(model.Chair),
            ChairId: model.ChairId,
            CheckoutNotes: model.CheckoutNotes,
            CompletedOn: Date_Factory_1.Date_Factory.CreateIncoming(model.CompletedOn),
            DoctorId: model.DoctorId,
            DoNotShowPreferredLocationPrompt: model.DoNotShowPreferredLocationPrompt,
            DurationInMinutes: model.DurationInMinutes,
            EnterpriseLocationId: model.EnterpriseLocationId,
            EstateReferralId: model.EstateReferralId,
            ExcusePrinted: model.ExcusePrinted,
            HasPrescreened: model.HasPrescreened,
            HygienistId: model.HygienistId,
            IsConfirmed: model.IsConfirmed,
            IsRescheduling: model.IsRescheduling,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            MawidRequests: model.MawidRequests && model.MawidRequests.map(function (i) { return MawidRequest_1.MawidRequest_Factory.CreateIncoming(i); }),
            MissedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.MissedDate),
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            PatientProceduresScheduled: model.PatientProceduresScheduled && model.PatientProceduresScheduled.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(i); }),
            RebookedAppointmentId: model.RebookedAppointmentId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
            RequiresFollowUp: model.RequiresFollowUp,
            ShortCallTypeCode: model.ShortCallTypeCode,
            StartTime: Date_Factory_1.Date_Factory.CreateIncoming(model.StartTime),
            StatusCode: model.StatusCode,
            TeledentistryId: model.TeledentistryId,
            TeledentistryLink: model.TeledentistryLink,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            User: User_1.User_Factory.CreateIncoming(model.User),
            User1: User_1.User_Factory.CreateIncoming(model.User1),
            User2: User_1.User_Factory.CreateIncoming(model.User2),
            VisitNotesId: model.VisitNotesId,
            WalkoutPrinted: model.WalkoutPrinted,
        };
        return result;
    };
    Appointment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Appointment1: Appointment_Factory.CreateOutgoing(model.Appointment1),
            AppointmentAuditLogs: model.AppointmentAuditLogs && model.AppointmentAuditLogs.map(function (i) { return AppointmentAuditLog_1.AppointmentAuditLog_Factory.CreateOutgoing(i); }),
            AppointmentNotes: model.AppointmentNotes && model.AppointmentNotes.map(function (i) { return AppointmentNote_1.AppointmentNote_Factory.CreateOutgoing(i); }),
            AppointmentStatusCode: AppointmentStatusCode_1.AppointmentStatusCode_Factory.CreateOutgoing(model.AppointmentStatusCode),
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateOutgoing(model.AppointmentType),
            AppointmentTypeId: model.AppointmentTypeId,
            AssistantId: model.AssistantId,
            Chair: Chair_1.Chair_Factory.CreateOutgoing(model.Chair),
            ChairId: model.ChairId,
            CheckoutNotes: model.CheckoutNotes,
            CompletedOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.CompletedOn),
            DoctorId: model.DoctorId,
            DoNotShowPreferredLocationPrompt: model.DoNotShowPreferredLocationPrompt,
            DurationInMinutes: model.DurationInMinutes,
            EnterpriseLocationId: model.EnterpriseLocationId,
            EstateReferralId: model.EstateReferralId,
            ExcusePrinted: model.ExcusePrinted,
            HasPrescreened: model.HasPrescreened,
            HygienistId: model.HygienistId,
            IsConfirmed: model.IsConfirmed,
            IsRescheduling: model.IsRescheduling,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            MawidRequests: model.MawidRequests && model.MawidRequests.map(function (i) { return MawidRequest_1.MawidRequest_Factory.CreateOutgoing(i); }),
            MissedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.MissedDate),
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            PatientProceduresScheduled: model.PatientProceduresScheduled && model.PatientProceduresScheduled.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(i); }),
            RebookedAppointmentId: model.RebookedAppointmentId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
            RequiresFollowUp: model.RequiresFollowUp,
            ShortCallTypeCode: model.ShortCallTypeCode,
            StartTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartTime),
            StatusCode: model.StatusCode,
            TeledentistryId: model.TeledentistryId,
            TeledentistryLink: model.TeledentistryLink,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            User: User_1.User_Factory.CreateOutgoing(model.User),
            User1: User_1.User_Factory.CreateOutgoing(model.User1),
            User2: User_1.User_Factory.CreateOutgoing(model.User2),
            VisitNotesId: model.VisitNotesId,
            WalkoutPrinted: model.WalkoutPrinted,
        };
        return result;
    };
    return Appointment_Factory;
}());
exports.Appointment_Factory = Appointment_Factory;
