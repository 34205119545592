"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientLedgerBalance_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientLedgerBalance_Factory = /** @class */ (function () {
    function PatientLedgerBalance_Factory() {
    }
    PatientLedgerBalance_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BalanceAged30: model.BalanceAged30,
            BalanceAged60: model.BalanceAged60,
            BalanceAged90: model.BalanceAged90,
            BalanceCurrent: model.BalanceCurrent,
            BalanceTotal: model.BalanceTotal,
            InsuranceContractTotal: model.InsuranceContractTotal,
            InsuranceTotal: model.InsuranceTotal,
            InsuranceWriteOffTotal: model.InsuranceWriteOffTotal,
            LastPaymentAmount: model.LastPaymentAmount,
            LastPaymentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.LastPaymentDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientLedgerBalance_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BalanceAged30: model.BalanceAged30,
            BalanceAged60: model.BalanceAged60,
            BalanceAged90: model.BalanceAged90,
            BalanceCurrent: model.BalanceCurrent,
            BalanceTotal: model.BalanceTotal,
            InsuranceContractTotal: model.InsuranceContractTotal,
            InsuranceTotal: model.InsuranceTotal,
            InsuranceWriteOffTotal: model.InsuranceWriteOffTotal,
            LastPaymentAmount: model.LastPaymentAmount,
            LastPaymentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastPaymentDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientLedgerBalance_Factory;
}());
exports.PatientLedgerBalance_Factory = PatientLedgerBalance_Factory;
