"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCategoryBand_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSActivityBand_1 = require("./NHSActivityBand");
var NHSCategory_1 = require("./NHSCategory");
var NHSCategoryDate_1 = require("./NHSCategoryDate");
var NHSChargeBand_1 = require("./NHSChargeBand");
var NHSRegion_1 = require("./NHSRegion");
var NHSCategoryBand_Factory = /** @class */ (function () {
    function NHSCategoryBand_Factory() {
    }
    NHSCategoryBand_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CanBeFullCost: model.CanBeFullCost,
            CanBeGtee: model.CanBeGtee,
            FreeCode: model.FreeCode,
            InternalNotes: model.InternalNotes,
            NHSActivityBand: NHSActivityBand_1.NHSActivityBand_Factory.CreateIncoming(model.NHSActivityBand),
            NHSActivityBandID: model.NHSActivityBandID,
            NHSCategory: NHSCategory_1.NHSCategory_Factory.CreateIncoming(model.NHSCategory),
            NHSCategoryBandID: model.NHSCategoryBandID,
            NHSCategoryDate: NHSCategoryDate_1.NHSCategoryDate_Factory.CreateIncoming(model.NHSCategoryDate),
            NHSCategoryDateID: model.NHSCategoryDateID,
            NHSCategoryID: model.NHSCategoryID,
            NHSChargeBand: NHSChargeBand_1.NHSChargeBand_Factory.CreateIncoming(model.NHSChargeBand),
            NHSChargeBandID: model.NHSChargeBandID,
            NHSRegion: NHSRegion_1.NHSRegion_Factory.CreateIncoming(model.NHSRegion),
            NHSRegionID: model.NHSRegionID,
            RefCode: model.RefCode,
            RuleCode: model.RuleCode,
            SpecialCode: model.SpecialCode,
            Urgent: model.Urgent,
        };
        return result;
    };
    NHSCategoryBand_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CanBeFullCost: model.CanBeFullCost,
            CanBeGtee: model.CanBeGtee,
            FreeCode: model.FreeCode,
            InternalNotes: model.InternalNotes,
            NHSActivityBand: NHSActivityBand_1.NHSActivityBand_Factory.CreateOutgoing(model.NHSActivityBand),
            NHSActivityBandID: model.NHSActivityBandID,
            NHSCategory: NHSCategory_1.NHSCategory_Factory.CreateOutgoing(model.NHSCategory),
            NHSCategoryBandID: model.NHSCategoryBandID,
            NHSCategoryDate: NHSCategoryDate_1.NHSCategoryDate_Factory.CreateOutgoing(model.NHSCategoryDate),
            NHSCategoryDateID: model.NHSCategoryDateID,
            NHSCategoryID: model.NHSCategoryID,
            NHSChargeBand: NHSChargeBand_1.NHSChargeBand_Factory.CreateOutgoing(model.NHSChargeBand),
            NHSChargeBandID: model.NHSChargeBandID,
            NHSRegion: NHSRegion_1.NHSRegion_Factory.CreateOutgoing(model.NHSRegion),
            NHSRegionID: model.NHSRegionID,
            RefCode: model.RefCode,
            RuleCode: model.RuleCode,
            SpecialCode: model.SpecialCode,
            Urgent: model.Urgent,
        };
        return result;
    };
    return NHSCategoryBand_Factory;
}());
exports.NHSCategoryBand_Factory = NHSCategoryBand_Factory;
