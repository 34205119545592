"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Appliance_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ApplianceType_1 = require("./ApplianceType");
var Appliance_Factory = /** @class */ (function () {
    function Appliance_Factory() {
    }
    Appliance_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ApplianceType: ApplianceType_1.ApplianceType_Factory.CreateIncoming(model.ApplianceType),
            ApplianceTypeId: model.ApplianceTypeId,
            EndDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EndDate),
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
        };
        return result;
    };
    Appliance_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ApplianceType: ApplianceType_1.ApplianceType_Factory.CreateOutgoing(model.ApplianceType),
            ApplianceTypeId: model.ApplianceTypeId,
            EndDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EndDate),
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
        };
        return result;
    };
    return Appliance_Factory;
}());
exports.Appliance_Factory = Appliance_Factory;
