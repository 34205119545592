import moment from "moment-timezone";
import { defaultDateFormatString } from "./dataFormatters";

export interface IDateRange {
  start: Date,
  end: Date,
}

export const millisecondsInADay = 86400000;

// http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.1
const dateLimit = 8640000000000000;
export const MIN_DATE = new Date(-dateLimit);
export const MAX_DATE = new Date(dateLimit);

export function getDate(date: Date) {
  var result = new Date(date);
  result.setHours(0, 0, 0, 0);
  return result;
}

export function today() {
  return getDate(new Date());
}

export function endOfDay(date?: Date) {
  var result = date ? new Date(date) : new Date();
  result.setHours(23, 59, 59, 0);
  return result;
}

export function addDays(date: Date, number: number) {
  return new Date(date.valueOf() + (number * millisecondsInADay));
}

// export function nextDay(date: Date) {
//   return addDays(date, 1);
// }

// export function prevDay(date: Date) {
//   return addDays(date, 1);
// }


export function firstOfYear() {
  let year = new Date().getFullYear();

  return new Date(year, 0, 1)
}

/**
 * Make sure both dates provided to this function are from the same timezone
 * toUTC and fromUTC can be used to achieve this...
 * @param date1
 * @param date2
 */
export function isSameDate(date1: Date, date2: Date) {

  if (date1 === date2)// Note: intentional loose type checking
    return true;

  if (!date1 || !date2)
    return false;

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate());
}

/**
 * Make sure both dates provided to this function are from the same timezone
 * toUTC and fromUTC can be used to achieve this...
 * @param date1
 * @param date2
 */
export function isSameDateTime(date1: Date | null, date2: Date | null) {

  if (date1 === date2)// Note: intentional loose type checking
    return true;

  if (!date1 || !date2)
    return false;

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate() &&
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes());
}

export function isBeforeToday(date: Date) {
  return moment(date).isBefore(today(), "day")
}

export function isAfterToday(date: Date) {
  return moment(date).isAfter(today(), "day")
}

export function isToday(date: Date) {
  return moment(date).isSame(today(), "day")
}

export function getAgeInMonths(birthDate: Date) {
  if (!birthDate)
    return 0;
  return moment().diff(moment.utc(birthDate), 'months');
}

export function getAgeInYears(birthDate: Date) {
  if (!birthDate)
    return 0;
  return moment().diff(moment.utc(birthDate), 'year');
}


export function getDisplayAge(birthDate?: Date, monthStr = 'm', yearStr = 'y') {
  if (!birthDate)
    return null;

  const months = getAgeInMonths(birthDate);
  return months >= 12 ?
    Math.floor(months / 12) + yearStr :
    months + monthStr;
}


// Splits date string into parts, and then recombines
export function sanitizeDateInput(dateStr?: string | null) {
  const dateRegex = /^(\d{1,2})[ /-]+(\d{1,2})[ /-]+(\d{4})/;
  const parts = (dateStr || "").match(dateRegex);
  if (parts == null || parts.length !== 4) {
    return ""
  }

  return `${parts[1].padStart(2, "0")}/${parts[2].padStart(2, "0")}/${parts[3]}`
}

export function parseLocaleDate(str: string, locale?: string): Date | null {
  str = sanitizeDateInput(str);
  return moment(str, defaultDateFormatString(locale)).toDate();
}


export function parseDate(dateStr: string) {
  return dateStr ? new Date(dateStr) : null;
}


const isProdBuild = process.env.NODE_ENV === "production";
/**
 * Convert a JavaScript local Date to UTC, with the assumption that the
 * various date parts represent the data/time in the specified source time zone.
 * Ex: (Assuming the code is running in US Easter Standard time zone)
 * const dt1 = new Date(2017, 0, 1, 10, 30);
 * const dt2 = toUtc(dt1, "America/Los_Angeles");
 * - dt1.toISOString() === "2017-01-01T15:30:00.000Z"
 * - dt1.toString() === "Sun Jan 01 2017 10:30:00 GMT-0500 (Eastern Standard Time)"
 * - dt2.toISOString() === "2017-01-01T18:30:00.000Z"
 * - dt2.toString() === "Sun Jan 01 2017 13:30:00 GMT-0500 (Eastern Standard Time)"
 * (See unit tests for additional examples)
 *
 * @param date The source date
 * @param srcTimeZoneId Time zone that the suplied date is specified in.
 * (https://en.wikipedia.org/wiki/List_of_tz_database_time_zones)
 * @returns The converted date. You must call toISOString() or dt.toJSON() to get the string representation in UTC.
 */
// export function toUtc(date: Date, srcTimeZoneId: string): Date;
// export function toUtc(date: undefined, srcTimeZoneId: string): undefined;
export function toUtc(date: Date | undefined, srcTimeZoneId: string) {
  if (!date)
    return date;

  if (!isProdBuild && !moment.tz.zone(srcTimeZoneId))
    throw new Error(`Invalid/Unknown timezone: "${srcTimeZoneId}"`);

  // Assume the quantities in the supplied value represents
  // the date/time in the office's time zone.
  const tzDate = moment.tz([
      date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()],
    srcTimeZoneId);

  return tzDate.toDate();
}


/**
 * Convert the supplied Date to the destination time zone.
 * Ex: (Assuming the code is running in US Easter Standard time zone)
 * const dt1 = new Date("2017-01-01T18:30:00.000Z");
 * const dt2 = fromUtc(dt1, "America/Los_Angeles");
 * - dt1.toISOString() === "2017-01-01T18:30:00.000Z"
 * - dt1.toString() === "Sun Jan 01 2017 13:30:00 GMT-0500 (Eastern Standard Time)"
 * - dt2.toISOString() === "2017-01-01T15:30:00.000Z"
 * - dt2.toString() === "Sun Jan 01 2017 10:30:00 GMT-0500 (Eastern Standard Time)"
 * (See unit tests for additional examples)
 *
 * @param date The source date
 * @param destTimeZoneId Destination time zone ID. (https://en.wikipedia.org/wiki/List_of_tz_database_time_zones)
 * @returns The converted date.
 */
// export function fromUtc(date: Date, destTimeZoneId: string): Date;
// export function fromUtc(date: undefined, destTimeZoneId: string): undefined;
export function fromUtc(date: Date | undefined, destTimeZoneId: string) {
  if (!date)
    return date;

  if (!isProdBuild && !moment.tz.zone(destTimeZoneId))
    throw new Error(`Invalid/Unknown timezone: "${destTimeZoneId}"`);

  const tzDate = moment.tz(date.toISOString(), destTimeZoneId);

  // Create a local date instance with the same quantities as the
  // date/time in the office's time zone.
  const {years, months, date: _date, hours, minutes, seconds, milliseconds} = tzDate.toObject();
  const destDate = new Date(years, months, _date, hours, minutes, seconds, milliseconds);

  return destDate;
}

export function parseUtcDate(dateString: string | Date) {
  var date = moment(dateString).toDate();
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

export function ignoreLocalOffset(date?: Date | string) {
  if (typeof date === "string")
    date = new Date(date);

  if (!date) return date;

  //How many milliseconds UTC is away from current time
  const adjustment = date.getTimezoneOffset() * 1000 * 60;
  //This will be come the same date again in UTC
  return new Date(date.valueOf() - adjustment);
}

export function areContiguous(dates: Date[]) {
  for (var i = 0; i < dates.length - 1; i++) {
    var current = moment(dates[i]);
    var next = moment(dates[i + 1]);
    if (!current.add(1, "day").isSame(next)) {
      return false;
    }
  }
  return true;
}


export function getDateRanges(dates: Date[]): IDateRange[] {
  var results = new Array<IDateRange>();
  var copy = dates.slice();
  if (copy.length === 0) return [];
  var range: IDateRange = {start: copy[0], end: copy[0]};
  results.push(range);
  for (var i = 1; i < dates.length; i++) {
    var date = dates[i];
    var previousDate = moment(date).add(-1, "day").toDate();
    if (isSameDate(range.end, previousDate)) {
      range.end = date;
    } else {
      range = {start: date, end: date};
      results.push(range);
    }
  }
  return results;
}


// Returns the names of properties that are of the specified type.
// Ex: `PropNamesOfType<{ name: string, dob: Date }, Date>` returns "dob"
type PropNamesOfType<T, PropType> = { [K in keyof T]: [T[K]] extends [PropType] ? K : never }[keyof T];


// Gets the names of fields/props of type `Date`.
// Ex: `DatePropNames<{ name: string, dob: Date }, Date>` returns "dob"
type DatePropNames<T> = PropNamesOfType<T, (Date | undefined | null)>;

/**
 * Sorts an array of items based on a Date field/property.
 * Example: If `items` is an array of type: `{ id: number, dob: Date }`,
 * `sortOnDateField(items, "dob")` will sort the array on the `dob` field.
 *
 * @param {T[]} items The array to sort. (A copy of the array will be sorted -- not the original array.)
 * @param {K} sortKey The name of the property to sort on.
 * @param {boolean} [sortDescending=false] Sorts in descending order when `true`.
 * @returns The sorted array.
 */
export function sortOnDateField<T, K extends DatePropNames<T>>(items: T[] | undefined, sortKey: K, sortDescending = false) {
  const sortOrder = sortDescending ? dateTimeSortOrderDesc : dateTimeSortOrder;
  return ((items && items.slice()) || [])
    //.map(a => a || {}) // map null/undefined items
    .sort((a, b) => sortOrder(a && a[sortKey] as unknown as Date, b && b[sortKey] as unknown as Date));
}


export function dateTimeSortOrder(a?: Date | null, b?: Date | null) {
  // Note: JavaScript `Array.sort()` always sorts `undefined` values to
  // the end of the array, and does not call the compare function for `undefined` values.
  // If that's not the desired outcome, call `map` on the array first
  // to convert `undefined` values to something else.

  // Note: Sorts `null` values to the beginning of the array.
  return (a || Number(-1)).valueOf() - (b || Number(-1)).valueOf();
}

export function dateTimeSortOrderDesc(a?: Date | null, b?: Date | null) {
  return -dateTimeSortOrder(a, b);
}

// export function sortDateAsc(dates: Date[]): Date[] {
//   return dates.slice().sort(dateTimeSortOrder)
// }
