"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPaymentDevice_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var EPaymentDevice_Factory = /** @class */ (function () {
    function EPaymentDevice_Factory() {
    }
    EPaymentDevice_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DeviceID: model.DeviceID,
            DeviceInUse: model.DeviceInUse,
            DeviceName: model.DeviceName,
            EPaymentsVersion: model.EPaymentsVersion,
            ExternalDeviceId: model.ExternalDeviceId,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            TsysDeviceId: model.TsysDeviceId,
        };
        return result;
    };
    EPaymentDevice_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DeviceID: model.DeviceID,
            DeviceInUse: model.DeviceInUse,
            DeviceName: model.DeviceName,
            EPaymentsVersion: model.EPaymentsVersion,
            ExternalDeviceId: model.ExternalDeviceId,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            TsysDeviceId: model.TsysDeviceId,
        };
        return result;
    };
    return EPaymentDevice_Factory;
}());
exports.EPaymentDevice_Factory = EPaymentDevice_Factory;
