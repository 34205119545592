"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicationType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MedicationType_Factory = /** @class */ (function () {
    function MedicationType_Factory() {
    }
    MedicationType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CreatesMedicalAlert: model.CreatesMedicalAlert,
            MedicationType1: model.MedicationType1,
            NotUserEditable: model.NotUserEditable,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    MedicationType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CreatesMedicalAlert: model.CreatesMedicalAlert,
            MedicationType1: model.MedicationType1,
            NotUserEditable: model.NotUserEditable,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return MedicationType_Factory;
}());
exports.MedicationType_Factory = MedicationType_Factory;
