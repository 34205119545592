"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnterpriseUserLocation_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var EnterpriseUserLocation_Factory = /** @class */ (function () {
    function EnterpriseUserLocation_Factory() {
    }
    EnterpriseUserLocation_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseLocationId: model.EnterpriseLocationId,
            RecordId: model.RecordId,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    EnterpriseUserLocation_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseLocationId: model.EnterpriseLocationId,
            RecordId: model.RecordId,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return EnterpriseUserLocation_Factory;
}());
exports.EnterpriseUserLocation_Factory = EnterpriseUserLocation_Factory;
