import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { logout, tryRestoreSession } from "../authService";
import { getTokensFromStorage, getCurrentTenantSelection, saveTokensToStorage, getConnectionFromStorage } from "../authService/storage";
import CallingProcessType from "../../models/CallingProcessTypes";

export enum ContentTypes  {
  MultipartFormData = 'multipart/form-data'
}


interface IServerApiInnerError {
  type: string;
  message: string;
  stacktrace: string;
  innerError?: IServerApiInnerError;
}

export interface IServerApiError {
  error: {
    code: string;
    message: string;
    innererror?: IServerApiInnerError;
  }
}

function isServerApiError(error: any): error is IServerApiError {
  return error.error && error.error.code && error.error.message;
}


// This is returned, for example, when the WebApi fails to parse the JSON (ex. invalid Guid)
export interface IServerException {
  Message: string;
  ExceptionType: string;
  ExceptionMessage: string;
}


function isServerException(error: any): error is IServerException {
  return error.Message && error.ExceptionType && error.ExceptionMessage;
}


function isAxiosError<T = any>(error: any): error is AxiosError<T> {
  return error && error.isAxiosError && error.config;
}


export class HttpError extends Error {
  readonly statusCode: number | undefined;

  constructor(message: string, statusCode?: number) {
    super(message);
    this.name = "XHttpError" + (statusCode ? `.${statusCode}` : '');
    this.statusCode = statusCode;
  }
}


// Create custom instance for API reqests
const http = axios.create();

function setRequestAccessToken(config: AxiosRequestConfig, accessToken: string) {
  console.assert(!!accessToken);
  config.headers.Authorization = `Bearer ${accessToken}`;
}


const tenantInstallationUserIdHeaderName = 'X-TenantInstallationUserId';
const callingProcessTypeHeaderName = "X-CallingProcessType";


function requestInterceptor(config: AxiosRequestConfig) {
  // Calling process type
  config.headers[callingProcessTypeHeaderName] = CallingProcessType.EForms;

  // Bearer token
  if (!config.headers.Authorization) {
    const authTokens =  getTokensFromStorage();
    const token = authTokens && authTokens.accessToken.token;
    if (token) {
      setRequestAccessToken(config, token);
    }
  }

  // Tenant installation user
  if (!config.headers[tenantInstallationUserIdHeaderName]) {
    const tenantSelection = getCurrentTenantSelection();
    if (tenantSelection) {
      config.headers[tenantInstallationUserIdHeaderName] = tenantSelection.TenantInstallationUserId;
    }
  }

  if (!config.headers['X-SignalR-ConnectionId']) {
    var connectionId = getConnectionFromStorage();
    if (connectionId) {
      config.headers['X-SignalR-ConnectionId'] = connectionId;
    }
  }

  return config;
};




function throwHttpError(error: AxiosError<any>, isRetry: boolean): never {
  const statusCode = error.response && error.response.status;
  console.assert(statusCode !== 401);
  const prefix = isRetry ? "[Retry-Phase] " : "";

  // Handle "400 - Bad Data" error.
  if (statusCode === 400) {
    const errorResponse = error.response!.data;

    if (isServerException(errorResponse)) {
      throw new HttpError(prefix + errorResponse.ExceptionMessage, statusCode);
    }

    if (isServerApiError(errorResponse)) {
      throw new HttpError(prefix + errorResponse.error.message, statusCode);
    }

    if (errorResponse.Message) {
      throw new HttpError(prefix + errorResponse.Message, statusCode);
    }

    throw new HttpError(prefix + errorResponse, statusCode);
  }

  throw new HttpError(prefix + error.message, statusCode);
}



async function handleAuthErrorResponse(error: AxiosError<any>) {
  const statusCode = error.response && error.response.status;
  console.assert(statusCode === 401);

  // // Important: calling an API before the user has logged-in causes the app to go into an infinite reload loop.
  // if (!getTokensFromStorage()) {
  //   throw new HttpError("API called before login.")
  // }

  const tokens = await tryRestoreSession();
  if (!tokens) {
    // No refresh token. Can't continue :(
    logout(true);
    throw new HttpError("Session renewal failed. Forcing logout.", statusCode);
  }

  // Session was restored successfully. Resend the request.
  const { config: originalRequestConfig } = error;
  setRequestAccessToken(originalRequestConfig, tokens.accessToken.token);
  try {
    // We don't want the request/response interceptors to run again on this  request.
    // So, we'll use the standard/unconfigured Axios instance.
    const response = await axios.request(originalRequestConfig);
    return response;
  }
  catch(ex)  {
    console.assert(isAxiosError(ex));
    throwHttpError(ex, true);
  }
}



function errorResponseInterceptor(error: AxiosError<any>) {
  // NOTE: When a CORS request fails, no response is passed along to Axios, and Axios will return
  // `error.code === undefined`, `error.response === undefined` and `error.message` === "Network Error".
  // Ref: https://github.com/axios/axios/issues/383
  const statusCode = error.response && error.response.status;
  if (!statusCode) {
    throw new HttpError(typeof error.code === 'undefined' ?  "Possible CORS failure." : error.message, statusCode);
  }

  // Is this an authentication error?
  if (statusCode === 401) {
    return handleAuthErrorResponse(error);
  }

  throwHttpError(error, false);
};


const authDebugger = {
  enabled: false,

  interceptor(response: AxiosResponse<any>) {
    if (!authDebugger.enabled) {
      return response;
    }

    response = {
      config: response.config,
      status: 401,
      data: {},
      headers: [],
      statusText: "Debug auth failure"
    }

    const error: AxiosError<any> = {
      config: response.config,
      isAxiosError: true,
      response,
      name: "debugAuth",
      message: "Debug auth failure",
      toJSON: () => ({})
    }

    // Force access token expiration
    const tokens = getTokensFromStorage()!;
    tokens.accessToken.expiresOn = new Date(0);
    saveTokensToStorage(tokens);
    // Call error interceptor
    return errorResponseInterceptor(error);
  }
}

export function enableAuthDebugger() {
  authDebugger.enabled = true;
}



http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(authDebugger.interceptor, errorResponseInterceptor);
export default http;

