"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientConnection_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Patient_1 = require("./Patient");
var PatientConnectionType_1 = require("./PatientConnectionType");
var PatientConnection_Factory = /** @class */ (function () {
    function PatientConnection_Factory() {
    }
    PatientConnection_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ConnectedPatient: Patient_1.Patient_Factory.CreateIncoming(model.ConnectedPatient),
            ConnectedPatientId: model.ConnectedPatientId,
            IsByInsurancePolicy: model.IsByInsurancePolicy,
            IsByResponsibleParty: model.IsByResponsibleParty,
            IsForScheduling: model.IsForScheduling,
            IsManual: model.IsManual,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientConnectionType: PatientConnectionType_1.PatientConnectionType_Factory.CreateIncoming(model.PatientConnectionType),
            PatientConnectionTypeCode: model.PatientConnectionTypeCode,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientConnection_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ConnectedPatient: Patient_1.Patient_Factory.CreateOutgoing(model.ConnectedPatient),
            ConnectedPatientId: model.ConnectedPatientId,
            IsByInsurancePolicy: model.IsByInsurancePolicy,
            IsByResponsibleParty: model.IsByResponsibleParty,
            IsForScheduling: model.IsForScheduling,
            IsManual: model.IsManual,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientConnectionType: PatientConnectionType_1.PatientConnectionType_Factory.CreateOutgoing(model.PatientConnectionType),
            PatientConnectionTypeCode: model.PatientConnectionTypeCode,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientConnection_Factory;
}());
exports.PatientConnection_Factory = PatientConnection_Factory;
