"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSTreatmentArrangement_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCourseDetail_1 = require("./NHSCourseDetail");
var NHSTreatmentArrangement_Factory = /** @class */ (function () {
    function NHSTreatmentArrangement_Factory() {
    }
    NHSTreatmentArrangement_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CastsAvailable: model.CastsAvailable,
            CourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateIncoming(model.CourseDetail),
            FreeRepairOrReplacementComents: model.FreeRepairOrReplacementComents,
            FreeRepairOrReplacementRequired: model.FreeRepairOrReplacementRequired,
            FTRComments: model.FTRComments,
            FTRFeeRequired: model.FTRFeeRequired,
            NHSCourseDetailsId: model.NHSCourseDetailsId,
            RadiographsExcluded: model.RadiographsExcluded,
            RadiographsExcludedReason: model.RadiographsExcludedReason,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            ReferralReason: model.ReferralReason,
            RefusedTreatmentComments: model.RefusedTreatmentComments,
        };
        return result;
    };
    NHSTreatmentArrangement_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CastsAvailable: model.CastsAvailable,
            CourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateOutgoing(model.CourseDetail),
            FreeRepairOrReplacementComents: model.FreeRepairOrReplacementComents,
            FreeRepairOrReplacementRequired: model.FreeRepairOrReplacementRequired,
            FTRComments: model.FTRComments,
            FTRFeeRequired: model.FTRFeeRequired,
            NHSCourseDetailsId: model.NHSCourseDetailsId,
            RadiographsExcluded: model.RadiographsExcluded,
            RadiographsExcludedReason: model.RadiographsExcludedReason,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            ReferralReason: model.ReferralReason,
            RefusedTreatmentComments: model.RefusedTreatmentComments,
        };
        return result;
    };
    return NHSTreatmentArrangement_Factory;
}());
exports.NHSTreatmentArrangement_Factory = NHSTreatmentArrangement_Factory;
