"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracMaterial_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracCaseMaterial_1 = require("./LabtracCaseMaterial");
var LabtracLab_1 = require("./LabtracLab");
var LabtracMaterialGroup_1 = require("./LabtracMaterialGroup");
var LabtracMaterial_Factory = /** @class */ (function () {
    function LabtracMaterial_Factory() {
    }
    LabtracMaterial_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DisplaySequence: model.DisplaySequence,
            ExternalId: model.ExternalId,
            LabCode: model.LabCode,
            LabReference: model.LabReference,
            LabtracCaseMaterials: model.LabtracCaseMaterials && model.LabtracCaseMaterials.map(function (i) { return LabtracCaseMaterial_1.LabtracCaseMaterial_Factory.CreateIncoming(i); }),
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateIncoming(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            LabtracMaterialGroup: LabtracMaterialGroup_1.LabtracMaterialGroup_Factory.CreateIncoming(model.LabtracMaterialGroup),
            LabtracMaterialGroupId: model.LabtracMaterialGroupId,
            Name: model.Name,
            Notes: model.Notes,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    LabtracMaterial_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DisplaySequence: model.DisplaySequence,
            ExternalId: model.ExternalId,
            LabCode: model.LabCode,
            LabReference: model.LabReference,
            LabtracCaseMaterials: model.LabtracCaseMaterials && model.LabtracCaseMaterials.map(function (i) { return LabtracCaseMaterial_1.LabtracCaseMaterial_Factory.CreateOutgoing(i); }),
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateOutgoing(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            LabtracMaterialGroup: LabtracMaterialGroup_1.LabtracMaterialGroup_Factory.CreateOutgoing(model.LabtracMaterialGroup),
            LabtracMaterialGroupId: model.LabtracMaterialGroupId,
            Name: model.Name,
            Notes: model.Notes,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return LabtracMaterial_Factory;
}());
exports.LabtracMaterial_Factory = LabtracMaterial_Factory;
