"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VitalsRecordVersion_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var HealthMeasurement_1 = require("./HealthMeasurement");
var User_1 = require("./User");
var VitalsRecordVersion_Factory = /** @class */ (function () {
    function VitalsRecordVersion_Factory() {
    }
    VitalsRecordVersion_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Measurements: model.Measurements && model.Measurements.map(function (i) { return HealthMeasurement_1.HealthMeasurement_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
            VersionNumber: model.VersionNumber,
            VitalsRecordId: model.VitalsRecordId,
        };
        return result;
    };
    VitalsRecordVersion_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Measurements: model.Measurements && model.Measurements.map(function (i) { return HealthMeasurement_1.HealthMeasurement_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
            VersionNumber: model.VersionNumber,
            VitalsRecordId: model.VitalsRecordId,
        };
        return result;
    };
    return VitalsRecordVersion_Factory;
}());
exports.VitalsRecordVersion_Factory = VitalsRecordVersion_Factory;
