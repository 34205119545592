"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralFollowUp_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Appointment_1 = require("./Appointment");
var Referral_1 = require("./Referral");
var ReferralFollowUp_Factory = /** @class */ (function () {
    function ReferralFollowUp_Factory() {
    }
    ReferralFollowUp_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Appointment: Appointment_1.Appointment_Factory.CreateIncoming(model.Appointment),
            AppointmentId: model.AppointmentId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referral: Referral_1.Referral_Factory.CreateIncoming(model.Referral),
            ReferralCompleted: Date_Factory_1.Date_Factory.CreateIncoming(model.ReferralCompleted),
            ReferralCompleteMethod: model.ReferralCompleteMethod,
            ReferralId: model.ReferralId,
        };
        return result;
    };
    ReferralFollowUp_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Appointment: Appointment_1.Appointment_Factory.CreateOutgoing(model.Appointment),
            AppointmentId: model.AppointmentId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referral: Referral_1.Referral_Factory.CreateOutgoing(model.Referral),
            ReferralCompleted: Date_Factory_1.Date_Factory.CreateOutgoing(model.ReferralCompleted),
            ReferralCompleteMethod: model.ReferralCompleteMethod,
            ReferralId: model.ReferralId,
        };
        return result;
    };
    return ReferralFollowUp_Factory;
}());
exports.ReferralFollowUp_Factory = ReferralFollowUp_Factory;
