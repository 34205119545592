"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmsMessage_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SmsMessage_Factory = /** @class */ (function () {
    function SmsMessage_Factory() {
    }
    SmsMessage_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Body: model.Body,
            Cost: model.Cost,
            Currency: model.Currency,
            ErrorCode: model.ErrorCode,
            ErrorMessage: model.ErrorMessage,
            IsInboundMessage: model.IsInboundMessage,
            PartnerResourceId: model.PartnerResourceId,
            Receiver: model.Receiver,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RowVersion: model.RowVersion,
            Segments: model.Segments,
            Sender: model.Sender,
            Status: model.Status,
        };
        return result;
    };
    SmsMessage_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Body: model.Body,
            Cost: model.Cost,
            Currency: model.Currency,
            ErrorCode: model.ErrorCode,
            ErrorMessage: model.ErrorMessage,
            IsInboundMessage: model.IsInboundMessage,
            PartnerResourceId: model.PartnerResourceId,
            Receiver: model.Receiver,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RowVersion: model.RowVersion,
            Segments: model.Segments,
            Sender: model.Sender,
            Status: model.Status,
        };
        return result;
    };
    return SmsMessage_Factory;
}());
exports.SmsMessage_Factory = SmsMessage_Factory;
