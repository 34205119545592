"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSActivityBand_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSActivityBandValue_1 = require("./NHSActivityBandValue");
var NHSCategoryBand_1 = require("./NHSCategoryBand");
var NHSActivityBand_Factory = /** @class */ (function () {
    function NHSActivityBand_Factory() {
    }
    NHSActivityBand_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BandDescription: model.BandDescription,
            BCDSBand: model.BCDSBand,
            InternalNotes: model.InternalNotes,
            NHSActivityBandID: model.NHSActivityBandID,
            NHSActivityBandValues: model.NHSActivityBandValues && model.NHSActivityBandValues.map(function (i) { return NHSActivityBandValue_1.NHSActivityBandValue_Factory.CreateIncoming(i); }),
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateIncoming(i); }),
            TypeCode: model.TypeCode,
        };
        return result;
    };
    NHSActivityBand_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BandDescription: model.BandDescription,
            BCDSBand: model.BCDSBand,
            InternalNotes: model.InternalNotes,
            NHSActivityBandID: model.NHSActivityBandID,
            NHSActivityBandValues: model.NHSActivityBandValues && model.NHSActivityBandValues.map(function (i) { return NHSActivityBandValue_1.NHSActivityBandValue_Factory.CreateOutgoing(i); }),
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateOutgoing(i); }),
            TypeCode: model.TypeCode,
        };
        return result;
    };
    return NHSActivityBand_Factory;
}());
exports.NHSActivityBand_Factory = NHSActivityBand_Factory;
