"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reminder_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReminderPatientDetail_1 = require("./ReminderPatientDetail");
var ReminderUser_1 = require("./ReminderUser");
var Reminder_Factory = /** @class */ (function () {
    function Reminder_Factory() {
    }
    Reminder_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdditionalDetails: model.AdditionalDetails,
            CompletedByUserId: model.CompletedByUserId,
            CreatedByUserId: model.CreatedByUserId,
            DateDue: Date_Factory_1.Date_Factory.CreateIncoming(model.DateDue, { ignoreTimePortion: true }),
            Description: model.Description,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReminderPatientDetail: ReminderPatientDetail_1.ReminderPatientDetail_Factory.CreateIncoming(model.ReminderPatientDetail),
            ReminderUsers: model.ReminderUsers && model.ReminderUsers.map(function (i) { return ReminderUser_1.ReminderUser_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    Reminder_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdditionalDetails: model.AdditionalDetails,
            CompletedByUserId: model.CompletedByUserId,
            CreatedByUserId: model.CreatedByUserId,
            DateDue: Date_Factory_1.Date_Factory.CreateOutgoing(model.DateDue, { ignoreTimePortion: true }),
            Description: model.Description,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReminderPatientDetail: ReminderPatientDetail_1.ReminderPatientDetail_Factory.CreateOutgoing(model.ReminderPatientDetail),
            ReminderUsers: model.ReminderUsers && model.ReminderUsers.map(function (i) { return ReminderUser_1.ReminderUser_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return Reminder_Factory;
}());
exports.Reminder_Factory = Reminder_Factory;
