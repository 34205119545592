"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CPTCode_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CPTCode_Factory = /** @class */ (function () {
    function CPTCode_Factory() {
    }
    CPTCode_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CPTCategory: model.CPTCategory,
            CPTCode1: model.CPTCode1,
            LongDescription: model.LongDescription,
            MediumDescription: model.MediumDescription,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ShortDescription: model.ShortDescription,
        };
        return result;
    };
    CPTCode_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CPTCategory: model.CPTCategory,
            CPTCode1: model.CPTCode1,
            LongDescription: model.LongDescription,
            MediumDescription: model.MediumDescription,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ShortDescription: model.ShortDescription,
        };
        return result;
    };
    return CPTCode_Factory;
}());
exports.CPTCode_Factory = CPTCode_Factory;
