"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImplantBrand_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ImplantBrandSize_1 = require("./ImplantBrandSize");
var ImplantBrand_Factory = /** @class */ (function () {
    function ImplantBrand_Factory() {
    }
    ImplantBrand_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ImplantBrandSizes: model.ImplantBrandSizes && model.ImplantBrandSizes.map(function (i) { return ImplantBrandSize_1.ImplantBrandSize_Factory.CreateIncoming(i); }),
            ImplantManufacturerId: model.ImplantManufacturerId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    ImplantBrand_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ImplantBrandSizes: model.ImplantBrandSizes && model.ImplantBrandSizes.map(function (i) { return ImplantBrandSize_1.ImplantBrandSize_Factory.CreateOutgoing(i); }),
            ImplantManufacturerId: model.ImplantManufacturerId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return ImplantBrand_Factory;
}());
exports.ImplantBrand_Factory = ImplantBrand_Factory;
