"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsDataElement_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsBinaryElementDetail_1 = require("./EFormsBinaryElementDetail");
var EFormsDataElementCategory_1 = require("./EFormsDataElementCategory");
var EFormsNumericElementDetail_1 = require("./EFormsNumericElementDetail");
var EFormsDataElementOptionType_1 = require("./EFormsDataElementOptionType");
var EFormsSelectElementOption_1 = require("./EFormsSelectElementOption");
var EFormsTemplateDataElement_1 = require("./EFormsTemplateDataElement");
var EFormsToothChartElementDetail_1 = require("./EFormsToothChartElementDetail");
var EFormsToothChartElementTooth_1 = require("./EFormsToothChartElementTooth");
var EFormsDataElementType_1 = require("./EFormsDataElementType");
var EFormsDataElement_Factory = /** @class */ (function () {
    function EFormsDataElement_Factory() {
    }
    EFormsDataElement_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AssociatedTemplateCount: model.AssociatedTemplateCount,
            BinaryDetail: EFormsBinaryElementDetail_1.EFormsBinaryElementDetail_Factory.CreateIncoming(model.BinaryDetail),
            Category: EFormsDataElementCategory_1.EFormsDataElementCategory_Factory.CreateIncoming(model.Category),
            CategoryId: model.CategoryId,
            IsMergeField: model.IsMergeField,
            IsSystemQuestion: model.IsSystemQuestion,
            LabelLong: model.LabelLong,
            LabelShort: model.LabelShort,
            LatestVersion: EFormsDataElement_Factory.CreateIncoming(model.LatestVersion),
            LatestVersionId: model.LatestVersionId,
            Name: model.Name,
            NotUserEditable: model.NotUserEditable,
            NumericDetail: EFormsNumericElementDetail_1.EFormsNumericElementDetail_Factory.CreateIncoming(model.NumericDetail),
            OptionType: EFormsDataElementOptionType_1.EFormsDataElementOptionType_Factory.CreateIncoming(model.OptionType),
            OptionTypeCode: model.OptionTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SelectOptions: model.SelectOptions && model.SelectOptions.map(function (i) { return EFormsSelectElementOption_1.EFormsSelectElementOption_Factory.CreateIncoming(i); }),
            TemplateDataElements: model.TemplateDataElements && model.TemplateDataElements.map(function (i) { return EFormsTemplateDataElement_1.EFormsTemplateDataElement_Factory.CreateIncoming(i); }),
            ToothChartDetail: EFormsToothChartElementDetail_1.EFormsToothChartElementDetail_Factory.CreateIncoming(model.ToothChartDetail),
            ToothChartTeeth: model.ToothChartTeeth && model.ToothChartTeeth.map(function (i) { return EFormsToothChartElementTooth_1.EFormsToothChartElementTooth_Factory.CreateIncoming(i); }),
            Type: EFormsDataElementType_1.EFormsDataElementType_Factory.CreateIncoming(model.Type),
            TypeCode: model.TypeCode,
        };
        return result;
    };
    EFormsDataElement_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AssociatedTemplateCount: model.AssociatedTemplateCount,
            BinaryDetail: EFormsBinaryElementDetail_1.EFormsBinaryElementDetail_Factory.CreateOutgoing(model.BinaryDetail),
            Category: EFormsDataElementCategory_1.EFormsDataElementCategory_Factory.CreateOutgoing(model.Category),
            CategoryId: model.CategoryId,
            IsMergeField: model.IsMergeField,
            IsSystemQuestion: model.IsSystemQuestion,
            LabelLong: model.LabelLong,
            LabelShort: model.LabelShort,
            LatestVersion: EFormsDataElement_Factory.CreateOutgoing(model.LatestVersion),
            LatestVersionId: model.LatestVersionId,
            Name: model.Name,
            NotUserEditable: model.NotUserEditable,
            NumericDetail: EFormsNumericElementDetail_1.EFormsNumericElementDetail_Factory.CreateOutgoing(model.NumericDetail),
            OptionType: EFormsDataElementOptionType_1.EFormsDataElementOptionType_Factory.CreateOutgoing(model.OptionType),
            OptionTypeCode: model.OptionTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SelectOptions: model.SelectOptions && model.SelectOptions.map(function (i) { return EFormsSelectElementOption_1.EFormsSelectElementOption_Factory.CreateOutgoing(i); }),
            TemplateDataElements: model.TemplateDataElements && model.TemplateDataElements.map(function (i) { return EFormsTemplateDataElement_1.EFormsTemplateDataElement_Factory.CreateOutgoing(i); }),
            ToothChartDetail: EFormsToothChartElementDetail_1.EFormsToothChartElementDetail_Factory.CreateOutgoing(model.ToothChartDetail),
            ToothChartTeeth: model.ToothChartTeeth && model.ToothChartTeeth.map(function (i) { return EFormsToothChartElementTooth_1.EFormsToothChartElementTooth_Factory.CreateOutgoing(i); }),
            Type: EFormsDataElementType_1.EFormsDataElementType_Factory.CreateOutgoing(model.Type),
            TypeCode: model.TypeCode,
        };
        return result;
    };
    return EFormsDataElement_Factory;
}());
exports.EFormsDataElement_Factory = EFormsDataElement_Factory;
