"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabCase_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Lab_1 = require("./Lab");
var Patient_1 = require("./Patient");
var LabCase_Factory = /** @class */ (function () {
    function LabCase_Factory() {
    }
    LabCase_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CaseComplete: model.CaseComplete,
            Description: model.Description,
            DueDate: Date_Factory_1.Date_Factory.CreateIncoming(model.DueDate),
            Lab: Lab_1.Lab_Factory.CreateIncoming(model.Lab),
            LabId: model.LabId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            ReceivedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ReceivedDate),
            RecordId: model.RecordId,
        };
        return result;
    };
    LabCase_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CaseComplete: model.CaseComplete,
            Description: model.Description,
            DueDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.DueDate),
            Lab: Lab_1.Lab_Factory.CreateOutgoing(model.Lab),
            LabId: model.LabId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            ReceivedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ReceivedDate),
            RecordId: model.RecordId,
        };
        return result;
    };
    return LabCase_Factory;
}());
exports.LabCase_Factory = LabCase_Factory;
