"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsMedicalQuestionnaireAnswerPrefill_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsMedicalQuestionnaireAnswerPrefill_Factory = /** @class */ (function () {
    function EFormsMedicalQuestionnaireAnswerPrefill_Factory() {
    }
    EFormsMedicalQuestionnaireAnswerPrefill_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Answer: model.Answer,
            Note: model.Note,
            NoteOnYes: model.NoteOnYes,
            QuestionId: model.QuestionId,
        };
        return result;
    };
    EFormsMedicalQuestionnaireAnswerPrefill_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Answer: model.Answer,
            Note: model.Note,
            NoteOnYes: model.NoteOnYes,
            QuestionId: model.QuestionId,
        };
        return result;
    };
    return EFormsMedicalQuestionnaireAnswerPrefill_Factory;
}());
exports.EFormsMedicalQuestionnaireAnswerPrefill_Factory = EFormsMedicalQuestionnaireAnswerPrefill_Factory;
