"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralSourceSpecialty_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReferralSourceSpecialty_Factory = /** @class */ (function () {
    function ReferralSourceSpecialty_Factory() {
    }
    ReferralSourceSpecialty_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            SpecialtyId: model.SpecialtyId,
            SpecialtyName: model.SpecialtyName,
        };
        return result;
    };
    ReferralSourceSpecialty_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            SpecialtyId: model.SpecialtyId,
            SpecialtyName: model.SpecialtyName,
        };
        return result;
    };
    return ReferralSourceSpecialty_Factory;
}());
exports.ReferralSourceSpecialty_Factory = ReferralSourceSpecialty_Factory;
