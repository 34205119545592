"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaimStatusCode_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceClaimStatusCode_Factory = /** @class */ (function () {
    function InsuranceClaimStatusCode_Factory() {
    }
    InsuranceClaimStatusCode_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimStatusCode: model.ClaimStatusCode,
            StatusDescription: model.StatusDescription,
        };
        return result;
    };
    InsuranceClaimStatusCode_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimStatusCode: model.ClaimStatusCode,
            StatusDescription: model.StatusDescription,
        };
        return result;
    };
    return InsuranceClaimStatusCode_Factory;
}());
exports.InsuranceClaimStatusCode_Factory = InsuranceClaimStatusCode_Factory;
