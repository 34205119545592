"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserWorkingDayChairTime_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Chair_1 = require("./Chair");
var UserWorkingDay_1 = require("./UserWorkingDay");
var UserWorkingDayChairTime_Factory = /** @class */ (function () {
    function UserWorkingDayChairTime_Factory() {
    }
    UserWorkingDayChairTime_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Chair: Chair_1.Chair_Factory.CreateIncoming(model.Chair),
            ChairId: model.ChairId,
            EndTime: Date_Factory_1.Date_Factory.CreateIncoming(model.EndTime),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartTime: Date_Factory_1.Date_Factory.CreateIncoming(model.StartTime),
            UserWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(model.UserWorkingDay),
            UserWorkingDayId: model.UserWorkingDayId,
        };
        return result;
    };
    UserWorkingDayChairTime_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Chair: Chair_1.Chair_Factory.CreateOutgoing(model.Chair),
            ChairId: model.ChairId,
            EndTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.EndTime),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartTime),
            UserWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(model.UserWorkingDay),
            UserWorkingDayId: model.UserWorkingDayId,
        };
        return result;
    };
    return UserWorkingDayChairTime_Factory;
}());
exports.UserWorkingDayChairTime_Factory = UserWorkingDayChairTime_Factory;
