"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsBinaryElementDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MedicalAlertType_1 = require("./MedicalAlertType");
var EFormsDataElementType_1 = require("./EFormsDataElementType");
var EFormsBinaryElementDetail_Factory = /** @class */ (function () {
    function EFormsBinaryElementDetail_Factory() {
    }
    EFormsBinaryElementDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AllowUndefined: model.AllowUndefined,
            MedicalAlertAnswer: model.MedicalAlertAnswer,
            MedicalAlertType: MedicalAlertType_1.MedicalAlertType_Factory.CreateIncoming(model.MedicalAlertType),
            MedicalAlertTypeCode: model.MedicalAlertTypeCode,
            PromptAnswer: model.PromptAnswer,
            PromptElementType: EFormsDataElementType_1.EFormsDataElementType_Factory.CreateIncoming(model.PromptElementType),
            PromptElementTypeCode: model.PromptElementTypeCode,
            PromptText: model.PromptText,
            RecordId: model.RecordId,
        };
        return result;
    };
    EFormsBinaryElementDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AllowUndefined: model.AllowUndefined,
            MedicalAlertAnswer: model.MedicalAlertAnswer,
            MedicalAlertType: MedicalAlertType_1.MedicalAlertType_Factory.CreateOutgoing(model.MedicalAlertType),
            MedicalAlertTypeCode: model.MedicalAlertTypeCode,
            PromptAnswer: model.PromptAnswer,
            PromptElementType: EFormsDataElementType_1.EFormsDataElementType_Factory.CreateOutgoing(model.PromptElementType),
            PromptElementTypeCode: model.PromptElementTypeCode,
            PromptText: model.PromptText,
            RecordId: model.RecordId,
        };
        return result;
    };
    return EFormsBinaryElementDetail_Factory;
}());
exports.EFormsBinaryElementDetail_Factory = EFormsBinaryElementDetail_Factory;
