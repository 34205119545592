"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var Location_1 = require("./Location");
var EmploymentStatus_1 = require("./EmploymentStatus");
var GenderType_1 = require("./GenderType");
var NHSContractMembership_1 = require("./NHSContractMembership");
var NHSUserDetail_1 = require("./NHSUserDetail");
var OrthoPatientTreatmentStatus_1 = require("./OrthoPatientTreatmentStatus");
var NHSContract_1 = require("./NHSContract");
var PatientConcern_1 = require("./PatientConcern");
var PatientNotification_1 = require("./PatientNotification");
var PermissionGroup_1 = require("./PermissionGroup");
var PhoneNumber_1 = require("./PhoneNumber");
var ProcedureList_1 = require("./ProcedureList");
var ProviderTaxonomyCode_1 = require("./ProviderTaxonomyCode");
var RecordStatus_1 = require("./RecordStatus");
var SalutationType_1 = require("./SalutationType");
var UserAdditionalInfoKSA_1 = require("./UserAdditionalInfoKSA");
var UserCredentials_1 = require("./UserCredentials");
var UserNotification_1 = require("./UserNotification");
var UserPermission_1 = require("./UserPermission");
var UserRegistration_1 = require("./UserRegistration");
var UserRoleType_1 = require("./UserRoleType");
var User_Factory = /** @class */ (function () {
    function User_Factory() {
    }
    User_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Address: Address_1.Address_Factory.CreateIncoming(model.Address),
            AddressId: model.AddressId,
            AutoClockIn: model.AutoClockIn,
            AutoClockOut: model.AutoClockOut,
            Birthdate: Date_Factory_1.Date_Factory.CreateIncoming(model.Birthdate),
            BlueCrossBlueShieldID: model.BlueCrossBlueShieldID,
            CanLogin: model.CanLogin,
            Color: model.Color,
            CommercialNumber: model.CommercialNumber,
            Consultant: model.Consultant,
            DefaultDoctorLocations: model.DefaultDoctorLocations && model.DefaultDoctorLocations.map(function (i) { return Location_1.Location_Factory.CreateIncoming(i); }),
            DefaultHygienistLocations: model.DefaultHygienistLocations && model.DefaultHygienistLocations.map(function (i) { return Location_1.Location_Factory.CreateIncoming(i); }),
            DefaultLocationId: model.DefaultLocationId,
            Degree: model.Degree,
            DeltaNumber: model.DeltaNumber,
            EmailAddress: model.EmailAddress,
            EmploymentStatus: EmploymentStatus_1.EmploymentStatus_Factory.CreateIncoming(model.EmploymentStatus),
            EmploymentStatusCode: model.EmploymentStatusCode,
            EnterpriseLocationId: model.EnterpriseLocationId,
            EULAVersionSeen: model.EULAVersionSeen,
            ExternalIdentifier: model.ExternalIdentifier,
            FirstName: model.FirstName,
            FullName: model.FullName,
            Gender: model.Gender,
            GenderType: GenderType_1.GenderType_Factory.CreateIncoming(model.GenderType),
            HireDate: Date_Factory_1.Date_Factory.CreateIncoming(model.HireDate),
            IsSystemUser: model.IsSystemUser,
            LastName: model.LastName,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            LicenseNumber: model.LicenseNumber,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationNumber: model.LocationNumber,
            MedicaidID: model.MedicaidID,
            MiddleName: model.MiddleName,
            NationalProviderIdentifier: model.NationalProviderIdentifier,
            NHSContractMemberships: model.NHSContractMemberships && model.NHSContractMemberships.map(function (i) { return NHSContractMembership_1.NHSContractMembership_Factory.CreateIncoming(i); }),
            NHSUserDetail: NHSUserDetail_1.NHSUserDetail_Factory.CreateIncoming(model.NHSUserDetail),
            Nickname: model.Nickname,
            OrthoPatientTreatmentStatuses: model.OrthoPatientTreatmentStatuses && model.OrthoPatientTreatmentStatuses.map(function (i) { return OrthoPatientTreatmentStatus_1.OrthoPatientTreatmentStatus_Factory.CreateIncoming(i); }),
            OwnedNHSContracts: model.OwnedNHSContracts && model.OwnedNHSContracts.map(function (i) { return NHSContract_1.NHSContract_Factory.CreateIncoming(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateIncoming(i); }),
            PatientNotifications: model.PatientNotifications && model.PatientNotifications.map(function (i) { return PatientNotification_1.PatientNotification_Factory.CreateIncoming(i); }),
            PayrollTypeId: model.PayrollTypeId,
            PermissionGroups: model.PermissionGroups && model.PermissionGroups.map(function (i) { return PermissionGroup_1.PermissionGroup_Factory.CreateIncoming(i); }),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(i); }),
            PostedEmailAddress: model.PostedEmailAddress,
            PreferredProcedureList: ProcedureList_1.ProcedureList_Factory.CreateIncoming(model.PreferredProcedureList),
            PreferredProcedureListId: model.PreferredProcedureListId,
            ProviderTaxonomyCode: ProviderTaxonomyCode_1.ProviderTaxonomyCode_Factory.CreateIncoming(model.ProviderTaxonomyCode),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
            RoleTypeCode: model.RoleTypeCode,
            SalutationCode: model.SalutationCode,
            SalutationType: SalutationType_1.SalutationType_Factory.CreateIncoming(model.SalutationType),
            SigningPin: model.SigningPin,
            SSOUserId: model.SSOUserId,
            TaxID: model.TaxID,
            TaxonomyCode: model.TaxonomyCode,
            TerminationDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TerminationDate),
            Title: model.Title,
            UPIN: model.UPIN,
            UserAdditionalInfoKSA: UserAdditionalInfoKSA_1.UserAdditionalInfoKSA_Factory.CreateIncoming(model.UserAdditionalInfoKSA),
            UserCredentials: model.UserCredentials && model.UserCredentials.map(function (i) { return UserCredentials_1.UserCredentials_Factory.CreateIncoming(i); }),
            UserNotifications: model.UserNotifications && model.UserNotifications.map(function (i) { return UserNotification_1.UserNotification_Factory.CreateIncoming(i); }),
            UserPermission: UserPermission_1.UserPermission_Factory.CreateIncoming(model.UserPermission),
            UserRegistrations: model.UserRegistrations && model.UserRegistrations.map(function (i) { return UserRegistration_1.UserRegistration_Factory.CreateIncoming(i); }),
            UserRoleType: UserRoleType_1.UserRoleType_Factory.CreateIncoming(model.UserRoleType),
            WebUserId: model.WebUserId,
        };
        return result;
    };
    User_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Address: Address_1.Address_Factory.CreateOutgoing(model.Address),
            AddressId: model.AddressId,
            AutoClockIn: model.AutoClockIn,
            AutoClockOut: model.AutoClockOut,
            Birthdate: Date_Factory_1.Date_Factory.CreateOutgoing(model.Birthdate),
            BlueCrossBlueShieldID: model.BlueCrossBlueShieldID,
            CanLogin: model.CanLogin,
            Color: model.Color,
            CommercialNumber: model.CommercialNumber,
            Consultant: model.Consultant,
            DefaultDoctorLocations: model.DefaultDoctorLocations && model.DefaultDoctorLocations.map(function (i) { return Location_1.Location_Factory.CreateOutgoing(i); }),
            DefaultHygienistLocations: model.DefaultHygienistLocations && model.DefaultHygienistLocations.map(function (i) { return Location_1.Location_Factory.CreateOutgoing(i); }),
            DefaultLocationId: model.DefaultLocationId,
            Degree: model.Degree,
            DeltaNumber: model.DeltaNumber,
            EmailAddress: model.EmailAddress,
            EmploymentStatus: EmploymentStatus_1.EmploymentStatus_Factory.CreateOutgoing(model.EmploymentStatus),
            EmploymentStatusCode: model.EmploymentStatusCode,
            EnterpriseLocationId: model.EnterpriseLocationId,
            EULAVersionSeen: model.EULAVersionSeen,
            ExternalIdentifier: model.ExternalIdentifier,
            FirstName: model.FirstName,
            FullName: model.FullName,
            Gender: model.Gender,
            GenderType: GenderType_1.GenderType_Factory.CreateOutgoing(model.GenderType),
            HireDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.HireDate),
            IsSystemUser: model.IsSystemUser,
            LastName: model.LastName,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            LicenseNumber: model.LicenseNumber,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationNumber: model.LocationNumber,
            MedicaidID: model.MedicaidID,
            MiddleName: model.MiddleName,
            NationalProviderIdentifier: model.NationalProviderIdentifier,
            NHSContractMemberships: model.NHSContractMemberships && model.NHSContractMemberships.map(function (i) { return NHSContractMembership_1.NHSContractMembership_Factory.CreateOutgoing(i); }),
            NHSUserDetail: NHSUserDetail_1.NHSUserDetail_Factory.CreateOutgoing(model.NHSUserDetail),
            Nickname: model.Nickname,
            OrthoPatientTreatmentStatuses: model.OrthoPatientTreatmentStatuses && model.OrthoPatientTreatmentStatuses.map(function (i) { return OrthoPatientTreatmentStatus_1.OrthoPatientTreatmentStatus_Factory.CreateOutgoing(i); }),
            OwnedNHSContracts: model.OwnedNHSContracts && model.OwnedNHSContracts.map(function (i) { return NHSContract_1.NHSContract_Factory.CreateOutgoing(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateOutgoing(i); }),
            PatientNotifications: model.PatientNotifications && model.PatientNotifications.map(function (i) { return PatientNotification_1.PatientNotification_Factory.CreateOutgoing(i); }),
            PayrollTypeId: model.PayrollTypeId,
            PermissionGroups: model.PermissionGroups && model.PermissionGroups.map(function (i) { return PermissionGroup_1.PermissionGroup_Factory.CreateOutgoing(i); }),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(i); }),
            PostedEmailAddress: model.PostedEmailAddress,
            PreferredProcedureList: ProcedureList_1.ProcedureList_Factory.CreateOutgoing(model.PreferredProcedureList),
            PreferredProcedureListId: model.PreferredProcedureListId,
            ProviderTaxonomyCode: ProviderTaxonomyCode_1.ProviderTaxonomyCode_Factory.CreateOutgoing(model.ProviderTaxonomyCode),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
            RoleTypeCode: model.RoleTypeCode,
            SalutationCode: model.SalutationCode,
            SalutationType: SalutationType_1.SalutationType_Factory.CreateOutgoing(model.SalutationType),
            SigningPin: model.SigningPin,
            SSOUserId: model.SSOUserId,
            TaxID: model.TaxID,
            TaxonomyCode: model.TaxonomyCode,
            TerminationDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TerminationDate),
            Title: model.Title,
            UPIN: model.UPIN,
            UserAdditionalInfoKSA: UserAdditionalInfoKSA_1.UserAdditionalInfoKSA_Factory.CreateOutgoing(model.UserAdditionalInfoKSA),
            UserCredentials: model.UserCredentials && model.UserCredentials.map(function (i) { return UserCredentials_1.UserCredentials_Factory.CreateOutgoing(i); }),
            UserNotifications: model.UserNotifications && model.UserNotifications.map(function (i) { return UserNotification_1.UserNotification_Factory.CreateOutgoing(i); }),
            UserPermission: UserPermission_1.UserPermission_Factory.CreateOutgoing(model.UserPermission),
            UserRegistrations: model.UserRegistrations && model.UserRegistrations.map(function (i) { return UserRegistration_1.UserRegistration_Factory.CreateOutgoing(i); }),
            UserRoleType: UserRoleType_1.UserRoleType_Factory.CreateOutgoing(model.UserRoleType),
            WebUserId: model.WebUserId,
        };
        return result;
    };
    return User_Factory;
}());
exports.User_Factory = User_Factory;
