"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsToothChartElementDentitionType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsToothChartElementDentitionType_Factory = /** @class */ (function () {
    function EFormsToothChartElementDentitionType_Factory() {
    }
    EFormsToothChartElementDentitionType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
        };
        return result;
    };
    EFormsToothChartElementDentitionType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
        };
        return result;
    };
    return EFormsToothChartElementDentitionType_Factory;
}());
exports.EFormsToothChartElementDentitionType_Factory = EFormsToothChartElementDentitionType_Factory;
