"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientProcedureNote_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Note_1 = require("./Note");
var PatientProcedure_1 = require("./PatientProcedure");
var PatientProcedureNoteType_1 = require("./PatientProcedureNoteType");
var PatientProcedureNote_Factory = /** @class */ (function () {
    function PatientProcedureNote_Factory() {
    }
    PatientProcedureNote_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            FeeChangeAmount: model.FeeChangeAmount,
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateIncoming(model.NoteTakenOn),
            NoteTypeCode: model.NoteTypeCode,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            PatientProcedureNoteType: PatientProcedureNoteType_1.PatientProcedureNoteType_Factory.CreateIncoming(model.PatientProcedureNoteType),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientProcedureNote_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            FeeChangeAmount: model.FeeChangeAmount,
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.NoteTakenOn),
            NoteTypeCode: model.NoteTypeCode,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            PatientProcedureNoteType: PatientProcedureNoteType_1.PatientProcedureNoteType_Factory.CreateOutgoing(model.PatientProcedureNoteType),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientProcedureNote_Factory;
}());
exports.PatientProcedureNote_Factory = PatientProcedureNote_Factory;
