"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaimItemAttachmentRequirement_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceClaimItem_1 = require("./InsuranceClaimItem");
var InsuranceClaimItemAttachmentRequirement_Factory = /** @class */ (function () {
    function InsuranceClaimItemAttachmentRequirement_Factory() {
    }
    InsuranceClaimItemAttachmentRequirement_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            InsuranceClaimItem: InsuranceClaimItem_1.InsuranceClaimItem_Factory.CreateIncoming(model.InsuranceClaimItem),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequiresFullMouthSeries: model.RequiresFullMouthSeries,
            RequiresNarative: model.RequiresNarative,
            RequiresPanoramic: model.RequiresPanoramic,
            RequiresPerioChart: model.RequiresPerioChart,
            RequiresXray: model.RequiresXray,
        };
        return result;
    };
    InsuranceClaimItemAttachmentRequirement_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            InsuranceClaimItem: InsuranceClaimItem_1.InsuranceClaimItem_Factory.CreateOutgoing(model.InsuranceClaimItem),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequiresFullMouthSeries: model.RequiresFullMouthSeries,
            RequiresNarative: model.RequiresNarative,
            RequiresPanoramic: model.RequiresPanoramic,
            RequiresPerioChart: model.RequiresPerioChart,
            RequiresXray: model.RequiresXray,
        };
        return result;
    };
    return InsuranceClaimItemAttachmentRequirement_Factory;
}());
exports.InsuranceClaimItemAttachmentRequirement_Factory = InsuranceClaimItemAttachmentRequirement_Factory;
