"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSChargeBand_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCategoryBand_1 = require("./NHSCategoryBand");
var NHSChargeBandValue_1 = require("./NHSChargeBandValue");
var NHSChargeBand_Factory = /** @class */ (function () {
    function NHSChargeBand_Factory() {
    }
    NHSChargeBand_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BandDescription: model.BandDescription,
            InternalNotes: model.InternalNotes,
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateIncoming(i); }),
            NHSChargeBandID: model.NHSChargeBandID,
            NHSChargeBandValues: model.NHSChargeBandValues && model.NHSChargeBandValues.map(function (i) { return NHSChargeBandValue_1.NHSChargeBandValue_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    NHSChargeBand_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BandDescription: model.BandDescription,
            InternalNotes: model.InternalNotes,
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateOutgoing(i); }),
            NHSChargeBandID: model.NHSChargeBandID,
            NHSChargeBandValues: model.NHSChargeBandValues && model.NHSChargeBandValues.map(function (i) { return NHSChargeBandValue_1.NHSChargeBandValue_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return NHSChargeBand_Factory;
}());
exports.NHSChargeBand_Factory = NHSChargeBand_Factory;
