"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedReport_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReportCategory_1 = require("./ReportCategory");
var User_1 = require("./User");
var ReportType_1 = require("./ReportType");
var UserReportFavourite_1 = require("./UserReportFavourite");
var SavedReport_Factory = /** @class */ (function () {
    function SavedReport_Factory() {
    }
    SavedReport_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Category: ReportCategory_1.ReportCategory_Factory.CreateIncoming(model.Category),
            CategoryCode: model.CategoryCode,
            IsSystemDefault: model.IsSystemDefault,
            LastUpdatedByUser: User_1.User_Factory.CreateIncoming(model.LastUpdatedByUser),
            LastUpdatedByUserId: model.LastUpdatedByUserId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequestJson: model.RequestJson,
            Type: ReportType_1.ReportType_Factory.CreateIncoming(model.Type),
            TypeCode: model.TypeCode,
            UserReportFavourites: model.UserReportFavourites && model.UserReportFavourites.map(function (i) { return UserReportFavourite_1.UserReportFavourite_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    SavedReport_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Category: ReportCategory_1.ReportCategory_Factory.CreateOutgoing(model.Category),
            CategoryCode: model.CategoryCode,
            IsSystemDefault: model.IsSystemDefault,
            LastUpdatedByUser: User_1.User_Factory.CreateOutgoing(model.LastUpdatedByUser),
            LastUpdatedByUserId: model.LastUpdatedByUserId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequestJson: model.RequestJson,
            Type: ReportType_1.ReportType_Factory.CreateOutgoing(model.Type),
            TypeCode: model.TypeCode,
            UserReportFavourites: model.UserReportFavourites && model.UserReportFavourites.map(function (i) { return UserReportFavourite_1.UserReportFavourite_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return SavedReport_Factory;
}());
exports.SavedReport_Factory = SavedReport_Factory;
