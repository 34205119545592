"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkCorrespondenceBatch_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BulkCorrespondencePatientInstance_1 = require("./BulkCorrespondencePatientInstance");
var BulkCorrespondenceBatch_Factory = /** @class */ (function () {
    function BulkCorrespondenceBatch_Factory() {
    }
    BulkCorrespondenceBatch_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BatchFormatTypeCode: model.BatchFormatTypeCode,
            BatchStatusCode: model.BatchStatusCode,
            EmailedCount: model.EmailedCount,
            EmailTemplateId: model.EmailTemplateId,
            LocationId: model.LocationId,
            PatientInstances: model.PatientInstances && model.PatientInstances.map(function (i) { return BulkCorrespondencePatientInstance_1.BulkCorrespondencePatientInstance_Factory.CreateIncoming(i); }),
            PrintedCount: model.PrintedCount,
            PrintTemplateId: model.PrintTemplateId,
            PurposeTypeCode: model.PurposeTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UnsentCount: model.UnsentCount,
            UsePatientPreferredContactType: model.UsePatientPreferredContactType,
            UserId: model.UserId,
        };
        return result;
    };
    BulkCorrespondenceBatch_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BatchFormatTypeCode: model.BatchFormatTypeCode,
            BatchStatusCode: model.BatchStatusCode,
            EmailedCount: model.EmailedCount,
            EmailTemplateId: model.EmailTemplateId,
            LocationId: model.LocationId,
            PatientInstances: model.PatientInstances && model.PatientInstances.map(function (i) { return BulkCorrespondencePatientInstance_1.BulkCorrespondencePatientInstance_Factory.CreateOutgoing(i); }),
            PrintedCount: model.PrintedCount,
            PrintTemplateId: model.PrintTemplateId,
            PurposeTypeCode: model.PurposeTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UnsentCount: model.UnsentCount,
            UsePatientPreferredContactType: model.UsePatientPreferredContactType,
            UserId: model.UserId,
        };
        return result;
    };
    return BulkCorrespondenceBatch_Factory;
}());
exports.BulkCorrespondenceBatch_Factory = BulkCorrespondenceBatch_Factory;
