"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSPatientDeclaration_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClaim_1 = require("./NHSClaim");
var TreatmentPlan_1 = require("./TreatmentPlan");
var NHSPatientDeclaration_Factory = /** @class */ (function () {
    function NHSPatientDeclaration_Factory() {
    }
    NHSPatientDeclaration_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimId: model.ClaimId,
            DeclarationMode: model.DeclarationMode,
            DeclarationType: model.DeclarationType,
            FamilyName: model.FamilyName,
            GivenName: model.GivenName,
            NHSClaim: NHSClaim_1.NHSClaim_Factory.CreateIncoming(model.NHSClaim),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SignatureDate: Date_Factory_1.Date_Factory.CreateIncoming(model.SignatureDate),
            Title: model.Title,
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateIncoming(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
        };
        return result;
    };
    NHSPatientDeclaration_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimId: model.ClaimId,
            DeclarationMode: model.DeclarationMode,
            DeclarationType: model.DeclarationType,
            FamilyName: model.FamilyName,
            GivenName: model.GivenName,
            NHSClaim: NHSClaim_1.NHSClaim_Factory.CreateOutgoing(model.NHSClaim),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SignatureDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.SignatureDate),
            Title: model.Title,
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateOutgoing(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
        };
        return result;
    };
    return NHSPatientDeclaration_Factory;
}());
exports.NHSPatientDeclaration_Factory = NHSPatientDeclaration_Factory;
