"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportCategory_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReportTypeCategoryColumn_1 = require("./ReportTypeCategoryColumn");
var SavedReport_1 = require("./SavedReport");
var ReportCategory_Factory = /** @class */ (function () {
    function ReportCategory_Factory() {
    }
    ReportCategory_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            ReportTypeCategoryColumns: model.ReportTypeCategoryColumns && model.ReportTypeCategoryColumns.map(function (i) { return ReportTypeCategoryColumn_1.ReportTypeCategoryColumn_Factory.CreateIncoming(i); }),
            SavedReports: model.SavedReports && model.SavedReports.map(function (i) { return SavedReport_1.SavedReport_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    ReportCategory_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            ReportTypeCategoryColumns: model.ReportTypeCategoryColumns && model.ReportTypeCategoryColumns.map(function (i) { return ReportTypeCategoryColumn_1.ReportTypeCategoryColumn_Factory.CreateOutgoing(i); }),
            SavedReports: model.SavedReports && model.SavedReports.map(function (i) { return SavedReport_1.SavedReport_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return ReportCategory_Factory;
}());
exports.ReportCategory_Factory = ReportCategory_Factory;
