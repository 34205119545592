"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsTemplateDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsTemplateCategory_1 = require("./EFormsTemplateCategory");
var EFormsTemplateDataElement_1 = require("./EFormsTemplateDataElement");
var EFormsTemplateDetail_Factory = /** @class */ (function () {
    function EFormsTemplateDetail_Factory() {
    }
    EFormsTemplateDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Category: EFormsTemplateCategory_1.EFormsTemplateCategory_Factory.CreateIncoming(model.Category),
            CategoryId: model.CategoryId,
            DataElements: model.DataElements && model.DataElements.map(function (i) { return EFormsTemplateDataElement_1.EFormsTemplateDataElement_Factory.CreateIncoming(i); }),
            IsClinical: model.IsClinical,
            IsInUse: model.IsInUse,
            LatestVersion: EFormsTemplateDetail_Factory.CreateIncoming(model.LatestVersion),
            LatestVersionId: model.LatestVersionId,
            RecordId: model.RecordId,
        };
        return result;
    };
    EFormsTemplateDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Category: EFormsTemplateCategory_1.EFormsTemplateCategory_Factory.CreateOutgoing(model.Category),
            CategoryId: model.CategoryId,
            DataElements: model.DataElements && model.DataElements.map(function (i) { return EFormsTemplateDataElement_1.EFormsTemplateDataElement_Factory.CreateOutgoing(i); }),
            IsClinical: model.IsClinical,
            IsInUse: model.IsInUse,
            LatestVersion: EFormsTemplateDetail_Factory.CreateOutgoing(model.LatestVersion),
            LatestVersionId: model.LatestVersionId,
            RecordId: model.RecordId,
        };
        return result;
    };
    return EFormsTemplateDetail_Factory;
}());
exports.EFormsTemplateDetail_Factory = EFormsTemplateDetail_Factory;
