"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumberTypes_DisplayOrders = exports.PhoneNumberTypes_Descriptions_English = exports.PhoneNumberTypes_Descriptions = exports.PhoneNumberTypes_translateDescriptionBack = exports.PhoneNumberTypes_translateDescriptions = exports.PhoneNumberTypes = void 0;
var PhoneNumberTypes;
(function (PhoneNumberTypes) {
    PhoneNumberTypes[PhoneNumberTypes["Unspecified"] = 1] = "Unspecified";
    PhoneNumberTypes[PhoneNumberTypes["Mobile"] = 2] = "Mobile";
    PhoneNumberTypes[PhoneNumberTypes["Home"] = 3] = "Home";
    PhoneNumberTypes[PhoneNumberTypes["Work"] = 4] = "Work";
    PhoneNumberTypes[PhoneNumberTypes["Office"] = 5] = "Office";
    PhoneNumberTypes[PhoneNumberTypes["Main"] = 6] = "Main";
    PhoneNumberTypes[PhoneNumberTypes["Home_Fax"] = 7] = "Home_Fax";
    PhoneNumberTypes[PhoneNumberTypes["Work_Fax"] = 8] = "Work_Fax";
    PhoneNumberTypes[PhoneNumberTypes["Back_Office"] = 9] = "Back_Office";
    PhoneNumberTypes[PhoneNumberTypes["After_Hours"] = 10] = "After_Hours";
    PhoneNumberTypes[PhoneNumberTypes["Fax"] = 11] = "Fax";
})(PhoneNumberTypes = exports.PhoneNumberTypes || (exports.PhoneNumberTypes = {}));
function PhoneNumberTypes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.PhoneNumberTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.PhoneNumberTypes_Descriptions[key] = translationFunction(exports.PhoneNumberTypes_Descriptions[key]);
    });
}
exports.PhoneNumberTypes_translateDescriptions = PhoneNumberTypes_translateDescriptions;
function PhoneNumberTypes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.PhoneNumberTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.PhoneNumberTypes_Descriptions[key] = exports.PhoneNumberTypes_Descriptions_English[key];
    });
}
exports.PhoneNumberTypes_translateDescriptionBack = PhoneNumberTypes_translateDescriptionBack;
var _PhoneNumberTypes_Descriptions = {};
_PhoneNumberTypes_Descriptions.Unspecified = _PhoneNumberTypes_Descriptions[1] = "Unspecified";
_PhoneNumberTypes_Descriptions.Mobile = _PhoneNumberTypes_Descriptions[2] = "Mobile";
_PhoneNumberTypes_Descriptions.Home = _PhoneNumberTypes_Descriptions[3] = "Home";
_PhoneNumberTypes_Descriptions.Work = _PhoneNumberTypes_Descriptions[4] = "Work";
_PhoneNumberTypes_Descriptions.Office = _PhoneNumberTypes_Descriptions[5] = "Office";
_PhoneNumberTypes_Descriptions.Main = _PhoneNumberTypes_Descriptions[6] = "Main";
_PhoneNumberTypes_Descriptions.Home_Fax = _PhoneNumberTypes_Descriptions[7] = "Home Fax";
_PhoneNumberTypes_Descriptions.Work_Fax = _PhoneNumberTypes_Descriptions[8] = "Work Fax";
_PhoneNumberTypes_Descriptions.Back_Office = _PhoneNumberTypes_Descriptions[9] = "Back Office";
_PhoneNumberTypes_Descriptions.After_Hours = _PhoneNumberTypes_Descriptions[10] = "After Hours";
_PhoneNumberTypes_Descriptions.Fax = _PhoneNumberTypes_Descriptions[11] = "Fax";
var _PhoneNumberTypes_Descriptions_English = {};
_PhoneNumberTypes_Descriptions_English.Unspecified = _PhoneNumberTypes_Descriptions_English[1] = "Unspecified";
_PhoneNumberTypes_Descriptions_English.Mobile = _PhoneNumberTypes_Descriptions_English[2] = "Mobile";
_PhoneNumberTypes_Descriptions_English.Home = _PhoneNumberTypes_Descriptions_English[3] = "Home";
_PhoneNumberTypes_Descriptions_English.Work = _PhoneNumberTypes_Descriptions_English[4] = "Work";
_PhoneNumberTypes_Descriptions_English.Office = _PhoneNumberTypes_Descriptions_English[5] = "Office";
_PhoneNumberTypes_Descriptions_English.Main = _PhoneNumberTypes_Descriptions_English[6] = "Main";
_PhoneNumberTypes_Descriptions_English.Home_Fax = _PhoneNumberTypes_Descriptions_English[7] = "Home Fax";
_PhoneNumberTypes_Descriptions_English.Work_Fax = _PhoneNumberTypes_Descriptions_English[8] = "Work Fax";
_PhoneNumberTypes_Descriptions_English.Back_Office = _PhoneNumberTypes_Descriptions_English[9] = "Back Office";
_PhoneNumberTypes_Descriptions_English.After_Hours = _PhoneNumberTypes_Descriptions_English[10] = "After Hours";
_PhoneNumberTypes_Descriptions_English.Fax = _PhoneNumberTypes_Descriptions_English[11] = "Fax";
exports.PhoneNumberTypes_Descriptions = _PhoneNumberTypes_Descriptions;
exports.PhoneNumberTypes_Descriptions_English = _PhoneNumberTypes_Descriptions_English;
var _PhoneNumberTypes_DisplayOrders = {};
_PhoneNumberTypes_DisplayOrders.Unspecified = _PhoneNumberTypes_DisplayOrders[1] = 1;
_PhoneNumberTypes_DisplayOrders.Mobile = _PhoneNumberTypes_DisplayOrders[2] = 2;
_PhoneNumberTypes_DisplayOrders.Home = _PhoneNumberTypes_DisplayOrders[3] = 3;
_PhoneNumberTypes_DisplayOrders.Work = _PhoneNumberTypes_DisplayOrders[4] = 4;
_PhoneNumberTypes_DisplayOrders.Office = _PhoneNumberTypes_DisplayOrders[5] = 5;
_PhoneNumberTypes_DisplayOrders.Main = _PhoneNumberTypes_DisplayOrders[6] = 6;
_PhoneNumberTypes_DisplayOrders.Home_Fax = _PhoneNumberTypes_DisplayOrders[7] = 7;
_PhoneNumberTypes_DisplayOrders.Work_Fax = _PhoneNumberTypes_DisplayOrders[8] = 8;
_PhoneNumberTypes_DisplayOrders.Back_Office = _PhoneNumberTypes_DisplayOrders[9] = 9;
_PhoneNumberTypes_DisplayOrders.After_Hours = _PhoneNumberTypes_DisplayOrders[10] = 10;
_PhoneNumberTypes_DisplayOrders.Fax = _PhoneNumberTypes_DisplayOrders[11] = 11;
exports.PhoneNumberTypes_DisplayOrders = _PhoneNumberTypes_DisplayOrders;
