"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSResponseHistory_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSResponseHistory_Factory = /** @class */ (function () {
    function NHSResponseHistory_Factory() {
    }
    NHSResponseHistory_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ErrorDetected: model.ErrorDetected,
            ErrorInfo: model.ErrorInfo,
            ErrorsResolved: model.ErrorsResolved,
            Id: model.Id,
            LastClaimNo: model.LastClaimNo,
            LastInterchangeNo: model.LastInterchangeNo,
            LoggedInUserId: model.LoggedInUserId,
            MailboxDescription: model.MailboxDescription,
            MailboxId: model.MailboxId,
            MailboxLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.MailboxLastUpdated),
            NHSLocationId: model.NHSLocationId,
            PinProtectSchedule: model.PinProtectSchedule,
            Pswd: model.Pswd,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            ResponseData: model.ResponseData,
            TransmissionFormat: model.TransmissionFormat,
            UserName: model.UserName,
        };
        return result;
    };
    NHSResponseHistory_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ErrorDetected: model.ErrorDetected,
            ErrorInfo: model.ErrorInfo,
            ErrorsResolved: model.ErrorsResolved,
            Id: model.Id,
            LastClaimNo: model.LastClaimNo,
            LastInterchangeNo: model.LastInterchangeNo,
            LoggedInUserId: model.LoggedInUserId,
            MailboxDescription: model.MailboxDescription,
            MailboxId: model.MailboxId,
            MailboxLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.MailboxLastUpdated),
            NHSLocationId: model.NHSLocationId,
            PinProtectSchedule: model.PinProtectSchedule,
            Pswd: model.Pswd,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            ResponseData: model.ResponseData,
            TransmissionFormat: model.TransmissionFormat,
            UserName: model.UserName,
        };
        return result;
    };
    return NHSResponseHistory_Factory;
}());
exports.NHSResponseHistory_Factory = NHSResponseHistory_Factory;
