"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsProviderPrefill_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsProviderPrefill_Factory = /** @class */ (function () {
    function EFormsProviderPrefill_Factory() {
    }
    EFormsProviderPrefill_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DefaultLocationId: model.DefaultLocationId,
            FirstName: model.FirstName,
            FullName: model.FullName,
            IsNhsProvider: model.IsNhsProvider,
            LastName: model.LastName,
            RecordId: model.RecordId,
        };
        return result;
    };
    EFormsProviderPrefill_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DefaultLocationId: model.DefaultLocationId,
            FirstName: model.FirstName,
            FullName: model.FullName,
            IsNhsProvider: model.IsNhsProvider,
            LastName: model.LastName,
            RecordId: model.RecordId,
        };
        return result;
    };
    return EFormsProviderPrefill_Factory;
}());
exports.EFormsProviderPrefill_Factory = EFormsProviderPrefill_Factory;
