"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCourseCDSValue_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCourseCDSValue_Factory = /** @class */ (function () {
    function NHSCourseCDSValue_Factory() {
    }
    NHSCourseCDSValue_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AcclimatisationAppointmentCount: model.AcclimatisationAppointmentCount,
            ACORNAssessment: model.ACORNAssessment,
            AcrylicDentureLower: model.AcrylicDentureLower,
            AcrylicDentureUpper: model.AcrylicDentureUpper,
            AGP: model.AGP,
            AMSReferral: model.AMSReferral,
            Antibiotics: model.Antibiotics,
            Bridges: model.Bridges,
            BrokenAppointmentCount: model.BrokenAppointmentCount,
            CategoryOfReferral: model.CategoryOfReferral,
            ConsultantledDayCaseSedationCount: model.ConsultantledDayCaseSedationCount,
            Crowns: model.Crowns,
            DeciduousDecayed: model.DeciduousDecayed,
            DeciduousFilled: model.DeciduousFilled,
            DeciduousMissing: model.DeciduousMissing,
            DentureAdditions: model.DentureAdditions,
            DomiciliaryVisitCount: model.DomiciliaryVisitCount,
            Endodontic: model.Endodontic,
            Examination: model.Examination,
            Extractions: model.Extractions,
            FissureSealants: model.FissureSealants,
            FluorideVarnish: model.FluorideVarnish,
            GeneralAnaesthesis: model.GeneralAnaesthesis,
            HighestBPESextantScore: model.HighestBPESextantScore,
            HypnosisAppointmentCount: model.HypnosisAppointmentCount,
            IncompleteTreatment: model.IncompleteTreatment,
            Inlays: model.Inlays,
            MCMTTotalScore: model.MCMTTotalScore,
            MetalDentureLower: model.MetalDentureLower,
            MetalDentureUpper: model.MetalDentureUpper,
            MolarEndodontics: model.MolarEndodontics,
            NonMolarEndodontics: model.NonMolarEndodontics,
            NonSurgicalExtraction_NonOrtho: model.NonSurgicalExtraction_NonOrtho,
            OralTransmucosalSedationCount: model.OralTransmucosalSedationCount,
            Other: model.Other,
            PatientAssessment: model.PatientAssessment,
            PerioRootDebridement: model.PerioRootDebridement,
            PermanentDecayed: model.PermanentDecayed,
            PermanentFilled: model.PermanentFilled,
            PermanentMissing: model.PermanentMissing,
            PermanentRestorations: model.PermanentRestorations,
            PreformedCrowns: model.PreformedCrowns,
            Radiographs: model.Radiographs,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferredOutForCompletion: model.ReferredOutForCompletion,
            ScaleAndPolish: model.ScaleAndPolish,
            SourceOfReferral: model.SourceOfReferral,
            Stabilisation: model.Stabilisation,
            SurgicalRemoval_NonOrtho: model.SurgicalRemoval_NonOrtho,
            TreatmentType: model.TreatmentType,
            UserOverride: model.UserOverride,
            Veneers: model.Veneers,
            VisitsForCareAndTreatmentCount: model.VisitsForCareAndTreatmentCount,
        };
        return result;
    };
    NHSCourseCDSValue_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AcclimatisationAppointmentCount: model.AcclimatisationAppointmentCount,
            ACORNAssessment: model.ACORNAssessment,
            AcrylicDentureLower: model.AcrylicDentureLower,
            AcrylicDentureUpper: model.AcrylicDentureUpper,
            AGP: model.AGP,
            AMSReferral: model.AMSReferral,
            Antibiotics: model.Antibiotics,
            Bridges: model.Bridges,
            BrokenAppointmentCount: model.BrokenAppointmentCount,
            CategoryOfReferral: model.CategoryOfReferral,
            ConsultantledDayCaseSedationCount: model.ConsultantledDayCaseSedationCount,
            Crowns: model.Crowns,
            DeciduousDecayed: model.DeciduousDecayed,
            DeciduousFilled: model.DeciduousFilled,
            DeciduousMissing: model.DeciduousMissing,
            DentureAdditions: model.DentureAdditions,
            DomiciliaryVisitCount: model.DomiciliaryVisitCount,
            Endodontic: model.Endodontic,
            Examination: model.Examination,
            Extractions: model.Extractions,
            FissureSealants: model.FissureSealants,
            FluorideVarnish: model.FluorideVarnish,
            GeneralAnaesthesis: model.GeneralAnaesthesis,
            HighestBPESextantScore: model.HighestBPESextantScore,
            HypnosisAppointmentCount: model.HypnosisAppointmentCount,
            IncompleteTreatment: model.IncompleteTreatment,
            Inlays: model.Inlays,
            MCMTTotalScore: model.MCMTTotalScore,
            MetalDentureLower: model.MetalDentureLower,
            MetalDentureUpper: model.MetalDentureUpper,
            MolarEndodontics: model.MolarEndodontics,
            NonMolarEndodontics: model.NonMolarEndodontics,
            NonSurgicalExtraction_NonOrtho: model.NonSurgicalExtraction_NonOrtho,
            OralTransmucosalSedationCount: model.OralTransmucosalSedationCount,
            Other: model.Other,
            PatientAssessment: model.PatientAssessment,
            PerioRootDebridement: model.PerioRootDebridement,
            PermanentDecayed: model.PermanentDecayed,
            PermanentFilled: model.PermanentFilled,
            PermanentMissing: model.PermanentMissing,
            PermanentRestorations: model.PermanentRestorations,
            PreformedCrowns: model.PreformedCrowns,
            Radiographs: model.Radiographs,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferredOutForCompletion: model.ReferredOutForCompletion,
            ScaleAndPolish: model.ScaleAndPolish,
            SourceOfReferral: model.SourceOfReferral,
            Stabilisation: model.Stabilisation,
            SurgicalRemoval_NonOrtho: model.SurgicalRemoval_NonOrtho,
            TreatmentType: model.TreatmentType,
            UserOverride: model.UserOverride,
            Veneers: model.Veneers,
            VisitsForCareAndTreatmentCount: model.VisitsForCareAndTreatmentCount,
        };
        return result;
    };
    return NHSCourseCDSValue_Factory;
}());
exports.NHSCourseCDSValue_Factory = NHSCourseCDSValue_Factory;
