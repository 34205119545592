"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCourseODSValue_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCourseODSValue_Factory = /** @class */ (function () {
    function NHSCourseODSValue_Factory() {
    }
    NHSCourseODSValue_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Extractions: model.Extractions,
            FixedLowerAppliance: model.FixedLowerAppliance,
            FixedUpperAppliance: model.FixedUpperAppliance,
            FunctionalAppliance: model.FunctionalAppliance,
            OverrideCalculatedValues: model.OverrideCalculatedValues,
            PhotographsTaken: model.PhotographsTaken,
            Radiographs: model.Radiographs,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RemovableLowerAppliance: model.RemovableLowerAppliance,
            RemovableUpperAppliance: model.RemovableUpperAppliance,
            RetainerLower: model.RetainerLower,
            RetainerUpper: model.RetainerUpper,
            SelectedTeeth: model.SelectedTeeth,
            StudyModel: model.StudyModel,
        };
        return result;
    };
    NHSCourseODSValue_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Extractions: model.Extractions,
            FixedLowerAppliance: model.FixedLowerAppliance,
            FixedUpperAppliance: model.FixedUpperAppliance,
            FunctionalAppliance: model.FunctionalAppliance,
            OverrideCalculatedValues: model.OverrideCalculatedValues,
            PhotographsTaken: model.PhotographsTaken,
            Radiographs: model.Radiographs,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RemovableLowerAppliance: model.RemovableLowerAppliance,
            RemovableUpperAppliance: model.RemovableUpperAppliance,
            RetainerLower: model.RetainerLower,
            RetainerUpper: model.RetainerUpper,
            SelectedTeeth: model.SelectedTeeth,
            StudyModel: model.StudyModel,
        };
        return result;
    };
    return NHSCourseODSValue_Factory;
}());
exports.NHSCourseODSValue_Factory = NHSCourseODSValue_Factory;
