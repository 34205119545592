"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstateReferralCustomDateUnit_DisplayOrders = exports.EstateReferralCustomDateUnit_Descriptions_English = exports.EstateReferralCustomDateUnit_Descriptions = exports.EstateReferralCustomDateUnit_translateDescriptionBack = exports.EstateReferralCustomDateUnit_translateDescriptions = exports.EstateReferralCustomDateUnit = void 0;
var EstateReferralCustomDateUnit;
(function (EstateReferralCustomDateUnit) {
    EstateReferralCustomDateUnit[EstateReferralCustomDateUnit["Days"] = 0] = "Days";
    EstateReferralCustomDateUnit[EstateReferralCustomDateUnit["Weeks"] = 1] = "Weeks";
    EstateReferralCustomDateUnit[EstateReferralCustomDateUnit["Months"] = 2] = "Months";
})(EstateReferralCustomDateUnit = exports.EstateReferralCustomDateUnit || (exports.EstateReferralCustomDateUnit = {}));
function EstateReferralCustomDateUnit_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EstateReferralCustomDateUnit_Descriptions);
    allKeys.forEach(function (key) {
        exports.EstateReferralCustomDateUnit_Descriptions[key] = translationFunction(exports.EstateReferralCustomDateUnit_Descriptions[key]);
    });
}
exports.EstateReferralCustomDateUnit_translateDescriptions = EstateReferralCustomDateUnit_translateDescriptions;
function EstateReferralCustomDateUnit_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EstateReferralCustomDateUnit_Descriptions);
    allKeys.forEach(function (key) {
        exports.EstateReferralCustomDateUnit_Descriptions[key] = exports.EstateReferralCustomDateUnit_Descriptions_English[key];
    });
}
exports.EstateReferralCustomDateUnit_translateDescriptionBack = EstateReferralCustomDateUnit_translateDescriptionBack;
var _EstateReferralCustomDateUnit_Descriptions = {};
_EstateReferralCustomDateUnit_Descriptions.Days = _EstateReferralCustomDateUnit_Descriptions[0] = "Days";
_EstateReferralCustomDateUnit_Descriptions.Weeks = _EstateReferralCustomDateUnit_Descriptions[1] = "Weeks";
_EstateReferralCustomDateUnit_Descriptions.Months = _EstateReferralCustomDateUnit_Descriptions[2] = "Months";
var _EstateReferralCustomDateUnit_Descriptions_English = {};
_EstateReferralCustomDateUnit_Descriptions_English.Days = _EstateReferralCustomDateUnit_Descriptions_English[0] = "Days";
_EstateReferralCustomDateUnit_Descriptions_English.Weeks = _EstateReferralCustomDateUnit_Descriptions_English[1] = "Weeks";
_EstateReferralCustomDateUnit_Descriptions_English.Months = _EstateReferralCustomDateUnit_Descriptions_English[2] = "Months";
exports.EstateReferralCustomDateUnit_Descriptions = _EstateReferralCustomDateUnit_Descriptions;
exports.EstateReferralCustomDateUnit_Descriptions_English = _EstateReferralCustomDateUnit_Descriptions_English;
var _EstateReferralCustomDateUnit_DisplayOrders = {};
_EstateReferralCustomDateUnit_DisplayOrders.Days = _EstateReferralCustomDateUnit_DisplayOrders[0] = 0;
_EstateReferralCustomDateUnit_DisplayOrders.Weeks = _EstateReferralCustomDateUnit_DisplayOrders[1] = 1;
_EstateReferralCustomDateUnit_DisplayOrders.Months = _EstateReferralCustomDateUnit_DisplayOrders[2] = 2;
exports.EstateReferralCustomDateUnit_DisplayOrders = _EstateReferralCustomDateUnit_DisplayOrders;
