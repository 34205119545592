"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationDefaultWorkingDay_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LocationWorkingDay_1 = require("./LocationWorkingDay");
var LocationDefaultWorkingDay_Factory = /** @class */ (function () {
    function LocationDefaultWorkingDay_Factory() {
    }
    LocationDefaultWorkingDay_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            FridayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(model.FridayWorkingDay),
            FridayWorkingDayId: model.FridayWorkingDayId,
            MondayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(model.MondayWorkingDay),
            MondayWorkingDayId: model.MondayWorkingDayId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            SaturdayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(model.SaturdayWorkingDay),
            SaturdayWorkingDayId: model.SaturdayWorkingDayId,
            SundayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(model.SundayWorkingDay),
            SundayWorkingDayId: model.SundayWorkingDayId,
            ThursdayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(model.ThursdayWorkingDay),
            ThursdayWorkingDayId: model.ThursdayWorkingDayId,
            TuesdayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(model.TuesdayWorkingDay),
            TuesdayWorkingDayId: model.TuesdayWorkingDayId,
            WednesdayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(model.WednesdayWorkingDay),
            WednesdayWorkingDayId: model.WednesdayWorkingDayId,
        };
        return result;
    };
    LocationDefaultWorkingDay_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            FridayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(model.FridayWorkingDay),
            FridayWorkingDayId: model.FridayWorkingDayId,
            MondayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(model.MondayWorkingDay),
            MondayWorkingDayId: model.MondayWorkingDayId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            SaturdayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(model.SaturdayWorkingDay),
            SaturdayWorkingDayId: model.SaturdayWorkingDayId,
            SundayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(model.SundayWorkingDay),
            SundayWorkingDayId: model.SundayWorkingDayId,
            ThursdayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(model.ThursdayWorkingDay),
            ThursdayWorkingDayId: model.ThursdayWorkingDayId,
            TuesdayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(model.TuesdayWorkingDay),
            TuesdayWorkingDayId: model.TuesdayWorkingDayId,
            WednesdayWorkingDay: LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(model.WednesdayWorkingDay),
            WednesdayWorkingDayId: model.WednesdayWorkingDayId,
        };
        return result;
    };
    return LocationDefaultWorkingDay_Factory;
}());
exports.LocationDefaultWorkingDay_Factory = LocationDefaultWorkingDay_Factory;
