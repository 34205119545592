"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreOpEvaluation_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var VitalsRecord_1 = require("./VitalsRecord");
var PreOpEvaluation_Factory = /** @class */ (function () {
    function PreOpEvaluation_Factory() {
    }
    PreOpEvaluation_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BodyPosition: model.BodyPosition,
            BodyPositionOther: model.BodyPositionOther,
            Comments: model.Comments,
            DentalStatus: model.DentalStatus,
            DentalStatusOther: model.DentalStatusOther,
            EyeCare: model.EyeCare,
            EyeCareOther: model.EyeCareOther,
            HeartOther: model.HeartOther,
            HeartRegularRR: model.HeartRegularRR,
            InterincisalOpeningMm: model.InterincisalOpeningMm,
            LeftArmPosition: model.LeftArmPosition,
            LeftArmPositionOther: model.LeftArmPositionOther,
            LungsClearPA: model.LungsClearPA,
            LungsOther: model.LungsOther,
            MallampatiScore: model.MallampatiScore,
            NeckCervicalRange: model.NeckCervicalRange,
            NeckCircumferenceCm: model.NeckCircumferenceCm,
            PreMeds: model.PreMeds,
            PreMedTime: Date_Factory_1.Date_Factory.CreateIncoming(model.PreMedTime),
            PreOpVitalsRecord: VitalsRecord_1.VitalsRecord_Factory.CreateIncoming(model.PreOpVitalsRecord),
            PreOpVitalsRecordId: model.PreOpVitalsRecordId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RightArmPosition: model.RightArmPosition,
            RightArmPositionOther: model.RightArmPositionOther,
            SleepApnea: model.SleepApnea,
            Snoring: model.Snoring,
            ThyrolmentalDistance: model.ThyrolmentalDistance,
        };
        return result;
    };
    PreOpEvaluation_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BodyPosition: model.BodyPosition,
            BodyPositionOther: model.BodyPositionOther,
            Comments: model.Comments,
            DentalStatus: model.DentalStatus,
            DentalStatusOther: model.DentalStatusOther,
            EyeCare: model.EyeCare,
            EyeCareOther: model.EyeCareOther,
            HeartOther: model.HeartOther,
            HeartRegularRR: model.HeartRegularRR,
            InterincisalOpeningMm: model.InterincisalOpeningMm,
            LeftArmPosition: model.LeftArmPosition,
            LeftArmPositionOther: model.LeftArmPositionOther,
            LungsClearPA: model.LungsClearPA,
            LungsOther: model.LungsOther,
            MallampatiScore: model.MallampatiScore,
            NeckCervicalRange: model.NeckCervicalRange,
            NeckCircumferenceCm: model.NeckCircumferenceCm,
            PreMeds: model.PreMeds,
            PreMedTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.PreMedTime),
            PreOpVitalsRecord: VitalsRecord_1.VitalsRecord_Factory.CreateOutgoing(model.PreOpVitalsRecord),
            PreOpVitalsRecordId: model.PreOpVitalsRecordId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RightArmPosition: model.RightArmPosition,
            RightArmPositionOther: model.RightArmPositionOther,
            SleepApnea: model.SleepApnea,
            Snoring: model.Snoring,
            ThyrolmentalDistance: model.ThyrolmentalDistance,
        };
        return result;
    };
    return PreOpEvaluation_Factory;
}());
exports.PreOpEvaluation_Factory = PreOpEvaluation_Factory;
