"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EServicesSetting_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EServicesSetting_Factory = /** @class */ (function () {
    function EServicesSetting_Factory() {
    }
    EServicesSetting_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AutomatedStatementsType: model.AutomatedStatementsType,
            BillByProvider: model.BillByProvider,
            EPaymentsActivationStatus: model.EPaymentsActivationStatus,
            EPaymentsNumReceipts: model.EPaymentsNumReceipts,
            EServicesPassword: model.EServicesPassword,
            EServicesSiteID: model.EServicesSiteID,
            EServicesUsername: model.EServicesUsername,
            ForceOnlyOneEntry: model.ForceOnlyOneEntry,
            NEAFacilityId: model.NEAFacilityId,
            OnlineStatementsEnabled: model.OnlineStatementsEnabled,
            RecordId: model.RecordId,
        };
        return result;
    };
    EServicesSetting_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AutomatedStatementsType: model.AutomatedStatementsType,
            BillByProvider: model.BillByProvider,
            EPaymentsActivationStatus: model.EPaymentsActivationStatus,
            EPaymentsNumReceipts: model.EPaymentsNumReceipts,
            EServicesPassword: model.EServicesPassword,
            EServicesSiteID: model.EServicesSiteID,
            EServicesUsername: model.EServicesUsername,
            ForceOnlyOneEntry: model.ForceOnlyOneEntry,
            NEAFacilityId: model.NEAFacilityId,
            OnlineStatementsEnabled: model.OnlineStatementsEnabled,
            RecordId: model.RecordId,
        };
        return result;
    };
    return EServicesSetting_Factory;
}());
exports.EServicesSetting_Factory = EServicesSetting_Factory;
