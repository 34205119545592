"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickbooksTransmission_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ArchivedDaysheet_1 = require("./ArchivedDaysheet");
var Location_1 = require("./Location");
var QuickbooksTransmissionHistory_1 = require("./QuickbooksTransmissionHistory");
var QuickbooksTransmission_Factory = /** @class */ (function () {
    function QuickbooksTransmission_Factory() {
    }
    QuickbooksTransmission_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ArchivedDaysheet: ArchivedDaysheet_1.ArchivedDaysheet_Factory.CreateIncoming(model.ArchivedDaysheet),
            ArchivedDaysheetId: model.ArchivedDaysheetId,
            CloseOfDayDate: Date_Factory_1.Date_Factory.CreateIncoming(model.CloseOfDayDate),
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            OriginalSettings: model.OriginalSettings,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransmissionHistories: model.TransmissionHistories && model.TransmissionHistories.map(function (i) { return QuickbooksTransmissionHistory_1.QuickbooksTransmissionHistory_Factory.CreateIncoming(i); }),
            TransmittionData: model.TransmittionData,
        };
        return result;
    };
    QuickbooksTransmission_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ArchivedDaysheet: ArchivedDaysheet_1.ArchivedDaysheet_Factory.CreateOutgoing(model.ArchivedDaysheet),
            ArchivedDaysheetId: model.ArchivedDaysheetId,
            CloseOfDayDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.CloseOfDayDate),
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            OriginalSettings: model.OriginalSettings,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransmissionHistories: model.TransmissionHistories && model.TransmissionHistories.map(function (i) { return QuickbooksTransmissionHistory_1.QuickbooksTransmissionHistory_Factory.CreateOutgoing(i); }),
            TransmittionData: model.TransmittionData,
        };
        return result;
    };
    return QuickbooksTransmission_Factory;
}());
exports.QuickbooksTransmission_Factory = QuickbooksTransmission_Factory;
