import container from "../../di/index";
import { createSignal } from "../../utils/signalDispatcher";
import { hubLoggingLevel } from "./BaseHub";
import * as signalR from "@aspnet/signalr";
import { loadConfig } from "../appConfigService";
import http from "../api/http";
//import { iid_TestHub } from "./TestHub";
import { iid_FormsHub } from "./FormsHub";
import { IBaseHub } from "./BaseHub";
import { getConnectionFromStorage } from "../authService/storage";

type SignalRConnectionInfo = {
  url: string;
  accessToken: string;
};

export var iid_HubConnectionId = Symbol("Hub Connection Id");

export class HubManager {
  //@lazyInject(iid_rootStore) private rootStore: IRootStore;

  public Connecting = createSignal<signalR.HubConnection>();
  public Connected = createSignal<signalR.HubConnection>();
  public Disconnected = createSignal<signalR.HubConnection>();

  public ReConnectInterval = 5;
  private reconnectTimer?: number;
  private connection?: signalR.HubConnection;

  private hubsDisabled = false;

  private apiEndpoint?: string;

  configure = async () => {
    await loadConfig().then(async config => {
      this.apiEndpoint = `${config.apiUrl}signalr/negotiate`;
    });
  }

  private establishConnection = async () => {
    if( !this.apiEndpoint) return;

    let info: SignalRConnectionInfo;
    try {
      const { data } = await http.post<SignalRConnectionInfo>(
        this.apiEndpoint);
      info = data;
    } catch (error) {
      console.error("Failed to connect to Azure SignalR", error);
      //If this fails then the Azure Function is down...
      return;
    }

    const options = {
      accessTokenFactory: () => info.accessToken,
    };

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(info.url, options)
      .configureLogging(hubLoggingLevel)
      .build();
  };

  public start = async () => {
    //If our config specifies not to establish a connection then leave
    if (this.hubsDisabled) return;

    await this.establishConnection();

    this.bindHubs();

    this.connection?.onclose((error: any) => {
      if (error) console.error("SignalR connection closed: ", error);
      this.Disconnected.dispatch(this.connection!);
      this.startReconnect();
    });

    // open the websocket
    this.startConnection();
  };

  private startConnection() {
    if(!this.connection) return;

    this.Connecting.dispatch(this.connection);
    this.connection
      .start()
      .then(_ => {
        console.log("SignalR connected.");
        //the unique connection does have an id (e.g. id=SAxjFysFzsk0PMRAxTBxIga4349c941)
        //But this is not exposed
        this.Connected.dispatch(this.connection!);
      })
      .catch(e => {
        console.log("SignalR connection attempt failed.");
        if (e) console.error(e);
        this.startReconnect();
      });
  }

  private startReconnect() {
    if (this.reconnectTimer) clearTimeout(this.reconnectTimer);
    this.reconnectTimer = (setTimeout(() => {
      this.establishConnection();
      this.startConnection();
    }, this.ReConnectInterval * 1000) as any) as number;
  }

  public bindHubs() {
    var connectionId = getConnectionFromStorage();
    if(!this.connection || !connectionId) return;

    //container.get<IBaseHub>(iid_TestHub).initialize(this.connection!, connectionId);
    container.get<IBaseHub>(iid_FormsHub).initialize(this.connection!, connectionId);
  }
}

