"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduledPaymentResult_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ScheduledPayment_1 = require("./ScheduledPayment");
var ScheduledPaymentResult_Factory = /** @class */ (function () {
    function ScheduledPaymentResult_Factory() {
    }
    ScheduledPaymentResult_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AmountCharged: model.AmountCharged,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduledPayment: ScheduledPayment_1.ScheduledPayment_Factory.CreateIncoming(model.ScheduledPayment),
            ScheduledPaymentId: model.ScheduledPaymentId,
            Status: model.Status,
        };
        return result;
    };
    ScheduledPaymentResult_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AmountCharged: model.AmountCharged,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduledPayment: ScheduledPayment_1.ScheduledPayment_Factory.CreateOutgoing(model.ScheduledPayment),
            ScheduledPaymentId: model.ScheduledPaymentId,
            Status: model.Status,
        };
        return result;
    };
    return ScheduledPaymentResult_Factory;
}());
exports.ScheduledPaymentResult_Factory = ScheduledPaymentResult_Factory;
