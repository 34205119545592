"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsTemplateDataElement_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsTemplateDataElement_Factory = /** @class */ (function () {
    function EFormsTemplateDataElement_Factory() {
    }
    EFormsTemplateDataElement_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DataElementId: model.DataElementId,
            Rank: model.Rank,
            RecordId: model.RecordId,
            TemplateId: model.TemplateId,
        };
        return result;
    };
    EFormsTemplateDataElement_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DataElementId: model.DataElementId,
            Rank: model.Rank,
            RecordId: model.RecordId,
            TemplateId: model.TemplateId,
        };
        return result;
    };
    return EFormsTemplateDataElement_Factory;
}());
exports.EFormsTemplateDataElement_Factory = EFormsTemplateDataElement_Factory;
