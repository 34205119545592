"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalTreatmentMaterial_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalTreatmentMaterial_Factory = /** @class */ (function () {
    function ClinicalTreatmentMaterial_Factory() {
    }
    ClinicalTreatmentMaterial_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalTreatmentMaterialCode: model.ClinicalTreatmentMaterialCode,
            Description: model.Description,
        };
        return result;
    };
    ClinicalTreatmentMaterial_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalTreatmentMaterialCode: model.ClinicalTreatmentMaterialCode,
            Description: model.Description,
        };
        return result;
    };
    return ClinicalTreatmentMaterial_Factory;
}());
exports.ClinicalTreatmentMaterial_Factory = ClinicalTreatmentMaterial_Factory;
