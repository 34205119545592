"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalQuestionnaireQuestionGroup_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MedicalQuestionnaire_1 = require("./MedicalQuestionnaire");
var MedicalQuestionnaireQuestion_1 = require("./MedicalQuestionnaireQuestion");
var MedicalQuestionnaireQuestionGroup_Factory = /** @class */ (function () {
    function MedicalQuestionnaireQuestionGroup_Factory() {
    }
    MedicalQuestionnaireQuestionGroup_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            GroupId: model.GroupId,
            GroupQuestionText: model.GroupQuestionText,
            Heading: model.Heading,
            MedicalQuestionnaire: MedicalQuestionnaire_1.MedicalQuestionnaire_Factory.CreateIncoming(model.MedicalQuestionnaire),
            MedicalQuestionnaireId: model.MedicalQuestionnaireId,
            MedicalQuestionnaireQuestions: model.MedicalQuestionnaireQuestions && model.MedicalQuestionnaireQuestions.map(function (i) { return MedicalQuestionnaireQuestion_1.MedicalQuestionnaireQuestion_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    MedicalQuestionnaireQuestionGroup_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            GroupId: model.GroupId,
            GroupQuestionText: model.GroupQuestionText,
            Heading: model.Heading,
            MedicalQuestionnaire: MedicalQuestionnaire_1.MedicalQuestionnaire_Factory.CreateOutgoing(model.MedicalQuestionnaire),
            MedicalQuestionnaireId: model.MedicalQuestionnaireId,
            MedicalQuestionnaireQuestions: model.MedicalQuestionnaireQuestions && model.MedicalQuestionnaireQuestions.map(function (i) { return MedicalQuestionnaireQuestion_1.MedicalQuestionnaireQuestion_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return MedicalQuestionnaireQuestionGroup_Factory;
}());
exports.MedicalQuestionnaireQuestionGroup_Factory = MedicalQuestionnaireQuestionGroup_Factory;
