"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracProduct_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracCaseProduct_1 = require("./LabtracCaseProduct");
var LabtracLab_1 = require("./LabtracLab");
var LabtracProductGroup_1 = require("./LabtracProductGroup");
var LabtracProduct_Factory = /** @class */ (function () {
    function LabtracProduct_Factory() {
    }
    LabtracProduct_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DisplaySequence: model.DisplaySequence,
            ExternalId: model.ExternalId,
            LabCode: model.LabCode,
            LabReference: model.LabReference,
            LabtracCaseProducts: model.LabtracCaseProducts && model.LabtracCaseProducts.map(function (i) { return LabtracCaseProduct_1.LabtracCaseProduct_Factory.CreateIncoming(i); }),
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateIncoming(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            LabtracProductGroup: LabtracProductGroup_1.LabtracProductGroup_Factory.CreateIncoming(model.LabtracProductGroup),
            LabtracProductGroupId: model.LabtracProductGroupId,
            LeadDays: model.LeadDays,
            Name: model.Name,
            Notes: model.Notes,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    LabtracProduct_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DisplaySequence: model.DisplaySequence,
            ExternalId: model.ExternalId,
            LabCode: model.LabCode,
            LabReference: model.LabReference,
            LabtracCaseProducts: model.LabtracCaseProducts && model.LabtracCaseProducts.map(function (i) { return LabtracCaseProduct_1.LabtracCaseProduct_Factory.CreateOutgoing(i); }),
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateOutgoing(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            LabtracProductGroup: LabtracProductGroup_1.LabtracProductGroup_Factory.CreateOutgoing(model.LabtracProductGroup),
            LabtracProductGroupId: model.LabtracProductGroupId,
            LeadDays: model.LeadDays,
            Name: model.Name,
            Notes: model.Notes,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return LabtracProduct_Factory;
}());
exports.LabtracProduct_Factory = LabtracProduct_Factory;
