"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Location_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var AnesthesiaRecord_1 = require("./AnesthesiaRecord");
var ArchivedDaysheet_1 = require("./ArchivedDaysheet");
var Chair_1 = require("./Chair");
var User_1 = require("./User");
var LocationDefaultWorkingDay_1 = require("./LocationDefaultWorkingDay");
var LocationWorkingDay_1 = require("./LocationWorkingDay");
var NHSLocation_1 = require("./NHSLocation");
var OrthoPatientTreatmentStatus_1 = require("./OrthoPatientTreatmentStatus");
var PatientConcern_1 = require("./PatientConcern");
var PhoneNumber_1 = require("./PhoneNumber");
var PracticeEmailAccountType_1 = require("./PracticeEmailAccountType");
var ProcedureList_1 = require("./ProcedureList");
var ProviderTaxonomyCode_1 = require("./ProviderTaxonomyCode");
var ScheduledPayment_1 = require("./ScheduledPayment");
var ScheduleView_1 = require("./ScheduleView");
var TimeZone_1 = require("./TimeZone");
var ToothNumberingType_1 = require("./ToothNumberingType");
var UserDefaultWorkingDay_1 = require("./UserDefaultWorkingDay");
var UserWorkingDay_1 = require("./UserWorkingDay");
var WaitingList_1 = require("./WaitingList");
var Location_Factory = /** @class */ (function () {
    function Location_Factory() {
    }
    Location_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Addresses: model.Addresses && model.Addresses.map(function (i) { return Address_1.Address_Factory.CreateIncoming(i); }),
            AnesthesiaRecords: model.AnesthesiaRecords && model.AnesthesiaRecords.map(function (i) { return AnesthesiaRecord_1.AnesthesiaRecord_Factory.CreateIncoming(i); }),
            ArchivedDaysheets: model.ArchivedDaysheets && model.ArchivedDaysheets.map(function (i) { return ArchivedDaysheet_1.ArchivedDaysheet_Factory.CreateIncoming(i); }),
            BusinessLicenseNumber: model.BusinessLicenseNumber,
            BusinessName: model.BusinessName,
            Chairs: model.Chairs && model.Chairs.map(function (i) { return Chair_1.Chair_Factory.CreateIncoming(i); }),
            ConsultationChairsideResponseTimeInMinutes: model.ConsultationChairsideResponseTimeInMinutes,
            ConsultationRemoteResponseTimeInHours: model.ConsultationRemoteResponseTimeInHours,
            ConvertedFromDPMSTypeId: model.ConvertedFromDPMSTypeId,
            DefaultDoctor: User_1.User_Factory.CreateIncoming(model.DefaultDoctor),
            DefaultDoctorId: model.DefaultDoctorId,
            DefaultHygienist: User_1.User_Factory.CreateIncoming(model.DefaultHygienist),
            DefaultHygienistId: model.DefaultHygienistId,
            DefaultRecallMinutes: model.DefaultRecallMinutes,
            EnterpriseLocationId: model.EnterpriseLocationId,
            EPaymentsDefaultMerchantId: model.EPaymentsDefaultMerchantId,
            Facebook: model.Facebook,
            GooglePlus: model.GooglePlus,
            InternalName: model.InternalName,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            LinkedIn: model.LinkedIn,
            LocationDefaultWorkingDay: LocationDefaultWorkingDay_1.LocationDefaultWorkingDay_Factory.CreateIncoming(model.LocationDefaultWorkingDay),
            LocationStatus: model.LocationStatus,
            LocationWorkingDays: model.LocationWorkingDays && model.LocationWorkingDays.map(function (i) { return LocationWorkingDay_1.LocationWorkingDay_Factory.CreateIncoming(i); }),
            NHSEnabled: model.NHSEnabled,
            NHSLocation: NHSLocation_1.NHSLocation_Factory.CreateIncoming(model.NHSLocation),
            NHSLocationId: model.NHSLocationId,
            NPI: model.NPI,
            OrthoPatientTreatmentStatuses: model.OrthoPatientTreatmentStatuses && model.OrthoPatientTreatmentStatuses.map(function (i) { return OrthoPatientTreatmentStatus_1.OrthoPatientTreatmentStatus_Factory.CreateIncoming(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateIncoming(i); }),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(i); }),
            Pinterest: model.Pinterest,
            PracticeEmail: model.PracticeEmail,
            PracticeEmailAccountType: PracticeEmailAccountType_1.PracticeEmailAccountType_Factory.CreateIncoming(model.PracticeEmailAccountType),
            PracticeEmailAccountTypeCode: model.PracticeEmailAccountTypeCode,
            PracticeEmailDisplaySender: model.PracticeEmailDisplaySender,
            PracticeEmailPassword: model.PracticeEmailPassword,
            PreferredProcedureList: ProcedureList_1.ProcedureList_Factory.CreateIncoming(model.PreferredProcedureList),
            PreferredProcedureListId: model.PreferredProcedureListId,
            ProviderTaxonomyCode: model.ProviderTaxonomyCode,
            ProviderTaxonomyCode1: ProviderTaxonomyCode_1.ProviderTaxonomyCode_Factory.CreateIncoming(model.ProviderTaxonomyCode1),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralWorkingHourResponseTime: model.ReferralWorkingHourResponseTime,
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateIncoming(i); }),
            SchedulerBaseTimeUnits: model.SchedulerBaseTimeUnits,
            ScheduleViews: model.ScheduleViews && model.ScheduleViews.map(function (i) { return ScheduleView_1.ScheduleView_Factory.CreateIncoming(i); }),
            SearchSlotLength: model.SearchSlotLength,
            TaxIdNumber: model.TaxIdNumber,
            TimeZone: TimeZone_1.TimeZone_Factory.CreateIncoming(model.TimeZone),
            TimeZoneId: model.TimeZoneId,
            ToothNumberingType: ToothNumberingType_1.ToothNumberingType_Factory.CreateIncoming(model.ToothNumberingType),
            ToothNumberingTypeCode: model.ToothNumberingTypeCode,
            Twitter: model.Twitter,
            UniqueID: model.UniqueID,
            UserDefaultWorkingDays: model.UserDefaultWorkingDays && model.UserDefaultWorkingDays.map(function (i) { return UserDefaultWorkingDay_1.UserDefaultWorkingDay_Factory.CreateIncoming(i); }),
            UserWorkingDays: model.UserWorkingDays && model.UserWorkingDays.map(function (i) { return UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(i); }),
            WaitingLists: model.WaitingLists && model.WaitingLists.map(function (i) { return WaitingList_1.WaitingList_Factory.CreateIncoming(i); }),
            WaitingListsEnabled: model.WaitingListsEnabled,
            Website: model.Website,
            YouTube: model.YouTube,
        };
        return result;
    };
    Location_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Addresses: model.Addresses && model.Addresses.map(function (i) { return Address_1.Address_Factory.CreateOutgoing(i); }),
            AnesthesiaRecords: model.AnesthesiaRecords && model.AnesthesiaRecords.map(function (i) { return AnesthesiaRecord_1.AnesthesiaRecord_Factory.CreateOutgoing(i); }),
            ArchivedDaysheets: model.ArchivedDaysheets && model.ArchivedDaysheets.map(function (i) { return ArchivedDaysheet_1.ArchivedDaysheet_Factory.CreateOutgoing(i); }),
            BusinessLicenseNumber: model.BusinessLicenseNumber,
            BusinessName: model.BusinessName,
            Chairs: model.Chairs && model.Chairs.map(function (i) { return Chair_1.Chair_Factory.CreateOutgoing(i); }),
            ConsultationChairsideResponseTimeInMinutes: model.ConsultationChairsideResponseTimeInMinutes,
            ConsultationRemoteResponseTimeInHours: model.ConsultationRemoteResponseTimeInHours,
            ConvertedFromDPMSTypeId: model.ConvertedFromDPMSTypeId,
            DefaultDoctor: User_1.User_Factory.CreateOutgoing(model.DefaultDoctor),
            DefaultDoctorId: model.DefaultDoctorId,
            DefaultHygienist: User_1.User_Factory.CreateOutgoing(model.DefaultHygienist),
            DefaultHygienistId: model.DefaultHygienistId,
            DefaultRecallMinutes: model.DefaultRecallMinutes,
            EnterpriseLocationId: model.EnterpriseLocationId,
            EPaymentsDefaultMerchantId: model.EPaymentsDefaultMerchantId,
            Facebook: model.Facebook,
            GooglePlus: model.GooglePlus,
            InternalName: model.InternalName,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            LinkedIn: model.LinkedIn,
            LocationDefaultWorkingDay: LocationDefaultWorkingDay_1.LocationDefaultWorkingDay_Factory.CreateOutgoing(model.LocationDefaultWorkingDay),
            LocationStatus: model.LocationStatus,
            LocationWorkingDays: model.LocationWorkingDays && model.LocationWorkingDays.map(function (i) { return LocationWorkingDay_1.LocationWorkingDay_Factory.CreateOutgoing(i); }),
            NHSEnabled: model.NHSEnabled,
            NHSLocation: NHSLocation_1.NHSLocation_Factory.CreateOutgoing(model.NHSLocation),
            NHSLocationId: model.NHSLocationId,
            NPI: model.NPI,
            OrthoPatientTreatmentStatuses: model.OrthoPatientTreatmentStatuses && model.OrthoPatientTreatmentStatuses.map(function (i) { return OrthoPatientTreatmentStatus_1.OrthoPatientTreatmentStatus_Factory.CreateOutgoing(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateOutgoing(i); }),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(i); }),
            Pinterest: model.Pinterest,
            PracticeEmail: model.PracticeEmail,
            PracticeEmailAccountType: PracticeEmailAccountType_1.PracticeEmailAccountType_Factory.CreateOutgoing(model.PracticeEmailAccountType),
            PracticeEmailAccountTypeCode: model.PracticeEmailAccountTypeCode,
            PracticeEmailDisplaySender: model.PracticeEmailDisplaySender,
            PracticeEmailPassword: model.PracticeEmailPassword,
            PreferredProcedureList: ProcedureList_1.ProcedureList_Factory.CreateOutgoing(model.PreferredProcedureList),
            PreferredProcedureListId: model.PreferredProcedureListId,
            ProviderTaxonomyCode: model.ProviderTaxonomyCode,
            ProviderTaxonomyCode1: ProviderTaxonomyCode_1.ProviderTaxonomyCode_Factory.CreateOutgoing(model.ProviderTaxonomyCode1),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralWorkingHourResponseTime: model.ReferralWorkingHourResponseTime,
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateOutgoing(i); }),
            SchedulerBaseTimeUnits: model.SchedulerBaseTimeUnits,
            ScheduleViews: model.ScheduleViews && model.ScheduleViews.map(function (i) { return ScheduleView_1.ScheduleView_Factory.CreateOutgoing(i); }),
            SearchSlotLength: model.SearchSlotLength,
            TaxIdNumber: model.TaxIdNumber,
            TimeZone: TimeZone_1.TimeZone_Factory.CreateOutgoing(model.TimeZone),
            TimeZoneId: model.TimeZoneId,
            ToothNumberingType: ToothNumberingType_1.ToothNumberingType_Factory.CreateOutgoing(model.ToothNumberingType),
            ToothNumberingTypeCode: model.ToothNumberingTypeCode,
            Twitter: model.Twitter,
            UniqueID: model.UniqueID,
            UserDefaultWorkingDays: model.UserDefaultWorkingDays && model.UserDefaultWorkingDays.map(function (i) { return UserDefaultWorkingDay_1.UserDefaultWorkingDay_Factory.CreateOutgoing(i); }),
            UserWorkingDays: model.UserWorkingDays && model.UserWorkingDays.map(function (i) { return UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(i); }),
            WaitingLists: model.WaitingLists && model.WaitingLists.map(function (i) { return WaitingList_1.WaitingList_Factory.CreateOutgoing(i); }),
            WaitingListsEnabled: model.WaitingListsEnabled,
            Website: model.Website,
            YouTube: model.YouTube,
        };
        return result;
    };
    return Location_Factory;
}());
exports.Location_Factory = Location_Factory;
