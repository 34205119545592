"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReminderPatientDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Reminder_1 = require("./Reminder");
var ReminderPatientDetail_Factory = /** @class */ (function () {
    function ReminderPatientDetail_Factory() {
    }
    ReminderPatientDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            FirstName: model.FirstName,
            FullName: model.FullName,
            LastName: model.LastName,
            Reminder: Reminder_1.Reminder_Factory.CreateIncoming(model.Reminder),
            ReminderId: model.ReminderId,
        };
        return result;
    };
    ReminderPatientDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            FirstName: model.FirstName,
            FullName: model.FullName,
            LastName: model.LastName,
            Reminder: Reminder_1.Reminder_Factory.CreateOutgoing(model.Reminder),
            ReminderId: model.ReminderId,
        };
        return result;
    };
    return ReminderPatientDetail_Factory;
}());
exports.ReminderPatientDetail_Factory = ReminderPatientDetail_Factory;
