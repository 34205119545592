"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCDSBand_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCDSBand_Factory = /** @class */ (function () {
    function NHSCDSBand_Factory() {
    }
    NHSCDSBand_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Band: model.Band,
            BandID: model.BandID,
            Code: model.Code,
            CountryCode: model.CountryCode,
            EndDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EndDate),
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
            StringBand: model.StringBand,
        };
        return result;
    };
    NHSCDSBand_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Band: model.Band,
            BandID: model.BandID,
            Code: model.Code,
            CountryCode: model.CountryCode,
            EndDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EndDate),
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
            StringBand: model.StringBand,
        };
        return result;
    };
    return NHSCDSBand_Factory;
}());
exports.NHSCDSBand_Factory = NHSCDSBand_Factory;
