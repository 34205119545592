"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrthoContractAdditionalCDTCode_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CDTCode_1 = require("./CDTCode");
var OrthoContract_1 = require("./OrthoContract");
var OrthoContractChargeSchedule_1 = require("./OrthoContractChargeSchedule");
var Procedure_1 = require("./Procedure");
var OrthoContractAdditionalCDTCode_Factory = /** @class */ (function () {
    function OrthoContractAdditionalCDTCode_Factory() {
    }
    OrthoContractAdditionalCDTCode_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CDTCode: CDTCode_1.CDTCode_Factory.CreateIncoming(model.CDTCode),
            CDTCodeId: model.CDTCodeId,
            ChargeScheduleId: model.ChargeScheduleId,
            ContractId: model.ContractId,
            InsurancePolicyId: model.InsurancePolicyId,
            IsCOT: model.IsCOT,
            IsPrimary: model.IsPrimary,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            OrthoContract: OrthoContract_1.OrthoContract_Factory.CreateIncoming(model.OrthoContract),
            OrthoContractChargeSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateIncoming(model.OrthoContractChargeSchedule),
            Procedure: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure),
            ProcedureId: model.ProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Surfaces: model.Surfaces,
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothRootsMask: model.ToothRootsMask,
            ToothSurfacesMask: model.ToothSurfacesMask,
        };
        return result;
    };
    OrthoContractAdditionalCDTCode_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CDTCode: CDTCode_1.CDTCode_Factory.CreateOutgoing(model.CDTCode),
            CDTCodeId: model.CDTCodeId,
            ChargeScheduleId: model.ChargeScheduleId,
            ContractId: model.ContractId,
            InsurancePolicyId: model.InsurancePolicyId,
            IsCOT: model.IsCOT,
            IsPrimary: model.IsPrimary,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            OrthoContract: OrthoContract_1.OrthoContract_Factory.CreateOutgoing(model.OrthoContract),
            OrthoContractChargeSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateOutgoing(model.OrthoContractChargeSchedule),
            Procedure: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure),
            ProcedureId: model.ProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Surfaces: model.Surfaces,
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothRootsMask: model.ToothRootsMask,
            ToothSurfacesMask: model.ToothSurfacesMask,
        };
        return result;
    };
    return OrthoContractAdditionalCDTCode_Factory;
}());
exports.OrthoContractAdditionalCDTCode_Factory = OrthoContractAdditionalCDTCode_Factory;
