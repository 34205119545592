"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateImagingRequest_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var ImagingOrder_1 = require("./ImagingOrder");
var CreateImagingRequest_Factory = /** @class */ (function () {
    function CreateImagingRequest_Factory() {
    }
    CreateImagingRequest_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            appointmentTime: Date_Factory_1.Date_Factory.CreateIncoming(model.appointmentTime),
            location: Location_1.Location_Factory.CreateIncoming(model.location),
            order: ImagingOrder_1.ImagingOrder_Factory.CreateIncoming(model.order),
        };
        return result;
    };
    CreateImagingRequest_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            appointmentTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.appointmentTime),
            location: Location_1.Location_Factory.CreateOutgoing(model.location),
            order: ImagingOrder_1.ImagingOrder_Factory.CreateOutgoing(model.order),
        };
        return result;
    };
    return CreateImagingRequest_Factory;
}());
exports.CreateImagingRequest_Factory = CreateImagingRequest_Factory;
