"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcedureCategoryType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ProcedureCategoryType_Factory = /** @class */ (function () {
    function ProcedureCategoryType_Factory() {
    }
    ProcedureCategoryType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CategoryCode: model.CategoryCode,
            Description: model.Description,
            FirstCDTCode: model.FirstCDTCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    ProcedureCategoryType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CategoryCode: model.CategoryCode,
            Description: model.Description,
            FirstCDTCode: model.FirstCDTCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return ProcedureCategoryType_Factory;
}());
exports.ProcedureCategoryType_Factory = ProcedureCategoryType_Factory;
