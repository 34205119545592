"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MawidDeltaExtractProblem_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MawidDeltaExtractProblem_Factory = /** @class */ (function () {
    function MawidDeltaExtractProblem_Factory() {
    }
    MawidDeltaExtractProblem_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ErrorMessage: model.ErrorMessage,
            IsError: model.IsError,
            RecordId: model.RecordId,
        };
        return result;
    };
    MawidDeltaExtractProblem_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ErrorMessage: model.ErrorMessage,
            IsError: model.IsError,
            RecordId: model.RecordId,
        };
        return result;
    };
    return MawidDeltaExtractProblem_Factory;
}());
exports.MawidDeltaExtractProblem_Factory = MawidDeltaExtractProblem_Factory;
