"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurgeryInfo_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SurgeryInfo_Factory = /** @class */ (function () {
    function SurgeryInfo_Factory() {
    }
    SurgeryInfo_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AnesthesiaTechnique: model.AnesthesiaTechnique,
            AnesthesiaTechniqueOther: model.AnesthesiaTechniqueOther,
            EBL: model.EBL,
            EndotrachealIntubation: model.EndotrachealIntubation,
            GasDelivery: model.GasDelivery,
            GasDeliveryOther: model.GasDeliveryOther,
            IVDetailsOther: model.IVDetailsOther,
            IVDetailsSize: model.IVDetailsSize,
            IVLocationCode: model.IVLocationCode,
            IVTypeCode: model.IVTypeCode,
            MonitorUsed: model.MonitorUsed,
            MonitorUsedOther: model.MonitorUsedOther,
            Nasal: model.Nasal,
            NoComplications: model.NoComplications,
            Notes: model.Notes,
            OperativeSiteMarked: model.OperativeSiteMarked,
            PreOpVerificationComplete: model.PreOpVerificationComplete,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Size: model.Size,
        };
        return result;
    };
    SurgeryInfo_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AnesthesiaTechnique: model.AnesthesiaTechnique,
            AnesthesiaTechniqueOther: model.AnesthesiaTechniqueOther,
            EBL: model.EBL,
            EndotrachealIntubation: model.EndotrachealIntubation,
            GasDelivery: model.GasDelivery,
            GasDeliveryOther: model.GasDeliveryOther,
            IVDetailsOther: model.IVDetailsOther,
            IVDetailsSize: model.IVDetailsSize,
            IVLocationCode: model.IVLocationCode,
            IVTypeCode: model.IVTypeCode,
            MonitorUsed: model.MonitorUsed,
            MonitorUsedOther: model.MonitorUsedOther,
            Nasal: model.Nasal,
            NoComplications: model.NoComplications,
            Notes: model.Notes,
            OperativeSiteMarked: model.OperativeSiteMarked,
            PreOpVerificationComplete: model.PreOpVerificationComplete,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Size: model.Size,
        };
        return result;
    };
    return SurgeryInfo_Factory;
}());
exports.SurgeryInfo_Factory = SurgeryInfo_Factory;
