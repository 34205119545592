"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSContractMembership_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSContractPeriod_1 = require("./NHSContractPeriod");
var NHSContractTarget_1 = require("./NHSContractTarget");
var User_1 = require("./User");
var NHSContractMembership_Factory = /** @class */ (function () {
    function NHSContractMembership_Factory() {
    }
    NHSContractMembership_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            NHSContractPeriod: NHSContractPeriod_1.NHSContractPeriod_Factory.CreateIncoming(model.NHSContractPeriod),
            NHSContractTargets: model.NHSContractTargets && model.NHSContractTargets.map(function (i) { return NHSContractTarget_1.NHSContractTarget_Factory.CreateIncoming(i); }),
            PeriodID: model.PeriodID,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UDATarget: model.UDATarget,
            UOATarget: model.UOATarget,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    NHSContractMembership_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            NHSContractPeriod: NHSContractPeriod_1.NHSContractPeriod_Factory.CreateOutgoing(model.NHSContractPeriod),
            NHSContractTargets: model.NHSContractTargets && model.NHSContractTargets.map(function (i) { return NHSContractTarget_1.NHSContractTarget_Factory.CreateOutgoing(i); }),
            PeriodID: model.PeriodID,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UDATarget: model.UDATarget,
            UOATarget: model.UOATarget,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return NHSContractMembership_Factory;
}());
exports.NHSContractMembership_Factory = NHSContractMembership_Factory;
