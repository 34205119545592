"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerioRecord_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PerioRecord_Factory = /** @class */ (function () {
    function PerioRecord_Factory() {
    }
    PerioRecord_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Furcation: model.Furcation,
            GingivalMargin: model.GingivalMargin,
            HasBleeding: model.HasBleeding,
            HasSuppuration: model.HasSuppuration,
            Mobility: model.Mobility,
            PerioExamId: model.PerioExamId,
            Pocket: model.Pocket,
            PocketDepth: model.PocketDepth,
            Surface: model.Surface,
            ToothNum: model.ToothNum,
        };
        return result;
    };
    PerioRecord_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Furcation: model.Furcation,
            GingivalMargin: model.GingivalMargin,
            HasBleeding: model.HasBleeding,
            HasSuppuration: model.HasSuppuration,
            Mobility: model.Mobility,
            PerioExamId: model.PerioExamId,
            Pocket: model.Pocket,
            PocketDepth: model.PocketDepth,
            Surface: model.Surface,
            ToothNum: model.ToothNum,
        };
        return result;
    };
    return PerioRecord_Factory;
}());
exports.PerioRecord_Factory = PerioRecord_Factory;
