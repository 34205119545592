"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsNumericElementDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsNumericElementDetail_Factory = /** @class */ (function () {
    function EFormsNumericElementDetail_Factory() {
    }
    EFormsNumericElementDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            MaxValue: model.MaxValue,
            MinValue: model.MinValue,
            RecordId: model.RecordId,
        };
        return result;
    };
    EFormsNumericElementDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            MaxValue: model.MaxValue,
            MinValue: model.MinValue,
            RecordId: model.RecordId,
        };
        return result;
    };
    return EFormsNumericElementDetail_Factory;
}());
exports.EFormsNumericElementDetail_Factory = EFormsNumericElementDetail_Factory;
