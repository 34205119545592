"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ITeroScan_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEvent_1 = require("./ClinicalEvent");
var ITeroScan_Factory = /** @class */ (function () {
    function ITeroScan_Factory() {
    }
    ITeroScan_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            DoctorName: model.DoctorName,
            OrderId: model.OrderId,
            PatientId: model.PatientId,
            ProcedureName: model.ProcedureName,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RxId: model.RxId,
            ScanningDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ScanningDate),
            Status: model.Status,
            ViewerUrl: model.ViewerUrl,
        };
        return result;
    };
    ITeroScan_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            DoctorName: model.DoctorName,
            OrderId: model.OrderId,
            PatientId: model.PatientId,
            ProcedureName: model.ProcedureName,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RxId: model.RxId,
            ScanningDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ScanningDate),
            Status: model.Status,
            ViewerUrl: model.ViewerUrl,
        };
        return result;
    };
    return ITeroScan_Factory;
}());
exports.ITeroScan_Factory = ITeroScan_Factory;
