"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsurancePaperClaimFormat_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsurancePaperClaimFormat_Factory = /** @class */ (function () {
    function InsurancePaperClaimFormat_Factory() {
    }
    InsurancePaperClaimFormat_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            DisplayText: model.DisplayText,
            InsurancePlanTypeCode: model.InsurancePlanTypeCode,
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    InsurancePaperClaimFormat_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            DisplayText: model.DisplayText,
            InsurancePlanTypeCode: model.InsurancePlanTypeCode,
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return InsurancePaperClaimFormat_Factory;
}());
exports.InsurancePaperClaimFormat_Factory = InsurancePaperClaimFormat_Factory;
