"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstateReferral_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AppointmentType_1 = require("./AppointmentType");
var ClinicalEvent_1 = require("./ClinicalEvent");
var User_1 = require("./User");
var EnterpriseConsultationSubjectLink_1 = require("./EnterpriseConsultationSubjectLink");
var EstateReferralAuditLog_1 = require("./EstateReferralAuditLog");
var EstateReferralRejectionReason_1 = require("./EstateReferralRejectionReason");
var EnterpriseLocation_1 = require("./EnterpriseLocation");
var Location_1 = require("./Location");
var Patient_1 = require("./Patient");
var EstateReferral_Factory = /** @class */ (function () {
    function EstateReferral_Factory() {
    }
    EstateReferral_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateIncoming(model.AppointmentType),
            AppointmentTypeId: model.AppointmentTypeId,
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            CreatedBy: User_1.User_Factory.CreateIncoming(model.CreatedBy),
            CreatedById: model.CreatedById,
            Doctor: User_1.User_Factory.CreateIncoming(model.Doctor),
            DoctorId: model.DoctorId,
            EnterpriseConsultationSubjectLink: EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateIncoming(model.EnterpriseConsultationSubjectLink),
            EnterpriseConsultationSubjectLinkId: model.EnterpriseConsultationSubjectLinkId,
            EstateReferralAuditLogs: model.EstateReferralAuditLogs && model.EstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateIncoming(i); }),
            EstateReferralRejectionReason: EstateReferralRejectionReason_1.EstateReferralRejectionReason_Factory.CreateIncoming(model.EstateReferralRejectionReason),
            EstateReferralRejectionReasonId: model.EstateReferralRejectionReasonId,
            IsUrgent: model.IsUrgent,
            NotesToDoctor: model.NotesToDoctor,
            NotesToReceptionist: model.NotesToReceptionist,
            OriginatingEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateIncoming(model.OriginatingEnterpriseLocation),
            OriginatingEnterpriseLocationId: model.OriginatingEnterpriseLocationId,
            OriginatingLocation: Location_1.Location_Factory.CreateIncoming(model.OriginatingLocation),
            OriginatingLocationId: model.OriginatingLocationId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PreferredTime: model.PreferredTime,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralDateRange: model.ReferralDateRange,
            ReferralDateUnit: model.ReferralDateUnit,
            ReferralEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateIncoming(model.ReferralEnterpriseLocation),
            ReferralEnterpriseLocationId: model.ReferralEnterpriseLocationId,
            ReferralLocation: Location_1.Location_Factory.CreateIncoming(model.ReferralLocation),
            ReferralLocationId: model.ReferralLocationId,
            ReferralNumberOfDateUnits: model.ReferralNumberOfDateUnits,
            ReferralStatus: model.ReferralStatus,
            ReferralToSummary: model.ReferralToSummary,
            SummaryLinkedObjectsJson: model.SummaryLinkedObjectsJson,
        };
        return result;
    };
    EstateReferral_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateOutgoing(model.AppointmentType),
            AppointmentTypeId: model.AppointmentTypeId,
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            CreatedBy: User_1.User_Factory.CreateOutgoing(model.CreatedBy),
            CreatedById: model.CreatedById,
            Doctor: User_1.User_Factory.CreateOutgoing(model.Doctor),
            DoctorId: model.DoctorId,
            EnterpriseConsultationSubjectLink: EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateOutgoing(model.EnterpriseConsultationSubjectLink),
            EnterpriseConsultationSubjectLinkId: model.EnterpriseConsultationSubjectLinkId,
            EstateReferralAuditLogs: model.EstateReferralAuditLogs && model.EstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateOutgoing(i); }),
            EstateReferralRejectionReason: EstateReferralRejectionReason_1.EstateReferralRejectionReason_Factory.CreateOutgoing(model.EstateReferralRejectionReason),
            EstateReferralRejectionReasonId: model.EstateReferralRejectionReasonId,
            IsUrgent: model.IsUrgent,
            NotesToDoctor: model.NotesToDoctor,
            NotesToReceptionist: model.NotesToReceptionist,
            OriginatingEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateOutgoing(model.OriginatingEnterpriseLocation),
            OriginatingEnterpriseLocationId: model.OriginatingEnterpriseLocationId,
            OriginatingLocation: Location_1.Location_Factory.CreateOutgoing(model.OriginatingLocation),
            OriginatingLocationId: model.OriginatingLocationId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PreferredTime: model.PreferredTime,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralDateRange: model.ReferralDateRange,
            ReferralDateUnit: model.ReferralDateUnit,
            ReferralEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateOutgoing(model.ReferralEnterpriseLocation),
            ReferralEnterpriseLocationId: model.ReferralEnterpriseLocationId,
            ReferralLocation: Location_1.Location_Factory.CreateOutgoing(model.ReferralLocation),
            ReferralLocationId: model.ReferralLocationId,
            ReferralNumberOfDateUnits: model.ReferralNumberOfDateUnits,
            ReferralStatus: model.ReferralStatus,
            ReferralToSummary: model.ReferralToSummary,
            SummaryLinkedObjectsJson: model.SummaryLinkedObjectsJson,
        };
        return result;
    };
    return EstateReferral_Factory;
}());
exports.EstateReferral_Factory = EstateReferral_Factory;
