"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstateReferralDateRange_DisplayOrders = exports.EstateReferralDateRange_Descriptions_English = exports.EstateReferralDateRange_Descriptions = exports.EstateReferralDateRange_translateDescriptionBack = exports.EstateReferralDateRange_translateDescriptions = exports.EstateReferralDateRange = void 0;
var EstateReferralDateRange;
(function (EstateReferralDateRange) {
    EstateReferralDateRange[EstateReferralDateRange["ER"] = 0] = "ER";
    EstateReferralDateRange[EstateReferralDateRange["NearestAvailableAppointment"] = 1] = "NearestAvailableAppointment";
    EstateReferralDateRange[EstateReferralDateRange["Custom"] = 99] = "Custom";
})(EstateReferralDateRange = exports.EstateReferralDateRange || (exports.EstateReferralDateRange = {}));
function EstateReferralDateRange_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EstateReferralDateRange_Descriptions);
    allKeys.forEach(function (key) {
        exports.EstateReferralDateRange_Descriptions[key] = translationFunction(exports.EstateReferralDateRange_Descriptions[key]);
    });
}
exports.EstateReferralDateRange_translateDescriptions = EstateReferralDateRange_translateDescriptions;
function EstateReferralDateRange_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EstateReferralDateRange_Descriptions);
    allKeys.forEach(function (key) {
        exports.EstateReferralDateRange_Descriptions[key] = exports.EstateReferralDateRange_Descriptions_English[key];
    });
}
exports.EstateReferralDateRange_translateDescriptionBack = EstateReferralDateRange_translateDescriptionBack;
var _EstateReferralDateRange_Descriptions = {};
_EstateReferralDateRange_Descriptions.ER = _EstateReferralDateRange_Descriptions[0] = "ER";
_EstateReferralDateRange_Descriptions.NearestAvailableAppointment = _EstateReferralDateRange_Descriptions[1] = "Nearest Available Appointment";
_EstateReferralDateRange_Descriptions.Custom = _EstateReferralDateRange_Descriptions[99] = "Custom";
var _EstateReferralDateRange_Descriptions_English = {};
_EstateReferralDateRange_Descriptions_English.ER = _EstateReferralDateRange_Descriptions_English[0] = "ER";
_EstateReferralDateRange_Descriptions_English.NearestAvailableAppointment = _EstateReferralDateRange_Descriptions_English[1] = "Nearest Available Appointment";
_EstateReferralDateRange_Descriptions_English.Custom = _EstateReferralDateRange_Descriptions_English[99] = "Custom";
exports.EstateReferralDateRange_Descriptions = _EstateReferralDateRange_Descriptions;
exports.EstateReferralDateRange_Descriptions_English = _EstateReferralDateRange_Descriptions_English;
var _EstateReferralDateRange_DisplayOrders = {};
_EstateReferralDateRange_DisplayOrders.ER = _EstateReferralDateRange_DisplayOrders[0] = 0;
_EstateReferralDateRange_DisplayOrders.NearestAvailableAppointment = _EstateReferralDateRange_DisplayOrders[1] = 1;
_EstateReferralDateRange_DisplayOrders.Custom = _EstateReferralDateRange_DisplayOrders[99] = 99;
exports.EstateReferralDateRange_DisplayOrders = _EstateReferralDateRange_DisplayOrders;
