"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationClaimsActivity_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LocationClaimsActivity_Factory = /** @class */ (function () {
    function LocationClaimsActivity_Factory() {
    }
    LocationClaimsActivity_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            OpenActiveAmount: model.OpenActiveAmount,
            OpenActiveCount: model.OpenActiveCount,
            OpenAmount: model.OpenAmount,
            OpenCount: model.OpenCount,
            OpenPendingAmount: model.OpenPendingAmount,
            OpenPendingCount: model.OpenPendingCount,
            OpenProblematicAmount: model.OpenProblematicAmount,
            OpenProblematicCount: model.OpenProblematicCount,
            UnclaimedAmount: model.UnclaimedAmount,
            UnclaimedCount: model.UnclaimedCount,
        };
        return result;
    };
    LocationClaimsActivity_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            OpenActiveAmount: model.OpenActiveAmount,
            OpenActiveCount: model.OpenActiveCount,
            OpenAmount: model.OpenAmount,
            OpenCount: model.OpenCount,
            OpenPendingAmount: model.OpenPendingAmount,
            OpenPendingCount: model.OpenPendingCount,
            OpenProblematicAmount: model.OpenProblematicAmount,
            OpenProblematicCount: model.OpenProblematicCount,
            UnclaimedAmount: model.UnclaimedAmount,
            UnclaimedCount: model.UnclaimedCount,
        };
        return result;
    };
    return LocationClaimsActivity_Factory;
}());
exports.LocationClaimsActivity_Factory = LocationClaimsActivity_Factory;
