"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcedureList_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var Procedure_1 = require("./Procedure");
var User_1 = require("./User");
var ServiceScheme_1 = require("./ServiceScheme");
var ProcedureList_Factory = /** @class */ (function () {
    function ProcedureList_Factory() {
    }
    ProcedureList_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            IsDefault: model.IsDefault,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            Name: model.Name,
            Procedures: model.Procedures && model.Procedures.map(function (i) { return Procedure_1.Procedure_Factory.CreateIncoming(i); }),
            Provider: User_1.User_Factory.CreateIncoming(model.Provider),
            ProviderId: model.ProviderId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Scheme: ServiceScheme_1.ServiceScheme_Factory.CreateIncoming(model.Scheme),
            SchemeId: model.SchemeId,
        };
        return result;
    };
    ProcedureList_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            IsDefault: model.IsDefault,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            Name: model.Name,
            Procedures: model.Procedures && model.Procedures.map(function (i) { return Procedure_1.Procedure_Factory.CreateOutgoing(i); }),
            Provider: User_1.User_Factory.CreateOutgoing(model.Provider),
            ProviderId: model.ProviderId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Scheme: ServiceScheme_1.ServiceScheme_Factory.CreateOutgoing(model.Scheme),
            SchemeId: model.SchemeId,
        };
        return result;
    };
    return ProcedureList_Factory;
}());
exports.ProcedureList_Factory = ProcedureList_Factory;
