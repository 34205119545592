"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEFormsPrefillDataResponse_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsLocationPrefill_1 = require("./EFormsLocationPrefill");
var EFormsPatientPrefill_1 = require("./EFormsPatientPrefill");
var EFormsProviderPrefill_1 = require("./EFormsProviderPrefill");
var GetEFormsPrefillDataResponse_Factory = /** @class */ (function () {
    function GetEFormsPrefillDataResponse_Factory() {
    }
    GetEFormsPrefillDataResponse_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Locations: model.Locations && model.Locations.map(function (i) { return EFormsLocationPrefill_1.EFormsLocationPrefill_Factory.CreateIncoming(i); }),
            LogoUrl: model.LogoUrl,
            Patient: EFormsPatientPrefill_1.EFormsPatientPrefill_Factory.CreateIncoming(model.Patient),
            Providers: model.Providers && model.Providers.map(function (i) { return EFormsProviderPrefill_1.EFormsProviderPrefill_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    GetEFormsPrefillDataResponse_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Locations: model.Locations && model.Locations.map(function (i) { return EFormsLocationPrefill_1.EFormsLocationPrefill_Factory.CreateOutgoing(i); }),
            LogoUrl: model.LogoUrl,
            Patient: EFormsPatientPrefill_1.EFormsPatientPrefill_Factory.CreateOutgoing(model.Patient),
            Providers: model.Providers && model.Providers.map(function (i) { return EFormsProviderPrefill_1.EFormsProviderPrefill_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return GetEFormsPrefillDataResponse_Factory;
}());
exports.GetEFormsPrefillDataResponse_Factory = GetEFormsPrefillDataResponse_Factory;
