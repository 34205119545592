"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSUserDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var NHSUserDetail_Factory = /** @class */ (function () {
    function NHSUserDetail_Factory() {
    }
    NHSUserDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DCPType: model.DCPType,
            GDCNumber: model.GDCNumber,
            PerformerNumber: model.PerformerNumber,
            Pin: model.Pin,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    NHSUserDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DCPType: model.DCPType,
            GDCNumber: model.GDCNumber,
            PerformerNumber: model.PerformerNumber,
            Pin: model.Pin,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return NHSUserDetail_Factory;
}());
exports.NHSUserDetail_Factory = NHSUserDetail_Factory;
