"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTypeCategoryColumn_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReportCategory_1 = require("./ReportCategory");
var ReportColumn_1 = require("./ReportColumn");
var ReportType_1 = require("./ReportType");
var ReportTypeCategoryColumn_Factory = /** @class */ (function () {
    function ReportTypeCategoryColumn_Factory() {
    }
    ReportTypeCategoryColumn_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Category: ReportCategory_1.ReportCategory_Factory.CreateIncoming(model.Category),
            CategoryCode: model.CategoryCode,
            Column: ReportColumn_1.ReportColumn_Factory.CreateIncoming(model.Column),
            ColumnCode: model.ColumnCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Type: ReportType_1.ReportType_Factory.CreateIncoming(model.Type),
            TypeCode: model.TypeCode,
        };
        return result;
    };
    ReportTypeCategoryColumn_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Category: ReportCategory_1.ReportCategory_Factory.CreateOutgoing(model.Category),
            CategoryCode: model.CategoryCode,
            Column: ReportColumn_1.ReportColumn_Factory.CreateOutgoing(model.Column),
            ColumnCode: model.ColumnCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Type: ReportType_1.ReportType_Factory.CreateOutgoing(model.Type),
            TypeCode: model.TypeCode,
        };
        return result;
    };
    return ReportTypeCategoryColumn_Factory;
}());
exports.ReportTypeCategoryColumn_Factory = ReportTypeCategoryColumn_Factory;
