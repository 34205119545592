"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrespondenceTemplateContent_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CorrespondenceTemplateContentType_1 = require("./CorrespondenceTemplateContentType");
var Language_1 = require("./Language");
var CorrespondenceTemplateContent_Factory = /** @class */ (function () {
    function CorrespondenceTemplateContent_Factory() {
    }
    CorrespondenceTemplateContent_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ContentType: model.ContentType,
            CorrespondenceTemplateContentType: CorrespondenceTemplateContentType_1.CorrespondenceTemplateContentType_Factory.CreateIncoming(model.CorrespondenceTemplateContentType),
            CorrespondenceTemplateId: model.CorrespondenceTemplateId,
            Language: Language_1.Language_Factory.CreateIncoming(model.Language),
            LanguageCultureString: model.LanguageCultureString,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TemplateContent: model.TemplateContent,
        };
        return result;
    };
    CorrespondenceTemplateContent_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ContentType: model.ContentType,
            CorrespondenceTemplateContentType: CorrespondenceTemplateContentType_1.CorrespondenceTemplateContentType_Factory.CreateOutgoing(model.CorrespondenceTemplateContentType),
            CorrespondenceTemplateId: model.CorrespondenceTemplateId,
            Language: Language_1.Language_Factory.CreateOutgoing(model.Language),
            LanguageCultureString: model.LanguageCultureString,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TemplateContent: model.TemplateContent,
        };
        return result;
    };
    return CorrespondenceTemplateContent_Factory;
}());
exports.CorrespondenceTemplateContent_Factory = CorrespondenceTemplateContent_Factory;
