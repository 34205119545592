"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCaseMixRecordedCriteria_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCaseMixRecordedCriteria_Factory = /** @class */ (function () {
    function NHSCaseMixRecordedCriteria_Factory() {
    }
    NHSCaseMixRecordedCriteria_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CriterionCode: model.CriterionCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordingId: model.RecordingId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScoreCode: model.ScoreCode,
        };
        return result;
    };
    NHSCaseMixRecordedCriteria_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CriterionCode: model.CriterionCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordingId: model.RecordingId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScoreCode: model.ScoreCode,
        };
        return result;
    };
    return NHSCaseMixRecordedCriteria_Factory;
}());
exports.NHSCaseMixRecordedCriteria_Factory = NHSCaseMixRecordedCriteria_Factory;
