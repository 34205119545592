"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientSnapshot_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EthnicityType_1 = require("./EthnicityType");
var PatientSnapshot_Factory = /** @class */ (function () {
    function PatientSnapshot_Factory() {
    }
    PatientSnapshot_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Address1: model.Address1,
            Address2: model.Address2,
            Address3: model.Address3,
            Address4: model.Address4,
            Address5: model.Address5,
            CertificateDate: Date_Factory_1.Date_Factory.CreateIncoming(model.CertificateDate),
            DOB: Date_Factory_1.Date_Factory.CreateIncoming(model.DOB),
            EthnicityType: EthnicityType_1.EthnicityType_Factory.CreateIncoming(model.EthnicityType),
            EthnicityTypeCode: model.EthnicityTypeCode,
            ExemptAmount: model.ExemptAmount,
            ExemptCode: model.ExemptCode,
            ExemptionCheck: model.ExemptionCheck,
            ExemptionDetails: model.ExemptionDetails,
            ExemptRemissCode: model.ExemptRemissCode,
            ExemptText: model.ExemptText,
            NHSNo: model.NHSNo,
            PatForename: model.PatForename,
            PatientId: model.PatientId,
            PatPreviousSurname: model.PatPreviousSurname,
            PatSex: model.PatSex,
            PatSurname: model.PatSurname,
            PatTitle: model.PatTitle,
            Postcode: model.Postcode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientSnapshot_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Address1: model.Address1,
            Address2: model.Address2,
            Address3: model.Address3,
            Address4: model.Address4,
            Address5: model.Address5,
            CertificateDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.CertificateDate),
            DOB: Date_Factory_1.Date_Factory.CreateOutgoing(model.DOB),
            EthnicityType: EthnicityType_1.EthnicityType_Factory.CreateOutgoing(model.EthnicityType),
            EthnicityTypeCode: model.EthnicityTypeCode,
            ExemptAmount: model.ExemptAmount,
            ExemptCode: model.ExemptCode,
            ExemptionCheck: model.ExemptionCheck,
            ExemptionDetails: model.ExemptionDetails,
            ExemptRemissCode: model.ExemptRemissCode,
            ExemptText: model.ExemptText,
            NHSNo: model.NHSNo,
            PatForename: model.PatForename,
            PatientId: model.PatientId,
            PatPreviousSurname: model.PatPreviousSurname,
            PatSex: model.PatSex,
            PatSurname: model.PatSurname,
            PatTitle: model.PatTitle,
            Postcode: model.Postcode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientSnapshot_Factory;
}());
exports.PatientSnapshot_Factory = PatientSnapshot_Factory;
