import { ISimplePatientSearchResultModel } from "csd.phoenix.models/SimplePatientSearchResultModel";
import { PhoneNumberTypes } from "csd.phoenix.models/PhoneNumberTypes"
import { loadConfig } from "../services/appConfigService";
import ODataApi from "../services/api/odata";
import AsyncLruCache from "../utils/AsyncLruCache";
import { IODataExpand, ODataQueryParams } from "../services/api/odata/models";
import { IPatient, Patient_Factory } from "csd.phoenix.models/Patient";
import { getDateFilterExpression } from "../services/api/odata/QueryOptionsBuilder";
import { RecordStatusCodes } from "csd.phoenix.models";
import { GenderTypes } from "csd.phoenix.models/GenderTypes";
import { assertNonNil } from "../utils/objectUtils";
import { formatPhoneNumber } from "../utils/dataFormatters";


//#region Get patient
const patientExpansion: IODataExpand = {
  Person: {
    $expand: {
      PhoneNumber: {}
    }
  },
  Scheme: {
    $expand: {
      ServiceSchemeType: {}
    }
  }
}


async function _loadPatient(patientId: string) {
  //console.log("Loading patient info for: " + patientId);
  const { oDataUrl } = await loadConfig();
  const api = new ODataApi(oDataUrl, "Patients");
  const query: ODataQueryParams = {
    filter: `RecordId eq ${patientId}`,
    expand: patientExpansion
  }
  const patient = await api.runEntityQuery<IPatient>(patientId, { method: "GET" }, query);
  return Patient_Factory.CreateIncoming(patient)!;
}

const fifteenMinutes = 1000 * 60 * 15;
const patientCache = new AsyncLruCache<string, IPatient>({maxItems: 100, expireAfter: fifteenMinutes, promiseFactory: _loadPatient})

export function getPatient(patientId: string) {
  return patientCache.get(patientId);
}


export function toPatientSearchModel(patient?: IPatient) {
  if (!patient) {
    return patient;
  }

  const person = assertNonNil(patient.Person);

  const model: ISimplePatientSearchResultModel = {
    IsPatient: true,
    PatientId: patient.RecordId!,
    PersonId: person.RecordId!,
    IsActive: patient.RecordStatus === RecordStatusCodes.Active,
    IsMissing: false,
    Name: person.FullName,
    Gender: person.Gender as GenderTypes,
    Email: person.EmailAddress,
    Birthdate: person.Birthdate,
    Phone: (person.PhoneNumber && formatPhoneNumber(person.PhoneNumber)) || '',
    PhoneType: (person.PhoneNumber && person.PhoneNumber.PhoneTypeCode) || PhoneNumberTypes.Unspecified,
    PreferredLocationId: (patient.PreferredLocationId),
    SchemeType: patient.Scheme?.ServiceSchemeType,
    IsRp: false,
    IsSelfRpOnly: false
  }

  return model;
}



export async function findPatients(firstName: string, lastName: string, birthdate: Date) {
  const { oDataUrl } = await loadConfig();
  const api = new ODataApi(oDataUrl, "Patients");

  const dateFilter = getDateFilterExpression("Person/Birthdate", birthdate);

  // Names need to be escaped to handle apostrophes
  firstName = firstName.replace(/'/g, "''");
  lastName = lastName.replace(/'/g, "''");

  const query: ODataQueryParams = {
    expand: patientExpansion,
    filter: `RecordStatus eq 0 and Person/LastName eq '${lastName}' and (Person/FirstName eq '${firstName}' or Person/Nickname eq '${firstName}') and ${dateFilter}`

  }

  const { value } = await api.runSetQuery<IPatient>({method: "GET"}, query);
  const patients = value.map(p => Patient_Factory.CreateIncoming(p)!);
  // Update cache
  patients.forEach(p => patientCache.setExplicitly(p.RecordId!, p));

  return patients;
}

//#endregion
