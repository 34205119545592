"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralSourceTransactionType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReferralSourceTransactionType_Factory = /** @class */ (function () {
    function ReferralSourceTransactionType_Factory() {
    }
    ReferralSourceTransactionType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Type: model.Type,
            TypeId: model.TypeId,
        };
        return result;
    };
    ReferralSourceTransactionType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Type: model.Type,
            TypeId: model.TypeId,
        };
        return result;
    };
    return ReferralSourceTransactionType_Factory;
}());
exports.ReferralSourceTransactionType_Factory = ReferralSourceTransactionType_Factory;
