"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickbooksPaymentMapping_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var TransactionType_1 = require("./TransactionType");
var QuickbooksPaymentMapping_Factory = /** @class */ (function () {
    function QuickbooksPaymentMapping_Factory() {
    }
    QuickbooksPaymentMapping_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DepositToAccount: model.DepositToAccount,
            DeposLineItemDetails: model.DeposLineItemDetails,
            IncludeTransactionType: model.IncludeTransactionType,
            QBOClass: model.QBOClass,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransactionType: TransactionType_1.TransactionType_Factory.CreateIncoming(model.TransactionType),
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    QuickbooksPaymentMapping_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DepositToAccount: model.DepositToAccount,
            DeposLineItemDetails: model.DeposLineItemDetails,
            IncludeTransactionType: model.IncludeTransactionType,
            QBOClass: model.QBOClass,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransactionType: TransactionType_1.TransactionType_Factory.CreateOutgoing(model.TransactionType),
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    return QuickbooksPaymentMapping_Factory;
}());
exports.QuickbooksPaymentMapping_Factory = QuickbooksPaymentMapping_Factory;
