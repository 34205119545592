"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalClaimAdditionalInfo_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var StatesProvince_1 = require("./StatesProvince");
var ReferralSource_1 = require("./ReferralSource");
var MedicalClaimAdditionalInfo_Factory = /** @class */ (function () {
    function MedicalClaimAdditionalInfo_Factory() {
    }
    MedicalClaimAdditionalInfo_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Item10a: model.Item10a,
            Item10b: model.Item10b,
            Item10bStateProvince: StatesProvince_1.StatesProvince_Factory.CreateIncoming(model.Item10bStateProvince),
            Item10bStateProvinceId: model.Item10bStateProvinceId,
            Item10c: model.Item10c,
            Item10dClaimCode: model.Item10dClaimCode,
            Item14Date: Date_Factory_1.Date_Factory.CreateIncoming(model.Item14Date, { ignoreTimePortion: true }),
            Item14QualifierCode: model.Item14QualifierCode,
            Item15Date: Date_Factory_1.Date_Factory.CreateIncoming(model.Item15Date, { ignoreTimePortion: true }),
            Item15QualifierCode: model.Item15QualifierCode,
            Item16DateFrom: Date_Factory_1.Date_Factory.CreateIncoming(model.Item16DateFrom, { ignoreTimePortion: true }),
            Item16DateTo: Date_Factory_1.Date_Factory.CreateIncoming(model.Item16DateTo, { ignoreTimePortion: true }),
            Item17ReferralSourceId: model.Item17ReferralSourceId,
            Item18DateFrom: Date_Factory_1.Date_Factory.CreateIncoming(model.Item18DateFrom, { ignoreTimePortion: true }),
            Item18DateTo: Date_Factory_1.Date_Factory.CreateIncoming(model.Item18DateTo, { ignoreTimePortion: true }),
            Item20Charges: model.Item20Charges,
            Item22FrequencyCode: model.Item22FrequencyCode,
            Item22ReferenceNumber: model.Item22ReferenceNumber,
            RecordId: model.RecordId,
            ReferralSource: ReferralSource_1.ReferralSource_Factory.CreateIncoming(model.ReferralSource),
        };
        return result;
    };
    MedicalClaimAdditionalInfo_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Item10a: model.Item10a,
            Item10b: model.Item10b,
            Item10bStateProvince: StatesProvince_1.StatesProvince_Factory.CreateOutgoing(model.Item10bStateProvince),
            Item10bStateProvinceId: model.Item10bStateProvinceId,
            Item10c: model.Item10c,
            Item10dClaimCode: model.Item10dClaimCode,
            Item14Date: Date_Factory_1.Date_Factory.CreateOutgoing(model.Item14Date, { ignoreTimePortion: true }),
            Item14QualifierCode: model.Item14QualifierCode,
            Item15Date: Date_Factory_1.Date_Factory.CreateOutgoing(model.Item15Date, { ignoreTimePortion: true }),
            Item15QualifierCode: model.Item15QualifierCode,
            Item16DateFrom: Date_Factory_1.Date_Factory.CreateOutgoing(model.Item16DateFrom, { ignoreTimePortion: true }),
            Item16DateTo: Date_Factory_1.Date_Factory.CreateOutgoing(model.Item16DateTo, { ignoreTimePortion: true }),
            Item17ReferralSourceId: model.Item17ReferralSourceId,
            Item18DateFrom: Date_Factory_1.Date_Factory.CreateOutgoing(model.Item18DateFrom, { ignoreTimePortion: true }),
            Item18DateTo: Date_Factory_1.Date_Factory.CreateOutgoing(model.Item18DateTo, { ignoreTimePortion: true }),
            Item20Charges: model.Item20Charges,
            Item22FrequencyCode: model.Item22FrequencyCode,
            Item22ReferenceNumber: model.Item22ReferenceNumber,
            RecordId: model.RecordId,
            ReferralSource: ReferralSource_1.ReferralSource_Factory.CreateOutgoing(model.ReferralSource),
        };
        return result;
    };
    return MedicalClaimAdditionalInfo_Factory;
}());
exports.MedicalClaimAdditionalInfo_Factory = MedicalClaimAdditionalInfo_Factory;
