"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkInsurancePayment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BulkInsurancePaymentAdjustment_1 = require("./BulkInsurancePaymentAdjustment");
var BulkInsurancePaymentClaim_1 = require("./BulkInsurancePaymentClaim");
var User_1 = require("./User");
var InsurancePayer_1 = require("./InsurancePayer");
var Location_1 = require("./Location");
var TransactionType_1 = require("./TransactionType");
var BulkInsurancePayment_Factory = /** @class */ (function () {
    function BulkInsurancePayment_Factory() {
    }
    BulkInsurancePayment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Adjustments: model.Adjustments && model.Adjustments.map(function (i) { return BulkInsurancePaymentAdjustment_1.BulkInsurancePaymentAdjustment_Factory.CreateIncoming(i); }),
            Amount: model.Amount,
            ArchivedDaysheetId: model.ArchivedDaysheetId,
            Claims: model.Claims && model.Claims.map(function (i) { return BulkInsurancePaymentClaim_1.BulkInsurancePaymentClaim_Factory.CreateIncoming(i); }),
            CompletedByUser: User_1.User_Factory.CreateIncoming(model.CompletedByUser),
            CompletedByUserId: model.CompletedByUserId,
            CompletedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.CompletedDate),
            EPaymentsAmount: model.EPaymentsAmount,
            EPaymentsStatus: model.EPaymentsStatus,
            EPaymentsTransactionId: model.EPaymentsTransactionId,
            InsurancePayer: InsurancePayer_1.InsurancePayer_Factory.CreateIncoming(model.InsurancePayer),
            InsurancePayerId: model.InsurancePayerId,
            IsIncomplete: model.IsIncomplete,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            PaymentType: TransactionType_1.TransactionType_Factory.CreateIncoming(model.PaymentType),
            PaymentTypeCode: model.PaymentTypeCode,
            ReceivedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ReceivedDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Reference: model.Reference,
        };
        return result;
    };
    BulkInsurancePayment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Adjustments: model.Adjustments && model.Adjustments.map(function (i) { return BulkInsurancePaymentAdjustment_1.BulkInsurancePaymentAdjustment_Factory.CreateOutgoing(i); }),
            Amount: model.Amount,
            ArchivedDaysheetId: model.ArchivedDaysheetId,
            Claims: model.Claims && model.Claims.map(function (i) { return BulkInsurancePaymentClaim_1.BulkInsurancePaymentClaim_Factory.CreateOutgoing(i); }),
            CompletedByUser: User_1.User_Factory.CreateOutgoing(model.CompletedByUser),
            CompletedByUserId: model.CompletedByUserId,
            CompletedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.CompletedDate),
            EPaymentsAmount: model.EPaymentsAmount,
            EPaymentsStatus: model.EPaymentsStatus,
            EPaymentsTransactionId: model.EPaymentsTransactionId,
            InsurancePayer: InsurancePayer_1.InsurancePayer_Factory.CreateOutgoing(model.InsurancePayer),
            InsurancePayerId: model.InsurancePayerId,
            IsIncomplete: model.IsIncomplete,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            PaymentType: TransactionType_1.TransactionType_Factory.CreateOutgoing(model.PaymentType),
            PaymentTypeCode: model.PaymentTypeCode,
            ReceivedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ReceivedDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Reference: model.Reference,
        };
        return result;
    };
    return BulkInsurancePayment_Factory;
}());
exports.BulkInsurancePayment_Factory = BulkInsurancePayment_Factory;
