"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsToothChartElementDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsToothChartElementDentitionType_1 = require("./EFormsToothChartElementDentitionType");
var ToothNumberingType_1 = require("./ToothNumberingType");
var EFormsToothChartElementDetail_Factory = /** @class */ (function () {
    function EFormsToothChartElementDetail_Factory() {
    }
    EFormsToothChartElementDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DentitionType: EFormsToothChartElementDentitionType_1.EFormsToothChartElementDentitionType_Factory.CreateIncoming(model.DentitionType),
            DentitionTypeCode: model.DentitionTypeCode,
            RecordId: model.RecordId,
            ToothNumberingType: ToothNumberingType_1.ToothNumberingType_Factory.CreateIncoming(model.ToothNumberingType),
            ToothNumberingTypeCode: model.ToothNumberingTypeCode,
        };
        return result;
    };
    EFormsToothChartElementDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DentitionType: EFormsToothChartElementDentitionType_1.EFormsToothChartElementDentitionType_Factory.CreateOutgoing(model.DentitionType),
            DentitionTypeCode: model.DentitionTypeCode,
            RecordId: model.RecordId,
            ToothNumberingType: ToothNumberingType_1.ToothNumberingType_Factory.CreateOutgoing(model.ToothNumberingType),
            ToothNumberingTypeCode: model.ToothNumberingTypeCode,
        };
        return result;
    };
    return EFormsToothChartElementDetail_Factory;
}());
exports.EFormsToothChartElementDetail_Factory = EFormsToothChartElementDetail_Factory;
