"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientResponsibleParty_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientConnectionType_1 = require("./PatientConnectionType");
var PatientPaymentMethod_1 = require("./PatientPaymentMethod");
var Person_1 = require("./Person");
var PatientResponsibleParty_Factory = /** @class */ (function () {
    function PatientResponsibleParty_Factory() {
    }
    PatientResponsibleParty_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            IsEmergencyContact: model.IsEmergencyContact,
            IsGuarantor: model.IsGuarantor,
            PatientConnectionType: PatientConnectionType_1.PatientConnectionType_Factory.CreateIncoming(model.PatientConnectionType),
            PatientConnectionTypeCode: model.PatientConnectionTypeCode,
            PatientId: model.PatientId,
            PatientPaymentMethods: model.PatientPaymentMethods && model.PatientPaymentMethods.map(function (i) { return PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ResponsiblePerson: Person_1.Person_Factory.CreateIncoming(model.ResponsiblePerson),
            ResponsiblePersonId: model.ResponsiblePersonId,
        };
        return result;
    };
    PatientResponsibleParty_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            IsEmergencyContact: model.IsEmergencyContact,
            IsGuarantor: model.IsGuarantor,
            PatientConnectionType: PatientConnectionType_1.PatientConnectionType_Factory.CreateOutgoing(model.PatientConnectionType),
            PatientConnectionTypeCode: model.PatientConnectionTypeCode,
            PatientId: model.PatientId,
            PatientPaymentMethods: model.PatientPaymentMethods && model.PatientPaymentMethods.map(function (i) { return PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ResponsiblePerson: Person_1.Person_Factory.CreateOutgoing(model.ResponsiblePerson),
            ResponsiblePersonId: model.ResponsiblePersonId,
        };
        return result;
    };
    return PatientResponsibleParty_Factory;
}());
exports.PatientResponsibleParty_Factory = PatientResponsibleParty_Factory;
