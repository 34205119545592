"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoftTissueExamFinding_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SoftTissueCondition_1 = require("./SoftTissueCondition");
var Note_1 = require("./Note");
var SoftTissueRegion_1 = require("./SoftTissueRegion");
var SoftTissueExamFinding_Factory = /** @class */ (function () {
    function SoftTissueExamFinding_Factory() {
    }
    SoftTissueExamFinding_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdditionalNotes: model.AdditionalNotes,
            Condition: SoftTissueCondition_1.SoftTissueCondition_Factory.CreateIncoming(model.Condition),
            ConditionId: model.ConditionId,
            Depth: model.Depth,
            EntireRegion: model.EntireRegion,
            ExamId: model.ExamId,
            ExamNote: Note_1.Note_Factory.CreateIncoming(model.ExamNote),
            ExamNoteId: model.ExamNoteId,
            Length: model.Length,
            PositionX: model.PositionX,
            PositionY: model.PositionY,
            PreviousFinding: SoftTissueExamFinding_Factory.CreateIncoming(model.PreviousFinding),
            PreviousFindingId: model.PreviousFindingId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Region: SoftTissueRegion_1.SoftTissueRegion_Factory.CreateIncoming(model.Region),
            RegionCode: model.RegionCode,
            Rotation: model.Rotation,
            Width: model.Width,
        };
        return result;
    };
    SoftTissueExamFinding_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdditionalNotes: model.AdditionalNotes,
            Condition: SoftTissueCondition_1.SoftTissueCondition_Factory.CreateOutgoing(model.Condition),
            ConditionId: model.ConditionId,
            Depth: model.Depth,
            EntireRegion: model.EntireRegion,
            ExamId: model.ExamId,
            ExamNote: Note_1.Note_Factory.CreateOutgoing(model.ExamNote),
            ExamNoteId: model.ExamNoteId,
            Length: model.Length,
            PositionX: model.PositionX,
            PositionY: model.PositionY,
            PreviousFinding: SoftTissueExamFinding_Factory.CreateOutgoing(model.PreviousFinding),
            PreviousFindingId: model.PreviousFindingId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Region: SoftTissueRegion_1.SoftTissueRegion_Factory.CreateOutgoing(model.Region),
            RegionCode: model.RegionCode,
            Rotation: model.Rotation,
            Width: model.Width,
        };
        return result;
    };
    return SoftTissueExamFinding_Factory;
}());
exports.SoftTissueExamFinding_Factory = SoftTissueExamFinding_Factory;
