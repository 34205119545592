"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationWorkingDay_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LocationWorkingDayTime_1 = require("./LocationWorkingDayTime");
var RecordStatus_1 = require("./RecordStatus");
var LocationWorkingDay_Factory = /** @class */ (function () {
    function LocationWorkingDay_Factory() {
    }
    LocationWorkingDay_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            IsClosed: model.IsClosed,
            IsForOverriddenDate: model.IsForOverriddenDate,
            LocationId: model.LocationId,
            LocationWorkingDayTimes: model.LocationWorkingDayTimes && model.LocationWorkingDayTimes.map(function (i) { return LocationWorkingDayTime_1.LocationWorkingDayTime_Factory.CreateIncoming(i); }),
            OverriddenDate: Date_Factory_1.Date_Factory.CreateIncoming(model.OverriddenDate, { ignoreTimePortion: true }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
        };
        return result;
    };
    LocationWorkingDay_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            IsClosed: model.IsClosed,
            IsForOverriddenDate: model.IsForOverriddenDate,
            LocationId: model.LocationId,
            LocationWorkingDayTimes: model.LocationWorkingDayTimes && model.LocationWorkingDayTimes.map(function (i) { return LocationWorkingDayTime_1.LocationWorkingDayTime_Factory.CreateOutgoing(i); }),
            OverriddenDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.OverriddenDate, { ignoreTimePortion: true }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
        };
        return result;
    };
    return LocationWorkingDay_Factory;
}());
exports.LocationWorkingDay_Factory = LocationWorkingDay_Factory;
