"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceLocationInsuranceClaim_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceClaim_1 = require("./InsuranceClaim");
var Location_1 = require("./Location");
var ServiceLocation_1 = require("./ServiceLocation");
var ServiceLocationInsuranceClaim_Factory = /** @class */ (function () {
    function ServiceLocationInsuranceClaim_Factory() {
    }
    ServiceLocationInsuranceClaim_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            InsuranceClaims: model.InsuranceClaims && model.InsuranceClaims.map(function (i) { return InsuranceClaim_1.InsuranceClaim_Factory.CreateIncoming(i); }),
            PracticeLocation: Location_1.Location_Factory.CreateIncoming(model.PracticeLocation),
            PracticeLocationId: model.PracticeLocationId,
            RecordId: model.RecordId,
            ServiceLocation: ServiceLocation_1.ServiceLocation_Factory.CreateIncoming(model.ServiceLocation),
            ServiceLocationId: model.ServiceLocationId,
        };
        return result;
    };
    ServiceLocationInsuranceClaim_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            InsuranceClaims: model.InsuranceClaims && model.InsuranceClaims.map(function (i) { return InsuranceClaim_1.InsuranceClaim_Factory.CreateOutgoing(i); }),
            PracticeLocation: Location_1.Location_Factory.CreateOutgoing(model.PracticeLocation),
            PracticeLocationId: model.PracticeLocationId,
            RecordId: model.RecordId,
            ServiceLocation: ServiceLocation_1.ServiceLocation_Factory.CreateOutgoing(model.ServiceLocation),
            ServiceLocationId: model.ServiceLocationId,
        };
        return result;
    };
    return ServiceLocationInsuranceClaim_Factory;
}());
exports.ServiceLocationInsuranceClaim_Factory = ServiceLocationInsuranceClaim_Factory;
