"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSContractPeriod_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSContract_1 = require("./NHSContract");
var NHSContractMembership_1 = require("./NHSContractMembership");
var NHSContractPeriodMonth_1 = require("./NHSContractPeriodMonth");
var NHSContractPeriod_Factory = /** @class */ (function () {
    function NHSContractPeriod_Factory() {
    }
    NHSContractPeriod_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ContractID: model.ContractID,
            EndDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EndDate, { ignoreTimePortion: true }),
            NHSContract: NHSContract_1.NHSContract_Factory.CreateIncoming(model.NHSContract),
            NHSContractMemberships: model.NHSContractMemberships && model.NHSContractMemberships.map(function (i) { return NHSContractMembership_1.NHSContractMembership_Factory.CreateIncoming(i); }),
            NHSContractPeriodMonths: model.NHSContractPeriodMonths && model.NHSContractPeriodMonths.map(function (i) { return NHSContractPeriodMonth_1.NHSContractPeriodMonth_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate, { ignoreTimePortion: true }),
            UDARequirement: model.UDARequirement,
            UOARequirement: model.UOARequirement,
        };
        return result;
    };
    NHSContractPeriod_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ContractID: model.ContractID,
            EndDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EndDate, { ignoreTimePortion: true }),
            NHSContract: NHSContract_1.NHSContract_Factory.CreateOutgoing(model.NHSContract),
            NHSContractMemberships: model.NHSContractMemberships && model.NHSContractMemberships.map(function (i) { return NHSContractMembership_1.NHSContractMembership_Factory.CreateOutgoing(i); }),
            NHSContractPeriodMonths: model.NHSContractPeriodMonths && model.NHSContractPeriodMonths.map(function (i) { return NHSContractPeriodMonth_1.NHSContractPeriodMonth_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate, { ignoreTimePortion: true }),
            UDARequirement: model.UDARequirement,
            UOARequirement: model.UOARequirement,
        };
        return result;
    };
    return NHSContractPeriod_Factory;
}());
exports.NHSContractPeriod_Factory = NHSContractPeriod_Factory;
