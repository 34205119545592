"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSFP17TriageDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCourseDetail_1 = require("./NHSCourseDetail");
var NHSFP17TriageDetail_Factory = /** @class */ (function () {
    function NHSFP17TriageDetail_Factory() {
    }
    NHSFP17TriageDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateIncoming(model.CourseDetail),
            NHSCourseDetailsId: model.NHSCourseDetailsId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            TriageIncreasedRisk: model.TriageIncreasedRisk,
            TriageOtherStatus: model.TriageOtherStatus,
            TriageSameHousehold: model.TriageSameHousehold,
            TriageShieldingPatient: model.TriageShieldingPatient,
            TriageSymptomFree: model.TriageSymptomFree,
        };
        return result;
    };
    NHSFP17TriageDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateOutgoing(model.CourseDetail),
            NHSCourseDetailsId: model.NHSCourseDetailsId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            TriageIncreasedRisk: model.TriageIncreasedRisk,
            TriageOtherStatus: model.TriageOtherStatus,
            TriageSameHousehold: model.TriageSameHousehold,
            TriageShieldingPatient: model.TriageShieldingPatient,
            TriageSymptomFree: model.TriageSymptomFree,
        };
        return result;
    };
    return NHSFP17TriageDetail_Factory;
}());
exports.NHSFP17TriageDetail_Factory = NHSFP17TriageDetail_Factory;
