import isDate from "lodash.isdate";
import { getIn, setIn } from "formik";


export function visit(node: any, keyPrefix = '', callback: (key: string, node: any) => boolean) {
  // Note: typeof null ==== 'object'
  if (node == null || typeof node !== 'object' || isDate(node)) {
    return;
  }

  if (Array.isArray(node)) {
    node.forEach((value, index) => {
      const kp = `${keyPrefix}[${index}]`;
      if (callback(kp, value) !== false)
        visit(value, kp, callback);
    });
    return;
  }

  // Non-array object
  const entries = Object.entries(node) as [string, any][];
  entries.forEach(([key, value]) => {
    const kp = `${keyPrefix}.${key}`;
    if (callback(kp, value) !== false) {
      visit(value, kp, callback);
    }
  });
}


export function* traverse(node: any, keyPrefix = '$'): Generator<{key: string, value: any}> {
  // Note: typeof null ==== 'object'
  if (node == null || typeof node !== 'object' || isDate(node)) {
    return;
  }

  if (Array.isArray(node)) {
    for (let i = 0; i < node.length; i ++) {
      const key = `${keyPrefix}[${i}]`;
      const value = node[i];
      const keepGoing = yield ({key, value});
      if (keepGoing === false) {
        return;
      }
      yield* traverse(value, key);
    }
    return;
  }

  // Non-array object
  const entries = Object.entries(node) as [string, any][];
  for (let i = 0; i < entries.length; i++) {
    const [name, value] = entries[i];
    const key = `${keyPrefix}.${name}`;
    const keepGoing = yield ({key, value});
    if (keepGoing === false) {
      return;
    }
    yield* traverse(value, key);
  }
}



export function getParentJsonPath(jsonPath = '') {
  console.assert(typeof jsonPath === "string" && !jsonPath.startsWith('$'))
  const lastDot = jsonPath.lastIndexOf('.');
  return lastDot < 1
  ?  null // There must be at least a single character before the dot
  : jsonPath.substr(0, lastDot);
}


export function ensureObjectPathsExit<T extends object>(node: T, paths: string[]) {
  console.assert(typeof node === "object");

  // Get all unique parent paths
  const parentPaths =
    (Array.from(new Set(paths.map(getParentJsonPath)))
    .filter(str => !!str) as string[])
    .sort((a, b) => {
      // Order by depth, then name
      const aDepth = a.split('.').length;
      const bDepth = b.split('.').length;
      return aDepth === bDepth
        ? a.localeCompare(b)
        : aDepth - bDepth;
    });

  for (const path of parentPaths) {
    if (!getIn(node, path)) {
      //console.info("Creating JSON node: " + path);
      node = setIn(node, path, {});
    }
  }

  return node;
}


const jsonConstructor = ({}).constructor;

function isEmptyJsonObject(value: any): boolean {
  if (typeof value === 'undefined') {
    return true;
  }

  const isJsonObject = value !== null && typeof value === 'object' && value.constructor === jsonConstructor;
  if (isJsonObject) {
    // Note: Empty objects may be nested. ex: `{Patient: { Address: {} }}`.
    const fields = Object.values(value);
    return fields.every(field => isEmptyJsonObject(field));
  }

  return false;
}


function removeEmptyObjects(_: string, value: any) {
  return isEmptyJsonObject(value) ? undefined : value;
};


export function pruneJsonData(data: string | object) {
  console.assert(!!data);
  const json = typeof data === 'string' ? JSON.parse(data) : data;
  const prunedJsonStr = JSON.stringify(json, removeEmptyObjects);
  return prunedJsonStr;
}



// const paths = ["", "Patient.Insurance", "Patient.Person.FirstName", "Patient.Person.LastName", "Patient.Insurance.Name", "Patient.Insurance.Address.Line1" ]
// const json = {};
// const result = ensureObjectPathsExit(json, paths); //?

// function callback(key: string, node: any) {
//   console.log(`${key} ==> `, node);
//   return true;
// }

// const json = {name: 'abc', someArray: [10, 20, 30, {name: 'karen', dob: { last: 'beg'}}],  other: { age: 10,  again: 'you', date: new Date()  }};

// //visit(json, '$', callback);
// const items = Array.from(traverse(json));
// console.log(items)

