"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSSchedulePayment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClaim_1 = require("./NHSClaim");
var NHSSchedule_1 = require("./NHSSchedule");
var NHSSchedulePayment_Factory = /** @class */ (function () {
    function NHSSchedulePayment_Factory() {
    }
    NHSSchedulePayment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Activity: model.Activity,
            ClaimId: model.ClaimId,
            Fee: model.Fee,
            NHSClaim: NHSClaim_1.NHSClaim_Factory.CreateIncoming(model.NHSClaim),
            NHSSchedule: NHSSchedule_1.NHSSchedule_Factory.CreateIncoming(model.NHSSchedule),
            PatientCharge: model.PatientCharge,
            Percentage: model.Percentage,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Remission: model.Remission,
            ScheduleId: model.ScheduleId,
        };
        return result;
    };
    NHSSchedulePayment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Activity: model.Activity,
            ClaimId: model.ClaimId,
            Fee: model.Fee,
            NHSClaim: NHSClaim_1.NHSClaim_Factory.CreateOutgoing(model.NHSClaim),
            NHSSchedule: NHSSchedule_1.NHSSchedule_Factory.CreateOutgoing(model.NHSSchedule),
            PatientCharge: model.PatientCharge,
            Percentage: model.Percentage,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Remission: model.Remission,
            ScheduleId: model.ScheduleId,
        };
        return result;
    };
    return NHSSchedulePayment_Factory;
}());
exports.NHSSchedulePayment_Factory = NHSSchedulePayment_Factory;
