"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracAddress_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracDoctor_1 = require("./LabtracDoctor");
var LabtracAddress_Factory = /** @class */ (function () {
    function LabtracAddress_Factory() {
    }
    LabtracAddress_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Address1: model.Address1,
            Address2: model.Address2,
            Address3: model.Address3,
            City: model.City,
            ContactName: model.ContactName,
            Country: model.Country,
            Description: model.Description,
            Email: model.Email,
            ExternalId: model.ExternalId,
            Fax: model.Fax,
            IsBilling: model.IsBilling,
            IsCaseEntry: model.IsCaseEntry,
            IsDefault: model.IsDefault,
            LabReference: model.LabReference,
            LabtracDoctor: LabtracDoctor_1.LabtracDoctor_Factory.CreateIncoming(model.LabtracDoctor),
            LabtracDoctorId: model.LabtracDoctorId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            State: model.State,
            Telephone1: model.Telephone1,
            Telephone2: model.Telephone2,
            Zip: model.Zip,
        };
        return result;
    };
    LabtracAddress_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Address1: model.Address1,
            Address2: model.Address2,
            Address3: model.Address3,
            City: model.City,
            ContactName: model.ContactName,
            Country: model.Country,
            Description: model.Description,
            Email: model.Email,
            ExternalId: model.ExternalId,
            Fax: model.Fax,
            IsBilling: model.IsBilling,
            IsCaseEntry: model.IsCaseEntry,
            IsDefault: model.IsDefault,
            LabReference: model.LabReference,
            LabtracDoctor: LabtracDoctor_1.LabtracDoctor_Factory.CreateOutgoing(model.LabtracDoctor),
            LabtracDoctorId: model.LabtracDoctorId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            State: model.State,
            Telephone1: model.Telephone1,
            Telephone2: model.Telephone2,
            Zip: model.Zip,
        };
        return result;
    };
    return LabtracAddress_Factory;
}());
exports.LabtracAddress_Factory = LabtracAddress_Factory;
