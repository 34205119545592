"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewPayment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NewPaymentSplit_1 = require("./NewPaymentSplit");
var NewPayment_Factory = /** @class */ (function () {
    function NewPayment_Factory() {
    }
    NewPayment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AcceptanceDate),
            AppointmentId: model.AppointmentId,
            Comment: model.Comment,
            EPaymentsVersion: model.EPaymentsVersion,
            LocationId: model.LocationId,
            PaidInsuranceClaimId: model.PaidInsuranceClaimId,
            PatientId: model.PatientId,
            PaymentSubTypeId: model.PaymentSubTypeId,
            PaymentTypeId: model.PaymentTypeId,
            Reference: model.Reference,
            Splits: model.Splits && model.Splits.map(function (i) { return NewPaymentSplit_1.NewPaymentSplit_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    NewPayment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AcceptanceDate),
            AppointmentId: model.AppointmentId,
            Comment: model.Comment,
            EPaymentsVersion: model.EPaymentsVersion,
            LocationId: model.LocationId,
            PaidInsuranceClaimId: model.PaidInsuranceClaimId,
            PatientId: model.PatientId,
            PaymentSubTypeId: model.PaymentSubTypeId,
            PaymentTypeId: model.PaymentTypeId,
            Reference: model.Reference,
            Splits: model.Splits && model.Splits.map(function (i) { return NewPaymentSplit_1.NewPaymentSplit_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return NewPayment_Factory;
}());
exports.NewPayment_Factory = NewPayment_Factory;
