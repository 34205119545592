"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduledPayment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var OrthoContractChargeSchedule_1 = require("./OrthoContractChargeSchedule");
var PatientPaymentMethod_1 = require("./PatientPaymentMethod");
var ScheduledPaymentResult_1 = require("./ScheduledPaymentResult");
var Transaction_1 = require("./Transaction");
var ScheduledPayment_Factory = /** @class */ (function () {
    function ScheduledPayment_Factory() {
    }
    ScheduledPayment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AcceptanceDate),
            Amount: model.Amount,
            Description: model.Description,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            OrthoContractChargeSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateIncoming(model.OrthoContractChargeSchedule),
            OrthoContractChargeScheduleId: model.OrthoContractChargeScheduleId,
            PatientPaymentMethod: PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateIncoming(model.PatientPaymentMethod),
            PaymentMethodId: model.PaymentMethodId,
            ProcessingRequired: model.ProcessingRequired,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduledPaymentResults: model.ScheduledPaymentResults && model.ScheduledPaymentResults.map(function (i) { return ScheduledPaymentResult_1.ScheduledPaymentResult_Factory.CreateIncoming(i); }),
            Transaction: Transaction_1.Transaction_Factory.CreateIncoming(model.Transaction),
            TransactionId: model.TransactionId,
        };
        return result;
    };
    ScheduledPayment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AcceptanceDate),
            Amount: model.Amount,
            Description: model.Description,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            OrthoContractChargeSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateOutgoing(model.OrthoContractChargeSchedule),
            OrthoContractChargeScheduleId: model.OrthoContractChargeScheduleId,
            PatientPaymentMethod: PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateOutgoing(model.PatientPaymentMethod),
            PaymentMethodId: model.PaymentMethodId,
            ProcessingRequired: model.ProcessingRequired,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduledPaymentResults: model.ScheduledPaymentResults && model.ScheduledPaymentResults.map(function (i) { return ScheduledPaymentResult_1.ScheduledPaymentResult_Factory.CreateOutgoing(i); }),
            Transaction: Transaction_1.Transaction_Factory.CreateOutgoing(model.Transaction),
            TransactionId: model.TransactionId,
        };
        return result;
    };
    return ScheduledPayment_Factory;
}());
exports.ScheduledPayment_Factory = ScheduledPayment_Factory;
