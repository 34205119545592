"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnesthesiaRecord_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var EscortType_1 = require("./EscortType");
var HealthMeasurement_1 = require("./HealthMeasurement");
var Location_1 = require("./Location");
var Patient_1 = require("./Patient");
var PatientProcedure_1 = require("./PatientProcedure");
var PostOpEvaluation_1 = require("./PostOpEvaluation");
var PreOpEvaluation_1 = require("./PreOpEvaluation");
var SurgeryInfo_1 = require("./SurgeryInfo");
var SurgeryVitals_1 = require("./SurgeryVitals");
var AnesthesiaRecord_Factory = /** @class */ (function () {
    function AnesthesiaRecord_Factory() {
    }
    AnesthesiaRecord_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Anesthesiologist: User_1.User_Factory.CreateIncoming(model.Anesthesiologist),
            AnesthesiologistId: model.AnesthesiologistId,
            ASA: model.ASA,
            AsaEmergency: model.AsaEmergency,
            Assistant1: User_1.User_Factory.CreateIncoming(model.Assistant1),
            Assistant1Id: model.Assistant1Id,
            Assistant2: User_1.User_Factory.CreateIncoming(model.Assistant2),
            Assistant2Id: model.Assistant2Id,
            ConsentSigned: model.ConsentSigned,
            EscortDescription: model.EscortDescription,
            EscortType: EscortType_1.EscortType_Factory.CreateIncoming(model.EscortType),
            EscortTypeCode: model.EscortTypeCode,
            HeightHealthMeasurement: HealthMeasurement_1.HealthMeasurement_Factory.CreateIncoming(model.HeightHealthMeasurement),
            HeightHealthMeasurementId: model.HeightHealthMeasurementId,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            NPO: model.NPO,
            NPOAmount: model.NPOAmount,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            PostOpEvaluation: PostOpEvaluation_1.PostOpEvaluation_Factory.CreateIncoming(model.PostOpEvaluation),
            PostOpEvaluationId: model.PostOpEvaluationId,
            PreOpEvaluation: PreOpEvaluation_1.PreOpEvaluation_Factory.CreateIncoming(model.PreOpEvaluation),
            PreOpEvaluationId: model.PreOpEvaluationId,
            ProcedureDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ProcedureDate),
            ProcedureDescription: model.ProcedureDescription,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ResidentNurse: User_1.User_Factory.CreateIncoming(model.ResidentNurse),
            ResidentNurseId: model.ResidentNurseId,
            Surgeon: User_1.User_Factory.CreateIncoming(model.Surgeon),
            SurgeonId: model.SurgeonId,
            SurgeryInfo: SurgeryInfo_1.SurgeryInfo_Factory.CreateIncoming(model.SurgeryInfo),
            SurgeryInfoId: model.SurgeryInfoId,
            SurgeryVitalId: model.SurgeryVitalId,
            SurgeryVitals: SurgeryVitals_1.SurgeryVitals_Factory.CreateIncoming(model.SurgeryVitals),
            WeightHealthMeasurement: HealthMeasurement_1.HealthMeasurement_Factory.CreateIncoming(model.WeightHealthMeasurement),
            WeightHealthMeasurementId: model.WeightHealthMeasurementId,
        };
        return result;
    };
    AnesthesiaRecord_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Anesthesiologist: User_1.User_Factory.CreateOutgoing(model.Anesthesiologist),
            AnesthesiologistId: model.AnesthesiologistId,
            ASA: model.ASA,
            AsaEmergency: model.AsaEmergency,
            Assistant1: User_1.User_Factory.CreateOutgoing(model.Assistant1),
            Assistant1Id: model.Assistant1Id,
            Assistant2: User_1.User_Factory.CreateOutgoing(model.Assistant2),
            Assistant2Id: model.Assistant2Id,
            ConsentSigned: model.ConsentSigned,
            EscortDescription: model.EscortDescription,
            EscortType: EscortType_1.EscortType_Factory.CreateOutgoing(model.EscortType),
            EscortTypeCode: model.EscortTypeCode,
            HeightHealthMeasurement: HealthMeasurement_1.HealthMeasurement_Factory.CreateOutgoing(model.HeightHealthMeasurement),
            HeightHealthMeasurementId: model.HeightHealthMeasurementId,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            NPO: model.NPO,
            NPOAmount: model.NPOAmount,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            PostOpEvaluation: PostOpEvaluation_1.PostOpEvaluation_Factory.CreateOutgoing(model.PostOpEvaluation),
            PostOpEvaluationId: model.PostOpEvaluationId,
            PreOpEvaluation: PreOpEvaluation_1.PreOpEvaluation_Factory.CreateOutgoing(model.PreOpEvaluation),
            PreOpEvaluationId: model.PreOpEvaluationId,
            ProcedureDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ProcedureDate),
            ProcedureDescription: model.ProcedureDescription,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ResidentNurse: User_1.User_Factory.CreateOutgoing(model.ResidentNurse),
            ResidentNurseId: model.ResidentNurseId,
            Surgeon: User_1.User_Factory.CreateOutgoing(model.Surgeon),
            SurgeonId: model.SurgeonId,
            SurgeryInfo: SurgeryInfo_1.SurgeryInfo_Factory.CreateOutgoing(model.SurgeryInfo),
            SurgeryInfoId: model.SurgeryInfoId,
            SurgeryVitalId: model.SurgeryVitalId,
            SurgeryVitals: SurgeryVitals_1.SurgeryVitals_Factory.CreateOutgoing(model.SurgeryVitals),
            WeightHealthMeasurement: HealthMeasurement_1.HealthMeasurement_Factory.CreateOutgoing(model.WeightHealthMeasurement),
            WeightHealthMeasurementId: model.WeightHealthMeasurementId,
        };
        return result;
    };
    return AnesthesiaRecord_Factory;
}());
exports.AnesthesiaRecord_Factory = AnesthesiaRecord_Factory;
