"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsurancePayerDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsurancePayerDetail_Factory = /** @class */ (function () {
    function InsurancePayerDetail_Factory() {
    }
    InsurancePayerDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            PayerId: model.PayerId,
            PlanCount: model.PlanCount,
        };
        return result;
    };
    InsurancePayerDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            PayerId: model.PayerId,
            PlanCount: model.PlanCount,
        };
        return result;
    };
    return InsurancePayerDetail_Factory;
}());
exports.InsurancePayerDetail_Factory = InsurancePayerDetail_Factory;
