"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientRx_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Patient_1 = require("./Patient");
var PatientRxAuditLog_1 = require("./PatientRxAuditLog");
var User_1 = require("./User");
var PatientRx_Factory = /** @class */ (function () {
    function PatientRx_Factory() {
    }
    PatientRx_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Controlled: model.Controlled,
            CreateDate: Date_Factory_1.Date_Factory.CreateIncoming(model.CreateDate, { ignoreTimePortion: true }),
            Days: model.Days,
            Dosage: model.Dosage,
            Drug: model.Drug,
            eRxFacilityId: model.eRxFacilityId,
            eRxId: model.eRxId,
            Formulation: model.Formulation,
            IsActive: model.IsActive,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PatientRxAuditLogs: model.PatientRxAuditLogs && model.PatientRxAuditLogs.map(function (i) { return PatientRxAuditLog_1.PatientRxAuditLog_Factory.CreateIncoming(i); }),
            Provider: User_1.User_Factory.CreateIncoming(model.Provider),
            ProviderId: model.ProviderId,
            Quantity: model.Quantity,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Refills: model.Refills,
            RxId: model.RxId,
            SequenceNum: model.SequenceNum,
            Sig: model.Sig,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate, { ignoreTimePortion: true }),
            SubAllowed: model.SubAllowed,
        };
        return result;
    };
    PatientRx_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Controlled: model.Controlled,
            CreateDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.CreateDate, { ignoreTimePortion: true }),
            Days: model.Days,
            Dosage: model.Dosage,
            Drug: model.Drug,
            eRxFacilityId: model.eRxFacilityId,
            eRxId: model.eRxId,
            Formulation: model.Formulation,
            IsActive: model.IsActive,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PatientRxAuditLogs: model.PatientRxAuditLogs && model.PatientRxAuditLogs.map(function (i) { return PatientRxAuditLog_1.PatientRxAuditLog_Factory.CreateOutgoing(i); }),
            Provider: User_1.User_Factory.CreateOutgoing(model.Provider),
            ProviderId: model.ProviderId,
            Quantity: model.Quantity,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Refills: model.Refills,
            RxId: model.RxId,
            SequenceNum: model.SequenceNum,
            Sig: model.Sig,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate, { ignoreTimePortion: true }),
            SubAllowed: model.SubAllowed,
        };
        return result;
    };
    return PatientRx_Factory;
}());
exports.PatientRx_Factory = PatientRx_Factory;
