"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DaysheetTransaction_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Transaction_1 = require("./Transaction");
var DaysheetTransaction_Factory = /** @class */ (function () {
    function DaysheetTransaction_Factory() {
    }
    DaysheetTransaction_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ArchivedDaysheetId: model.ArchivedDaysheetId,
            Transaction: Transaction_1.Transaction_Factory.CreateIncoming(model.Transaction),
            TransactionId: model.TransactionId,
        };
        return result;
    };
    DaysheetTransaction_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ArchivedDaysheetId: model.ArchivedDaysheetId,
            Transaction: Transaction_1.Transaction_Factory.CreateOutgoing(model.Transaction),
            TransactionId: model.TransactionId,
        };
        return result;
    };
    return DaysheetTransaction_Factory;
}());
exports.DaysheetTransaction_Factory = DaysheetTransaction_Factory;
