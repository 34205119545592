
export enum FormModes {
  View, // Readonly
  Edit, // Can make changes and save
  Print,
  //Review,
  //Design
}

export enum CancelButtonState { EnabledIfDirty, AlwaysEnabled, AlwaysHide };

// export enum DataType { String = "text", Number = "number", Date="date" } // Note: Matches HTML Input types

export enum ContentAlignment { Left = "left", Top = "top", Center = "center", Middle = "middle", Right = "right", Bottom = "bottom" }

export enum Orientation { Horizontal = "horizontal", Vertical = "vertical" }

export enum ColumnWidth {
  OneQuarter = "one-quarter",
  OneThird = "one-third",
  Half = "half",
  TwoThrids = "two-thirds",
  ThreeQuarters = "three-quarters",
  FullWidth = "full"
}

export type ColCount = 1 | 2 | 3 | 4;

export interface IColumnCounts {
  mobileCols?: ColCount;
  tabletCols?: ColCount;
  desktopCols?: ColCount;
}



export enum FormPartType {
  Spacer = "spacer",
  PageBreak = "pageBreak",
  Header = "header",
  Logo = "logo",
  Content = "content",
  Markdown = "markdown",
  Section = "section",
  Row = "row",
  Col = "col",
  ErrorMsg = "errorMessage",
  TextField = "textField",
  NumberField = "numberField",
  DateField = "dateField",
  CheckboxField = "checkboxField",
  CheckboxGroupField = "checkboxGroupField",
  PhoneNumField = "phoneNumField",
  PostCodeField = "postCodeField",
  RadioGroupField = "radioGroupField",
  SelectField = "selectField",
  SignatureField = "signatureField",
  StateProvinceField = "stateProvinceField",
  PatientConnectionTypeField = "patientConnectionTypeField",
  RelationshipToInsuredField = "relationshipToInsuredField",
  ProviderSelectionField = "providerSelectionField",
  MedicalQuestionnaireField = "medicalQuestionnaireField",
  MedicalQuestionField = "medicalQuestionField",
}

export enum HeaderSize {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6"
}



