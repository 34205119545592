"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSPatientDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSExemptCode_1 = require("./NHSExemptCode");
var NHSExemptionCheck_1 = require("./NHSExemptionCheck");
var NHSRegistrationType_1 = require("./NHSRegistrationType");
var User_1 = require("./User");
var NHSPatientDetail_Factory = /** @class */ (function () {
    function NHSPatientDetail_Factory() {
    }
    NHSPatientDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BabyBorn: Date_Factory_1.Date_Factory.CreateIncoming(model.BabyBorn),
            CertificateNumber: model.CertificateNumber,
            DisabilityHandicap: model.DisabilityHandicap,
            ExemptCode: model.ExemptCode,
            ExemptionCheck: model.ExemptionCheck,
            ExemptText: model.ExemptText,
            ExemptUntil: Date_Factory_1.Date_Factory.CreateIncoming(model.ExemptUntil),
            NHSExemptCode: NHSExemptCode_1.NHSExemptCode_Factory.CreateIncoming(model.NHSExemptCode),
            NHSExemptionCheck: NHSExemptionCheck_1.NHSExemptionCheck_Factory.CreateIncoming(model.NHSExemptionCheck),
            NHSNumber: model.NHSNumber,
            NHSRegistrationType: NHSRegistrationType_1.NHSRegistrationType_Factory.CreateIncoming(model.NHSRegistrationType),
            OldSurname: model.OldSurname,
            PartialExemptAmount: model.PartialExemptAmount,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RegisteredDentist: User_1.User_Factory.CreateIncoming(model.RegisteredDentist),
            RegisteredDentistId: model.RegisteredDentistId,
            RegistrationType: model.RegistrationType,
        };
        return result;
    };
    NHSPatientDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BabyBorn: Date_Factory_1.Date_Factory.CreateOutgoing(model.BabyBorn),
            CertificateNumber: model.CertificateNumber,
            DisabilityHandicap: model.DisabilityHandicap,
            ExemptCode: model.ExemptCode,
            ExemptionCheck: model.ExemptionCheck,
            ExemptText: model.ExemptText,
            ExemptUntil: Date_Factory_1.Date_Factory.CreateOutgoing(model.ExemptUntil),
            NHSExemptCode: NHSExemptCode_1.NHSExemptCode_Factory.CreateOutgoing(model.NHSExemptCode),
            NHSExemptionCheck: NHSExemptionCheck_1.NHSExemptionCheck_Factory.CreateOutgoing(model.NHSExemptionCheck),
            NHSNumber: model.NHSNumber,
            NHSRegistrationType: NHSRegistrationType_1.NHSRegistrationType_Factory.CreateOutgoing(model.NHSRegistrationType),
            OldSurname: model.OldSurname,
            PartialExemptAmount: model.PartialExemptAmount,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RegisteredDentist: User_1.User_Factory.CreateOutgoing(model.RegisteredDentist),
            RegisteredDentistId: model.RegisteredDentistId,
            RegistrationType: model.RegistrationType,
        };
        return result;
    };
    return NHSPatientDetail_Factory;
}());
exports.NHSPatientDetail_Factory = NHSPatientDetail_Factory;
