"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Referral_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReferralSource_1 = require("./ReferralSource");
var ReferralType_1 = require("./ReferralType");
var Referral_Factory = /** @class */ (function () {
    function Referral_Factory() {
    }
    Referral_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralCompleted: Date_Factory_1.Date_Factory.CreateIncoming(model.ReferralCompleted),
            ReferralCompleteMethod: model.ReferralCompleteMethod,
            ReferralDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ReferralDate),
            ReferralSource: ReferralSource_1.ReferralSource_Factory.CreateIncoming(model.ReferralSource),
            ReferralSourceId: model.ReferralSourceId,
            ReferralStatus: model.ReferralStatus,
            ReferralType: ReferralType_1.ReferralType_Factory.CreateIncoming(model.ReferralType),
            ReferralTypeId: model.ReferralTypeId,
        };
        return result;
    };
    Referral_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralCompleted: Date_Factory_1.Date_Factory.CreateOutgoing(model.ReferralCompleted),
            ReferralCompleteMethod: model.ReferralCompleteMethod,
            ReferralDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ReferralDate),
            ReferralSource: ReferralSource_1.ReferralSource_Factory.CreateOutgoing(model.ReferralSource),
            ReferralSourceId: model.ReferralSourceId,
            ReferralStatus: model.ReferralStatus,
            ReferralType: ReferralType_1.ReferralType_Factory.CreateOutgoing(model.ReferralType),
            ReferralTypeId: model.ReferralTypeId,
        };
        return result;
    };
    return Referral_Factory;
}());
exports.Referral_Factory = Referral_Factory;
