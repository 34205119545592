"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientNoteType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientNoteType_Factory = /** @class */ (function () {
    function PatientNoteType_Factory() {
    }
    PatientNoteType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            IsCategoryType: model.IsCategoryType,
            PatientNoteCategoryId: model.PatientNoteCategoryId,
            PatientNoteTypeId: model.PatientNoteTypeId,
        };
        return result;
    };
    PatientNoteType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            IsCategoryType: model.IsCategoryType,
            PatientNoteCategoryId: model.PatientNoteCategoryId,
            PatientNoteTypeId: model.PatientNoteTypeId,
        };
        return result;
    };
    return PatientNoteType_Factory;
}());
exports.PatientNoteType_Factory = PatientNoteType_Factory;
