"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Elastic_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ElasticType_1 = require("./ElasticType");
var Elastic_Factory = /** @class */ (function () {
    function Elastic_Factory() {
    }
    Elastic_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ElasticType: ElasticType_1.ElasticType_Factory.CreateIncoming(model.ElasticType),
            ElasticTypeId: model.ElasticTypeId,
            ElasticUsageCode: model.ElasticUsageCode,
            EndDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EndDate),
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
            ToothCode1: model.ToothCode1,
            ToothCode2: model.ToothCode2,
            ToothCode3: model.ToothCode3,
            ToothCode4: model.ToothCode4,
        };
        return result;
    };
    Elastic_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ElasticType: ElasticType_1.ElasticType_Factory.CreateOutgoing(model.ElasticType),
            ElasticTypeId: model.ElasticTypeId,
            ElasticUsageCode: model.ElasticUsageCode,
            EndDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EndDate),
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
            ToothCode1: model.ToothCode1,
            ToothCode2: model.ToothCode2,
            ToothCode3: model.ToothCode3,
            ToothCode4: model.ToothCode4,
        };
        return result;
    };
    return Elastic_Factory;
}());
exports.Elastic_Factory = Elastic_Factory;
