"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataExportTemplateMergeField_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CorrespondenceMergeField_1 = require("./CorrespondenceMergeField");
var DataExportTemplate_1 = require("./DataExportTemplate");
var DataExportTemplateMergeField_Factory = /** @class */ (function () {
    function DataExportTemplateMergeField_Factory() {
    }
    DataExportTemplateMergeField_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CorrespondenceMergeField: CorrespondenceMergeField_1.CorrespondenceMergeField_Factory.CreateIncoming(model.CorrespondenceMergeField),
            DataExportTemplate: DataExportTemplate_1.DataExportTemplate_Factory.CreateIncoming(model.DataExportTemplate),
            DataTemplateId: model.DataTemplateId,
            MergeFieldId: model.MergeFieldId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    DataExportTemplateMergeField_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CorrespondenceMergeField: CorrespondenceMergeField_1.CorrespondenceMergeField_Factory.CreateOutgoing(model.CorrespondenceMergeField),
            DataExportTemplate: DataExportTemplate_1.DataExportTemplate_Factory.CreateOutgoing(model.DataExportTemplate),
            DataTemplateId: model.DataTemplateId,
            MergeFieldId: model.MergeFieldId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return DataExportTemplateMergeField_Factory;
}());
exports.DataExportTemplateMergeField_Factory = DataExportTemplateMergeField_Factory;
