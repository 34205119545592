"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsTemplateSourceTypes_DisplayOrders = exports.EFormsTemplateSourceTypes_Descriptions_English = exports.EFormsTemplateSourceTypes_Descriptions = exports.EFormsTemplateSourceTypes_translateDescriptionBack = exports.EFormsTemplateSourceTypes_translateDescriptions = exports.EFormsTemplateSourceTypes = void 0;
var EFormsTemplateSourceTypes;
(function (EFormsTemplateSourceTypes) {
    EFormsTemplateSourceTypes[EFormsTemplateSourceTypes["PatientForms"] = 0] = "PatientForms";
    EFormsTemplateSourceTypes[EFormsTemplateSourceTypes["SenseiCloud"] = 1] = "SenseiCloud";
})(EFormsTemplateSourceTypes = exports.EFormsTemplateSourceTypes || (exports.EFormsTemplateSourceTypes = {}));
function EFormsTemplateSourceTypes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EFormsTemplateSourceTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.EFormsTemplateSourceTypes_Descriptions[key] = translationFunction(exports.EFormsTemplateSourceTypes_Descriptions[key]);
    });
}
exports.EFormsTemplateSourceTypes_translateDescriptions = EFormsTemplateSourceTypes_translateDescriptions;
function EFormsTemplateSourceTypes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EFormsTemplateSourceTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.EFormsTemplateSourceTypes_Descriptions[key] = exports.EFormsTemplateSourceTypes_Descriptions_English[key];
    });
}
exports.EFormsTemplateSourceTypes_translateDescriptionBack = EFormsTemplateSourceTypes_translateDescriptionBack;
var _EFormsTemplateSourceTypes_Descriptions = {};
_EFormsTemplateSourceTypes_Descriptions.PatientForms = _EFormsTemplateSourceTypes_Descriptions[0] = "PatientForms";
_EFormsTemplateSourceTypes_Descriptions.SenseiCloud = _EFormsTemplateSourceTypes_Descriptions[1] = "SenseiCloud";
var _EFormsTemplateSourceTypes_Descriptions_English = {};
_EFormsTemplateSourceTypes_Descriptions_English.PatientForms = _EFormsTemplateSourceTypes_Descriptions_English[0] = "PatientForms";
_EFormsTemplateSourceTypes_Descriptions_English.SenseiCloud = _EFormsTemplateSourceTypes_Descriptions_English[1] = "SenseiCloud";
exports.EFormsTemplateSourceTypes_Descriptions = _EFormsTemplateSourceTypes_Descriptions;
exports.EFormsTemplateSourceTypes_Descriptions_English = _EFormsTemplateSourceTypes_Descriptions_English;
var _EFormsTemplateSourceTypes_DisplayOrders = {};
_EFormsTemplateSourceTypes_DisplayOrders.PatientForms = _EFormsTemplateSourceTypes_DisplayOrders[0] = 0;
_EFormsTemplateSourceTypes_DisplayOrders.SenseiCloud = _EFormsTemplateSourceTypes_DisplayOrders[1] = 1;
exports.EFormsTemplateSourceTypes_DisplayOrders = _EFormsTemplateSourceTypes_DisplayOrders;
