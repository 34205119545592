"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceLocation_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var PhoneNumber_1 = require("./PhoneNumber");
var ServiceLocationInsuranceClaim_1 = require("./ServiceLocationInsuranceClaim");
var ServiceLocationTransaction_1 = require("./ServiceLocationTransaction");
var ServiceLocation_Factory = /** @class */ (function () {
    function ServiceLocation_Factory() {
    }
    ServiceLocation_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Addresses: model.Addresses && model.Addresses.map(function (i) { return Address_1.Address_Factory.CreateIncoming(i); }),
            Email: model.Email,
            Facebook: model.Facebook,
            GooglePlus: model.GooglePlus,
            LinkedIn: model.LinkedIn,
            Name: model.Name,
            NPI: model.NPI,
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(i); }),
            Pinterest: model.Pinterest,
            POSCode: model.POSCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ServiceLocationInsuranceClaims: model.ServiceLocationInsuranceClaims && model.ServiceLocationInsuranceClaims.map(function (i) { return ServiceLocationInsuranceClaim_1.ServiceLocationInsuranceClaim_Factory.CreateIncoming(i); }),
            ServiceLocationTransactions: model.ServiceLocationTransactions && model.ServiceLocationTransactions.map(function (i) { return ServiceLocationTransaction_1.ServiceLocationTransaction_Factory.CreateIncoming(i); }),
            Twitter: model.Twitter,
            Website: model.Website,
            YouTube: model.YouTube,
        };
        return result;
    };
    ServiceLocation_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Addresses: model.Addresses && model.Addresses.map(function (i) { return Address_1.Address_Factory.CreateOutgoing(i); }),
            Email: model.Email,
            Facebook: model.Facebook,
            GooglePlus: model.GooglePlus,
            LinkedIn: model.LinkedIn,
            Name: model.Name,
            NPI: model.NPI,
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(i); }),
            Pinterest: model.Pinterest,
            POSCode: model.POSCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ServiceLocationInsuranceClaims: model.ServiceLocationInsuranceClaims && model.ServiceLocationInsuranceClaims.map(function (i) { return ServiceLocationInsuranceClaim_1.ServiceLocationInsuranceClaim_Factory.CreateOutgoing(i); }),
            ServiceLocationTransactions: model.ServiceLocationTransactions && model.ServiceLocationTransactions.map(function (i) { return ServiceLocationTransaction_1.ServiceLocationTransaction_Factory.CreateOutgoing(i); }),
            Twitter: model.Twitter,
            Website: model.Website,
            YouTube: model.YouTube,
        };
        return result;
    };
    return ServiceLocation_Factory;
}());
exports.ServiceLocation_Factory = ServiceLocation_Factory;
