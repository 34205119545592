"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MasterDataDistributionEvent_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var MasterDataDistributionEvent_Factory = /** @class */ (function () {
    function MasterDataDistributionEvent_Factory() {
    }
    MasterDataDistributionEvent_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ChangesFrom: Date_Factory_1.Date_Factory.CreateIncoming(model.ChangesFrom),
            ChangesTo: Date_Factory_1.Date_Factory.CreateIncoming(model.ChangesTo),
            Notes: model.Notes,
            RecordId: model.RecordId,
            SentByUser: User_1.User_Factory.CreateIncoming(model.SentByUser),
            SentByUserId: model.SentByUserId,
        };
        return result;
    };
    MasterDataDistributionEvent_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ChangesFrom: Date_Factory_1.Date_Factory.CreateOutgoing(model.ChangesFrom),
            ChangesTo: Date_Factory_1.Date_Factory.CreateOutgoing(model.ChangesTo),
            Notes: model.Notes,
            RecordId: model.RecordId,
            SentByUser: User_1.User_Factory.CreateOutgoing(model.SentByUser),
            SentByUserId: model.SentByUserId,
        };
        return result;
    };
    return MasterDataDistributionEvent_Factory;
}());
exports.MasterDataDistributionEvent_Factory = MasterDataDistributionEvent_Factory;
