"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaimAttachment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceClaimAttachmentFile_1 = require("./InsuranceClaimAttachmentFile");
var PerioExam_1 = require("./PerioExam");
var InsuranceClaimAttachment_Factory = /** @class */ (function () {
    function InsuranceClaimAttachment_Factory() {
    }
    InsuranceClaimAttachment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AttachmentId: model.AttachmentId,
            InsuranceClaimAttachmentFiles: model.InsuranceClaimAttachmentFiles && model.InsuranceClaimAttachmentFiles.map(function (i) { return InsuranceClaimAttachmentFile_1.InsuranceClaimAttachmentFile_Factory.CreateIncoming(i); }),
            Narrative: model.Narrative,
            NEANumber: model.NEANumber,
            PerioExam: PerioExam_1.PerioExam_Factory.CreateIncoming(model.PerioExam),
            PerioExamBoxFileId: model.PerioExamBoxFileId,
            PerioExamId: model.PerioExamId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    InsuranceClaimAttachment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AttachmentId: model.AttachmentId,
            InsuranceClaimAttachmentFiles: model.InsuranceClaimAttachmentFiles && model.InsuranceClaimAttachmentFiles.map(function (i) { return InsuranceClaimAttachmentFile_1.InsuranceClaimAttachmentFile_Factory.CreateOutgoing(i); }),
            Narrative: model.Narrative,
            NEANumber: model.NEANumber,
            PerioExam: PerioExam_1.PerioExam_Factory.CreateOutgoing(model.PerioExam),
            PerioExamBoxFileId: model.PerioExamBoxFileId,
            PerioExamId: model.PerioExamId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return InsuranceClaimAttachment_Factory;
}());
exports.InsuranceClaimAttachment_Factory = InsuranceClaimAttachment_Factory;
