"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmsSetting_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SmsSetting_Factory = /** @class */ (function () {
    function SmsSetting_Factory() {
    }
    SmsSetting_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AllowSingleRowOnly: model.AllowSingleRowOnly,
            AutoSendDaysBeforeAppt: model.AutoSendDaysBeforeAppt,
            AutoSendWhenApptScheduled: model.AutoSendWhenApptScheduled,
            CoolOffPeriod: model.CoolOffPeriod,
            DeliveryWindowEnd: model.DeliveryWindowEnd,
            DeliveryWindowStart: model.DeliveryWindowStart,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SkipConfirmedAppts: model.SkipConfirmedAppts,
        };
        return result;
    };
    SmsSetting_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AllowSingleRowOnly: model.AllowSingleRowOnly,
            AutoSendDaysBeforeAppt: model.AutoSendDaysBeforeAppt,
            AutoSendWhenApptScheduled: model.AutoSendWhenApptScheduled,
            CoolOffPeriod: model.CoolOffPeriod,
            DeliveryWindowEnd: model.DeliveryWindowEnd,
            DeliveryWindowStart: model.DeliveryWindowStart,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SkipConfirmedAppts: model.SkipConfirmedAppts,
        };
        return result;
    };
    return SmsSetting_Factory;
}());
exports.SmsSetting_Factory = SmsSetting_Factory;
