"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlignerTrayPlan_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AlignerBrand_1 = require("./AlignerBrand");
var AlignerTrayUsageType_1 = require("./AlignerTrayUsageType");
var AlignerTrayPlan_Factory = /** @class */ (function () {
    function AlignerTrayPlan_Factory() {
    }
    AlignerTrayPlan_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AlignerBrand: AlignerBrand_1.AlignerBrand_Factory.CreateIncoming(model.AlignerBrand),
            AlignerBrandId: model.AlignerBrandId,
            AlignerDay: model.AlignerDay,
            AlignersDeliveredEnd: model.AlignersDeliveredEnd,
            AlignersDeliveredStart: model.AlignersDeliveredStart,
            AlignerTrackingPlanId: model.AlignerTrackingPlanId,
            ArchTypeCode: model.ArchTypeCode,
            ExpectedTray: model.ExpectedTray,
            LastVerified: model.LastVerified,
            Note: model.Note,
            NumberOfAligners: model.NumberOfAligners,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
            TrayChangeInterval: model.TrayChangeInterval,
            TrayUsageType: AlignerTrayUsageType_1.AlignerTrayUsageType_Factory.CreateIncoming(model.TrayUsageType),
            TrayUsageTypeCode: model.TrayUsageTypeCode,
        };
        return result;
    };
    AlignerTrayPlan_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AlignerBrand: AlignerBrand_1.AlignerBrand_Factory.CreateOutgoing(model.AlignerBrand),
            AlignerBrandId: model.AlignerBrandId,
            AlignerDay: model.AlignerDay,
            AlignersDeliveredEnd: model.AlignersDeliveredEnd,
            AlignersDeliveredStart: model.AlignersDeliveredStart,
            AlignerTrackingPlanId: model.AlignerTrackingPlanId,
            ArchTypeCode: model.ArchTypeCode,
            ExpectedTray: model.ExpectedTray,
            LastVerified: model.LastVerified,
            Note: model.Note,
            NumberOfAligners: model.NumberOfAligners,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
            TrayChangeInterval: model.TrayChangeInterval,
            TrayUsageType: AlignerTrayUsageType_1.AlignerTrayUsageType_Factory.CreateOutgoing(model.TrayUsageType),
            TrayUsageTypeCode: model.TrayUsageTypeCode,
        };
        return result;
    };
    return AlignerTrayPlan_Factory;
}());
exports.AlignerTrayPlan_Factory = AlignerTrayPlan_Factory;
