"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientAllergyVersion_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var PatientAllergyVersion_Factory = /** @class */ (function () {
    function PatientAllergyVersion_Factory() {
    }
    PatientAllergyVersion_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Note: model.Note,
            PatientAllergyId: model.PatientAllergyId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordedByUser: User_1.User_Factory.CreateIncoming(model.RecordedByUser),
            RecordedByUserId: model.RecordedByUserId,
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Version: model.Version,
        };
        return result;
    };
    PatientAllergyVersion_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Note: model.Note,
            PatientAllergyId: model.PatientAllergyId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordedByUser: User_1.User_Factory.CreateOutgoing(model.RecordedByUser),
            RecordedByUserId: model.RecordedByUserId,
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Version: model.Version,
        };
        return result;
    };
    return PatientAllergyVersion_Factory;
}());
exports.PatientAllergyVersion_Factory = PatientAllergyVersion_Factory;
