"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chair_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var Location_1 = require("./Location");
var RecordStatus_1 = require("./RecordStatus");
var Chair_Factory = /** @class */ (function () {
    function Chair_Factory() {
    }
    Chair_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AvailableForOnlineBooking: model.AvailableForOnlineBooking,
            DefaultProvider: User_1.User_Factory.CreateIncoming(model.DefaultProvider),
            DefaultProviderId: model.DefaultProviderId,
            IntegerIdentifier: model.IntegerIdentifier,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            Name: model.Name,
            OnlineBookingIdentifier: model.OnlineBookingIdentifier,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
            ShowOrder: model.ShowOrder,
        };
        return result;
    };
    Chair_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AvailableForOnlineBooking: model.AvailableForOnlineBooking,
            DefaultProvider: User_1.User_Factory.CreateOutgoing(model.DefaultProvider),
            DefaultProviderId: model.DefaultProviderId,
            IntegerIdentifier: model.IntegerIdentifier,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            Name: model.Name,
            OnlineBookingIdentifier: model.OnlineBookingIdentifier,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
            ShowOrder: model.ShowOrder,
        };
        return result;
    };
    return Chair_Factory;
}());
exports.Chair_Factory = Chair_Factory;
