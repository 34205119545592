"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentStatus_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EmploymentStatus_Factory = /** @class */ (function () {
    function EmploymentStatus_Factory() {
    }
    EmploymentStatus_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EmploymentStatus1: model.EmploymentStatus1,
            EmploymentStatusCode: model.EmploymentStatusCode,
        };
        return result;
    };
    EmploymentStatus_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EmploymentStatus1: model.EmploymentStatus1,
            EmploymentStatusCode: model.EmploymentStatusCode,
        };
        return result;
    };
    return EmploymentStatus_Factory;
}());
exports.EmploymentStatus_Factory = EmploymentStatus_Factory;
