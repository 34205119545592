"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientWaitingList_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Patient_1 = require("./Patient");
var PatientWaitingListNote_1 = require("./PatientWaitingListNote");
var PatientWaitingListStatusHistory_1 = require("./PatientWaitingListStatusHistory");
var WaitingList_1 = require("./WaitingList");
var PatientWaitingList_Factory = /** @class */ (function () {
    function PatientWaitingList_Factory() {
    }
    PatientWaitingList_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PatientWaitingListNotes: model.PatientWaitingListNotes && model.PatientWaitingListNotes.map(function (i) { return PatientWaitingListNote_1.PatientWaitingListNote_Factory.CreateIncoming(i); }),
            PatientWaitingListStatusHistory: model.PatientWaitingListStatusHistory && model.PatientWaitingListStatusHistory.map(function (i) { return PatientWaitingListStatusHistory_1.PatientWaitingListStatusHistory_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Status: model.Status,
            WaitingList: WaitingList_1.WaitingList_Factory.CreateIncoming(model.WaitingList),
            WaitingListId: model.WaitingListId,
        };
        return result;
    };
    PatientWaitingList_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PatientWaitingListNotes: model.PatientWaitingListNotes && model.PatientWaitingListNotes.map(function (i) { return PatientWaitingListNote_1.PatientWaitingListNote_Factory.CreateOutgoing(i); }),
            PatientWaitingListStatusHistory: model.PatientWaitingListStatusHistory && model.PatientWaitingListStatusHistory.map(function (i) { return PatientWaitingListStatusHistory_1.PatientWaitingListStatusHistory_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Status: model.Status,
            WaitingList: WaitingList_1.WaitingList_Factory.CreateOutgoing(model.WaitingList),
            WaitingListId: model.WaitingListId,
        };
        return result;
    };
    return PatientWaitingList_Factory;
}());
exports.PatientWaitingList_Factory = PatientWaitingList_Factory;
