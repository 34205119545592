"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSClaimStatusChange_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var NHSClaimStatusChange_Factory = /** @class */ (function () {
    function NHSClaimStatusChange_Factory() {
    }
    NHSClaimStatusChange_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimId: model.ClaimId,
            ClaimStatus: model.ClaimStatus,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    NHSClaimStatusChange_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimId: model.ClaimId,
            ClaimStatus: model.ClaimStatus,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return NHSClaimStatusChange_Factory;
}());
exports.NHSClaimStatusChange_Factory = NHSClaimStatusChange_Factory;
