"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientRxAuditLog_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientRxAuditLog_Factory = /** @class */ (function () {
    function PatientRxAuditLog_Factory() {
    }
    PatientRxAuditLog_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AuditDateTime: Date_Factory_1.Date_Factory.CreateIncoming(model.AuditDateTime),
            Description: model.Description,
            PatientRxId: model.PatientRxId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    PatientRxAuditLog_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AuditDateTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.AuditDateTime),
            Description: model.Description,
            PatientRxId: model.PatientRxId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    return PatientRxAuditLog_Factory;
}());
exports.PatientRxAuditLog_Factory = PatientRxAuditLog_Factory;
