"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MawidDeltaExtract_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MawidDeltaExtract_Factory = /** @class */ (function () {
    function MawidDeltaExtract_Factory() {
    }
    MawidDeltaExtract_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentCode: model.AppointmentCode,
            AppointmentStatus: model.AppointmentStatus,
            ContactNumber: model.ContactNumber,
            DateofBirth: Date_Factory_1.Date_Factory.CreateIncoming(model.DateofBirth),
            DateofBirthHijri: model.DateofBirthHijri,
            DirectorateName: model.DirectorateName,
            DirectorateNameArabic: model.DirectorateNameArabic,
            Duration: model.Duration,
            Email: model.Email,
            EndTime: model.EndTime,
            FacilityName: model.FacilityName,
            FacilityNameArabic: model.FacilityNameArabic,
            FacilityType: model.FacilityType,
            FacilityTypeArabic: model.FacilityTypeArabic,
            FatherName: model.FatherName,
            FatherNameArabic: model.FatherNameArabic,
            FirstName: model.FirstName,
            FirstName_1: model.FirstName_1,
            FirstNameArabic: model.FirstNameArabic,
            FullName: model.FullName,
            FullNameArabic: model.FullNameArabic,
            Gender: model.Gender,
            GISCode: model.GISCode,
            IsDependent: model.IsDependent,
            IsHIS: model.IsHIS,
            LastName: model.LastName,
            LastName_1: model.LastName_1,
            LastNameArabic: model.LastNameArabic,
            NationalIDIqamaNumber: model.NationalIDIqamaNumber,
            Nationality: model.Nationality,
            NationalityArabic: model.NationalityArabic,
            NationalityISO3Code: model.NationalityISO3Code,
            NoofDoctors: model.NoofDoctors,
            PatientNationalID: model.PatientNationalID,
            RecordId: model.RecordId,
            ScheduleDates: Date_Factory_1.Date_Factory.CreateIncoming(model.ScheduleDates),
            ScheduleType: model.ScheduleType,
            SecondName: model.SecondName,
            SecondNameArabic: model.SecondNameArabic,
            ServiceName: model.ServiceName,
            ServiceNameArabic: model.ServiceNameArabic,
            SHCCode: model.SHCCode,
            SlotDate: Date_Factory_1.Date_Factory.CreateIncoming(model.SlotDate),
            SlotEndTime: model.SlotEndTime,
            SlotStartTime: model.SlotStartTime,
            SlotStatus: model.SlotStatus,
            SlotType: model.SlotType,
            StartTime: model.StartTime,
            ThirdName: model.ThirdName,
            ThirdNameArabic: model.ThirdNameArabic,
        };
        return result;
    };
    MawidDeltaExtract_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentCode: model.AppointmentCode,
            AppointmentStatus: model.AppointmentStatus,
            ContactNumber: model.ContactNumber,
            DateofBirth: Date_Factory_1.Date_Factory.CreateOutgoing(model.DateofBirth),
            DateofBirthHijri: model.DateofBirthHijri,
            DirectorateName: model.DirectorateName,
            DirectorateNameArabic: model.DirectorateNameArabic,
            Duration: model.Duration,
            Email: model.Email,
            EndTime: model.EndTime,
            FacilityName: model.FacilityName,
            FacilityNameArabic: model.FacilityNameArabic,
            FacilityType: model.FacilityType,
            FacilityTypeArabic: model.FacilityTypeArabic,
            FatherName: model.FatherName,
            FatherNameArabic: model.FatherNameArabic,
            FirstName: model.FirstName,
            FirstName_1: model.FirstName_1,
            FirstNameArabic: model.FirstNameArabic,
            FullName: model.FullName,
            FullNameArabic: model.FullNameArabic,
            Gender: model.Gender,
            GISCode: model.GISCode,
            IsDependent: model.IsDependent,
            IsHIS: model.IsHIS,
            LastName: model.LastName,
            LastName_1: model.LastName_1,
            LastNameArabic: model.LastNameArabic,
            NationalIDIqamaNumber: model.NationalIDIqamaNumber,
            Nationality: model.Nationality,
            NationalityArabic: model.NationalityArabic,
            NationalityISO3Code: model.NationalityISO3Code,
            NoofDoctors: model.NoofDoctors,
            PatientNationalID: model.PatientNationalID,
            RecordId: model.RecordId,
            ScheduleDates: Date_Factory_1.Date_Factory.CreateOutgoing(model.ScheduleDates),
            ScheduleType: model.ScheduleType,
            SecondName: model.SecondName,
            SecondNameArabic: model.SecondNameArabic,
            ServiceName: model.ServiceName,
            ServiceNameArabic: model.ServiceNameArabic,
            SHCCode: model.SHCCode,
            SlotDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.SlotDate),
            SlotEndTime: model.SlotEndTime,
            SlotStartTime: model.SlotStartTime,
            SlotStatus: model.SlotStatus,
            SlotType: model.SlotType,
            StartTime: model.StartTime,
            ThirdName: model.ThirdName,
            ThirdNameArabic: model.ThirdNameArabic,
        };
        return result;
    };
    return MawidDeltaExtract_Factory;
}());
exports.MawidDeltaExtract_Factory = MawidDeltaExtract_Factory;
