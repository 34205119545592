"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsTemplate_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsTemplateDetail_1 = require("./EFormsTemplateDetail");
var EFormsTemplate_Factory = /** @class */ (function () {
    function EFormsTemplate_Factory() {
    }
    EFormsTemplate_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CreatedBy: model.CreatedBy,
            GlobalTemplateId: model.GlobalTemplateId,
            IsInUse: model.IsInUse,
            IsPublic: model.IsPublic,
            IsWriteBack: model.IsWriteBack,
            JSONTemplateBody: model.JSONTemplateBody,
            Ordinal: model.Ordinal,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SourceTypeId: model.SourceTypeId,
            TemplateDetail: EFormsTemplateDetail_1.EFormsTemplateDetail_Factory.CreateIncoming(model.TemplateDetail),
            TemplateName: model.TemplateName,
            TenantLocaleId: model.TenantLocaleId,
            UICulture: model.UICulture,
        };
        return result;
    };
    EFormsTemplate_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CreatedBy: model.CreatedBy,
            GlobalTemplateId: model.GlobalTemplateId,
            IsInUse: model.IsInUse,
            IsPublic: model.IsPublic,
            IsWriteBack: model.IsWriteBack,
            JSONTemplateBody: model.JSONTemplateBody,
            Ordinal: model.Ordinal,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SourceTypeId: model.SourceTypeId,
            TemplateDetail: EFormsTemplateDetail_1.EFormsTemplateDetail_Factory.CreateOutgoing(model.TemplateDetail),
            TemplateName: model.TemplateName,
            TenantLocaleId: model.TenantLocaleId,
            UICulture: model.UICulture,
        };
        return result;
    };
    return EFormsTemplate_Factory;
}());
exports.EFormsTemplate_Factory = EFormsTemplate_Factory;
