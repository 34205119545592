"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTypeFilter_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReportGeneratorFilter_1 = require("./ReportGeneratorFilter");
var ReportGeneratorReportType_1 = require("./ReportGeneratorReportType");
var ReportTypeFilter_Factory = /** @class */ (function () {
    function ReportTypeFilter_Factory() {
    }
    ReportTypeFilter_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ReportFilter: ReportGeneratorFilter_1.ReportGeneratorFilter_Factory.CreateIncoming(model.ReportFilter),
            ReportFilterId: model.ReportFilterId,
            ReportType: ReportGeneratorReportType_1.ReportGeneratorReportType_Factory.CreateIncoming(model.ReportType),
            ReportTypeId: model.ReportTypeId,
        };
        return result;
    };
    ReportTypeFilter_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ReportFilter: ReportGeneratorFilter_1.ReportGeneratorFilter_Factory.CreateOutgoing(model.ReportFilter),
            ReportFilterId: model.ReportFilterId,
            ReportType: ReportGeneratorReportType_1.ReportGeneratorReportType_Factory.CreateOutgoing(model.ReportType),
            ReportTypeId: model.ReportTypeId,
        };
        return result;
    };
    return ReportTypeFilter_Factory;
}());
exports.ReportTypeFilter_Factory = ReportTypeFilter_Factory;
