"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferredContactMethodType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Patient_1 = require("./Patient");
var PreferredContactMethodType_Factory = /** @class */ (function () {
    function PreferredContactMethodType_Factory() {
    }
    PreferredContactMethodType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            Patients: model.Patients && model.Patients.map(function (i) { return Patient_1.Patient_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    PreferredContactMethodType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            Patients: model.Patients && model.Patients.map(function (i) { return Patient_1.Patient_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return PreferredContactMethodType_Factory;
}());
exports.PreferredContactMethodType_Factory = PreferredContactMethodType_Factory;
