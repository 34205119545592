"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transaction_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CollectionAllocation_1 = require("./CollectionAllocation");
var Appointment_1 = require("./Appointment");
var ArchivedDaysheet_1 = require("./ArchivedDaysheet");
var OrthoContractChargeSchedule_1 = require("./OrthoContractChargeSchedule");
var Location_1 = require("./Location");
var OrthoContract_1 = require("./OrthoContract");
var Patient_1 = require("./Patient");
var PatientProcedure_1 = require("./PatientProcedure");
var Procedure_1 = require("./Procedure");
var ScheduledPayment_1 = require("./ScheduledPayment");
var ServiceLocationTransaction_1 = require("./ServiceLocationTransaction");
var SplitTransaction_1 = require("./SplitTransaction");
var TransactionPendingSplit_1 = require("./TransactionPendingSplit");
var TransactionSubType_1 = require("./TransactionSubType");
var TransactionType_1 = require("./TransactionType");
var User_1 = require("./User");
var Transaction_Factory = /** @class */ (function () {
    function Transaction_Factory() {
    }
    Transaction_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdjustmentTransactionId: model.AdjustmentTransactionId,
            AllocationsAsCredit: model.AllocationsAsCredit && model.AllocationsAsCredit.map(function (i) { return CollectionAllocation_1.CollectionAllocation_Factory.CreateIncoming(i); }),
            AllocationsAsDebit: model.AllocationsAsDebit && model.AllocationsAsDebit.map(function (i) { return CollectionAllocation_1.CollectionAllocation_Factory.CreateIncoming(i); }),
            AllowedAmount: model.AllowedAmount,
            Amount: model.Amount,
            AmountAllocated: model.AmountAllocated,
            AmountCurrent: model.AmountCurrent,
            Appointment: Appointment_1.Appointment_Factory.CreateIncoming(model.Appointment),
            AppointmentId: model.AppointmentId,
            ArchivedDaysheet: ArchivedDaysheet_1.ArchivedDaysheet_Factory.CreateIncoming(model.ArchivedDaysheet),
            ArchivedDaysheetId: model.ArchivedDaysheetId,
            BillInsurance: model.BillInsurance,
            BulkInsurancePaymentClaimId: model.BulkInsurancePaymentClaimId,
            CodeDescription: model.CodeDescription,
            Comments: model.Comments,
            ContractSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateIncoming(model.ContractSchedule),
            ContractScheduleId: model.ContractScheduleId,
            DeductibleAmount: model.DeductibleAmount,
            Description: model.Description,
            DownpaymentContractSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateIncoming(model.DownpaymentContractSchedule),
            DownpaymentContractScheduleId: model.DownpaymentContractScheduleId,
            EPaymentsAmount: model.EPaymentsAmount,
            EPaymentsStatus: model.EPaymentsStatus,
            EPaymentsTransactionId: model.EPaymentsTransactionId,
            EPaymentsVersion: model.EPaymentsVersion,
            FeeOverridden: model.FeeOverridden,
            InsuranceAmount: model.InsuranceAmount,
            InsuranceWriteOffAmount: model.InsuranceWriteOffAmount,
            IsAccountHistoryAdjustment: model.IsAccountHistoryAdjustment,
            IsAdjustment: model.IsAdjustment,
            IsBulkInsurancePaymentPortion: model.IsBulkInsurancePaymentPortion,
            IsCharge: model.IsCharge,
            IsContractCharge: model.IsContractCharge,
            IsCorrectedTransaction: model.IsCorrectedTransaction,
            IsCorrectionAdjustment: model.IsCorrectionAdjustment,
            IsOrthoContractDiscount: model.IsOrthoContractDiscount,
            IsSplitTransaction: model.IsSplitTransaction,
            IsStatementCharge: model.IsStatementCharge,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            OriginalFee: model.OriginalFee,
            OrthoContracts: model.OrthoContracts && model.OrthoContracts.map(function (i) { return OrthoContract_1.OrthoContract_Factory.CreateIncoming(i); }),
            OverrideNotes: model.OverrideNotes,
            PaidInsuranceClaimId: model.PaidInsuranceClaimId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            Procedure: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure),
            ProceduresOfficeCodeId: model.ProceduresOfficeCodeId,
            ProductionAmount: model.ProductionAmount,
            ProductionDoctorId: model.ProductionDoctorId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Reference: model.Reference,
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateIncoming(i); }),
            ServiceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ServiceDate),
            ServiceLocationTransaction: ServiceLocationTransaction_1.ServiceLocationTransaction_Factory.CreateIncoming(model.ServiceLocationTransaction),
            ServiceLocationTransactionId: model.ServiceLocationTransactionId,
            SplitPart: model.SplitPart && model.SplitPart.map(function (i) { return SplitTransaction_1.SplitTransaction_Factory.CreateIncoming(i); }),
            SplitTransactions: model.SplitTransactions && model.SplitTransactions.map(function (i) { return SplitTransaction_1.SplitTransaction_Factory.CreateIncoming(i); }),
            Transaction1: Transaction_Factory.CreateIncoming(model.Transaction1),
            TransactionDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TransactionDate),
            TransactionPendingSplits: model.TransactionPendingSplits && model.TransactionPendingSplits.map(function (i) { return TransactionPendingSplit_1.TransactionPendingSplit_Factory.CreateIncoming(i); }),
            TransactionSubType: TransactionSubType_1.TransactionSubType_Factory.CreateIncoming(model.TransactionSubType),
            TransactionSubTypeId: model.TransactionSubTypeId,
            TransactionType: TransactionType_1.TransactionType_Factory.CreateIncoming(model.TransactionType),
            TransactionTypeCode: model.TransactionTypeCode,
            User: User_1.User_Factory.CreateIncoming(model.User),
            User1: User_1.User_Factory.CreateIncoming(model.User1),
            UserId: model.UserId,
        };
        return result;
    };
    Transaction_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdjustmentTransactionId: model.AdjustmentTransactionId,
            AllocationsAsCredit: model.AllocationsAsCredit && model.AllocationsAsCredit.map(function (i) { return CollectionAllocation_1.CollectionAllocation_Factory.CreateOutgoing(i); }),
            AllocationsAsDebit: model.AllocationsAsDebit && model.AllocationsAsDebit.map(function (i) { return CollectionAllocation_1.CollectionAllocation_Factory.CreateOutgoing(i); }),
            AllowedAmount: model.AllowedAmount,
            Amount: model.Amount,
            AmountAllocated: model.AmountAllocated,
            AmountCurrent: model.AmountCurrent,
            Appointment: Appointment_1.Appointment_Factory.CreateOutgoing(model.Appointment),
            AppointmentId: model.AppointmentId,
            ArchivedDaysheet: ArchivedDaysheet_1.ArchivedDaysheet_Factory.CreateOutgoing(model.ArchivedDaysheet),
            ArchivedDaysheetId: model.ArchivedDaysheetId,
            BillInsurance: model.BillInsurance,
            BulkInsurancePaymentClaimId: model.BulkInsurancePaymentClaimId,
            CodeDescription: model.CodeDescription,
            Comments: model.Comments,
            ContractSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateOutgoing(model.ContractSchedule),
            ContractScheduleId: model.ContractScheduleId,
            DeductibleAmount: model.DeductibleAmount,
            Description: model.Description,
            DownpaymentContractSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateOutgoing(model.DownpaymentContractSchedule),
            DownpaymentContractScheduleId: model.DownpaymentContractScheduleId,
            EPaymentsAmount: model.EPaymentsAmount,
            EPaymentsStatus: model.EPaymentsStatus,
            EPaymentsTransactionId: model.EPaymentsTransactionId,
            EPaymentsVersion: model.EPaymentsVersion,
            FeeOverridden: model.FeeOverridden,
            InsuranceAmount: model.InsuranceAmount,
            InsuranceWriteOffAmount: model.InsuranceWriteOffAmount,
            IsAccountHistoryAdjustment: model.IsAccountHistoryAdjustment,
            IsAdjustment: model.IsAdjustment,
            IsBulkInsurancePaymentPortion: model.IsBulkInsurancePaymentPortion,
            IsCharge: model.IsCharge,
            IsContractCharge: model.IsContractCharge,
            IsCorrectedTransaction: model.IsCorrectedTransaction,
            IsCorrectionAdjustment: model.IsCorrectionAdjustment,
            IsOrthoContractDiscount: model.IsOrthoContractDiscount,
            IsSplitTransaction: model.IsSplitTransaction,
            IsStatementCharge: model.IsStatementCharge,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            OriginalFee: model.OriginalFee,
            OrthoContracts: model.OrthoContracts && model.OrthoContracts.map(function (i) { return OrthoContract_1.OrthoContract_Factory.CreateOutgoing(i); }),
            OverrideNotes: model.OverrideNotes,
            PaidInsuranceClaimId: model.PaidInsuranceClaimId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            Procedure: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure),
            ProceduresOfficeCodeId: model.ProceduresOfficeCodeId,
            ProductionAmount: model.ProductionAmount,
            ProductionDoctorId: model.ProductionDoctorId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Reference: model.Reference,
            ScheduledPayments: model.ScheduledPayments && model.ScheduledPayments.map(function (i) { return ScheduledPayment_1.ScheduledPayment_Factory.CreateOutgoing(i); }),
            ServiceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ServiceDate),
            ServiceLocationTransaction: ServiceLocationTransaction_1.ServiceLocationTransaction_Factory.CreateOutgoing(model.ServiceLocationTransaction),
            ServiceLocationTransactionId: model.ServiceLocationTransactionId,
            SplitPart: model.SplitPart && model.SplitPart.map(function (i) { return SplitTransaction_1.SplitTransaction_Factory.CreateOutgoing(i); }),
            SplitTransactions: model.SplitTransactions && model.SplitTransactions.map(function (i) { return SplitTransaction_1.SplitTransaction_Factory.CreateOutgoing(i); }),
            Transaction1: Transaction_Factory.CreateOutgoing(model.Transaction1),
            TransactionDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TransactionDate),
            TransactionPendingSplits: model.TransactionPendingSplits && model.TransactionPendingSplits.map(function (i) { return TransactionPendingSplit_1.TransactionPendingSplit_Factory.CreateOutgoing(i); }),
            TransactionSubType: TransactionSubType_1.TransactionSubType_Factory.CreateOutgoing(model.TransactionSubType),
            TransactionSubTypeId: model.TransactionSubTypeId,
            TransactionType: TransactionType_1.TransactionType_Factory.CreateOutgoing(model.TransactionType),
            TransactionTypeCode: model.TransactionTypeCode,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            User1: User_1.User_Factory.CreateOutgoing(model.User1),
            UserId: model.UserId,
        };
        return result;
    };
    return Transaction_Factory;
}());
exports.Transaction_Factory = Transaction_Factory;
