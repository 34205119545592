"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Patient_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientConnection_1 = require("./PatientConnection");
var Appointment_1 = require("./Appointment");
var BulkStatementBatchGuarantorPatient_1 = require("./BulkStatementBatchGuarantorPatient");
var BulkStatementBatchPatient_1 = require("./BulkStatementBatchPatient");
var CorrespondenceLetter_1 = require("./CorrespondenceLetter");
var ReferralSource_1 = require("./ReferralSource");
var EstateReferralAuditLog_1 = require("./EstateReferralAuditLog");
var EstateReferral_1 = require("./EstateReferral");
var ImagingOrder_1 = require("./ImagingOrder");
var InsuranceClaim_1 = require("./InsuranceClaim");
var InsurancePolicy_1 = require("./InsurancePolicy");
var Language_1 = require("./Language");
var Location_1 = require("./Location");
var LocationAccountsReceivable_1 = require("./LocationAccountsReceivable");
var NHSPatientDetail_1 = require("./NHSPatientDetail");
var OrthoPatientTreatmentStatus_1 = require("./OrthoPatientTreatmentStatus");
var PatientConcern_1 = require("./PatientConcern");
var PatientImagingIdentifier_1 = require("./PatientImagingIdentifier");
var PatientLedgerBalance_1 = require("./PatientLedgerBalance");
var PatientNotification_1 = require("./PatientNotification");
var PatientPaymentMethod_1 = require("./PatientPaymentMethod");
var PatientProcedure_1 = require("./PatientProcedure");
var PatientRecallInfo_1 = require("./PatientRecallInfo");
var PatientRecallPreference_1 = require("./PatientRecallPreference");
var PatientResponsibleParty_1 = require("./PatientResponsibleParty");
var PatientsMedicalAlert_1 = require("./PatientsMedicalAlert");
var PatientStatement_1 = require("./PatientStatement");
var PatientStatus_1 = require("./PatientStatus");
var PatientWaitingList_1 = require("./PatientWaitingList");
var Person_1 = require("./Person");
var User_1 = require("./User");
var PreferredContactMethodType_1 = require("./PreferredContactMethodType");
var ProcedureList_1 = require("./ProcedureList");
var Referral_1 = require("./Referral");
var ServiceScheme_1 = require("./ServiceScheme");
var School_1 = require("./School");
var TransactionPendingSplit_1 = require("./TransactionPendingSplit");
var Patient_Factory = /** @class */ (function () {
    function Patient_Factory() {
    }
    Patient_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AllPatientConnections: model.AllPatientConnections && model.AllPatientConnections.map(function (i) { return PatientConnection_1.PatientConnection_Factory.CreateIncoming(i); }),
            Appointments: model.Appointments && model.Appointments.map(function (i) { return Appointment_1.Appointment_Factory.CreateIncoming(i); }),
            BoxFolderId: model.BoxFolderId,
            BulkStatementBatchGuarantorPatients: model.BulkStatementBatchGuarantorPatients && model.BulkStatementBatchGuarantorPatients.map(function (i) { return BulkStatementBatchGuarantorPatient_1.BulkStatementBatchGuarantorPatient_Factory.CreateIncoming(i); }),
            BulkStatementBatchPatients: model.BulkStatementBatchPatients && model.BulkStatementBatchPatients.map(function (i) { return BulkStatementBatchPatient_1.BulkStatementBatchPatient_Factory.CreateIncoming(i); }),
            CorrespondenceLetters: model.CorrespondenceLetters && model.CorrespondenceLetters.map(function (i) { return CorrespondenceLetter_1.CorrespondenceLetter_Factory.CreateIncoming(i); }),
            Dentist: ReferralSource_1.ReferralSource_Factory.CreateIncoming(model.Dentist),
            DentistId: model.DentistId,
            EstateReferralAuditLogs: model.EstateReferralAuditLogs && model.EstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateIncoming(i); }),
            EstateReferrals: model.EstateReferrals && model.EstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateIncoming(i); }),
            FileStorageProviderTypeCode: model.FileStorageProviderTypeCode,
            ImagingOrders: model.ImagingOrders && model.ImagingOrders.map(function (i) { return ImagingOrder_1.ImagingOrder_Factory.CreateIncoming(i); }),
            IncomingPatientConnections: model.IncomingPatientConnections && model.IncomingPatientConnections.map(function (i) { return PatientConnection_1.PatientConnection_Factory.CreateIncoming(i); }),
            InsuranceClaims: model.InsuranceClaims && model.InsuranceClaims.map(function (i) { return InsuranceClaim_1.InsuranceClaim_Factory.CreateIncoming(i); }),
            InsurancePolicies: model.InsurancePolicies && model.InsurancePolicies.map(function (i) { return InsurancePolicy_1.InsurancePolicy_Factory.CreateIncoming(i); }),
            IsNew: model.IsNew,
            Language: Language_1.Language_Factory.CreateIncoming(model.Language),
            LastRecallDate: Date_Factory_1.Date_Factory.CreateIncoming(model.LastRecallDate, { ignoreTimePortion: true }),
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationAccountsReceivable: model.LocationAccountsReceivable && model.LocationAccountsReceivable.map(function (i) { return LocationAccountsReceivable_1.LocationAccountsReceivable_Factory.CreateIncoming(i); }),
            MigrationStatus: model.MigrationStatus,
            ModelBox: model.ModelBox,
            NHSPatientDetail: NHSPatientDetail_1.NHSPatientDetail_Factory.CreateIncoming(model.NHSPatientDetail),
            Notes: model.Notes,
            OrthoPatientTreatmentStatuses: model.OrthoPatientTreatmentStatuses && model.OrthoPatientTreatmentStatuses.map(function (i) { return OrthoPatientTreatmentStatus_1.OrthoPatientTreatmentStatus_Factory.CreateIncoming(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateIncoming(i); }),
            PatientConnections: model.PatientConnections && model.PatientConnections.map(function (i) { return PatientConnection_1.PatientConnection_Factory.CreateIncoming(i); }),
            PatientIdentifier: model.PatientIdentifier,
            PatientImagingIdentifiers: model.PatientImagingIdentifiers && model.PatientImagingIdentifiers.map(function (i) { return PatientImagingIdentifier_1.PatientImagingIdentifier_Factory.CreateIncoming(i); }),
            PatientLedgerBalance: PatientLedgerBalance_1.PatientLedgerBalance_Factory.CreateIncoming(model.PatientLedgerBalance),
            PatientNotifications: model.PatientNotifications && model.PatientNotifications.map(function (i) { return PatientNotification_1.PatientNotification_Factory.CreateIncoming(i); }),
            PatientPaymentMethods: model.PatientPaymentMethods && model.PatientPaymentMethods.map(function (i) { return PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateIncoming(i); }),
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(i); }),
            PatientRecallInfo: PatientRecallInfo_1.PatientRecallInfo_Factory.CreateIncoming(model.PatientRecallInfo),
            PatientRecallPreferences: model.PatientRecallPreferences && model.PatientRecallPreferences.map(function (i) { return PatientRecallPreference_1.PatientRecallPreference_Factory.CreateIncoming(i); }),
            PatientResponsibleParties: model.PatientResponsibleParties && model.PatientResponsibleParties.map(function (i) { return PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateIncoming(i); }),
            PatientSearchIdentifier: model.PatientSearchIdentifier,
            PatientsMedicalAlerts: model.PatientsMedicalAlerts && model.PatientsMedicalAlerts.map(function (i) { return PatientsMedicalAlert_1.PatientsMedicalAlert_Factory.CreateIncoming(i); }),
            PatientStatements: model.PatientStatements && model.PatientStatements.map(function (i) { return PatientStatement_1.PatientStatement_Factory.CreateIncoming(i); }),
            PatientStatus: model.PatientStatus,
            PatientStatus1: PatientStatus_1.PatientStatus_Factory.CreateIncoming(model.PatientStatus1),
            PatientWaitingLists: model.PatientWaitingLists && model.PatientWaitingLists.map(function (i) { return PatientWaitingList_1.PatientWaitingList_Factory.CreateIncoming(i); }),
            Person: Person_1.Person_Factory.CreateIncoming(model.Person),
            PersonRecordId: model.PersonRecordId,
            PreferredCultureString: model.PreferredCultureString,
            PreferredDoctor: User_1.User_Factory.CreateIncoming(model.PreferredDoctor),
            PreferredDoctorId: model.PreferredDoctorId,
            PreferredHygienist: User_1.User_Factory.CreateIncoming(model.PreferredHygienist),
            PreferredHygienistId: model.PreferredHygienistId,
            PreferredLocationId: model.PreferredLocationId,
            PreferredProcedureListId: model.PreferredProcedureListId,
            PreferredStatementContactType: PreferredContactMethodType_1.PreferredContactMethodType_Factory.CreateIncoming(model.PreferredStatementContactType),
            PreferredStatementContactTypeCode: model.PreferredStatementContactTypeCode,
            PrimaryInsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateIncoming(model.PrimaryInsurancePolicy),
            PrimaryInsurancePolicyId: model.PrimaryInsurancePolicyId,
            ProcedureList: ProcedureList_1.ProcedureList_Factory.CreateIncoming(model.ProcedureList),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referrals: model.Referrals && model.Referrals.map(function (i) { return Referral_1.Referral_Factory.CreateIncoming(i); }),
            Scheme: ServiceScheme_1.ServiceScheme_Factory.CreateIncoming(model.Scheme),
            SchemeId: model.SchemeId,
            School: School_1.School_Factory.CreateIncoming(model.School),
            SchoolId: model.SchoolId,
            SecondaryInsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateIncoming(model.SecondaryInsurancePolicy),
            SecondaryInsurancePolicyId: model.SecondaryInsurancePolicyId,
            TransactionPendingSplits: model.TransactionPendingSplits && model.TransactionPendingSplits.map(function (i) { return TransactionPendingSplit_1.TransactionPendingSplit_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    Patient_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AllPatientConnections: model.AllPatientConnections && model.AllPatientConnections.map(function (i) { return PatientConnection_1.PatientConnection_Factory.CreateOutgoing(i); }),
            Appointments: model.Appointments && model.Appointments.map(function (i) { return Appointment_1.Appointment_Factory.CreateOutgoing(i); }),
            BoxFolderId: model.BoxFolderId,
            BulkStatementBatchGuarantorPatients: model.BulkStatementBatchGuarantorPatients && model.BulkStatementBatchGuarantorPatients.map(function (i) { return BulkStatementBatchGuarantorPatient_1.BulkStatementBatchGuarantorPatient_Factory.CreateOutgoing(i); }),
            BulkStatementBatchPatients: model.BulkStatementBatchPatients && model.BulkStatementBatchPatients.map(function (i) { return BulkStatementBatchPatient_1.BulkStatementBatchPatient_Factory.CreateOutgoing(i); }),
            CorrespondenceLetters: model.CorrespondenceLetters && model.CorrespondenceLetters.map(function (i) { return CorrespondenceLetter_1.CorrespondenceLetter_Factory.CreateOutgoing(i); }),
            Dentist: ReferralSource_1.ReferralSource_Factory.CreateOutgoing(model.Dentist),
            DentistId: model.DentistId,
            EstateReferralAuditLogs: model.EstateReferralAuditLogs && model.EstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateOutgoing(i); }),
            EstateReferrals: model.EstateReferrals && model.EstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateOutgoing(i); }),
            FileStorageProviderTypeCode: model.FileStorageProviderTypeCode,
            ImagingOrders: model.ImagingOrders && model.ImagingOrders.map(function (i) { return ImagingOrder_1.ImagingOrder_Factory.CreateOutgoing(i); }),
            IncomingPatientConnections: model.IncomingPatientConnections && model.IncomingPatientConnections.map(function (i) { return PatientConnection_1.PatientConnection_Factory.CreateOutgoing(i); }),
            InsuranceClaims: model.InsuranceClaims && model.InsuranceClaims.map(function (i) { return InsuranceClaim_1.InsuranceClaim_Factory.CreateOutgoing(i); }),
            InsurancePolicies: model.InsurancePolicies && model.InsurancePolicies.map(function (i) { return InsurancePolicy_1.InsurancePolicy_Factory.CreateOutgoing(i); }),
            IsNew: model.IsNew,
            Language: Language_1.Language_Factory.CreateOutgoing(model.Language),
            LastRecallDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastRecallDate, { ignoreTimePortion: true }),
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationAccountsReceivable: model.LocationAccountsReceivable && model.LocationAccountsReceivable.map(function (i) { return LocationAccountsReceivable_1.LocationAccountsReceivable_Factory.CreateOutgoing(i); }),
            MigrationStatus: model.MigrationStatus,
            ModelBox: model.ModelBox,
            NHSPatientDetail: NHSPatientDetail_1.NHSPatientDetail_Factory.CreateOutgoing(model.NHSPatientDetail),
            Notes: model.Notes,
            OrthoPatientTreatmentStatuses: model.OrthoPatientTreatmentStatuses && model.OrthoPatientTreatmentStatuses.map(function (i) { return OrthoPatientTreatmentStatus_1.OrthoPatientTreatmentStatus_Factory.CreateOutgoing(i); }),
            PatientConcerns: model.PatientConcerns && model.PatientConcerns.map(function (i) { return PatientConcern_1.PatientConcern_Factory.CreateOutgoing(i); }),
            PatientConnections: model.PatientConnections && model.PatientConnections.map(function (i) { return PatientConnection_1.PatientConnection_Factory.CreateOutgoing(i); }),
            PatientIdentifier: model.PatientIdentifier,
            PatientImagingIdentifiers: model.PatientImagingIdentifiers && model.PatientImagingIdentifiers.map(function (i) { return PatientImagingIdentifier_1.PatientImagingIdentifier_Factory.CreateOutgoing(i); }),
            PatientLedgerBalance: PatientLedgerBalance_1.PatientLedgerBalance_Factory.CreateOutgoing(model.PatientLedgerBalance),
            PatientNotifications: model.PatientNotifications && model.PatientNotifications.map(function (i) { return PatientNotification_1.PatientNotification_Factory.CreateOutgoing(i); }),
            PatientPaymentMethods: model.PatientPaymentMethods && model.PatientPaymentMethods.map(function (i) { return PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateOutgoing(i); }),
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(i); }),
            PatientRecallInfo: PatientRecallInfo_1.PatientRecallInfo_Factory.CreateOutgoing(model.PatientRecallInfo),
            PatientRecallPreferences: model.PatientRecallPreferences && model.PatientRecallPreferences.map(function (i) { return PatientRecallPreference_1.PatientRecallPreference_Factory.CreateOutgoing(i); }),
            PatientResponsibleParties: model.PatientResponsibleParties && model.PatientResponsibleParties.map(function (i) { return PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateOutgoing(i); }),
            PatientSearchIdentifier: model.PatientSearchIdentifier,
            PatientsMedicalAlerts: model.PatientsMedicalAlerts && model.PatientsMedicalAlerts.map(function (i) { return PatientsMedicalAlert_1.PatientsMedicalAlert_Factory.CreateOutgoing(i); }),
            PatientStatements: model.PatientStatements && model.PatientStatements.map(function (i) { return PatientStatement_1.PatientStatement_Factory.CreateOutgoing(i); }),
            PatientStatus: model.PatientStatus,
            PatientStatus1: PatientStatus_1.PatientStatus_Factory.CreateOutgoing(model.PatientStatus1),
            PatientWaitingLists: model.PatientWaitingLists && model.PatientWaitingLists.map(function (i) { return PatientWaitingList_1.PatientWaitingList_Factory.CreateOutgoing(i); }),
            Person: Person_1.Person_Factory.CreateOutgoing(model.Person),
            PersonRecordId: model.PersonRecordId,
            PreferredCultureString: model.PreferredCultureString,
            PreferredDoctor: User_1.User_Factory.CreateOutgoing(model.PreferredDoctor),
            PreferredDoctorId: model.PreferredDoctorId,
            PreferredHygienist: User_1.User_Factory.CreateOutgoing(model.PreferredHygienist),
            PreferredHygienistId: model.PreferredHygienistId,
            PreferredLocationId: model.PreferredLocationId,
            PreferredProcedureListId: model.PreferredProcedureListId,
            PreferredStatementContactType: PreferredContactMethodType_1.PreferredContactMethodType_Factory.CreateOutgoing(model.PreferredStatementContactType),
            PreferredStatementContactTypeCode: model.PreferredStatementContactTypeCode,
            PrimaryInsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateOutgoing(model.PrimaryInsurancePolicy),
            PrimaryInsurancePolicyId: model.PrimaryInsurancePolicyId,
            ProcedureList: ProcedureList_1.ProcedureList_Factory.CreateOutgoing(model.ProcedureList),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referrals: model.Referrals && model.Referrals.map(function (i) { return Referral_1.Referral_Factory.CreateOutgoing(i); }),
            Scheme: ServiceScheme_1.ServiceScheme_Factory.CreateOutgoing(model.Scheme),
            SchemeId: model.SchemeId,
            School: School_1.School_Factory.CreateOutgoing(model.School),
            SchoolId: model.SchoolId,
            SecondaryInsurancePolicy: InsurancePolicy_1.InsurancePolicy_Factory.CreateOutgoing(model.SecondaryInsurancePolicy),
            SecondaryInsurancePolicyId: model.SecondaryInsurancePolicyId,
            TransactionPendingSplits: model.TransactionPendingSplits && model.TransactionPendingSplits.map(function (i) { return TransactionPendingSplit_1.TransactionPendingSplit_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return Patient_Factory;
}());
exports.Patient_Factory = Patient_Factory;
