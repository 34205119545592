"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientsMedicalAlert_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MedicalAlertType_1 = require("./MedicalAlertType");
var PatientsMedicalAlert_Factory = /** @class */ (function () {
    function PatientsMedicalAlert_Factory() {
    }
    PatientsMedicalAlert_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            MedicalAlertType: MedicalAlertType_1.MedicalAlertType_Factory.CreateIncoming(model.MedicalAlertType),
            MedicalAlertTypeCode: model.MedicalAlertTypeCode,
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientsMedicalAlert_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            MedicalAlertType: MedicalAlertType_1.MedicalAlertType_Factory.CreateOutgoing(model.MedicalAlertType),
            MedicalAlertTypeCode: model.MedicalAlertTypeCode,
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientsMedicalAlert_Factory;
}());
exports.PatientsMedicalAlert_Factory = PatientsMedicalAlert_Factory;
