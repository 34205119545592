"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsAddressPrefill_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsAddressPrefill_Factory = /** @class */ (function () {
    function EFormsAddressPrefill_Factory() {
    }
    EFormsAddressPrefill_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AddressLine1: model.AddressLine1,
            AddressLine2: model.AddressLine2,
            AddressLine3: model.AddressLine3,
            City: model.City,
            PostalZipCode: model.PostalZipCode,
            RecordId: model.RecordId,
            StateProvinceCode: model.StateProvinceCode,
        };
        return result;
    };
    EFormsAddressPrefill_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AddressLine1: model.AddressLine1,
            AddressLine2: model.AddressLine2,
            AddressLine3: model.AddressLine3,
            City: model.City,
            PostalZipCode: model.PostalZipCode,
            RecordId: model.RecordId,
            StateProvinceCode: model.StateProvinceCode,
        };
        return result;
    };
    return EFormsAddressPrefill_Factory;
}());
exports.EFormsAddressPrefill_Factory = EFormsAddressPrefill_Factory;
