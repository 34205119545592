import axios from "axios";
import UrlAssembler from "url-assembler";

export interface IAppConfig {
  readonly name: string;
  readonly authUrl: UrlAssembler;
  readonly apiUrl: UrlAssembler;
  readonly oDataUrl: UrlAssembler;
  readonly useLocalTemplateJson: boolean;
  readonly buildVersion: string;
}

type IAppConfigJson = Record<keyof IAppConfig | "dataUrl", string> & { useLocalTemplateJson: boolean };

export class AppConfig implements IAppConfig {
  readonly name: string;
  private readonly dataUrl: UrlAssembler;
  readonly apiUrl: UrlAssembler;
  readonly oDataUrl: UrlAssembler;
  readonly authUrl: UrlAssembler;
  readonly useLocalTemplateJson: boolean;
  readonly buildVersion: string;

  constructor(json: IAppConfigJson) {
    console.assert(json.name && json.dataUrl && json.authUrl);
    this.name = json.name;
    this.authUrl = new UrlAssembler(json.authUrl);
    this.dataUrl = new UrlAssembler(json.dataUrl);
    this.apiUrl = new UrlAssembler(json.dataUrl).segment("api/");
    this.oDataUrl = new UrlAssembler(json.dataUrl).segment("odata/");
    this.useLocalTemplateJson = json.useLocalTemplateJson === true;
    this.buildVersion = json.buildVersion;
  }
}

let loadedConfig: IAppConfig;

// Load config file from "/public/config.json"
const configFileName = "config.json";
const configUrl = process.env.PUBLIC_URL + "/" + configFileName;

async function internalLoadConfig() {
  try {
    const configResponse = await axios.get<IAppConfigJson>(configUrl);
    loadedConfig = new AppConfig(configResponse.data);
    return loadedConfig;
  }
  catch (e) {
      throw new Error(`Failed to load app configuration (${configFileName})`);
  }
}


const configLoaderPromise = internalLoadConfig();
// The following isn't strictly needed -- we could just export `cachedConfigLoader` directly.
// However, making it a function makes for a better API. Ex: Instead of `loadConfig.then(...)`, caller will need to use `loadConfig().then(...)`
const loadConfig = () => configLoaderPromise;

export { loadedConfig as default, loadConfig };




