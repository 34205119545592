"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentStatusCode_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AppointmentStatusCode_Factory = /** @class */ (function () {
    function AppointmentStatusCode_Factory() {
    }
    AppointmentStatusCode_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentStatusCode1: model.AppointmentStatusCode1,
            Description: model.Description,
            InternalComment: model.InternalComment,
        };
        return result;
    };
    AppointmentStatusCode_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentStatusCode1: model.AppointmentStatusCode1,
            Description: model.Description,
            InternalComment: model.InternalComment,
        };
        return result;
    };
    return AppointmentStatusCode_Factory;
}());
exports.AppointmentStatusCode_Factory = AppointmentStatusCode_Factory;
