"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooth_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EnterpriseConsultationSubjectLink_1 = require("./EnterpriseConsultationSubjectLink");
var Tooth_Factory = /** @class */ (function () {
    function Tooth_Factory() {
    }
    Tooth_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateIncoming(i); }),
            EruptMonths: model.EruptMonths,
            EruptYears: model.EruptYears,
            ExfoliateMonths: model.ExfoliateMonths,
            ExfoliateYears: model.ExfoliateYears,
            FDIString: model.FDIString,
            FDIToothCode: model.FDIToothCode,
            IsDeciduous: model.IsDeciduous,
            IsMolar: model.IsMolar,
            IsSupernumerary: model.IsSupernumerary,
            PalmerString: model.PalmerString,
            QuadrantCode: model.QuadrantCode,
            SplitOcclusal: model.SplitOcclusal,
            ToothLocation: model.ToothLocation,
            UniversalString: model.UniversalString,
        };
        return result;
    };
    Tooth_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateOutgoing(i); }),
            EruptMonths: model.EruptMonths,
            EruptYears: model.EruptYears,
            ExfoliateMonths: model.ExfoliateMonths,
            ExfoliateYears: model.ExfoliateYears,
            FDIString: model.FDIString,
            FDIToothCode: model.FDIToothCode,
            IsDeciduous: model.IsDeciduous,
            IsMolar: model.IsMolar,
            IsSupernumerary: model.IsSupernumerary,
            PalmerString: model.PalmerString,
            QuadrantCode: model.QuadrantCode,
            SplitOcclusal: model.SplitOcclusal,
            ToothLocation: model.ToothLocation,
            UniversalString: model.UniversalString,
        };
        return result;
    };
    return Tooth_Factory;
}());
exports.Tooth_Factory = Tooth_Factory;
