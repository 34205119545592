"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientProcedure_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var Location_1 = require("./Location");
var PatientProcedureCPTModifiers_1 = require("./PatientProcedureCPTModifiers");
var EnterpriseConsultationSubjectLink_1 = require("./EnterpriseConsultationSubjectLink");
var EstateReferralAuditLog_1 = require("./EstateReferralAuditLog");
var InsuranceClaimItem_1 = require("./InsuranceClaimItem");
var MouthRegionGroup_1 = require("./MouthRegionGroup");
var PatientProcedureNote_1 = require("./PatientProcedureNote");
var Procedure_1 = require("./Procedure");
var ProcedureDiagnosisCode_1 = require("./ProcedureDiagnosisCode");
var Appointment_1 = require("./Appointment");
var Tooth_1 = require("./Tooth");
var ToothRootMaskValue_1 = require("./ToothRootMaskValue");
var ToothSurfaceMaskValue_1 = require("./ToothSurfaceMaskValue");
var Transaction_1 = require("./Transaction");
var TreatmentPlan_1 = require("./TreatmentPlan");
var PatientProcedure_Factory = /** @class */ (function () {
    function PatientProcedure_Factory() {
    }
    PatientProcedure_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CompletedByAssistantId: model.CompletedByAssistantId,
            CompletedByAssistantUser: User_1.User_Factory.CreateIncoming(model.CompletedByAssistantUser),
            CompletedByDoctorId: model.CompletedByDoctorId,
            CompletedByDoctorUser: User_1.User_Factory.CreateIncoming(model.CompletedByDoctorUser),
            CompletedByHygienistId: model.CompletedByHygienistId,
            CompletedByHygienistUser: User_1.User_Factory.CreateIncoming(model.CompletedByHygienistUser),
            CompletedInLocation: Location_1.Location_Factory.CreateIncoming(model.CompletedInLocation),
            CompletedInLocationId: model.CompletedInLocationId,
            ComputedMouthRegionDescription: model.ComputedMouthRegionDescription,
            CPTModifiers: PatientProcedureCPTModifiers_1.PatientProcedureCPTModifiers_Factory.CreateIncoming(model.CPTModifiers),
            CreationSourceCode: model.CreationSourceCode,
            DateAdded: Date_Factory_1.Date_Factory.CreateIncoming(model.DateAdded),
            DateCompleted: Date_Factory_1.Date_Factory.CreateIncoming(model.DateCompleted),
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateIncoming(i); }),
            EnterpriseLocationId: model.EnterpriseLocationId,
            EstateReferralAuditLogs: model.EstateReferralAuditLogs && model.EstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateIncoming(i); }),
            Fee: model.Fee,
            FeeOverrideNote: model.FeeOverrideNote,
            InsuranceClaimItems: model.InsuranceClaimItems && model.InsuranceClaimItems.map(function (i) { return InsuranceClaimItem_1.InsuranceClaimItem_Factory.CreateIncoming(i); }),
            IsDeclined: model.IsDeclined,
            MouthRegionGroup: MouthRegionGroup_1.MouthRegionGroup_Factory.CreateIncoming(model.MouthRegionGroup),
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            OriginalFee: model.OriginalFee,
            PatientId: model.PatientId,
            PatientProcedureGroupId: model.PatientProcedureGroupId,
            PatientProcedureNotes: model.PatientProcedureNotes && model.PatientProcedureNotes.map(function (i) { return PatientProcedureNote_1.PatientProcedureNote_Factory.CreateIncoming(i); }),
            Procedure: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure),
            ProcedureDiagnosisCodes: model.ProcedureDiagnosisCodes && model.ProcedureDiagnosisCodes.map(function (i) { return ProcedureDiagnosisCode_1.ProcedureDiagnosisCode_Factory.CreateIncoming(i); }),
            ProcedureId: model.ProcedureId,
            ProposedByUser: User_1.User_Factory.CreateIncoming(model.ProposedByUser),
            ProposedByUserId: model.ProposedByUserId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduledInAppointment: Appointment_1.Appointment_Factory.CreateIncoming(model.ScheduledInAppointment),
            ScheduledInApptId: model.ScheduledInApptId,
            SchemeSpecificDataMask: model.SchemeSpecificDataMask,
            Surfaces: model.Surfaces,
            Tooth: Tooth_1.Tooth_Factory.CreateIncoming(model.Tooth),
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothRootMaskValue: ToothRootMaskValue_1.ToothRootMaskValue_Factory.CreateIncoming(model.ToothRootMaskValue),
            ToothRootsMask: model.ToothRootsMask,
            ToothSurfaceMaskValue: ToothSurfaceMaskValue_1.ToothSurfaceMaskValue_Factory.CreateIncoming(model.ToothSurfaceMaskValue),
            ToothSurfacesMask: model.ToothSurfacesMask,
            Transaction: Transaction_1.Transaction_Factory.CreateIncoming(model.Transaction),
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateIncoming(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
            TreatmentPlanProcedureGroupIndex: model.TreatmentPlanProcedureGroupIndex,
        };
        return result;
    };
    PatientProcedure_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CompletedByAssistantId: model.CompletedByAssistantId,
            CompletedByAssistantUser: User_1.User_Factory.CreateOutgoing(model.CompletedByAssistantUser),
            CompletedByDoctorId: model.CompletedByDoctorId,
            CompletedByDoctorUser: User_1.User_Factory.CreateOutgoing(model.CompletedByDoctorUser),
            CompletedByHygienistId: model.CompletedByHygienistId,
            CompletedByHygienistUser: User_1.User_Factory.CreateOutgoing(model.CompletedByHygienistUser),
            CompletedInLocation: Location_1.Location_Factory.CreateOutgoing(model.CompletedInLocation),
            CompletedInLocationId: model.CompletedInLocationId,
            ComputedMouthRegionDescription: model.ComputedMouthRegionDescription,
            CPTModifiers: PatientProcedureCPTModifiers_1.PatientProcedureCPTModifiers_Factory.CreateOutgoing(model.CPTModifiers),
            CreationSourceCode: model.CreationSourceCode,
            DateAdded: Date_Factory_1.Date_Factory.CreateOutgoing(model.DateAdded),
            DateCompleted: Date_Factory_1.Date_Factory.CreateOutgoing(model.DateCompleted),
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateOutgoing(i); }),
            EnterpriseLocationId: model.EnterpriseLocationId,
            EstateReferralAuditLogs: model.EstateReferralAuditLogs && model.EstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateOutgoing(i); }),
            Fee: model.Fee,
            FeeOverrideNote: model.FeeOverrideNote,
            InsuranceClaimItems: model.InsuranceClaimItems && model.InsuranceClaimItems.map(function (i) { return InsuranceClaimItem_1.InsuranceClaimItem_Factory.CreateOutgoing(i); }),
            IsDeclined: model.IsDeclined,
            MouthRegionGroup: MouthRegionGroup_1.MouthRegionGroup_Factory.CreateOutgoing(model.MouthRegionGroup),
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            OriginalFee: model.OriginalFee,
            PatientId: model.PatientId,
            PatientProcedureGroupId: model.PatientProcedureGroupId,
            PatientProcedureNotes: model.PatientProcedureNotes && model.PatientProcedureNotes.map(function (i) { return PatientProcedureNote_1.PatientProcedureNote_Factory.CreateOutgoing(i); }),
            Procedure: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure),
            ProcedureDiagnosisCodes: model.ProcedureDiagnosisCodes && model.ProcedureDiagnosisCodes.map(function (i) { return ProcedureDiagnosisCode_1.ProcedureDiagnosisCode_Factory.CreateOutgoing(i); }),
            ProcedureId: model.ProcedureId,
            ProposedByUser: User_1.User_Factory.CreateOutgoing(model.ProposedByUser),
            ProposedByUserId: model.ProposedByUserId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ScheduledInAppointment: Appointment_1.Appointment_Factory.CreateOutgoing(model.ScheduledInAppointment),
            ScheduledInApptId: model.ScheduledInApptId,
            SchemeSpecificDataMask: model.SchemeSpecificDataMask,
            Surfaces: model.Surfaces,
            Tooth: Tooth_1.Tooth_Factory.CreateOutgoing(model.Tooth),
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothRootMaskValue: ToothRootMaskValue_1.ToothRootMaskValue_Factory.CreateOutgoing(model.ToothRootMaskValue),
            ToothRootsMask: model.ToothRootsMask,
            ToothSurfaceMaskValue: ToothSurfaceMaskValue_1.ToothSurfaceMaskValue_Factory.CreateOutgoing(model.ToothSurfaceMaskValue),
            ToothSurfacesMask: model.ToothSurfacesMask,
            Transaction: Transaction_1.Transaction_Factory.CreateOutgoing(model.Transaction),
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            TreatmentPlan: TreatmentPlan_1.TreatmentPlan_Factory.CreateOutgoing(model.TreatmentPlan),
            TreatmentPlanId: model.TreatmentPlanId,
            TreatmentPlanProcedureGroupIndex: model.TreatmentPlanProcedureGroupIndex,
        };
        return result;
    };
    return PatientProcedure_Factory;
}());
exports.PatientProcedure_Factory = PatientProcedure_Factory;
