"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCourseSnapshot_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClaimSubmission_1 = require("./NHSClaimSubmission");
var NHSCourseSnapshot_Factory = /** @class */ (function () {
    function NHSCourseSnapshot_Factory() {
    }
    NHSCourseSnapshot_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AcceptanceDate),
            AdviceOnlyIC: model.AdviceOnlyIC,
            ApplianceFittingDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ApplianceFittingDate),
            ArrestOfBleeding: model.ArrestOfBleeding,
            AssessmentComplete: model.AssessmentComplete,
            AssessmentComplete_Ortho: model.AssessmentComplete_Ortho,
            AssessmentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.AssessmentDate),
            AssessmentResult: model.AssessmentResult,
            BestPracticePrevention: model.BestPracticePrevention,
            BPEId: model.BPEId,
            BriefInterventionAlcohol: model.BriefInterventionAlcohol,
            BriefInterventionTobacco: model.BriefInterventionTobacco,
            CariesTreatmentOffered: model.CariesTreatmentOffered,
            ClinHealthOtherDental: model.ClinHealthOtherDental,
            ClinHealthPeriodontal: model.ClinHealthPeriodontal,
            ClinHealthToothDecay: model.ClinHealthToothDecay,
            CommissionerApproval: model.CommissionerApproval,
            CompletionDate: Date_Factory_1.Date_Factory.CreateIncoming(model.CompletionDate),
            ContinuationOfTrt: model.ContinuationOfTrt,
            ContractId: model.ContractId,
            ContractNo: model.ContractNo,
            CustomOcclusalHard: model.CustomOcclusalHard,
            CustomOcclusalSoft: model.CustomOcclusalSoft,
            DCPType: model.DCPType,
            Declaration: model.Declaration,
            DentureRepairs: model.DentureRepairs,
            DerivedActivityBand: model.DerivedActivityBand,
            DerivedChargeBand: model.DerivedChargeBand,
            DerivedNICEPeriod: model.DerivedNICEPeriod,
            DietaryChangesAgreed: model.DietaryChangesAgreed,
            DomiciliaryVisits: model.DomiciliaryVisits,
            EndAestheticIndex: model.EndAestheticIndex,
            EndDentalHealthIndex: model.EndDentalHealthIndex,
            Examination: model.Examination,
            ExaminationDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ExaminationDate),
            Extractions: model.Extractions,
            FixedLowerAppliance: model.FixedLowerAppliance,
            FixedUpperAppliance: model.FixedUpperAppliance,
            FlexibleCommissioning: model.FlexibleCommissioning,
            FluorideToothpasteAdvice: model.FluorideToothpasteAdvice,
            FTR: model.FTR,
            FunctionalAppliance: model.FunctionalAppliance,
            GDCNumber: model.GDCNumber,
            GuaranteedTrt: model.GuaranteedTrt,
            HasReferredTreatment: model.HasReferredTreatment,
            IncludeNICE: model.IncludeNICE,
            IncompleteTreatment: model.IncompleteTreatment,
            InterDentalCleaningAids: model.InterDentalCleaningAids,
            IOTNAesthetic: model.IOTNAesthetic,
            IOTNDental: model.IOTNDental,
            IssueOfPrescription: model.IssueOfPrescription,
            NHSClaimSubmissions: model.NHSClaimSubmissions && model.NHSClaimSubmissions.map(function (i) { return NHSClaimSubmission_1.NHSClaimSubmission_Factory.CreateIncoming(i); }),
            NHSCourseDetailId: model.NHSCourseDetailId,
            NHSPerformerNo: model.NHSPerformerNo,
            NICEPeriod: model.NICEPeriod,
            NumOfTeethInMouth: model.NumOfTeethInMouth,
            Observations: model.Observations,
            ObservationsNotSent: model.ObservationsNotSent,
            OralHygieneImprovementPlan: model.OralHygieneImprovementPlan,
            OrthoApplianceRepair: model.OrthoApplianceRepair,
            OrthoAssessCat: model.OrthoAssessCat,
            OrthoAssessDate: Date_Factory_1.Date_Factory.CreateIncoming(model.OrthoAssessDate),
            OrthoReferralDate: Date_Factory_1.Date_Factory.CreateIncoming(model.OrthoReferralDate),
            OrthoTreatmentFinished: model.OrthoTreatmentFinished,
            OverrideActivityBand: model.OverrideActivityBand,
            OverrideCalculatedValues: model.OverrideCalculatedValues,
            OverrideChargeBand: model.OverrideChargeBand,
            OverrideNICEInclusion: model.OverrideNICEInclusion,
            PARScoresCalculated: model.PARScoresCalculated,
            PARScoresCalculated_Ortho: model.PARScoresCalculated_Ortho,
            PatientCharge: model.PatientCharge,
            PatientPresentedWith: model.PatientPresentedWith,
            PDSPlus: model.PDSPlus,
            PerformerId: model.PerformerId,
            PerioTreatmentOffered: model.PerioTreatmentOffered,
            PhasedTreatment: model.PhasedTreatment,
            PhotographsTaken: model.PhotographsTaken,
            PIAnalgesics: model.PIAnalgesics,
            PIAntibiotic: model.PIAntibiotic,
            PIAntifungals: model.PIAntifungals,
            PIAnxiolysis: model.PIAnxiolysis,
            PIArtificialSaliva: model.PIArtificialSaliva,
            PIHighFluorideToothpaste: model.PIHighFluorideToothpaste,
            PIHygieneMouthwash: model.PIHygieneMouthwash,
            PIMedicineMouthwash: model.PIMedicineMouthwash,
            PlaqueRetentiveFactorRemoval: model.PlaqueRetentiveFactorRemoval,
            PlaqueScore: model.PlaqueScore,
            PostTreatmentPARScore: model.PostTreatmentPARScore,
            PreTreatmentPARScore: model.PreTreatmentPARScore,
            Radiographs: model.Radiographs,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referral: model.Referral,
            RefusedTreatment: model.RefusedTreatment,
            RemovableLowerAppliance: model.RemovableLowerAppliance,
            RemovableUpperAppliance: model.RemovableUpperAppliance,
            RemovalOfSutures: model.RemovalOfSutures,
            RepairOfBridges: model.RepairOfBridges,
            ReplacementAppliance: model.ReplacementAppliance,
            RetainerLower: model.RetainerLower,
            RetainerUpper: model.RetainerUpper,
            RisksFromDentalHistory: model.RisksFromDentalHistory,
            RisksFromMedicalHistory: model.RisksFromMedicalHistory,
            RisksFromSocialHistory: model.RisksFromSocialHistory,
            Sedations: model.Sedations,
            SelectedTeeth: model.SelectedTeeth,
            StartAestheticIndex: model.StartAestheticIndex,
            StartDentalHealthIndex: model.StartDentalHealthIndex,
            StudyModel: model.StudyModel,
            SubmittedNICEPeriod: model.SubmittedNICEPeriod,
            SurveyEmailAddress: model.SurveyEmailAddress,
            SurveyMobileNumber: model.SurveyMobileNumber,
            ToothbrushingAdvice: model.ToothbrushingAdvice,
            TreatmentArrangements: model.TreatmentArrangements,
            TreatmentEnd: model.TreatmentEnd,
            TreatmentEndDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TreatmentEndDate),
            TreatmentFinished: model.TreatmentFinished,
            TreatmentPlanStatus: model.TreatmentPlanStatus,
            TreatmentReferralDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TreatmentReferralDate),
            TreatmentRefusalReason: model.TreatmentRefusalReason,
            TreatmentStartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TreatmentStartDate),
            TreatmentStarted: model.TreatmentStarted,
            UntreatedDecayedTeeth: model.UntreatedDecayedTeeth,
            UserActivityBand: model.UserActivityBand,
            UserChargeBand: model.UserChargeBand,
            VirtualConsultation: model.VirtualConsultation,
        };
        return result;
    };
    NHSCourseSnapshot_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptanceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AcceptanceDate),
            AdviceOnlyIC: model.AdviceOnlyIC,
            ApplianceFittingDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ApplianceFittingDate),
            ArrestOfBleeding: model.ArrestOfBleeding,
            AssessmentComplete: model.AssessmentComplete,
            AssessmentComplete_Ortho: model.AssessmentComplete_Ortho,
            AssessmentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.AssessmentDate),
            AssessmentResult: model.AssessmentResult,
            BestPracticePrevention: model.BestPracticePrevention,
            BPEId: model.BPEId,
            BriefInterventionAlcohol: model.BriefInterventionAlcohol,
            BriefInterventionTobacco: model.BriefInterventionTobacco,
            CariesTreatmentOffered: model.CariesTreatmentOffered,
            ClinHealthOtherDental: model.ClinHealthOtherDental,
            ClinHealthPeriodontal: model.ClinHealthPeriodontal,
            ClinHealthToothDecay: model.ClinHealthToothDecay,
            CommissionerApproval: model.CommissionerApproval,
            CompletionDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.CompletionDate),
            ContinuationOfTrt: model.ContinuationOfTrt,
            ContractId: model.ContractId,
            ContractNo: model.ContractNo,
            CustomOcclusalHard: model.CustomOcclusalHard,
            CustomOcclusalSoft: model.CustomOcclusalSoft,
            DCPType: model.DCPType,
            Declaration: model.Declaration,
            DentureRepairs: model.DentureRepairs,
            DerivedActivityBand: model.DerivedActivityBand,
            DerivedChargeBand: model.DerivedChargeBand,
            DerivedNICEPeriod: model.DerivedNICEPeriod,
            DietaryChangesAgreed: model.DietaryChangesAgreed,
            DomiciliaryVisits: model.DomiciliaryVisits,
            EndAestheticIndex: model.EndAestheticIndex,
            EndDentalHealthIndex: model.EndDentalHealthIndex,
            Examination: model.Examination,
            ExaminationDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ExaminationDate),
            Extractions: model.Extractions,
            FixedLowerAppliance: model.FixedLowerAppliance,
            FixedUpperAppliance: model.FixedUpperAppliance,
            FlexibleCommissioning: model.FlexibleCommissioning,
            FluorideToothpasteAdvice: model.FluorideToothpasteAdvice,
            FTR: model.FTR,
            FunctionalAppliance: model.FunctionalAppliance,
            GDCNumber: model.GDCNumber,
            GuaranteedTrt: model.GuaranteedTrt,
            HasReferredTreatment: model.HasReferredTreatment,
            IncludeNICE: model.IncludeNICE,
            IncompleteTreatment: model.IncompleteTreatment,
            InterDentalCleaningAids: model.InterDentalCleaningAids,
            IOTNAesthetic: model.IOTNAesthetic,
            IOTNDental: model.IOTNDental,
            IssueOfPrescription: model.IssueOfPrescription,
            NHSClaimSubmissions: model.NHSClaimSubmissions && model.NHSClaimSubmissions.map(function (i) { return NHSClaimSubmission_1.NHSClaimSubmission_Factory.CreateOutgoing(i); }),
            NHSCourseDetailId: model.NHSCourseDetailId,
            NHSPerformerNo: model.NHSPerformerNo,
            NICEPeriod: model.NICEPeriod,
            NumOfTeethInMouth: model.NumOfTeethInMouth,
            Observations: model.Observations,
            ObservationsNotSent: model.ObservationsNotSent,
            OralHygieneImprovementPlan: model.OralHygieneImprovementPlan,
            OrthoApplianceRepair: model.OrthoApplianceRepair,
            OrthoAssessCat: model.OrthoAssessCat,
            OrthoAssessDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.OrthoAssessDate),
            OrthoReferralDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.OrthoReferralDate),
            OrthoTreatmentFinished: model.OrthoTreatmentFinished,
            OverrideActivityBand: model.OverrideActivityBand,
            OverrideCalculatedValues: model.OverrideCalculatedValues,
            OverrideChargeBand: model.OverrideChargeBand,
            OverrideNICEInclusion: model.OverrideNICEInclusion,
            PARScoresCalculated: model.PARScoresCalculated,
            PARScoresCalculated_Ortho: model.PARScoresCalculated_Ortho,
            PatientCharge: model.PatientCharge,
            PatientPresentedWith: model.PatientPresentedWith,
            PDSPlus: model.PDSPlus,
            PerformerId: model.PerformerId,
            PerioTreatmentOffered: model.PerioTreatmentOffered,
            PhasedTreatment: model.PhasedTreatment,
            PhotographsTaken: model.PhotographsTaken,
            PIAnalgesics: model.PIAnalgesics,
            PIAntibiotic: model.PIAntibiotic,
            PIAntifungals: model.PIAntifungals,
            PIAnxiolysis: model.PIAnxiolysis,
            PIArtificialSaliva: model.PIArtificialSaliva,
            PIHighFluorideToothpaste: model.PIHighFluorideToothpaste,
            PIHygieneMouthwash: model.PIHygieneMouthwash,
            PIMedicineMouthwash: model.PIMedicineMouthwash,
            PlaqueRetentiveFactorRemoval: model.PlaqueRetentiveFactorRemoval,
            PlaqueScore: model.PlaqueScore,
            PostTreatmentPARScore: model.PostTreatmentPARScore,
            PreTreatmentPARScore: model.PreTreatmentPARScore,
            Radiographs: model.Radiographs,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Referral: model.Referral,
            RefusedTreatment: model.RefusedTreatment,
            RemovableLowerAppliance: model.RemovableLowerAppliance,
            RemovableUpperAppliance: model.RemovableUpperAppliance,
            RemovalOfSutures: model.RemovalOfSutures,
            RepairOfBridges: model.RepairOfBridges,
            ReplacementAppliance: model.ReplacementAppliance,
            RetainerLower: model.RetainerLower,
            RetainerUpper: model.RetainerUpper,
            RisksFromDentalHistory: model.RisksFromDentalHistory,
            RisksFromMedicalHistory: model.RisksFromMedicalHistory,
            RisksFromSocialHistory: model.RisksFromSocialHistory,
            Sedations: model.Sedations,
            SelectedTeeth: model.SelectedTeeth,
            StartAestheticIndex: model.StartAestheticIndex,
            StartDentalHealthIndex: model.StartDentalHealthIndex,
            StudyModel: model.StudyModel,
            SubmittedNICEPeriod: model.SubmittedNICEPeriod,
            SurveyEmailAddress: model.SurveyEmailAddress,
            SurveyMobileNumber: model.SurveyMobileNumber,
            ToothbrushingAdvice: model.ToothbrushingAdvice,
            TreatmentArrangements: model.TreatmentArrangements,
            TreatmentEnd: model.TreatmentEnd,
            TreatmentEndDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TreatmentEndDate),
            TreatmentFinished: model.TreatmentFinished,
            TreatmentPlanStatus: model.TreatmentPlanStatus,
            TreatmentReferralDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TreatmentReferralDate),
            TreatmentRefusalReason: model.TreatmentRefusalReason,
            TreatmentStartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TreatmentStartDate),
            TreatmentStarted: model.TreatmentStarted,
            UntreatedDecayedTeeth: model.UntreatedDecayedTeeth,
            UserActivityBand: model.UserActivityBand,
            UserChargeBand: model.UserChargeBand,
            VirtualConsultation: model.VirtualConsultation,
        };
        return result;
    };
    return NHSCourseSnapshot_Factory;
}());
exports.NHSCourseSnapshot_Factory = NHSCourseSnapshot_Factory;
