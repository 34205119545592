"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockBookingTemplate_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BlockBookingTemplateDate_1 = require("./BlockBookingTemplateDate");
var BookingTemplateBlock_1 = require("./BookingTemplateBlock");
var BlockBookingTemplate_Factory = /** @class */ (function () {
    function BlockBookingTemplate_Factory() {
    }
    BlockBookingTemplate_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BlockBookingTemplateDates: model.BlockBookingTemplateDates && model.BlockBookingTemplateDates.map(function (i) { return BlockBookingTemplateDate_1.BlockBookingTemplateDate_Factory.CreateIncoming(i); }),
            BookingTemplateBlocks: model.BookingTemplateBlocks && model.BookingTemplateBlocks.map(function (i) { return BookingTemplateBlock_1.BookingTemplateBlock_Factory.CreateIncoming(i); }),
            Description: model.Description,
            LocationId: model.LocationId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    BlockBookingTemplate_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BlockBookingTemplateDates: model.BlockBookingTemplateDates && model.BlockBookingTemplateDates.map(function (i) { return BlockBookingTemplateDate_1.BlockBookingTemplateDate_Factory.CreateOutgoing(i); }),
            BookingTemplateBlocks: model.BookingTemplateBlocks && model.BookingTemplateBlocks.map(function (i) { return BookingTemplateBlock_1.BookingTemplateBlock_Factory.CreateOutgoing(i); }),
            Description: model.Description,
            LocationId: model.LocationId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return BlockBookingTemplate_Factory;
}());
exports.BlockBookingTemplate_Factory = BlockBookingTemplate_Factory;
