"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstateReferralAdditionalProcedure_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EstateReferral_1 = require("./EstateReferral");
var Procedure_1 = require("./Procedure");
var EstateReferralAdditionalProcedure_Factory = /** @class */ (function () {
    function EstateReferralAdditionalProcedure_Factory() {
    }
    EstateReferralAdditionalProcedure_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EstateReferral: EstateReferral_1.EstateReferral_Factory.CreateIncoming(model.EstateReferral),
            EstateReferralId: model.EstateReferralId,
            Procedure: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure),
            ProcedureId: model.ProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    EstateReferralAdditionalProcedure_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EstateReferral: EstateReferral_1.EstateReferral_Factory.CreateOutgoing(model.EstateReferral),
            EstateReferralId: model.EstateReferralId,
            Procedure: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure),
            ProcedureId: model.ProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return EstateReferralAdditionalProcedure_Factory;
}());
exports.EstateReferralAdditionalProcedure_Factory = EstateReferralAdditionalProcedure_Factory;
