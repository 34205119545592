"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCategory_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCategoryBand_1 = require("./NHSCategoryBand");
var Procedure_1 = require("./Procedure");
var NHSCategory_Factory = /** @class */ (function () {
    function NHSCategory_Factory() {
    }
    NHSCategory_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Arthur: model.Arthur,
            CategoryDescription: model.CategoryDescription,
            DataSetID1: model.DataSetID1,
            DataSetID2: model.DataSetID2,
            DataSetID3: model.DataSetID3,
            DataSetID4: model.DataSetID4,
            DataSetSelectCode: model.DataSetSelectCode,
            InternalNotes: model.InternalNotes,
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateIncoming(i); }),
            NHSCategoryID: model.NHSCategoryID,
            Ortho: model.Ortho,
            Procedures: model.Procedures && model.Procedures.map(function (i) { return Procedure_1.Procedure_Factory.CreateIncoming(i); }),
            R4: model.R4,
            Title: model.Title,
        };
        return result;
    };
    NHSCategory_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Arthur: model.Arthur,
            CategoryDescription: model.CategoryDescription,
            DataSetID1: model.DataSetID1,
            DataSetID2: model.DataSetID2,
            DataSetID3: model.DataSetID3,
            DataSetID4: model.DataSetID4,
            DataSetSelectCode: model.DataSetSelectCode,
            InternalNotes: model.InternalNotes,
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateOutgoing(i); }),
            NHSCategoryID: model.NHSCategoryID,
            Ortho: model.Ortho,
            Procedures: model.Procedures && model.Procedures.map(function (i) { return Procedure_1.Procedure_Factory.CreateOutgoing(i); }),
            R4: model.R4,
            Title: model.Title,
        };
        return result;
    };
    return NHSCategory_Factory;
}());
exports.NHSCategory_Factory = NHSCategory_Factory;
