"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HISIntegrationQueryHistory_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var HISIntegrationQueryHistory_Factory = /** @class */ (function () {
    function HISIntegrationQueryHistory_Factory() {
    }
    HISIntegrationQueryHistory_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            HisResponse: model.HisResponse,
            MessageType: model.MessageType,
            PatientNationalId: model.PatientNationalId,
            QueryId: model.QueryId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RequestedAppointmentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.RequestedAppointmentDate),
            RequestedAppointmentDuration: model.RequestedAppointmentDuration,
        };
        return result;
    };
    HISIntegrationQueryHistory_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            HisResponse: model.HisResponse,
            MessageType: model.MessageType,
            PatientNationalId: model.PatientNationalId,
            QueryId: model.QueryId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RequestedAppointmentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.RequestedAppointmentDate),
            RequestedAppointmentDuration: model.RequestedAppointmentDuration,
        };
        return result;
    };
    return HISIntegrationQueryHistory_Factory;
}());
exports.HISIntegrationQueryHistory_Factory = HISIntegrationQueryHistory_Factory;
