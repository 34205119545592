"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bracket_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BracketType_1 = require("./BracketType");
var Tooth_1 = require("./Tooth");
var Bracket_Factory = /** @class */ (function () {
    function Bracket_Factory() {
    }
    Bracket_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BracketType: BracketType_1.BracketType_Factory.CreateIncoming(model.BracketType),
            BracketTypeId: model.BracketTypeId,
            EndDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EndDate),
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
            ToothCodeEnd: model.ToothCodeEnd,
            ToothCodeStart: model.ToothCodeStart,
            ToothEnd: Tooth_1.Tooth_Factory.CreateIncoming(model.ToothEnd),
            ToothStart: Tooth_1.Tooth_Factory.CreateIncoming(model.ToothStart),
        };
        return result;
    };
    Bracket_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BracketType: BracketType_1.BracketType_Factory.CreateOutgoing(model.BracketType),
            BracketTypeId: model.BracketTypeId,
            EndDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EndDate),
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
            ToothCodeEnd: model.ToothCodeEnd,
            ToothCodeStart: model.ToothCodeStart,
            ToothEnd: Tooth_1.Tooth_Factory.CreateOutgoing(model.ToothEnd),
            ToothStart: Tooth_1.Tooth_Factory.CreateOutgoing(model.ToothStart),
        };
        return result;
    };
    return Bracket_Factory;
}());
exports.Bracket_Factory = Bracket_Factory;
