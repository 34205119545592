"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrespondenceTemplate_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CorrespondenceTemplateAddresseeType_1 = require("./CorrespondenceTemplateAddresseeType");
var CorrespondenceTemplateCategory_1 = require("./CorrespondenceTemplateCategory");
var CorrespondenceTemplateContent_1 = require("./CorrespondenceTemplateContent");
var CorrespondenceTemplateFormatType_1 = require("./CorrespondenceTemplateFormatType");
var RecordStatus_1 = require("./RecordStatus");
var CorrespondenceTemplate_Factory = /** @class */ (function () {
    function CorrespondenceTemplate_Factory() {
    }
    CorrespondenceTemplate_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AddresseeTypeId: model.AddresseeTypeId,
            CategoryId: model.CategoryId,
            CorrespondenceTemplateAddresseeType: CorrespondenceTemplateAddresseeType_1.CorrespondenceTemplateAddresseeType_Factory.CreateIncoming(model.CorrespondenceTemplateAddresseeType),
            CorrespondenceTemplateCategory: CorrespondenceTemplateCategory_1.CorrespondenceTemplateCategory_Factory.CreateIncoming(model.CorrespondenceTemplateCategory),
            CorrespondenceTemplateContents: model.CorrespondenceTemplateContents && model.CorrespondenceTemplateContents.map(function (i) { return CorrespondenceTemplateContent_1.CorrespondenceTemplateContent_Factory.CreateIncoming(i); }),
            CorrespondenceTemplateFormatType: CorrespondenceTemplateFormatType_1.CorrespondenceTemplateFormatType_Factory.CreateIncoming(model.CorrespondenceTemplateFormatType),
            FormatId: model.FormatId,
            IsClinicalEvent: model.IsClinicalEvent,
            IsFrozen: model.IsFrozen,
            IsSystemTemplate: model.IsSystemTemplate,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
        };
        return result;
    };
    CorrespondenceTemplate_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AddresseeTypeId: model.AddresseeTypeId,
            CategoryId: model.CategoryId,
            CorrespondenceTemplateAddresseeType: CorrespondenceTemplateAddresseeType_1.CorrespondenceTemplateAddresseeType_Factory.CreateOutgoing(model.CorrespondenceTemplateAddresseeType),
            CorrespondenceTemplateCategory: CorrespondenceTemplateCategory_1.CorrespondenceTemplateCategory_Factory.CreateOutgoing(model.CorrespondenceTemplateCategory),
            CorrespondenceTemplateContents: model.CorrespondenceTemplateContents && model.CorrespondenceTemplateContents.map(function (i) { return CorrespondenceTemplateContent_1.CorrespondenceTemplateContent_Factory.CreateOutgoing(i); }),
            CorrespondenceTemplateFormatType: CorrespondenceTemplateFormatType_1.CorrespondenceTemplateFormatType_Factory.CreateOutgoing(model.CorrespondenceTemplateFormatType),
            FormatId: model.FormatId,
            IsClinicalEvent: model.IsClinicalEvent,
            IsFrozen: model.IsFrozen,
            IsSystemTemplate: model.IsSystemTemplate,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
        };
        return result;
    };
    return CorrespondenceTemplate_Factory;
}());
exports.CorrespondenceTemplate_Factory = CorrespondenceTemplate_Factory;
