"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrespondenceLetterPurposeType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CorrespondenceLetterPurposeType_Factory = /** @class */ (function () {
    function CorrespondenceLetterPurposeType_Factory() {
    }
    CorrespondenceLetterPurposeType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CorrespondenceLetterPurposeType1: model.CorrespondenceLetterPurposeType1,
            Description: model.Description,
        };
        return result;
    };
    CorrespondenceLetterPurposeType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CorrespondenceLetterPurposeType1: model.CorrespondenceLetterPurposeType1,
            Description: model.Description,
        };
        return result;
    };
    return CorrespondenceLetterPurposeType_Factory;
}());
exports.CorrespondenceLetterPurposeType_Factory = CorrespondenceLetterPurposeType_Factory;
