"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrespondenceLetter_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CorrespondenceLetterPurposeType_1 = require("./CorrespondenceLetterPurposeType");
var CorrespondenceTemplate_1 = require("./CorrespondenceTemplate");
var CorrespondenceTemplateCategory_1 = require("./CorrespondenceTemplateCategory");
var CorrespondenceTemplateFormatType_1 = require("./CorrespondenceTemplateFormatType");
var Note_1 = require("./Note");
var RecordStatus_1 = require("./RecordStatus");
var User_1 = require("./User");
var CorrespondenceLetter_Factory = /** @class */ (function () {
    function CorrespondenceLetter_Factory() {
    }
    CorrespondenceLetter_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AddresseePersonId: model.AddresseePersonId,
            CorrespondenceLetterPurposeType: CorrespondenceLetterPurposeType_1.CorrespondenceLetterPurposeType_Factory.CreateIncoming(model.CorrespondenceLetterPurposeType),
            CorrespondenceTemplate: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateIncoming(model.CorrespondenceTemplate),
            CorrespondenceTemplateCategory: CorrespondenceTemplateCategory_1.CorrespondenceTemplateCategory_Factory.CreateIncoming(model.CorrespondenceTemplateCategory),
            CorrespondenceTemplateFormatType: CorrespondenceTemplateFormatType_1.CorrespondenceTemplateFormatType_Factory.CreateIncoming(model.CorrespondenceTemplateFormatType),
            EmailSubject: model.EmailSubject,
            FileMetaData: model.FileMetaData,
            IsClinicalEvent: model.IsClinicalEvent,
            LetterPurposeTypeId: model.LetterPurposeTypeId,
            Notes: model.Notes && model.Notes.map(function (i) { return Note_1.Note_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            PrintedByUserId: model.PrintedByUserId,
            PrintedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.PrintedDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateIncoming(model.RecordStatus1),
            TemplateCategoryIdWhenPrinted: model.TemplateCategoryIdWhenPrinted,
            TemplateCategoryNameWhenPrinted: model.TemplateCategoryNameWhenPrinted,
            TemplateContent: model.TemplateContent,
            TemplateFormatTypeIdWhenPrinted: model.TemplateFormatTypeIdWhenPrinted,
            TemplateId: model.TemplateId,
            TemplateNameWhenPrinted: model.TemplateNameWhenPrinted,
            User: User_1.User_Factory.CreateIncoming(model.User),
        };
        return result;
    };
    CorrespondenceLetter_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AddresseePersonId: model.AddresseePersonId,
            CorrespondenceLetterPurposeType: CorrespondenceLetterPurposeType_1.CorrespondenceLetterPurposeType_Factory.CreateOutgoing(model.CorrespondenceLetterPurposeType),
            CorrespondenceTemplate: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateOutgoing(model.CorrespondenceTemplate),
            CorrespondenceTemplateCategory: CorrespondenceTemplateCategory_1.CorrespondenceTemplateCategory_Factory.CreateOutgoing(model.CorrespondenceTemplateCategory),
            CorrespondenceTemplateFormatType: CorrespondenceTemplateFormatType_1.CorrespondenceTemplateFormatType_Factory.CreateOutgoing(model.CorrespondenceTemplateFormatType),
            EmailSubject: model.EmailSubject,
            FileMetaData: model.FileMetaData,
            IsClinicalEvent: model.IsClinicalEvent,
            LetterPurposeTypeId: model.LetterPurposeTypeId,
            Notes: model.Notes && model.Notes.map(function (i) { return Note_1.Note_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            PrintedByUserId: model.PrintedByUserId,
            PrintedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.PrintedDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordStatus1: RecordStatus_1.RecordStatus_Factory.CreateOutgoing(model.RecordStatus1),
            TemplateCategoryIdWhenPrinted: model.TemplateCategoryIdWhenPrinted,
            TemplateCategoryNameWhenPrinted: model.TemplateCategoryNameWhenPrinted,
            TemplateContent: model.TemplateContent,
            TemplateFormatTypeIdWhenPrinted: model.TemplateFormatTypeIdWhenPrinted,
            TemplateId: model.TemplateId,
            TemplateNameWhenPrinted: model.TemplateNameWhenPrinted,
            User: User_1.User_Factory.CreateOutgoing(model.User),
        };
        return result;
    };
    return CorrespondenceLetter_Factory;
}());
exports.CorrespondenceLetter_Factory = CorrespondenceLetter_Factory;
