"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaimItem_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceClaim_1 = require("./InsuranceClaim");
var InsuranceClaimItemAttachmentRequirement_1 = require("./InsuranceClaimItemAttachmentRequirement");
var MedicalClaimItemAdditionalInfo_1 = require("./MedicalClaimItemAdditionalInfo");
var PatientProcedure_1 = require("./PatientProcedure");
var InsuranceClaimItem_Factory = /** @class */ (function () {
    function InsuranceClaimItem_Factory() {
    }
    InsuranceClaimItem_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            InsuranceClaim: InsuranceClaim_1.InsuranceClaim_Factory.CreateIncoming(model.InsuranceClaim),
            InsuranceClaimID: model.InsuranceClaimID,
            InsuranceClaimItemAttachmentRequirement: InsuranceClaimItemAttachmentRequirement_1.InsuranceClaimItemAttachmentRequirement_Factory.CreateIncoming(model.InsuranceClaimItemAttachmentRequirement),
            MedicalClaimItemAdditionalInfo: MedicalClaimItemAdditionalInfo_1.MedicalClaimItemAdditionalInfo_Factory.CreateIncoming(model.MedicalClaimItemAdditionalInfo),
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    InsuranceClaimItem_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            InsuranceClaim: InsuranceClaim_1.InsuranceClaim_Factory.CreateOutgoing(model.InsuranceClaim),
            InsuranceClaimID: model.InsuranceClaimID,
            InsuranceClaimItemAttachmentRequirement: InsuranceClaimItemAttachmentRequirement_1.InsuranceClaimItemAttachmentRequirement_Factory.CreateOutgoing(model.InsuranceClaimItemAttachmentRequirement),
            MedicalClaimItemAdditionalInfo: MedicalClaimItemAdditionalInfo_1.MedicalClaimItemAdditionalInfo_Factory.CreateOutgoing(model.MedicalClaimItemAdditionalInfo),
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return InsuranceClaimItem_Factory;
}());
exports.InsuranceClaimItem_Factory = InsuranceClaimItem_Factory;
