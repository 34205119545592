"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportColumn_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReportTypeCategoryColumn_1 = require("./ReportTypeCategoryColumn");
var ReportColumn_Factory = /** @class */ (function () {
    function ReportColumn_Factory() {
    }
    ReportColumn_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            ColumnDataTypeCode: model.ColumnDataTypeCode,
            ColumnFilterTypeCode: model.ColumnFilterTypeCode,
            Description: model.Description,
            PixelWidth: model.PixelWidth,
            ReportTypeCategoryColumns: model.ReportTypeCategoryColumns && model.ReportTypeCategoryColumns.map(function (i) { return ReportTypeCategoryColumn_1.ReportTypeCategoryColumn_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    ReportColumn_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            ColumnDataTypeCode: model.ColumnDataTypeCode,
            ColumnFilterTypeCode: model.ColumnFilterTypeCode,
            Description: model.Description,
            PixelWidth: model.PixelWidth,
            ReportTypeCategoryColumns: model.ReportTypeCategoryColumns && model.ReportTypeCategoryColumns.map(function (i) { return ReportTypeCategoryColumn_1.ReportTypeCategoryColumn_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return ReportColumn_Factory;
}());
exports.ReportColumn_Factory = ReportColumn_Factory;
