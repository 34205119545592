"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalQuestionnaire_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MedicalQuestionnaireQuestionGroup_1 = require("./MedicalQuestionnaireQuestionGroup");
var MedicalQuestionnaireQuestion_1 = require("./MedicalQuestionnaireQuestion");
var User_1 = require("./User");
var MedicalQuestionnaire_Factory = /** @class */ (function () {
    function MedicalQuestionnaire_Factory() {
    }
    MedicalQuestionnaire_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            LegacyKey: model.LegacyKey,
            MedicalQuestionnaireQuestionGroups: model.MedicalQuestionnaireQuestionGroups && model.MedicalQuestionnaireQuestionGroups.map(function (i) { return MedicalQuestionnaireQuestionGroup_1.MedicalQuestionnaireQuestionGroup_Factory.CreateIncoming(i); }),
            MedicalQuestionnaireQuestions: model.MedicalQuestionnaireQuestions && model.MedicalQuestionnaireQuestions.map(function (i) { return MedicalQuestionnaireQuestion_1.MedicalQuestionnaireQuestion_Factory.CreateIncoming(i); }),
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    MedicalQuestionnaire_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            LegacyKey: model.LegacyKey,
            MedicalQuestionnaireQuestionGroups: model.MedicalQuestionnaireQuestionGroups && model.MedicalQuestionnaireQuestionGroups.map(function (i) { return MedicalQuestionnaireQuestionGroup_1.MedicalQuestionnaireQuestionGroup_Factory.CreateOutgoing(i); }),
            MedicalQuestionnaireQuestions: model.MedicalQuestionnaireQuestions && model.MedicalQuestionnaireQuestions.map(function (i) { return MedicalQuestionnaireQuestion_1.MedicalQuestionnaireQuestion_Factory.CreateOutgoing(i); }),
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return MedicalQuestionnaire_Factory;
}());
exports.MedicalQuestionnaire_Factory = MedicalQuestionnaire_Factory;
