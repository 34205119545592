"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalEventConditionCode_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEventConditionCode_Factory = /** @class */ (function () {
    function ClinicalEventConditionCode_Factory() {
    }
    ClinicalEventConditionCode_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppliesToCrown: model.AppliesToCrown,
            AppliesToRoots: model.AppliesToRoots,
            AppliesToSurfaces: model.AppliesToSurfaces,
            AppliesToWholeTooth: model.AppliesToWholeTooth,
            ConditionCode: model.ConditionCode,
            Description: model.Description,
            NoteDescription: model.NoteDescription,
            RequiresMaterial: model.RequiresMaterial,
        };
        return result;
    };
    ClinicalEventConditionCode_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppliesToCrown: model.AppliesToCrown,
            AppliesToRoots: model.AppliesToRoots,
            AppliesToSurfaces: model.AppliesToSurfaces,
            AppliesToWholeTooth: model.AppliesToWholeTooth,
            ConditionCode: model.ConditionCode,
            Description: model.Description,
            NoteDescription: model.NoteDescription,
            RequiresMaterial: model.RequiresMaterial,
        };
        return result;
    };
    return ClinicalEventConditionCode_Factory;
}());
exports.ClinicalEventConditionCode_Factory = ClinicalEventConditionCode_Factory;
