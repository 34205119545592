"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnterpriseLocation_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EnterpriseConsultation_1 = require("./EnterpriseConsultation");
var EstateReferralAuditLog_1 = require("./EstateReferralAuditLog");
var EstateReferral_1 = require("./EstateReferral");
var EnterpriseLocation_Factory = /** @class */ (function () {
    function EnterpriseLocation_Factory() {
    }
    EnterpriseLocation_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Active: model.Active,
            EnterpriseLocationId: model.EnterpriseLocationId,
            FromEnterpriseConsultations: model.FromEnterpriseConsultations && model.FromEnterpriseConsultations.map(function (i) { return EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateIncoming(i); }),
            FromEstateReferralAuditLogs: model.FromEstateReferralAuditLogs && model.FromEstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateIncoming(i); }),
            FromEstateReferrals: model.FromEstateReferrals && model.FromEstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateIncoming(i); }),
            IsThisLocation: model.IsThisLocation,
            LocationName: model.LocationName,
            ToEnterpriseConsultations: model.ToEnterpriseConsultations && model.ToEnterpriseConsultations.map(function (i) { return EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateIncoming(i); }),
            ToEstateReferralAuditLogs: model.ToEstateReferralAuditLogs && model.ToEstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateIncoming(i); }),
            ToEstateReferrals: model.ToEstateReferrals && model.ToEstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    EnterpriseLocation_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Active: model.Active,
            EnterpriseLocationId: model.EnterpriseLocationId,
            FromEnterpriseConsultations: model.FromEnterpriseConsultations && model.FromEnterpriseConsultations.map(function (i) { return EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateOutgoing(i); }),
            FromEstateReferralAuditLogs: model.FromEstateReferralAuditLogs && model.FromEstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateOutgoing(i); }),
            FromEstateReferrals: model.FromEstateReferrals && model.FromEstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateOutgoing(i); }),
            IsThisLocation: model.IsThisLocation,
            LocationName: model.LocationName,
            ToEnterpriseConsultations: model.ToEnterpriseConsultations && model.ToEnterpriseConsultations.map(function (i) { return EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateOutgoing(i); }),
            ToEstateReferralAuditLogs: model.ToEstateReferralAuditLogs && model.ToEstateReferralAuditLogs.map(function (i) { return EstateReferralAuditLog_1.EstateReferralAuditLog_Factory.CreateOutgoing(i); }),
            ToEstateReferrals: model.ToEstateReferrals && model.ToEstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return EnterpriseLocation_Factory;
}());
exports.EnterpriseLocation_Factory = EnterpriseLocation_Factory;
