"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsAttachmentType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsAttachment_1 = require("./EFormsAttachment");
var EFormsAttachmentType_Factory = /** @class */ (function () {
    function EFormsAttachmentType_Factory() {
    }
    EFormsAttachmentType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Attachments: model.Attachments && model.Attachments.map(function (i) { return EFormsAttachment_1.EFormsAttachment_Factory.CreateIncoming(i); }),
            AttachmentTypeId: model.AttachmentTypeId,
            Description: model.Description,
        };
        return result;
    };
    EFormsAttachmentType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Attachments: model.Attachments && model.Attachments.map(function (i) { return EFormsAttachment_1.EFormsAttachment_Factory.CreateOutgoing(i); }),
            AttachmentTypeId: model.AttachmentTypeId,
            Description: model.Description,
        };
        return result;
    };
    return EFormsAttachmentType_Factory;
}());
exports.EFormsAttachmentType_Factory = EFormsAttachmentType_Factory;
