"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkStatementBatchGuarantor_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BulkStatementBatch_1 = require("./BulkStatementBatch");
var BulkStatementBatchGuarantorPatient_1 = require("./BulkStatementBatchGuarantorPatient");
var Person_1 = require("./Person");
var BulkStatementBatchGuarantor_Factory = /** @class */ (function () {
    function BulkStatementBatchGuarantor_Factory() {
    }
    BulkStatementBatchGuarantor_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BatchId: model.BatchId,
            BulkStatementBatch: BulkStatementBatch_1.BulkStatementBatch_Factory.CreateIncoming(model.BulkStatementBatch),
            BulkStatementBatchGuarantorPatients: model.BulkStatementBatchGuarantorPatients && model.BulkStatementBatchGuarantorPatients.map(function (i) { return BulkStatementBatchGuarantorPatient_1.BulkStatementBatchGuarantorPatient_Factory.CreateIncoming(i); }),
            Excluded: model.Excluded,
            Notes: model.Notes,
            Person: Person_1.Person_Factory.CreateIncoming(model.Person),
            PersonRecordId: model.PersonRecordId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    BulkStatementBatchGuarantor_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BatchId: model.BatchId,
            BulkStatementBatch: BulkStatementBatch_1.BulkStatementBatch_Factory.CreateOutgoing(model.BulkStatementBatch),
            BulkStatementBatchGuarantorPatients: model.BulkStatementBatchGuarantorPatients && model.BulkStatementBatchGuarantorPatients.map(function (i) { return BulkStatementBatchGuarantorPatient_1.BulkStatementBatchGuarantorPatient_Factory.CreateOutgoing(i); }),
            Excluded: model.Excluded,
            Notes: model.Notes,
            Person: Person_1.Person_Factory.CreateOutgoing(model.Person),
            PersonRecordId: model.PersonRecordId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return BulkStatementBatchGuarantor_Factory;
}());
exports.BulkStatementBatchGuarantor_Factory = BulkStatementBatchGuarantor_Factory;
