"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingTemplateBlock_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AppointmentType_1 = require("./AppointmentType");
var BookingTemplateBlock_Factory = /** @class */ (function () {
    function BookingTemplateBlock_Factory() {
    }
    BookingTemplateBlock_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateIncoming(model.AppointmentType),
            AppointmentTypeId: model.AppointmentTypeId,
            BlockBookingTemplateId: model.BlockBookingTemplateId,
            ChairId: model.ChairId,
            DurationInMinutes: model.DurationInMinutes,
            RecordId: model.RecordId,
            StartTime: Date_Factory_1.Date_Factory.CreateIncoming(model.StartTime),
        };
        return result;
    };
    BookingTemplateBlock_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateOutgoing(model.AppointmentType),
            AppointmentTypeId: model.AppointmentTypeId,
            BlockBookingTemplateId: model.BlockBookingTemplateId,
            ChairId: model.ChairId,
            DurationInMinutes: model.DurationInMinutes,
            RecordId: model.RecordId,
            StartTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartTime),
        };
        return result;
    };
    return BookingTemplateBlock_Factory;
}());
exports.BookingTemplateBlock_Factory = BookingTemplateBlock_Factory;
