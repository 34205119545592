"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientWaitingListStatusHistory_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientWaitingList_1 = require("./PatientWaitingList");
var PatientWaitingListStatusHistory_Factory = /** @class */ (function () {
    function PatientWaitingListStatusHistory_Factory() {
    }
    PatientWaitingListStatusHistory_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            PatientWaitingList: PatientWaitingList_1.PatientWaitingList_Factory.CreateIncoming(model.PatientWaitingList),
            PatientWaitingListId: model.PatientWaitingListId,
            PatientWaitingListStatus: model.PatientWaitingListStatus,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientWaitingListStatusHistory_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            PatientWaitingList: PatientWaitingList_1.PatientWaitingList_Factory.CreateOutgoing(model.PatientWaitingList),
            PatientWaitingListId: model.PatientWaitingListId,
            PatientWaitingListStatus: model.PatientWaitingListStatus,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientWaitingListStatusHistory_Factory;
}());
exports.PatientWaitingListStatusHistory_Factory = PatientWaitingListStatusHistory_Factory;
