"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagingOrder_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEvent_1 = require("./ClinicalEvent");
var Patient_1 = require("./Patient");
var Procedure_1 = require("./Procedure");
var User_1 = require("./User");
var ImagingOrder_Factory = /** @class */ (function () {
    function ImagingOrder_Factory() {
    }
    ImagingOrder_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AnatomyType: model.AnatomyType,
            ArchType: model.ArchType,
            BitewingSubType: model.BitewingSubType,
            BitewingType: model.BitewingType,
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            Description: model.Description,
            ExtraOralSubType: model.ExtraOralSubType,
            ExtraOralType: model.ExtraOralType,
            FOV: model.FOV,
            ImagingId: model.ImagingId,
            ImagingType: model.ImagingType,
            LocationId: model.LocationId,
            MAR: model.MAR,
            ModalityCode: model.ModalityCode,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            Procedure: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure),
            ProcedureId: model.ProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Region: model.Region,
            Resolution: model.Resolution,
            SentToEnterpriseLocationId: model.SentToEnterpriseLocationId,
            TMJType: model.TMJType,
            ToothSelection: model.ToothSelection,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    ImagingOrder_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AnatomyType: model.AnatomyType,
            ArchType: model.ArchType,
            BitewingSubType: model.BitewingSubType,
            BitewingType: model.BitewingType,
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            Description: model.Description,
            ExtraOralSubType: model.ExtraOralSubType,
            ExtraOralType: model.ExtraOralType,
            FOV: model.FOV,
            ImagingId: model.ImagingId,
            ImagingType: model.ImagingType,
            LocationId: model.LocationId,
            MAR: model.MAR,
            ModalityCode: model.ModalityCode,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            Procedure: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure),
            ProcedureId: model.ProcedureId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Region: model.Region,
            Resolution: model.Resolution,
            SentToEnterpriseLocationId: model.SentToEnterpriseLocationId,
            TMJType: model.TMJType,
            ToothSelection: model.ToothSelection,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return ImagingOrder_Factory;
}());
exports.ImagingOrder_Factory = ImagingOrder_Factory;
