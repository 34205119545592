"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSChargeBandValue_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSChargeBand_1 = require("./NHSChargeBand");
var NHSChargeValueSet_1 = require("./NHSChargeValueSet");
var NHSChargeBandValue_Factory = /** @class */ (function () {
    function NHSChargeBandValue_Factory() {
    }
    NHSChargeBandValue_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Charge: model.Charge,
            NHSChargeBand: NHSChargeBand_1.NHSChargeBand_Factory.CreateIncoming(model.NHSChargeBand),
            NHSChargeBandID: model.NHSChargeBandID,
            NHSChargeBandValueID: model.NHSChargeBandValueID,
            NHSChargeValueSet: NHSChargeValueSet_1.NHSChargeValueSet_Factory.CreateIncoming(model.NHSChargeValueSet),
            NHSChargeValueSetID: model.NHSChargeValueSetID,
        };
        return result;
    };
    NHSChargeBandValue_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Charge: model.Charge,
            NHSChargeBand: NHSChargeBand_1.NHSChargeBand_Factory.CreateOutgoing(model.NHSChargeBand),
            NHSChargeBandID: model.NHSChargeBandID,
            NHSChargeBandValueID: model.NHSChargeBandValueID,
            NHSChargeValueSet: NHSChargeValueSet_1.NHSChargeValueSet_Factory.CreateOutgoing(model.NHSChargeValueSet),
            NHSChargeValueSetID: model.NHSChargeValueSetID,
        };
        return result;
    };
    return NHSChargeBandValue_Factory;
}());
exports.NHSChargeBandValue_Factory = NHSChargeBandValue_Factory;
