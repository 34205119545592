"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientConcern_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Appointment_1 = require("./Appointment");
var ConcernType_1 = require("./ConcernType");
var Location_1 = require("./Location");
var Note_1 = require("./Note");
var Patient_1 = require("./Patient");
var User_1 = require("./User");
var PatientConcern_Factory = /** @class */ (function () {
    function PatientConcern_Factory() {
    }
    PatientConcern_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Appointment: Appointment_1.Appointment_Factory.CreateIncoming(model.Appointment),
            AppointmentId: model.AppointmentId,
            ConcernDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ConcernDate),
            ConcernType: ConcernType_1.ConcernType_Factory.CreateIncoming(model.ConcernType),
            ConcernTypeId: model.ConcernTypeId,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            NextPatientConcernVersion: model.NextPatientConcernVersion,
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            NoteId: model.NoteId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientConcernNextVersion: PatientConcern_Factory.CreateIncoming(model.PatientConcernNextVersion),
            PatientConcernNextVersions: model.PatientConcernNextVersions && model.PatientConcernNextVersions.map(function (i) { return PatientConcern_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordedAtLocation: model.RecordedAtLocation,
            RecordedByUser: model.RecordedByUser,
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
        };
        return result;
    };
    PatientConcern_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Appointment: Appointment_1.Appointment_Factory.CreateOutgoing(model.Appointment),
            AppointmentId: model.AppointmentId,
            ConcernDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ConcernDate),
            ConcernType: ConcernType_1.ConcernType_Factory.CreateOutgoing(model.ConcernType),
            ConcernTypeId: model.ConcernTypeId,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            NextPatientConcernVersion: model.NextPatientConcernVersion,
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            NoteId: model.NoteId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientConcernNextVersion: PatientConcern_Factory.CreateOutgoing(model.PatientConcernNextVersion),
            PatientConcernNextVersions: model.PatientConcernNextVersions && model.PatientConcernNextVersions.map(function (i) { return PatientConcern_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordedAtLocation: model.RecordedAtLocation,
            RecordedByUser: model.RecordedByUser,
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
        };
        return result;
    };
    return PatientConcern_Factory;
}());
exports.PatientConcern_Factory = PatientConcern_Factory;
