"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaimContinuationItem_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CDTCode_1 = require("./CDTCode");
var InsuranceClaimContinuationItem_Factory = /** @class */ (function () {
    function InsuranceClaimContinuationItem_Factory() {
    }
    InsuranceClaimContinuationItem_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            CDTCode: CDTCode_1.CDTCode_Factory.CreateIncoming(model.CDTCode),
            CDTCodeId: model.CDTCodeId,
            ContinuationDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ContinuationDate),
            InsuranceClaimId: model.InsuranceClaimId,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Surfaces: model.Surfaces,
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothRootsMask: model.ToothRootsMask,
            ToothSurfacesMask: model.ToothSurfacesMask,
        };
        return result;
    };
    InsuranceClaimContinuationItem_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            CDTCode: CDTCode_1.CDTCode_Factory.CreateOutgoing(model.CDTCode),
            CDTCodeId: model.CDTCodeId,
            ContinuationDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ContinuationDate),
            InsuranceClaimId: model.InsuranceClaimId,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Surfaces: model.Surfaces,
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothRootsMask: model.ToothRootsMask,
            ToothSurfacesMask: model.ToothSurfacesMask,
        };
        return result;
    };
    return InsuranceClaimContinuationItem_Factory;
}());
exports.InsuranceClaimContinuationItem_Factory = InsuranceClaimContinuationItem_Factory;
