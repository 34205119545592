"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRegistration_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var StatesProvince_1 = require("./StatesProvince");
var UserRegistrationType_1 = require("./UserRegistrationType");
var UserRegistration_Factory = /** @class */ (function () {
    function UserRegistration_Factory() {
    }
    UserRegistration_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RegistrationNumber: model.RegistrationNumber,
            RegistrationTypeCode: model.RegistrationTypeCode,
            StateProvinceId: model.StateProvinceId,
            StatesProvince: StatesProvince_1.StatesProvince_Factory.CreateIncoming(model.StatesProvince),
            UserId: model.UserId,
            UserRegistrationType: UserRegistrationType_1.UserRegistrationType_Factory.CreateIncoming(model.UserRegistrationType),
        };
        return result;
    };
    UserRegistration_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RegistrationNumber: model.RegistrationNumber,
            RegistrationTypeCode: model.RegistrationTypeCode,
            StateProvinceId: model.StateProvinceId,
            StatesProvince: StatesProvince_1.StatesProvince_Factory.CreateOutgoing(model.StatesProvince),
            UserId: model.UserId,
            UserRegistrationType: UserRegistrationType_1.UserRegistrationType_Factory.CreateOutgoing(model.UserRegistrationType),
        };
        return result;
    };
    return UserRegistration_Factory;
}());
exports.UserRegistration_Factory = UserRegistration_Factory;
