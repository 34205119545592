"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsAttachment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsAttachment_Factory = /** @class */ (function () {
    function EFormsAttachment_Factory() {
    }
    EFormsAttachment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AttachmentName: model.AttachmentName,
            AttachmentTypeId: model.AttachmentTypeId,
            CreatedBy: model.CreatedBy,
            IsLogo: model.IsLogo,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    EFormsAttachment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AttachmentName: model.AttachmentName,
            AttachmentTypeId: model.AttachmentTypeId,
            CreatedBy: model.CreatedBy,
            IsLogo: model.IsLogo,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return EFormsAttachment_Factory;
}());
exports.EFormsAttachment_Factory = EFormsAttachment_Factory;
