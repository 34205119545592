import { IAuthTokens, ssoClaimTypes } from "./models";
import ITenantInfo from "../../models/TenantInfo";
import { v4 as uuidv4 } from "uuid";

const tokenStorageKey = "auth";
const tenantStorageKey = "tenant";
const connectionStorageKey = "connectionId";

export function getTokensFromStorage(): IAuthTokens | null {
  const json = String(sessionStorage.getItem(tokenStorageKey));
  const tokens =  JSON.parse(json) as IAuthTokens;
  if (tokens) {
    tokens.accessToken.expiresOn = new Date(tokens.accessToken.expiresOn);
    tokens.refreshToken.expiresOn = new Date(tokens.refreshToken.expiresOn);
  }

  return tokens;
}

export function saveTokensToStorage(authTokens: IAuthTokens) {
  sessionStorage.setItem(tokenStorageKey, JSON.stringify(authTokens));
  return authTokens;
}

export function removeTokensFromStorage() {
  sessionStorage.removeItem(tokenStorageKey);
}

export function getCurrentTenantSelection() {
  const json = String(sessionStorage.getItem(tenantStorageKey));
  return (JSON.parse(json) as ITenantInfo) || undefined;
}

export function saveCurrentTenantSelection(tenant: ITenantInfo) {
  sessionStorage.setItem(tenantStorageKey, JSON.stringify(tenant));
}

export function getLoggedInUsersEmail() {
  const tokens = getTokensFromStorage();
  const emailClaim = tokens && tokens.claims.find(c => c.type === ssoClaimTypes.email);
  return (emailClaim && emailClaim.value.toLocaleLowerCase()) || undefined;
}

export function getConnectionFromStorage(): string | null {
   const connectionId = sessionStorage.getItem(connectionStorageKey);
   if (connectionId)
    return connectionId;

   const randomId = uuidv4();
   saveConnectionToStorage(randomId);
   return randomId;
}

export function saveConnectionToStorage(randomId: string) {
  sessionStorage.setItem(connectionStorageKey, randomId);
}
