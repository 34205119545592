"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkCorrespondencePatientInstance_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BulkCorrespondencePatientInstance_Factory = /** @class */ (function () {
    function BulkCorrespondencePatientInstance_Factory() {
    }
    BulkCorrespondencePatientInstance_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AddresseeId: model.AddresseeId,
            AppointmentId: model.AppointmentId,
            BatchId: model.BatchId,
            LanguageCultureString: model.LanguageCultureString,
            OrthoContractId: model.OrthoContractId,
            PatientId: model.PatientId,
            RecordId: model.RecordId,
            ReferralId: model.ReferralId,
        };
        return result;
    };
    BulkCorrespondencePatientInstance_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AddresseeId: model.AddresseeId,
            AppointmentId: model.AppointmentId,
            BatchId: model.BatchId,
            LanguageCultureString: model.LanguageCultureString,
            OrthoContractId: model.OrthoContractId,
            PatientId: model.PatientId,
            RecordId: model.RecordId,
            ReferralId: model.ReferralId,
        };
        return result;
    };
    return BulkCorrespondencePatientInstance_Factory;
}());
exports.BulkCorrespondencePatientInstance_Factory = BulkCorrespondencePatientInstance_Factory;
