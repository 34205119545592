"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyIdentifier_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EntityType_1 = require("./EntityType");
var LegacyIdentifier_Factory = /** @class */ (function () {
    function LegacyIdentifier_Factory() {
    }
    LegacyIdentifier_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EntityType: EntityType_1.EntityType_Factory.CreateIncoming(model.EntityType),
            EntityTypeCode: model.EntityTypeCode,
            LegacyId: model.LegacyId,
            RecordId: model.RecordId,
        };
        return result;
    };
    LegacyIdentifier_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EntityType: EntityType_1.EntityType_Factory.CreateOutgoing(model.EntityType),
            EntityTypeCode: model.EntityTypeCode,
            LegacyId: model.LegacyId,
            RecordId: model.RecordId,
        };
        return result;
    };
    return LegacyIdentifier_Factory;
}());
exports.LegacyIdentifier_Factory = LegacyIdentifier_Factory;
