"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lab_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var PhoneNumber_1 = require("./PhoneNumber");
var Lab_Factory = /** @class */ (function () {
    function Lab_Factory() {
    }
    Lab_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Active: model.Active,
            Address: Address_1.Address_Factory.CreateIncoming(model.Address),
            AddressId: model.AddressId,
            BusinessName: model.BusinessName,
            Email: model.Email,
            InternalName: model.InternalName,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(model.PhoneNumber),
            PhoneNumberId: model.PhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            Website: model.Website,
        };
        return result;
    };
    Lab_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Active: model.Active,
            Address: Address_1.Address_Factory.CreateOutgoing(model.Address),
            AddressId: model.AddressId,
            BusinessName: model.BusinessName,
            Email: model.Email,
            InternalName: model.InternalName,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(model.PhoneNumber),
            PhoneNumberId: model.PhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            Website: model.Website,
        };
        return result;
    };
    return Lab_Factory;
}());
exports.Lab_Factory = Lab_Factory;
