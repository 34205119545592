import * as signalR from "@aspnet/signalr";
import { isSignalDispatcherInstance, ISignalDispatcher } from "../../utils/signalDispatcher";

export const hubLoggingLevel: signalR.LogLevel = signalR.LogLevel.Information;

export interface IBaseHub {
  initialize(connection: signalR.HubConnection, userId: string): void;
}

export abstract class BaseHub implements IBaseHub {
  private currentSessionId?: string;
  private _hubName: string | undefined;

  constructor(hubName: string) {
    this._hubName = hubName;
  }

  private get debugMode() {
    return hubLoggingLevel < (signalR.LogLevel.Information as number);
  }

  protected hubName(): string {
    if (!this._hubName) return "";
    const name = this._hubName;
    const match = name.match(/(.+)Hub$/i);
    if (match) {
      return match[1].toLowerCase();
    } else {
      throw new Error(
        `Unable to parse hub name from the class name ${name}. Please override if not using standard convention {hubName}Hub.`
      );
    }
  }

  public initialize = (connection: signalR.HubConnection, sessionId: string) => {
    this.currentSessionId = sessionId;
    this.bindSignalRProxyEventsToSignalProperties(connection);
    return;
  };

  private bindSignalRProxyEventsToSignalProperties(proxy: signalR.HubConnection) {
    // Hookup each `GenericSignal` type property to the SignalR event of the same name.
    const hubName = this.hubName();
    for (const [propName, propValue] of Object.entries(this)) {
      if (isSignalDispatcherInstance(propValue)) {
        const signalName = `${hubName}_${propName}`;
        this.bindSignalRProxyEvent(proxy, signalName, propValue, this.debugMode);
      }
    }
  }

  private bindSignalRProxyEvent(
    proxy: signalR.HubConnection,
    methodName: string,
    dispatcher: ISignalDispatcher<any>,
    debug = false
  ) {
    if (debug) console.log(`Creating signal binding for ${methodName}`);
    const logger = debug ? console.log : (msg: string, ...args: any[]) => {};
    proxy.on(methodName, (arg: any) => {
      const { MessageFilter, ...payload } = arg;
      if (MessageFilter) {
        //Message filtering is only used to target a specific connection
        const [sessionId, action] = MessageFilter.split(":");
        const isMe = sessionId === this.currentSessionId;
        const shouldIgnore = action === "Reject";
        if (isMe === shouldIgnore) return;
      }

      logger(`dispatching property ${methodName} with arg `, payload);
      dispatcher.dispatch(payload);
    });
  }
}
