import React, { createContext, useMemo, useReducer, useContext, memo, useCallback, useState, useEffect } from "react";
import { IPatient } from "csd.phoenix.models/Patient";
import { NonIdealState, Intent, Icon, IconSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import PromiseWait from "../shared/PromiseWait";
import { getPatient } from "../../stores/patientsStore";
import { getInstances,  getTemplateList, IEFormsTemplateLight } from "../../stores/formsStore";
import { EFormsStatusCodes } from "csd.phoenix.models";


// Important: Patient-Mode must persist across browser refreshes.
interface IPatientModeStorage {
  readonly isEnabled: boolean;
  readonly patientId?: string;
}

const patientModeKey = "patientMode";
const getStoredData = () => JSON.parse(sessionStorage.getItem(patientModeKey) || "null") as IPatientModeStorage || { isEnabled: false };
const saveData = (data: IPatientModeStorage) => sessionStorage.setItem(patientModeKey, JSON.stringify(data));



export interface IPatientForm {
  instanceId: string;
  createdOn: Date;
  template: IEFormsTemplateLight;
  completed: boolean;
}


interface IPatientData {
  patient?: IPatient;
  forms: IPatientForm[];
}


async function loadPatientData(patientId?: string): Promise<IPatientData> {
  if (!patientId) {
    return { patient: undefined, forms: [] };
  }

  const patientPromise = getPatient(patientId);
  const instancesPromise = getInstances([EFormsStatusCodes.Assigned, EFormsStatusCodes.OnHold], 1, -1, undefined, undefined, patientId);
  const templatesPromise = getTemplateList();
  const [patient, templates, { Data: instances } ] = await Promise.all([patientPromise, templatesPromise, instancesPromise]);
  console.assert(patient);
  console.assert(Array.isArray(templates));
  console.assert(Array.isArray(instances));

  const forms = instances
    .map(i => ({
      instanceId: i.RecordId!,
      createdOn: i.RecordCreated!,
      template: templates.find(t => t.RecordId === i.TemplateId),
      completed: false
      })
    )
    .filter(f => !!f.template) as IPatientForm[];

  return { patient, forms };
}


interface IPatientModeContext {
  readonly isEnabled: boolean;
  enable: () => void;
  setPatientId: (patientId?: string) => void;
  patient?: IPatient;
  forms: IPatientForm[];
  markFormCompleted: (formInstanceId: string) => void;
}

const context = createContext<IPatientModeContext | undefined>(undefined);


interface IRenderProps extends Omit<IPatientModeContext, "markFormCompleted"> {
  patientId?: string;
}


const Render: React.FC<IRenderProps> = memo(({patientId, children, forms: formsProp, ...contextData}) => {
  const copyForms = useCallback(
    () => formsProp
      .filter(f => !!f.template)
      .sort(({template: a}, {template: b}) => {
        const val = a!.Ordinal - b!.Ordinal;
        return val === 0 ? a!.TemplateName.localeCompare(b!.TemplateName) : val;
        }), [formsProp]);


  const [forms, setForms] = useState(copyForms);

  useEffect(() => {
    setForms(copyForms());
  }, [copyForms, formsProp]);

  const markFormCompleted = useCallback((instanceId: string) => {
    const _forms = forms.map(f => f.instanceId !== instanceId ? f : { ...f, completed: true });
    setForms(_forms);
  }, [forms]);

  const value: IPatientModeContext = useMemo(() => ({
    ...contextData,
    forms,
    markFormCompleted
  }), [contextData, forms, markFormCompleted]);

  if (patientId && !contextData.patient) {
    const icon = <Icon size={IconSize.LARGE} icon={IconNames.BLOCKED_PERSON} intent={Intent.DANGER} />
    return (
      <NonIdealState icon={icon} title="Error" description="Failed to load patient Information!" />
    );
  }

  // if (contextData.patient) {
  //   console.info("Patient Context Data: ", JSON.parse(JSON.stringify(contextData)));
  // }

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  );
});



export const PatientModeProvider: React.FC = ({children}) => {
  const storedData = getStoredData();

  const [isEnabled, enable] = useReducer(() => {
    saveData({isEnabled: true});
    return true;
  }, storedData.isEnabled) as [boolean, () => void];

  const [patientId, setPatientId] = useReducer((_, patientId?: string) => {
    saveData({isEnabled, patientId});
    return patientId;
  }, storedData.patientId);

  const promise = useMemo(() => loadPatientData(patientId), [patientId]);

  const mapProps = useCallback(({ patient, forms }: IPatientData ) => ({
      isEnabled,
      enable,
      patientId,
      setPatientId,
      patient,
      forms,
      children
  }), [children, enable, isEnabled, patientId]);

  return (
    <PromiseWait promise={promise} component={Render} mapComponentProps={mapProps} />
  );
}



const usePatientMode = () => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error("Can't find a `PatientModeProvider` in compoent tree.")
  }

  return ctx;
}

export default usePatientMode;
