"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnterpriseConsultation_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var ClinicalEvent_1 = require("./ClinicalEvent");
var EnterpriseConsultationsAuditLog_1 = require("./EnterpriseConsultationsAuditLog");
var EnterpriseConsultationSubjectLink_1 = require("./EnterpriseConsultationSubjectLink");
var EnterpriseLocation_1 = require("./EnterpriseLocation");
var Location_1 = require("./Location");
var Patient_1 = require("./Patient");
var EnterpriseConsultation_Factory = /** @class */ (function () {
    function EnterpriseConsultation_Factory() {
    }
    EnterpriseConsultation_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Acknowledged: model.Acknowledged,
            AcknowledgeReminderSent: model.AcknowledgeReminderSent,
            AssignedToUser: User_1.User_Factory.CreateIncoming(model.AssignedToUser),
            AssignedToUserId: model.AssignedToUserId,
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            ConsultantsRecommendation: model.ConsultantsRecommendation,
            ConsultationStatus: model.ConsultationStatus,
            ConsultationToSummary: model.ConsultationToSummary,
            EnterpriseConsultationsAuditLogs: model.EnterpriseConsultationsAuditLogs && model.EnterpriseConsultationsAuditLogs.map(function (i) { return EnterpriseConsultationsAuditLog_1.EnterpriseConsultationsAuditLog_Factory.CreateIncoming(i); }),
            EnterpriseConsultationSubjectLink: EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateIncoming(model.EnterpriseConsultationSubjectLink),
            EnterpriseConsultationSubjectLinkId: model.EnterpriseConsultationSubjectLinkId,
            FromChairId: model.FromChairId,
            FromChairName: model.FromChairName,
            FromEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateIncoming(model.FromEnterpriseLocation),
            FromEnterpriseLocationId: model.FromEnterpriseLocationId,
            FromLocation: Location_1.Location_Factory.CreateIncoming(model.FromLocation),
            FromLocationId: model.FromLocationId,
            ImagesAvailable: model.ImagesAvailable,
            IsVirtual: model.IsVirtual,
            OverdueReminderSent: model.OverdueReminderSent,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequestedByUser: User_1.User_Factory.CreateIncoming(model.RequestedByUser),
            RequestedByUserId: model.RequestedByUserId,
            RequestNotes: model.RequestNotes,
            SubjectTypeId: model.SubjectTypeId,
            ToEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateIncoming(model.ToEnterpriseLocation),
            ToEnterpriseLocationId: model.ToEnterpriseLocationId,
            ToLocation: Location_1.Location_Factory.CreateIncoming(model.ToLocation),
            ToLocationId: model.ToLocationId,
            Urgent: model.Urgent,
            XrayAvailable: model.XrayAvailable,
        };
        return result;
    };
    EnterpriseConsultation_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Acknowledged: model.Acknowledged,
            AcknowledgeReminderSent: model.AcknowledgeReminderSent,
            AssignedToUser: User_1.User_Factory.CreateOutgoing(model.AssignedToUser),
            AssignedToUserId: model.AssignedToUserId,
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            ConsultantsRecommendation: model.ConsultantsRecommendation,
            ConsultationStatus: model.ConsultationStatus,
            ConsultationToSummary: model.ConsultationToSummary,
            EnterpriseConsultationsAuditLogs: model.EnterpriseConsultationsAuditLogs && model.EnterpriseConsultationsAuditLogs.map(function (i) { return EnterpriseConsultationsAuditLog_1.EnterpriseConsultationsAuditLog_Factory.CreateOutgoing(i); }),
            EnterpriseConsultationSubjectLink: EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateOutgoing(model.EnterpriseConsultationSubjectLink),
            EnterpriseConsultationSubjectLinkId: model.EnterpriseConsultationSubjectLinkId,
            FromChairId: model.FromChairId,
            FromChairName: model.FromChairName,
            FromEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateOutgoing(model.FromEnterpriseLocation),
            FromEnterpriseLocationId: model.FromEnterpriseLocationId,
            FromLocation: Location_1.Location_Factory.CreateOutgoing(model.FromLocation),
            FromLocationId: model.FromLocationId,
            ImagesAvailable: model.ImagesAvailable,
            IsVirtual: model.IsVirtual,
            OverdueReminderSent: model.OverdueReminderSent,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequestedByUser: User_1.User_Factory.CreateOutgoing(model.RequestedByUser),
            RequestedByUserId: model.RequestedByUserId,
            RequestNotes: model.RequestNotes,
            SubjectTypeId: model.SubjectTypeId,
            ToEnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateOutgoing(model.ToEnterpriseLocation),
            ToEnterpriseLocationId: model.ToEnterpriseLocationId,
            ToLocation: Location_1.Location_Factory.CreateOutgoing(model.ToLocation),
            ToLocationId: model.ToLocationId,
            Urgent: model.Urgent,
            XrayAvailable: model.XrayAvailable,
        };
        return result;
    };
    return EnterpriseConsultation_Factory;
}());
exports.EnterpriseConsultation_Factory = EnterpriseConsultation_Factory;
