import { getCurrentTenantSelection } from "../services/authService/storage";
//import * as Yup from "yup";
//import * as defaultLocale from "./locales/default.json";

// interface II18nMessage {
//   key: string;
//   defaultMessage: string;
// }

// export const validationMessageFactory = (defaultMessage: string ) => (params: Partial<Yup.TestMessageParams>) =>
// {
//   // TODO: Lookup i18n resource based on `params.path` and/or `params.label`
//   return defaultMessage;
// }

// export const requiredMessage = validationMessageFactory("This field is required.");


export function getLocale() {
  const tenant = getCurrentTenantSelection();
  if (tenant) {
    return tenant.LanguageCultureString;
  }

  const localeRegEx = /en-US|en-GB/;
  let locale = navigator.language;

  // Note: Some browsers (i.e. Safari) returns the string as lowercase.
  locale = locale.substr(0, 3) + locale.substr(3).toUpperCase();

  if (!localeRegEx.test(locale)) {
    const localeOverride = locale.endsWith("US") ? "en-US" : "en-GB";
    //console.log(`Locale override: ${browserLocale} --> ${localeOverride}`);
    locale = localeOverride;
  }

  console.assert(locale.length === 5);
  return locale;
}


export const isLocaleUS = (locale = getLocale()) => locale.endsWith("-US");
export const isLocaleUK = (locale = getLocale()) => locale.endsWith("-GB");


