"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientImagingIdentifier_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ImagingReferenceType_1 = require("./ImagingReferenceType");
var Patient_1 = require("./Patient");
var PatientImagingIdentifier_Factory = /** @class */ (function () {
    function PatientImagingIdentifier_Factory() {
    }
    PatientImagingIdentifier_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Identifier: model.Identifier,
            ImagingReferenceType: ImagingReferenceType_1.ImagingReferenceType_Factory.CreateIncoming(model.ImagingReferenceType),
            ImagingReferenceTypeId: model.ImagingReferenceTypeId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientImagingIdentifier_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Identifier: model.Identifier,
            ImagingReferenceType: ImagingReferenceType_1.ImagingReferenceType_Factory.CreateOutgoing(model.ImagingReferenceType),
            ImagingReferenceTypeId: model.ImagingReferenceTypeId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientImagingIdentifier_Factory;
}());
exports.PatientImagingIdentifier_Factory = PatientImagingIdentifier_Factory;
