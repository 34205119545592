"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Archwire_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ArchType_1 = require("./ArchType");
var ArchwireType_1 = require("./ArchwireType");
var Archwire_Factory = /** @class */ (function () {
    function Archwire_Factory() {
    }
    Archwire_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ArchType: ArchType_1.ArchType_Factory.CreateIncoming(model.ArchType),
            ArchTypeCode: model.ArchTypeCode,
            ArchwireType: ArchwireType_1.ArchwireType_Factory.CreateIncoming(model.ArchwireType),
            ArchwireTypeId: model.ArchwireTypeId,
            EndDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EndDate),
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
        };
        return result;
    };
    Archwire_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ArchType: ArchType_1.ArchType_Factory.CreateOutgoing(model.ArchType),
            ArchTypeCode: model.ArchTypeCode,
            ArchwireType: ArchwireType_1.ArchwireType_Factory.CreateOutgoing(model.ArchwireType),
            ArchwireTypeId: model.ArchwireTypeId,
            EndDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EndDate),
            PatientId: model.PatientId,
            Rank: model.Rank,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
        };
        return result;
    };
    return Archwire_Factory;
}());
exports.Archwire_Factory = Archwire_Factory;
