"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstateReferralStatus_DisplayOrders = exports.EstateReferralStatus_Descriptions_English = exports.EstateReferralStatus_Descriptions = exports.EstateReferralStatus_translateDescriptionBack = exports.EstateReferralStatus_translateDescriptions = exports.EstateReferralStatus = void 0;
var EstateReferralStatus;
(function (EstateReferralStatus) {
    EstateReferralStatus[EstateReferralStatus["NotSent"] = 1] = "NotSent";
    EstateReferralStatus[EstateReferralStatus["Sent"] = 2] = "Sent";
    EstateReferralStatus[EstateReferralStatus["Accepted"] = 3] = "Accepted";
    EstateReferralStatus[EstateReferralStatus["Rejected"] = 4] = "Rejected";
    EstateReferralStatus[EstateReferralStatus["AppointmentBooked"] = 5] = "AppointmentBooked";
    EstateReferralStatus[EstateReferralStatus["Completed"] = 6] = "Completed";
    EstateReferralStatus[EstateReferralStatus["AppointmentCancelled"] = 7] = "AppointmentCancelled";
    EstateReferralStatus[EstateReferralStatus["AppointmentProvisioned"] = 8] = "AppointmentProvisioned";
})(EstateReferralStatus = exports.EstateReferralStatus || (exports.EstateReferralStatus = {}));
function EstateReferralStatus_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EstateReferralStatus_Descriptions);
    allKeys.forEach(function (key) {
        exports.EstateReferralStatus_Descriptions[key] = translationFunction(exports.EstateReferralStatus_Descriptions[key]);
    });
}
exports.EstateReferralStatus_translateDescriptions = EstateReferralStatus_translateDescriptions;
function EstateReferralStatus_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EstateReferralStatus_Descriptions);
    allKeys.forEach(function (key) {
        exports.EstateReferralStatus_Descriptions[key] = exports.EstateReferralStatus_Descriptions_English[key];
    });
}
exports.EstateReferralStatus_translateDescriptionBack = EstateReferralStatus_translateDescriptionBack;
var _EstateReferralStatus_Descriptions = {};
_EstateReferralStatus_Descriptions.NotSent = _EstateReferralStatus_Descriptions[1] = "Not Sent";
_EstateReferralStatus_Descriptions.Sent = _EstateReferralStatus_Descriptions[2] = "Sent";
_EstateReferralStatus_Descriptions.Accepted = _EstateReferralStatus_Descriptions[3] = "Accepted";
_EstateReferralStatus_Descriptions.Rejected = _EstateReferralStatus_Descriptions[4] = "Rejected";
_EstateReferralStatus_Descriptions.AppointmentBooked = _EstateReferralStatus_Descriptions[5] = "Appointment Booked";
_EstateReferralStatus_Descriptions.Completed = _EstateReferralStatus_Descriptions[6] = "Completed";
_EstateReferralStatus_Descriptions.AppointmentCancelled = _EstateReferralStatus_Descriptions[7] = "Appointment Cancelled";
_EstateReferralStatus_Descriptions.AppointmentProvisioned = _EstateReferralStatus_Descriptions[8] = "Appointment Provisioned";
var _EstateReferralStatus_Descriptions_English = {};
_EstateReferralStatus_Descriptions_English.NotSent = _EstateReferralStatus_Descriptions_English[1] = "Not Sent";
_EstateReferralStatus_Descriptions_English.Sent = _EstateReferralStatus_Descriptions_English[2] = "Sent";
_EstateReferralStatus_Descriptions_English.Accepted = _EstateReferralStatus_Descriptions_English[3] = "Accepted";
_EstateReferralStatus_Descriptions_English.Rejected = _EstateReferralStatus_Descriptions_English[4] = "Rejected";
_EstateReferralStatus_Descriptions_English.AppointmentBooked = _EstateReferralStatus_Descriptions_English[5] = "Appointment Booked";
_EstateReferralStatus_Descriptions_English.Completed = _EstateReferralStatus_Descriptions_English[6] = "Completed";
_EstateReferralStatus_Descriptions_English.AppointmentCancelled = _EstateReferralStatus_Descriptions_English[7] = "Appointment Cancelled";
_EstateReferralStatus_Descriptions_English.AppointmentProvisioned = _EstateReferralStatus_Descriptions_English[8] = "Appointment Provisioned";
exports.EstateReferralStatus_Descriptions = _EstateReferralStatus_Descriptions;
exports.EstateReferralStatus_Descriptions_English = _EstateReferralStatus_Descriptions_English;
var _EstateReferralStatus_DisplayOrders = {};
_EstateReferralStatus_DisplayOrders.NotSent = _EstateReferralStatus_DisplayOrders[1] = 1;
_EstateReferralStatus_DisplayOrders.Sent = _EstateReferralStatus_DisplayOrders[2] = 2;
_EstateReferralStatus_DisplayOrders.Accepted = _EstateReferralStatus_DisplayOrders[3] = 3;
_EstateReferralStatus_DisplayOrders.Rejected = _EstateReferralStatus_DisplayOrders[4] = 4;
_EstateReferralStatus_DisplayOrders.AppointmentBooked = _EstateReferralStatus_DisplayOrders[5] = 5;
_EstateReferralStatus_DisplayOrders.Completed = _EstateReferralStatus_DisplayOrders[6] = 6;
_EstateReferralStatus_DisplayOrders.AppointmentCancelled = _EstateReferralStatus_DisplayOrders[7] = 7;
_EstateReferralStatus_DisplayOrders.AppointmentProvisioned = _EstateReferralStatus_DisplayOrders[8] = 8;
exports.EstateReferralStatus_DisplayOrders = _EstateReferralStatus_DisplayOrders;
