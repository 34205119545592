"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientRecallPreference_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AppointmentType_1 = require("./AppointmentType");
var PatientRecallCycleUnitType_1 = require("./PatientRecallCycleUnitType");
var PreferredContactMethodType_1 = require("./PreferredContactMethodType");
var Procedure_1 = require("./Procedure");
var PatientRecallPreference_Factory = /** @class */ (function () {
    function PatientRecallPreference_Factory() {
    }
    PatientRecallPreference_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AlternativeProcedureId: model.AlternativeProcedureId,
            AppointmentDurationInMins: model.AppointmentDurationInMins,
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateIncoming(model.AppointmentType),
            DefaultApptTypeId: model.DefaultApptTypeId,
            DefaultProcedureId: model.DefaultProcedureId,
            OnRecallList: model.OnRecallList,
            PatientId: model.PatientId,
            PatientRecallCycleUnitType: PatientRecallCycleUnitType_1.PatientRecallCycleUnitType_Factory.CreateIncoming(model.PatientRecallCycleUnitType),
            PreferredContactMethodType: PreferredContactMethodType_1.PreferredContactMethodType_Factory.CreateIncoming(model.PreferredContactMethodType),
            PreferredContactMethodTypeCode: model.PreferredContactMethodTypeCode,
            Procedure: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure),
            Procedure1: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure1),
            RecallCyclePeriod: model.RecallCyclePeriod,
            RecallCycleUnitTypeCode: model.RecallCycleUnitTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UseDefaults: model.UseDefaults,
        };
        return result;
    };
    PatientRecallPreference_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AlternativeProcedureId: model.AlternativeProcedureId,
            AppointmentDurationInMins: model.AppointmentDurationInMins,
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateOutgoing(model.AppointmentType),
            DefaultApptTypeId: model.DefaultApptTypeId,
            DefaultProcedureId: model.DefaultProcedureId,
            OnRecallList: model.OnRecallList,
            PatientId: model.PatientId,
            PatientRecallCycleUnitType: PatientRecallCycleUnitType_1.PatientRecallCycleUnitType_Factory.CreateOutgoing(model.PatientRecallCycleUnitType),
            PreferredContactMethodType: PreferredContactMethodType_1.PreferredContactMethodType_Factory.CreateOutgoing(model.PreferredContactMethodType),
            PreferredContactMethodTypeCode: model.PreferredContactMethodTypeCode,
            Procedure: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure),
            Procedure1: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure1),
            RecallCyclePeriod: model.RecallCyclePeriod,
            RecallCycleUnitTypeCode: model.RecallCycleUnitTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UseDefaults: model.UseDefaults,
        };
        return result;
    };
    return PatientRecallPreference_Factory;
}());
exports.PatientRecallPreference_Factory = PatientRecallPreference_Factory;
