"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalEvent_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var BPEExam_1 = require("./BPEExam");
var ClinicalEventConditionCode_1 = require("./ClinicalEventConditionCode");
var ClinicalEventGroup_1 = require("./ClinicalEventGroup");
var ClinicalEventWatchDetail_1 = require("./ClinicalEventWatchDetail");
var ClinicalNote_1 = require("./ClinicalNote");
var ClinicalTreatmentMaterial_1 = require("./ClinicalTreatmentMaterial");
var EFormsInstance_1 = require("./EFormsInstance");
var EnterpriseConsultation_1 = require("./EnterpriseConsultation");
var EnterpriseConsultationSubjectLink_1 = require("./EnterpriseConsultationSubjectLink");
var EstateReferral_1 = require("./EstateReferral");
var ImagingOrder_1 = require("./ImagingOrder");
var ITeroScan_1 = require("./ITeroScan");
var LabCase_1 = require("./LabCase");
var PatientProcedure_1 = require("./PatientProcedure");
var PatientTreatmentStatus_1 = require("./PatientTreatmentStatus");
var TreatmentPlan_1 = require("./TreatmentPlan");
var Tooth_1 = require("./Tooth");
var ToothRootMaskValue_1 = require("./ToothRootMaskValue");
var ToothSurfaceMaskValue_1 = require("./ToothSurfaceMaskValue");
var ClinicalEvent_Factory = /** @class */ (function () {
    function ClinicalEvent_Factory() {
    }
    ClinicalEvent_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AddedByUser: User_1.User_Factory.CreateIncoming(model.AddedByUser),
            AddedByUserId: model.AddedByUserId,
            AdditionalInfo: model.AdditionalInfo,
            AppliesToCrown: model.AppliesToCrown,
            AppliesToWholeTooth: model.AppliesToWholeTooth,
            BPEExams: model.BPEExams && model.BPEExams.map(function (i) { return BPEExam_1.BPEExam_Factory.CreateIncoming(i); }),
            ClinicalEventCondition: ClinicalEventConditionCode_1.ClinicalEventConditionCode_Factory.CreateIncoming(model.ClinicalEventCondition),
            ClinicalEventConditionCode: model.ClinicalEventConditionCode,
            ClinicalEventGroup: ClinicalEventGroup_1.ClinicalEventGroup_Factory.CreateIncoming(model.ClinicalEventGroup),
            ClinicalEventGroupId: model.ClinicalEventGroupId,
            ClinicalEventWatchDetails: model.ClinicalEventWatchDetails && model.ClinicalEventWatchDetails.map(function (i) { return ClinicalEventWatchDetail_1.ClinicalEventWatchDetail_Factory.CreateIncoming(i); }),
            ClinicalNotes: model.ClinicalNotes && model.ClinicalNotes.map(function (i) { return ClinicalNote_1.ClinicalNote_Factory.CreateIncoming(i); }),
            ClinicalTreatmentMaterial: ClinicalTreatmentMaterial_1.ClinicalTreatmentMaterial_Factory.CreateIncoming(model.ClinicalTreatmentMaterial),
            ClinicalTreatmentMaterialCode: model.ClinicalTreatmentMaterialCode,
            EFormsInstances: model.EFormsInstances && model.EFormsInstances.map(function (i) { return EFormsInstance_1.EFormsInstance_Factory.CreateIncoming(i); }),
            EnterpriseConsultations: model.EnterpriseConsultations && model.EnterpriseConsultations.map(function (i) { return EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateIncoming(i); }),
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateIncoming(i); }),
            EnterpriseLocationId: model.EnterpriseLocationId,
            EstateReferrals: model.EstateReferrals && model.EstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateIncoming(i); }),
            ExpiredByEvent: model.ExpiredByEvent,
            ExpiredOn: Date_Factory_1.Date_Factory.CreateIncoming(model.ExpiredOn),
            ImagingOrders: model.ImagingOrders && model.ImagingOrders.map(function (i) { return ImagingOrder_1.ImagingOrder_Factory.CreateIncoming(i); }),
            ITeroScans: model.ITeroScans && model.ITeroScans.map(function (i) { return ITeroScan_1.ITeroScan_Factory.CreateIncoming(i); }),
            LabCase: LabCase_1.LabCase_Factory.CreateIncoming(model.LabCase),
            LabCaseId: model.LabCaseId,
            OralHygieneScore: model.OralHygieneScore,
            PatientId: model.PatientId,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(model.PatientProcedure),
            PatientProcedureID: model.PatientProcedureID,
            PatientTreatmentStatus: PatientTreatmentStatus_1.PatientTreatmentStatus_Factory.CreateIncoming(model.PatientTreatmentStatus),
            PatientTreatmentStatusId: model.PatientTreatmentStatusId,
            PlanEventTreatmentPlans: model.PlanEventTreatmentPlans && model.PlanEventTreatmentPlans.map(function (i) { return TreatmentPlan_1.TreatmentPlan_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SecondaryDataCode: model.SecondaryDataCode,
            Tooth: Tooth_1.Tooth_Factory.CreateIncoming(model.Tooth),
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothRootMaskValue: ToothRootMaskValue_1.ToothRootMaskValue_Factory.CreateIncoming(model.ToothRootMaskValue),
            ToothRootsMask: model.ToothRootsMask,
            ToothSurfaceMaskValue: ToothSurfaceMaskValue_1.ToothSurfaceMaskValue_Factory.CreateIncoming(model.ToothSurfaceMaskValue),
            ToothSurfacesMask: model.ToothSurfacesMask,
            UndoneByClinicalEvent: ClinicalEvent_Factory.CreateIncoming(model.UndoneByClinicalEvent),
            UndoneByEventId: model.UndoneByEventId,
            UndoneClinicalEvents: model.UndoneClinicalEvents && model.UndoneClinicalEvents.map(function (i) { return ClinicalEvent_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    ClinicalEvent_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AddedByUser: User_1.User_Factory.CreateOutgoing(model.AddedByUser),
            AddedByUserId: model.AddedByUserId,
            AdditionalInfo: model.AdditionalInfo,
            AppliesToCrown: model.AppliesToCrown,
            AppliesToWholeTooth: model.AppliesToWholeTooth,
            BPEExams: model.BPEExams && model.BPEExams.map(function (i) { return BPEExam_1.BPEExam_Factory.CreateOutgoing(i); }),
            ClinicalEventCondition: ClinicalEventConditionCode_1.ClinicalEventConditionCode_Factory.CreateOutgoing(model.ClinicalEventCondition),
            ClinicalEventConditionCode: model.ClinicalEventConditionCode,
            ClinicalEventGroup: ClinicalEventGroup_1.ClinicalEventGroup_Factory.CreateOutgoing(model.ClinicalEventGroup),
            ClinicalEventGroupId: model.ClinicalEventGroupId,
            ClinicalEventWatchDetails: model.ClinicalEventWatchDetails && model.ClinicalEventWatchDetails.map(function (i) { return ClinicalEventWatchDetail_1.ClinicalEventWatchDetail_Factory.CreateOutgoing(i); }),
            ClinicalNotes: model.ClinicalNotes && model.ClinicalNotes.map(function (i) { return ClinicalNote_1.ClinicalNote_Factory.CreateOutgoing(i); }),
            ClinicalTreatmentMaterial: ClinicalTreatmentMaterial_1.ClinicalTreatmentMaterial_Factory.CreateOutgoing(model.ClinicalTreatmentMaterial),
            ClinicalTreatmentMaterialCode: model.ClinicalTreatmentMaterialCode,
            EFormsInstances: model.EFormsInstances && model.EFormsInstances.map(function (i) { return EFormsInstance_1.EFormsInstance_Factory.CreateOutgoing(i); }),
            EnterpriseConsultations: model.EnterpriseConsultations && model.EnterpriseConsultations.map(function (i) { return EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateOutgoing(i); }),
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateOutgoing(i); }),
            EnterpriseLocationId: model.EnterpriseLocationId,
            EstateReferrals: model.EstateReferrals && model.EstateReferrals.map(function (i) { return EstateReferral_1.EstateReferral_Factory.CreateOutgoing(i); }),
            ExpiredByEvent: model.ExpiredByEvent,
            ExpiredOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.ExpiredOn),
            ImagingOrders: model.ImagingOrders && model.ImagingOrders.map(function (i) { return ImagingOrder_1.ImagingOrder_Factory.CreateOutgoing(i); }),
            ITeroScans: model.ITeroScans && model.ITeroScans.map(function (i) { return ITeroScan_1.ITeroScan_Factory.CreateOutgoing(i); }),
            LabCase: LabCase_1.LabCase_Factory.CreateOutgoing(model.LabCase),
            LabCaseId: model.LabCaseId,
            OralHygieneScore: model.OralHygieneScore,
            PatientId: model.PatientId,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(model.PatientProcedure),
            PatientProcedureID: model.PatientProcedureID,
            PatientTreatmentStatus: PatientTreatmentStatus_1.PatientTreatmentStatus_Factory.CreateOutgoing(model.PatientTreatmentStatus),
            PatientTreatmentStatusId: model.PatientTreatmentStatusId,
            PlanEventTreatmentPlans: model.PlanEventTreatmentPlans && model.PlanEventTreatmentPlans.map(function (i) { return TreatmentPlan_1.TreatmentPlan_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SecondaryDataCode: model.SecondaryDataCode,
            Tooth: Tooth_1.Tooth_Factory.CreateOutgoing(model.Tooth),
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothRootMaskValue: ToothRootMaskValue_1.ToothRootMaskValue_Factory.CreateOutgoing(model.ToothRootMaskValue),
            ToothRootsMask: model.ToothRootsMask,
            ToothSurfaceMaskValue: ToothSurfaceMaskValue_1.ToothSurfaceMaskValue_Factory.CreateOutgoing(model.ToothSurfaceMaskValue),
            ToothSurfacesMask: model.ToothSurfacesMask,
            UndoneByClinicalEvent: ClinicalEvent_Factory.CreateOutgoing(model.UndoneByClinicalEvent),
            UndoneByEventId: model.UndoneByEventId,
            UndoneClinicalEvents: model.UndoneClinicalEvents && model.UndoneClinicalEvents.map(function (i) { return ClinicalEvent_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return ClinicalEvent_Factory;
}());
exports.ClinicalEvent_Factory = ClinicalEvent_Factory;
