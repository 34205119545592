"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Address_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AddressType_1 = require("./AddressType");
var StatesProvince_1 = require("./StatesProvince");
var Address_Factory = /** @class */ (function () {
    function Address_Factory() {
    }
    Address_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AddressBlock: model.AddressBlock,
            AddressLine1: model.AddressLine1,
            AddressLine2: model.AddressLine2,
            AddressLine3: model.AddressLine3,
            AddressType: AddressType_1.AddressType_Factory.CreateIncoming(model.AddressType),
            AddressTypeCode: model.AddressTypeCode,
            City: model.City,
            CityStateZipCode: model.CityStateZipCode,
            PostalZipCode: model.PostalZipCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StateProvinceId: model.StateProvinceId,
            StatesProvince: StatesProvince_1.StatesProvince_Factory.CreateIncoming(model.StatesProvince),
        };
        return result;
    };
    Address_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AddressBlock: model.AddressBlock,
            AddressLine1: model.AddressLine1,
            AddressLine2: model.AddressLine2,
            AddressLine3: model.AddressLine3,
            AddressType: AddressType_1.AddressType_Factory.CreateOutgoing(model.AddressType),
            AddressTypeCode: model.AddressTypeCode,
            City: model.City,
            CityStateZipCode: model.CityStateZipCode,
            PostalZipCode: model.PostalZipCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            StateProvinceId: model.StateProvinceId,
            StatesProvince: StatesProvince_1.StatesProvince_Factory.CreateOutgoing(model.StatesProvince),
        };
        return result;
    };
    return Address_Factory;
}());
exports.Address_Factory = Address_Factory;
