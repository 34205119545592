"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveAccessRequest_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var PatientArchiveRecord_1 = require("./PatientArchiveRecord");
var ArchiveAccessRequest_Factory = /** @class */ (function () {
    function ArchiveAccessRequest_Factory() {
    }
    ArchiveAccessRequest_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            GrantedAt: Date_Factory_1.Date_Factory.CreateIncoming(model.GrantedAt),
            GrantedBy: User_1.User_Factory.CreateIncoming(model.GrantedBy),
            GrantedById: model.GrantedById,
            Id: model.Id,
            PatientArchiveRecord: PatientArchiveRecord_1.PatientArchiveRecord_Factory.CreateIncoming(model.PatientArchiveRecord),
            PatientArchiveRecordId: model.PatientArchiveRecordId,
            PatientFullName: model.PatientFullName,
            Reason: model.Reason,
            RecordStatus: model.RecordStatus,
            RequestedAt: Date_Factory_1.Date_Factory.CreateIncoming(model.RequestedAt),
            RequestedBy: User_1.User_Factory.CreateIncoming(model.RequestedBy),
            RequestedById: model.RequestedById,
            ReturnedAt: Date_Factory_1.Date_Factory.CreateIncoming(model.ReturnedAt),
            ReturnedTo: User_1.User_Factory.CreateIncoming(model.ReturnedTo),
            ReturnedToId: model.ReturnedToId,
        };
        return result;
    };
    ArchiveAccessRequest_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            GrantedAt: Date_Factory_1.Date_Factory.CreateOutgoing(model.GrantedAt),
            GrantedBy: User_1.User_Factory.CreateOutgoing(model.GrantedBy),
            GrantedById: model.GrantedById,
            Id: model.Id,
            PatientArchiveRecord: PatientArchiveRecord_1.PatientArchiveRecord_Factory.CreateOutgoing(model.PatientArchiveRecord),
            PatientArchiveRecordId: model.PatientArchiveRecordId,
            PatientFullName: model.PatientFullName,
            Reason: model.Reason,
            RecordStatus: model.RecordStatus,
            RequestedAt: Date_Factory_1.Date_Factory.CreateOutgoing(model.RequestedAt),
            RequestedBy: User_1.User_Factory.CreateOutgoing(model.RequestedBy),
            RequestedById: model.RequestedById,
            ReturnedAt: Date_Factory_1.Date_Factory.CreateOutgoing(model.ReturnedAt),
            ReturnedTo: User_1.User_Factory.CreateOutgoing(model.ReturnedTo),
            ReturnedToId: model.ReturnedToId,
        };
        return result;
    };
    return ArchiveAccessRequest_Factory;
}());
exports.ArchiveAccessRequest_Factory = ArchiveAccessRequest_Factory;
