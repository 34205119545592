"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatesProvince_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var StatesProvince_Factory = /** @class */ (function () {
    function StatesProvince_Factory() {
    }
    StatesProvince_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CountryId: model.CountryId,
            StateProvinceCode: model.StateProvinceCode,
            StateProvinceId: model.StateProvinceId,
            StateProvinceName: model.StateProvinceName,
        };
        return result;
    };
    StatesProvince_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CountryId: model.CountryId,
            StateProvinceCode: model.StateProvinceCode,
            StateProvinceId: model.StateProvinceId,
            StateProvinceName: model.StateProvinceName,
        };
        return result;
    };
    return StatesProvince_Factory;
}());
exports.StatesProvince_Factory = StatesProvince_Factory;
