"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsInstanceView_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsInstanceView_Factory = /** @class */ (function () {
    function EFormsInstanceView_Factory() {
    }
    EFormsInstanceView_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BusinessName: model.BusinessName,
            HasMedicalConditions: model.HasMedicalConditions,
            IsRead: model.IsRead,
            LatestStatusCode: model.LatestStatusCode,
            LocationId: model.LocationId,
            PatientId: model.PatientId,
            PatientName: model.PatientName,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SourceTypeId: model.SourceTypeId,
            Status: model.Status,
            TemplateId: model.TemplateId,
        };
        return result;
    };
    EFormsInstanceView_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BusinessName: model.BusinessName,
            HasMedicalConditions: model.HasMedicalConditions,
            IsRead: model.IsRead,
            LatestStatusCode: model.LatestStatusCode,
            LocationId: model.LocationId,
            PatientId: model.PatientId,
            PatientName: model.PatientName,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SourceTypeId: model.SourceTypeId,
            Status: model.Status,
            TemplateId: model.TemplateId,
        };
        return result;
    };
    return EFormsInstanceView_Factory;
}());
exports.EFormsInstanceView_Factory = EFormsInstanceView_Factory;
