"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralSourceTransactions_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReferralSource_1 = require("./ReferralSource");
var ReferralSourceTransactions_Factory = /** @class */ (function () {
    function ReferralSourceTransactions_Factory() {
    }
    ReferralSourceTransactions_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            Comment: model.Comment,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralSourceId: model.ReferralSourceId,
            ReferralSources: ReferralSource_1.ReferralSource_Factory.CreateIncoming(model.ReferralSources),
            TransactionDate: Date_Factory_1.Date_Factory.CreateIncoming(model.TransactionDate),
            TransactionTypeId: model.TransactionTypeId,
        };
        return result;
    };
    ReferralSourceTransactions_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            Comment: model.Comment,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReferralSourceId: model.ReferralSourceId,
            ReferralSources: ReferralSource_1.ReferralSource_Factory.CreateOutgoing(model.ReferralSources),
            TransactionDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.TransactionDate),
            TransactionTypeId: model.TransactionTypeId,
        };
        return result;
    };
    return ReferralSourceTransactions_Factory;
}());
exports.ReferralSourceTransactions_Factory = ReferralSourceTransactions_Factory;
