"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSClaim_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClaimComment_1 = require("./NHSClaimComment");
var NHSClaimSubmission_1 = require("./NHSClaimSubmission");
var NHSCourseDetail_1 = require("./NHSCourseDetail");
var NHSCourseDetailOrtho_1 = require("./NHSCourseDetailOrtho");
var NHSSchedulePayment_1 = require("./NHSSchedulePayment");
var NHSClaim_Factory = /** @class */ (function () {
    function NHSClaim_Factory() {
    }
    NHSClaim_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimType: model.ClaimType,
            CLRN: model.CLRN,
            LatestSendStatus: model.LatestSendStatus,
            ManuallyReconciled: model.ManuallyReconciled,
            NHSClaimComments: model.NHSClaimComments && model.NHSClaimComments.map(function (i) { return NHSClaimComment_1.NHSClaimComment_Factory.CreateIncoming(i); }),
            NHSClaimSubmissions: model.NHSClaimSubmissions && model.NHSClaimSubmissions.map(function (i) { return NHSClaimSubmission_1.NHSClaimSubmission_Factory.CreateIncoming(i); }),
            NHSCourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateIncoming(model.NHSCourseDetail),
            NHSCourseDetailId: model.NHSCourseDetailId,
            NHSCourseDetailOrtho: NHSCourseDetailOrtho_1.NHSCourseDetailOrtho_Factory.CreateIncoming(model.NHSCourseDetailOrtho),
            NHSSchedulePayments: model.NHSSchedulePayments && model.NHSSchedulePayments.map(function (i) { return NHSSchedulePayment_1.NHSSchedulePayment_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    NHSClaim_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimType: model.ClaimType,
            CLRN: model.CLRN,
            LatestSendStatus: model.LatestSendStatus,
            ManuallyReconciled: model.ManuallyReconciled,
            NHSClaimComments: model.NHSClaimComments && model.NHSClaimComments.map(function (i) { return NHSClaimComment_1.NHSClaimComment_Factory.CreateOutgoing(i); }),
            NHSClaimSubmissions: model.NHSClaimSubmissions && model.NHSClaimSubmissions.map(function (i) { return NHSClaimSubmission_1.NHSClaimSubmission_Factory.CreateOutgoing(i); }),
            NHSCourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateOutgoing(model.NHSCourseDetail),
            NHSCourseDetailId: model.NHSCourseDetailId,
            NHSCourseDetailOrtho: NHSCourseDetailOrtho_1.NHSCourseDetailOrtho_Factory.CreateOutgoing(model.NHSCourseDetailOrtho),
            NHSSchedulePayments: model.NHSSchedulePayments && model.NHSSchedulePayments.map(function (i) { return NHSSchedulePayment_1.NHSSchedulePayment_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return NHSClaim_Factory;
}());
exports.NHSClaim_Factory = NHSClaim_Factory;
