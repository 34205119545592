"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialBalance_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InitialBalance_Factory = /** @class */ (function () {
    function InitialBalance_Factory() {
    }
    InitialBalance_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            Comments: model.Comments,
            LocationId: model.LocationId,
            PatientId: model.PatientId,
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    InitialBalance_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            Comments: model.Comments,
            LocationId: model.LocationId,
            PatientId: model.PatientId,
            TransactionTypeCode: model.TransactionTypeCode,
        };
        return result;
    };
    return InitialBalance_Factory;
}());
exports.InitialBalance_Factory = InitialBalance_Factory;
