"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSClaimSubmission_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClaim_1 = require("./NHSClaim");
var NHSCourseCDSValue_1 = require("./NHSCourseCDSValue");
var NHSCourseODSValue_1 = require("./NHSCourseODSValue");
var NHSCourseSnapshot_1 = require("./NHSCourseSnapshot");
var PatientSnapshot_1 = require("./PatientSnapshot");
var NHSClaimSubmission_Factory = /** @class */ (function () {
    function NHSClaimSubmission_Factory() {
    }
    NHSClaimSubmission_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CDSValuesId: model.CDSValuesId,
            ClaimId: model.ClaimId,
            DeleteOriginalSub: model.DeleteOriginalSub,
            NHSClaim: NHSClaim_1.NHSClaim_Factory.CreateIncoming(model.NHSClaim),
            NHSCourseCDSValue: NHSCourseCDSValue_1.NHSCourseCDSValue_Factory.CreateIncoming(model.NHSCourseCDSValue),
            NHSCourseODSValue: NHSCourseODSValue_1.NHSCourseODSValue_Factory.CreateIncoming(model.NHSCourseODSValue),
            NHSCourseSnapshot: NHSCourseSnapshot_1.NHSCourseSnapshot_Factory.CreateIncoming(model.NHSCourseSnapshot),
            NHSCourseSnapshotId: model.NHSCourseSnapshotId,
            ODSValuesId: model.ODSValuesId,
            PatientSnapshot: PatientSnapshot_1.PatientSnapshot_Factory.CreateIncoming(model.PatientSnapshot),
            PatientSnapshotId: model.PatientSnapshotId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReplaceScheduledClaim: model.ReplaceScheduledClaim,
            SendStatus: model.SendStatus,
            SubmissionNo: model.SubmissionNo,
        };
        return result;
    };
    NHSClaimSubmission_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CDSValuesId: model.CDSValuesId,
            ClaimId: model.ClaimId,
            DeleteOriginalSub: model.DeleteOriginalSub,
            NHSClaim: NHSClaim_1.NHSClaim_Factory.CreateOutgoing(model.NHSClaim),
            NHSCourseCDSValue: NHSCourseCDSValue_1.NHSCourseCDSValue_Factory.CreateOutgoing(model.NHSCourseCDSValue),
            NHSCourseODSValue: NHSCourseODSValue_1.NHSCourseODSValue_Factory.CreateOutgoing(model.NHSCourseODSValue),
            NHSCourseSnapshot: NHSCourseSnapshot_1.NHSCourseSnapshot_Factory.CreateOutgoing(model.NHSCourseSnapshot),
            NHSCourseSnapshotId: model.NHSCourseSnapshotId,
            ODSValuesId: model.ODSValuesId,
            PatientSnapshot: PatientSnapshot_1.PatientSnapshot_Factory.CreateOutgoing(model.PatientSnapshot),
            PatientSnapshotId: model.PatientSnapshotId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReplaceScheduledClaim: model.ReplaceScheduledClaim,
            SendStatus: model.SendStatus,
            SubmissionNo: model.SubmissionNo,
        };
        return result;
    };
    return NHSClaimSubmission_Factory;
}());
exports.NHSClaimSubmission_Factory = NHSClaimSubmission_Factory;
