"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VitalsRecord_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var VitalsRecordVersion_1 = require("./VitalsRecordVersion");
var VitalsRecord_Factory = /** @class */ (function () {
    function VitalsRecord_Factory() {
    }
    VitalsRecord_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            EffectiveDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EffectiveDate),
            LatestVersion: VitalsRecordVersion_1.VitalsRecordVersion_Factory.CreateIncoming(model.LatestVersion),
            LatestVersionNumber: model.LatestVersionNumber,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordTypeCode: model.RecordTypeCode,
            Versions: model.Versions && model.Versions.map(function (i) { return VitalsRecordVersion_1.VitalsRecordVersion_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    VitalsRecord_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            EffectiveDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EffectiveDate),
            LatestVersion: VitalsRecordVersion_1.VitalsRecordVersion_Factory.CreateOutgoing(model.LatestVersion),
            LatestVersionNumber: model.LatestVersionNumber,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RecordTypeCode: model.RecordTypeCode,
            Versions: model.Versions && model.Versions.map(function (i) { return VitalsRecordVersion_1.VitalsRecordVersion_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return VitalsRecord_Factory;
}());
exports.VitalsRecord_Factory = VitalsRecord_Factory;
