"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsSourceType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsInstance_1 = require("./EFormsInstance");
var EFormsSourceType_Factory = /** @class */ (function () {
    function EFormsSourceType_Factory() {
    }
    EFormsSourceType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            Instances: model.Instances && model.Instances.map(function (i) { return EFormsInstance_1.EFormsInstance_Factory.CreateIncoming(i); }),
            SourceTypeId: model.SourceTypeId,
        };
        return result;
    };
    EFormsSourceType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            Instances: model.Instances && model.Instances.map(function (i) { return EFormsInstance_1.EFormsInstance_Factory.CreateOutgoing(i); }),
            SourceTypeId: model.SourceTypeId,
        };
        return result;
    };
    return EFormsSourceType_Factory;
}());
exports.EFormsSourceType_Factory = EFormsSourceType_Factory;
