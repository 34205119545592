import UrlAssembler from "url-assembler";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { loadConfig } from "../../services/appConfigService";
import {
  IAuthFailureResponse,
  IAuthRequest,
  IAuthSuccessResponse,
  IAuthTokenInfo,
  ITokens,
  ErrorCodes,
  IToken,
  IAuthTokens,
  ssoClaimTypes
} from './models';
import { removeTokensFromStorage, saveTokensToStorage } from "./storage";

const ssoServiceInfo = {
  tokenPath: 'api/v2/auth/token',
  tokenInfoPath: 'api/v2/auth/token-info',
  client_id: 'OnlineDPMSFrontEnd2',
  client_secret: 'GyhfgMNQFlw3VC+27N8Nscq8GmpPanR34S7wfT11u0gqkrMJopyHXKFfLX1C8XXMLGYFFaDKlGJgjvgvSSGwTw==',
  scope: 'mobility'
};


function createToken(token: string, validityInSeconds: number, tokenType: string): IToken {
  const expiresOn =  new Date(new Date().valueOf() + (validityInSeconds * 1000));
  return {
    token,
    tokenType,
    expiresOn
  }
}


function urlEncodeParams(data: {[key: string]: any}) {
  const emptyUrl = new UrlAssembler();
  const query = emptyUrl.query(data);
  const queryStr = query.toString().substr(1); // Remove `?` prefix
  return queryStr;
}


function mapSuccessResponse(response : IAuthSuccessResponse) : ITokens {
  if(!response)
    throw new Error("Response should not be null/undefined.");

  if (!response.token_type)
    throw new Error("Missing: `token_type`");

  if (!response.access_token)
    throw new Error("Missing: `access_token`");

  if (!response.expires_in)
    throw new Error("Missing: `expires_in`");

  if (!response.refresh_token)
    throw new Error("Missing: `refresh_token`");

  if (!response.refresh_expires_in)
    throw new Error("Missing: `refresh_expires_in`");

  return {
    accessToken: createToken(response.access_token, parseInt(response.expires_in), response.token_type),
    refreshToken: createToken(response.refresh_token, parseInt(response.refresh_expires_in), "csd-sso:refresh"),
    state: response.state
  };
}


function getAuthRequestConfig(request?: IAuthRequest) : AxiosRequestConfig {
  const axiosConfig: AxiosRequestConfig = {
    auth: {
      username: ssoServiceInfo.client_id,
      password: ssoServiceInfo.client_secret
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-DeviceId': ''
    },
    data: request || null,
    transformRequest: urlEncodeParams
  }

  return axiosConfig;
}




function validateTokenInfo(tokenInfo: IAuthTokenInfo) {

  console.assert(!!tokenInfo);

  const { claims } = tokenInfo;
  console.assert(!!claims && !!claims.length);

  // Password Complexity check
  let claim = claims.find((claim) => claim.type ===  ssoClaimTypes.accountVersion);
  if (!claim || claim.value !== '2')
    throw new Error('Your user account password needs to be updated. Please reset your password.');
}


 export async function performAuth(authRequest: IAuthRequest) {
  const appConfig = await loadConfig();
  console.assert(!!appConfig.authUrl);

  removeTokensFromStorage();
  const { authUrl } = appConfig;

  // Call auth endpoint
  let url = new UrlAssembler(authUrl)
    .template(ssoServiceInfo.tokenPath)
    .toString();

    let authResponse: IAuthSuccessResponse;
  try {
    authRequest.scope = ssoServiceInfo.scope;
    const { data } = await axios.post<IAuthRequest, AxiosResponse<IAuthSuccessResponse>>(url, authRequest, getAuthRequestConfig(authRequest));
    authResponse = data;
  }
  catch(e) {
    const err = e as AxiosError<IAuthFailureResponse>;
    //Login Failure
    let errorMessage = `Login Failed: ${err.message}`;
    if (err.response && err.response.status === 400) {
      //OAuth Failure
      if (err.response.data.error === ErrorCodes.invalidGrant) {
        errorMessage = err.response.data.error_description || "The email address or password entered is incorrect";
      }
    }
    throw new Error(errorMessage);
  }

  var tokens = mapSuccessResponse(authResponse);
  console.assert(tokens.accessToken && tokens.refreshToken);

  // Verify token claims ...
  // Call token info endpoint
  url = authUrl
    .segment(ssoServiceInfo.tokenInfoPath)
    .param('access_token', tokens.accessToken.token)
    .toString();

  let tokenInfo: IAuthTokenInfo;
  try {
    const { data } = await axios.get<IAuthTokenInfo>(url);
    tokenInfo = data;
  }
  catch(e) {
    throw new Error("Failed to verify authentication token.")
  }

  validateTokenInfo(tokenInfo); // Throws exception, which is fine by us
  const authTokens: IAuthTokens = {
    ...tokens,
    claims: tokenInfo.claims
  }
  return saveTokensToStorage(authTokens);
}


// export function hasSessionExpired() {
//   let tokens = getTokensFromStorage();
//   return !!tokens && !hasExpired(tokens.accessToken);
// }

