"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrthoContract_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CDTCode_1 = require("./CDTCode");
var OrthoContractChargeSchedule_1 = require("./OrthoContractChargeSchedule");
var OrthoContractStatus_1 = require("./OrthoContractStatus");
var TransactionSubType_1 = require("./TransactionSubType");
var TransactionType_1 = require("./TransactionType");
var Location_1 = require("./Location");
var OrthoContractAdditionalCDTCode_1 = require("./OrthoContractAdditionalCDTCode");
var Patient_1 = require("./Patient");
var PatientPaymentMethod_1 = require("./PatientPaymentMethod");
var PatientResponsibleParty_1 = require("./PatientResponsibleParty");
var User_1 = require("./User");
var TreatmentModalityType_1 = require("./TreatmentModalityType");
var TreatmentStatus_1 = require("./TreatmentStatus");
var OrthoContract_Factory = /** @class */ (function () {
    function OrthoContract_Factory() {
    }
    OrthoContract_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ActualStartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ActualStartDate, { ignoreTimePortion: true }),
            Amount: model.Amount,
            AmountRemaining: model.AmountRemaining,
            CDTCode: CDTCode_1.CDTCode_Factory.CreateIncoming(model.CDTCode),
            CDTCodeId: model.CDTCodeId,
            ContractChargeSchedules: model.ContractChargeSchedules && model.ContractChargeSchedules.map(function (i) { return OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateIncoming(i); }),
            ContractStatus: OrthoContractStatus_1.OrthoContractStatus_Factory.CreateIncoming(model.ContractStatus),
            ContractStatusCode: model.ContractStatusCode,
            Description: model.Description,
            DiscountAmount: model.DiscountAmount,
            DiscountSubType: TransactionSubType_1.TransactionSubType_Factory.CreateIncoming(model.DiscountSubType),
            DiscountSubTypeId: model.DiscountSubTypeId,
            DiscountType: TransactionType_1.TransactionType_Factory.CreateIncoming(model.DiscountType),
            DiscountTypeCode: model.DiscountTypeCode,
            EstimatedCompletionDate: Date_Factory_1.Date_Factory.CreateIncoming(model.EstimatedCompletionDate, { ignoreTimePortion: true }),
            GuarantorAmount: model.GuarantorAmount,
            GuarantorChargeAmount: model.GuarantorChargeAmount,
            GuarantorChargeCount: model.GuarantorChargeCount,
            GuarantorContractFrequencyCode: model.GuarantorContractFrequencyCode,
            GuarantorDownpaymentAmount: model.GuarantorDownpaymentAmount,
            GuarantorDueDay: model.GuarantorDueDay,
            GuarantorFinalChargeAmount: model.GuarantorFinalChargeAmount,
            GuarantorFinalChargeDate: Date_Factory_1.Date_Factory.CreateIncoming(model.GuarantorFinalChargeDate, { ignoreTimePortion: true }),
            GuarantorMostRecentChargeDate: Date_Factory_1.Date_Factory.CreateIncoming(model.GuarantorMostRecentChargeDate, { ignoreTimePortion: true }),
            GuarantorSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateIncoming(model.GuarantorSchedule),
            GuarantorTotalChargeAmount: model.GuarantorTotalChargeAmount,
            HasInsuranceSchedule: model.HasInsuranceSchedule,
            InsuranceAmount: model.InsuranceAmount,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            OrthoContractAdditionalCDTCodes: model.OrthoContractAdditionalCDTCodes && model.OrthoContractAdditionalCDTCodes.map(function (i) { return OrthoContractAdditionalCDTCode_1.OrthoContractAdditionalCDTCode_Factory.CreateIncoming(i); }),
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            PatientPaymentMethod: PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateIncoming(model.PatientPaymentMethod),
            PatientPaymentMethodId: model.PatientPaymentMethodId,
            PatientResponsibleParty: PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateIncoming(model.PatientResponsibleParty),
            PatientResponsiblePartyId: model.PatientResponsiblePartyId,
            ProposedStartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ProposedStartDate, { ignoreTimePortion: true }),
            Provider: User_1.User_Factory.CreateIncoming(model.Provider),
            ProviderId: model.ProviderId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TreatmentModalityCode: model.TreatmentModalityCode,
            TreatmentModalityType: TreatmentModalityType_1.TreatmentModalityType_Factory.CreateIncoming(model.TreatmentModalityType),
            TreatmentStatus: TreatmentStatus_1.TreatmentStatus_Factory.CreateIncoming(model.TreatmentStatus),
            TreatmentStatusId: model.TreatmentStatusId,
        };
        return result;
    };
    OrthoContract_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ActualStartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ActualStartDate, { ignoreTimePortion: true }),
            Amount: model.Amount,
            AmountRemaining: model.AmountRemaining,
            CDTCode: CDTCode_1.CDTCode_Factory.CreateOutgoing(model.CDTCode),
            CDTCodeId: model.CDTCodeId,
            ContractChargeSchedules: model.ContractChargeSchedules && model.ContractChargeSchedules.map(function (i) { return OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateOutgoing(i); }),
            ContractStatus: OrthoContractStatus_1.OrthoContractStatus_Factory.CreateOutgoing(model.ContractStatus),
            ContractStatusCode: model.ContractStatusCode,
            Description: model.Description,
            DiscountAmount: model.DiscountAmount,
            DiscountSubType: TransactionSubType_1.TransactionSubType_Factory.CreateOutgoing(model.DiscountSubType),
            DiscountSubTypeId: model.DiscountSubTypeId,
            DiscountType: TransactionType_1.TransactionType_Factory.CreateOutgoing(model.DiscountType),
            DiscountTypeCode: model.DiscountTypeCode,
            EstimatedCompletionDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.EstimatedCompletionDate, { ignoreTimePortion: true }),
            GuarantorAmount: model.GuarantorAmount,
            GuarantorChargeAmount: model.GuarantorChargeAmount,
            GuarantorChargeCount: model.GuarantorChargeCount,
            GuarantorContractFrequencyCode: model.GuarantorContractFrequencyCode,
            GuarantorDownpaymentAmount: model.GuarantorDownpaymentAmount,
            GuarantorDueDay: model.GuarantorDueDay,
            GuarantorFinalChargeAmount: model.GuarantorFinalChargeAmount,
            GuarantorFinalChargeDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.GuarantorFinalChargeDate, { ignoreTimePortion: true }),
            GuarantorMostRecentChargeDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.GuarantorMostRecentChargeDate, { ignoreTimePortion: true }),
            GuarantorSchedule: OrthoContractChargeSchedule_1.OrthoContractChargeSchedule_Factory.CreateOutgoing(model.GuarantorSchedule),
            GuarantorTotalChargeAmount: model.GuarantorTotalChargeAmount,
            HasInsuranceSchedule: model.HasInsuranceSchedule,
            InsuranceAmount: model.InsuranceAmount,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            OrthoContractAdditionalCDTCodes: model.OrthoContractAdditionalCDTCodes && model.OrthoContractAdditionalCDTCodes.map(function (i) { return OrthoContractAdditionalCDTCode_1.OrthoContractAdditionalCDTCode_Factory.CreateOutgoing(i); }),
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            PatientPaymentMethod: PatientPaymentMethod_1.PatientPaymentMethod_Factory.CreateOutgoing(model.PatientPaymentMethod),
            PatientPaymentMethodId: model.PatientPaymentMethodId,
            PatientResponsibleParty: PatientResponsibleParty_1.PatientResponsibleParty_Factory.CreateOutgoing(model.PatientResponsibleParty),
            PatientResponsiblePartyId: model.PatientResponsiblePartyId,
            ProposedStartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ProposedStartDate, { ignoreTimePortion: true }),
            Provider: User_1.User_Factory.CreateOutgoing(model.Provider),
            ProviderId: model.ProviderId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TreatmentModalityCode: model.TreatmentModalityCode,
            TreatmentModalityType: TreatmentModalityType_1.TreatmentModalityType_Factory.CreateOutgoing(model.TreatmentModalityType),
            TreatmentStatus: TreatmentStatus_1.TreatmentStatus_Factory.CreateOutgoing(model.TreatmentStatus),
            TreatmentStatusId: model.TreatmentStatusId,
        };
        return result;
    };
    return OrthoContract_Factory;
}());
exports.OrthoContract_Factory = OrthoContract_Factory;
