"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsPatientPrefill_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsResponsiblePartyPrefill_1 = require("./EFormsResponsiblePartyPrefill");
var EFormsMedicalQuestionnairePrefill_1 = require("./EFormsMedicalQuestionnairePrefill");
var EFormsPersonPrefill_1 = require("./EFormsPersonPrefill");
var EFormsInsurancePrefill_1 = require("./EFormsInsurancePrefill");
var EFormsPatientPrefill_Factory = /** @class */ (function () {
    function EFormsPatientPrefill_Factory() {
    }
    EFormsPatientPrefill_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EmergencyContact: EFormsResponsiblePartyPrefill_1.EFormsResponsiblePartyPrefill_Factory.CreateIncoming(model.EmergencyContact),
            MedicalQuestionnaire: EFormsMedicalQuestionnairePrefill_1.EFormsMedicalQuestionnairePrefill_Factory.CreateIncoming(model.MedicalQuestionnaire),
            Person: EFormsPersonPrefill_1.EFormsPersonPrefill_Factory.CreateIncoming(model.Person),
            PrimaryInsurance: EFormsInsurancePrefill_1.EFormsInsurancePrefill_Factory.CreateIncoming(model.PrimaryInsurance),
            RecordId: model.RecordId,
            ResponsibleParty: EFormsResponsiblePartyPrefill_1.EFormsResponsiblePartyPrefill_Factory.CreateIncoming(model.ResponsibleParty),
            SecondaryInsurance: EFormsInsurancePrefill_1.EFormsInsurancePrefill_Factory.CreateIncoming(model.SecondaryInsurance),
        };
        return result;
    };
    EFormsPatientPrefill_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EmergencyContact: EFormsResponsiblePartyPrefill_1.EFormsResponsiblePartyPrefill_Factory.CreateOutgoing(model.EmergencyContact),
            MedicalQuestionnaire: EFormsMedicalQuestionnairePrefill_1.EFormsMedicalQuestionnairePrefill_Factory.CreateOutgoing(model.MedicalQuestionnaire),
            Person: EFormsPersonPrefill_1.EFormsPersonPrefill_Factory.CreateOutgoing(model.Person),
            PrimaryInsurance: EFormsInsurancePrefill_1.EFormsInsurancePrefill_Factory.CreateOutgoing(model.PrimaryInsurance),
            RecordId: model.RecordId,
            ResponsibleParty: EFormsResponsiblePartyPrefill_1.EFormsResponsiblePartyPrefill_Factory.CreateOutgoing(model.ResponsibleParty),
            SecondaryInsurance: EFormsInsurancePrefill_1.EFormsInsurancePrefill_Factory.CreateOutgoing(model.SecondaryInsurance),
        };
        return result;
    };
    return EFormsPatientPrefill_Factory;
}());
exports.EFormsPatientPrefill_Factory = EFormsPatientPrefill_Factory;
