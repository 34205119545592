"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalClaimItemAdditionalInfo_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MedicalClaimItemAdditionalInfo_Factory = /** @class */ (function () {
    function MedicalClaimItemAdditionalInfo_Factory() {
    }
    MedicalClaimItemAdditionalInfo_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Item24bPlaceOfService: model.Item24bPlaceOfService,
            Item24cEmergency: model.Item24cEmergency,
            Item24gDaysUnits: model.Item24gDaysUnits,
            Item24hEPSDTCode: model.Item24hEPSDTCode,
            Item24hFamilyPlan: model.Item24hFamilyPlan,
            RecordId: model.RecordId,
        };
        return result;
    };
    MedicalClaimItemAdditionalInfo_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Item24bPlaceOfService: model.Item24bPlaceOfService,
            Item24cEmergency: model.Item24cEmergency,
            Item24gDaysUnits: model.Item24gDaysUnits,
            Item24hEPSDTCode: model.Item24hEPSDTCode,
            Item24hFamilyPlan: model.Item24hFamilyPlan,
            RecordId: model.RecordId,
        };
        return result;
    };
    return MedicalClaimItemAdditionalInfo_Factory;
}());
exports.MedicalClaimItemAdditionalInfo_Factory = MedicalClaimItemAdditionalInfo_Factory;
