"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracEnclosure_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracCaseEnclosure_1 = require("./LabtracCaseEnclosure");
var LabtracLab_1 = require("./LabtracLab");
var LabtracEnclosure_Factory = /** @class */ (function () {
    function LabtracEnclosure_Factory() {
    }
    LabtracEnclosure_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DisplaySequence: model.DisplaySequence,
            ExternalId: model.ExternalId,
            LabCode: model.LabCode,
            LabReference: model.LabReference,
            LabtracCaseEnclosures: model.LabtracCaseEnclosures && model.LabtracCaseEnclosures.map(function (i) { return LabtracCaseEnclosure_1.LabtracCaseEnclosure_Factory.CreateIncoming(i); }),
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateIncoming(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    LabtracEnclosure_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DisplaySequence: model.DisplaySequence,
            ExternalId: model.ExternalId,
            LabCode: model.LabCode,
            LabReference: model.LabReference,
            LabtracCaseEnclosures: model.LabtracCaseEnclosures && model.LabtracCaseEnclosures.map(function (i) { return LabtracCaseEnclosure_1.LabtracCaseEnclosure_Factory.CreateOutgoing(i); }),
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateOutgoing(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return LabtracEnclosure_Factory;
}());
exports.LabtracEnclosure_Factory = LabtracEnclosure_Factory;
