"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReportTypeCategoryColumn_1 = require("./ReportTypeCategoryColumn");
var SavedReport_1 = require("./SavedReport");
var UserReportFavourite_1 = require("./UserReportFavourite");
var ReportType_Factory = /** @class */ (function () {
    function ReportType_Factory() {
    }
    ReportType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            ReportTypeCategoryColumns: model.ReportTypeCategoryColumns && model.ReportTypeCategoryColumns.map(function (i) { return ReportTypeCategoryColumn_1.ReportTypeCategoryColumn_Factory.CreateIncoming(i); }),
            SavedReports: model.SavedReports && model.SavedReports.map(function (i) { return SavedReport_1.SavedReport_Factory.CreateIncoming(i); }),
            UserReportFavourites: model.UserReportFavourites && model.UserReportFavourites.map(function (i) { return UserReportFavourite_1.UserReportFavourite_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    ReportType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            ReportTypeCategoryColumns: model.ReportTypeCategoryColumns && model.ReportTypeCategoryColumns.map(function (i) { return ReportTypeCategoryColumn_1.ReportTypeCategoryColumn_Factory.CreateOutgoing(i); }),
            SavedReports: model.SavedReports && model.SavedReports.map(function (i) { return SavedReport_1.SavedReport_Factory.CreateOutgoing(i); }),
            UserReportFavourites: model.UserReportFavourites && model.UserReportFavourites.map(function (i) { return UserReportFavourite_1.UserReportFavourite_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return ReportType_Factory;
}());
exports.ReportType_Factory = ReportType_Factory;
