"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSClinicalDataSet_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClinicalDataSet_Factory = /** @class */ (function () {
    function NHSClinicalDataSet_Factory() {
    }
    NHSClinicalDataSet_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            DataSetID: model.DataSetID,
            Description: model.Description,
            Encoding: model.Encoding,
            OrthoFormID: model.OrthoFormID,
            StdFormID: model.StdFormID,
        };
        return result;
    };
    NHSClinicalDataSet_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            DataSetID: model.DataSetID,
            Description: model.Description,
            Encoding: model.Encoding,
            OrthoFormID: model.OrthoFormID,
            StdFormID: model.StdFormID,
        };
        return result;
    };
    return NHSClinicalDataSet_Factory;
}());
exports.NHSClinicalDataSet_Factory = NHSClinicalDataSet_Factory;
