"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracLab_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracDoctor_1 = require("./LabtracDoctor");
var LabtracEnclosure_1 = require("./LabtracEnclosure");
var LabtracItemOption_1 = require("./LabtracItemOption");
var LabtracShippingRoute_1 = require("./LabtracShippingRoute");
var LabtracLab_Factory = /** @class */ (function () {
    function LabtracLab_Factory() {
    }
    LabtracLab_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Active: model.Active,
            ExternalId: model.ExternalId,
            LabtracDoctors: model.LabtracDoctors && model.LabtracDoctors.map(function (i) { return LabtracDoctor_1.LabtracDoctor_Factory.CreateIncoming(i); }),
            LabtracEnclosures: model.LabtracEnclosures && model.LabtracEnclosures.map(function (i) { return LabtracEnclosure_1.LabtracEnclosure_Factory.CreateIncoming(i); }),
            LabtracItemOptions: model.LabtracItemOptions && model.LabtracItemOptions.map(function (i) { return LabtracItemOption_1.LabtracItemOption_Factory.CreateIncoming(i); }),
            LabtracShippingRoutes: model.LabtracShippingRoutes && model.LabtracShippingRoutes.map(function (i) { return LabtracShippingRoute_1.LabtracShippingRoute_Factory.CreateIncoming(i); }),
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    LabtracLab_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Active: model.Active,
            ExternalId: model.ExternalId,
            LabtracDoctors: model.LabtracDoctors && model.LabtracDoctors.map(function (i) { return LabtracDoctor_1.LabtracDoctor_Factory.CreateOutgoing(i); }),
            LabtracEnclosures: model.LabtracEnclosures && model.LabtracEnclosures.map(function (i) { return LabtracEnclosure_1.LabtracEnclosure_Factory.CreateOutgoing(i); }),
            LabtracItemOptions: model.LabtracItemOptions && model.LabtracItemOptions.map(function (i) { return LabtracItemOption_1.LabtracItemOption_Factory.CreateOutgoing(i); }),
            LabtracShippingRoutes: model.LabtracShippingRoutes && model.LabtracShippingRoutes.map(function (i) { return LabtracShippingRoute_1.LabtracShippingRoute_Factory.CreateOutgoing(i); }),
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return LabtracLab_Factory;
}());
exports.LabtracLab_Factory = LabtracLab_Factory;
