"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnterpriseConsultationsAuditLog_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EnterpriseConsultation_1 = require("./EnterpriseConsultation");
var User_1 = require("./User");
var EnterpriseConsultationsAuditLog_Factory = /** @class */ (function () {
    function EnterpriseConsultationsAuditLog_Factory() {
    }
    EnterpriseConsultationsAuditLog_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ConsultationStatus: model.ConsultationStatus,
            EnterpriseConsultation: EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateIncoming(model.EnterpriseConsultation),
            EnterpriseConsultationId: model.EnterpriseConsultationId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    EnterpriseConsultationsAuditLog_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ConsultationStatus: model.ConsultationStatus,
            EnterpriseConsultation: EnterpriseConsultation_1.EnterpriseConsultation_Factory.CreateOutgoing(model.EnterpriseConsultation),
            EnterpriseConsultationId: model.EnterpriseConsultationId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return EnterpriseConsultationsAuditLog_Factory;
}());
exports.EnterpriseConsultationsAuditLog_Factory = EnterpriseConsultationsAuditLog_Factory;
