"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCategoryDate_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCategoryBand_1 = require("./NHSCategoryBand");
var NHSCategoryDate_Factory = /** @class */ (function () {
    function NHSCategoryDate_Factory() {
    }
    NHSCategoryDate_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            InternalNotes: model.InternalNotes,
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateIncoming(i); }),
            NHSCategoryDateID: model.NHSCategoryDateID,
            StartDate: Date_Factory_1.Date_Factory.CreateIncoming(model.StartDate),
        };
        return result;
    };
    NHSCategoryDate_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            InternalNotes: model.InternalNotes,
            NHSCategoryBands: model.NHSCategoryBands && model.NHSCategoryBands.map(function (i) { return NHSCategoryBand_1.NHSCategoryBand_Factory.CreateOutgoing(i); }),
            NHSCategoryDateID: model.NHSCategoryDateID,
            StartDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.StartDate),
        };
        return result;
    };
    return NHSCategoryDate_Factory;
}());
exports.NHSCategoryDate_Factory = NHSCategoryDate_Factory;
