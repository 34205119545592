"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsurancePlan_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Address_1 = require("./Address");
var InsurancePlanDetail_1 = require("./InsurancePlanDetail");
var InsurancePayer_1 = require("./InsurancePayer");
var MedicalInsuranceType_1 = require("./MedicalInsuranceType");
var OrthoContractFrequency_1 = require("./OrthoContractFrequency");
var PhoneNumber_1 = require("./PhoneNumber");
var InsurancePlan_Factory = /** @class */ (function () {
    function InsurancePlan_Factory() {
    }
    InsurancePlan_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Address: Address_1.Address_Factory.CreateIncoming(model.Address),
            AddressId: model.AddressId,
            AnnualCoverageEndDay: model.AnnualCoverageEndDay,
            AnnualCoverageEndMonth: model.AnnualCoverageEndMonth,
            AnnualCoverageStartDay: model.AnnualCoverageStartDay,
            AnnualCoverageStartMonth: model.AnnualCoverageStartMonth,
            ContactName: model.ContactName,
            COTClaimsNotRequired: model.COTClaimsNotRequired,
            Details: InsurancePlanDetail_1.InsurancePlanDetail_Factory.CreateIncoming(model.Details),
            EligibilityWebsite: model.EligibilityWebsite,
            FamilyDeductible: model.FamilyDeductible,
            FamilyMaxAllowableBenefit: model.FamilyMaxAllowableBenefit,
            FrequencyCode: model.FrequencyCode,
            GroupIdName: model.GroupIdName,
            IndividualDeductible: model.IndividualDeductible,
            IndividualMaxAllowableBenefit: model.IndividualMaxAllowableBenefit,
            InheritContactFromPayer: model.InheritContactFromPayer,
            InsurancePayer: InsurancePayer_1.InsurancePayer_Factory.CreateIncoming(model.InsurancePayer),
            InsurancePlanTypeCode: model.InsurancePlanTypeCode,
            IsAlternateProcedureCode: model.IsAlternateProcedureCode,
            IsContracted: model.IsContracted,
            IsCoverageMethodPercentage: model.IsCoverageMethodPercentage,
            IsPlanStatusActive: model.IsPlanStatusActive,
            MedicalInsuranceType: MedicalInsuranceType_1.MedicalInsuranceType_Factory.CreateIncoming(model.MedicalInsuranceType),
            MedicalInsuranceTypeCode: model.MedicalInsuranceTypeCode,
            OrthoContractFrequency: OrthoContractFrequency_1.OrthoContractFrequency_Factory.CreateIncoming(model.OrthoContractFrequency),
            PaperClaimFormatCode: model.PaperClaimFormatCode,
            PayerId: model.PayerId,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(model.PhoneNumber),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateIncoming(i); }),
            PlanName: model.PlanName,
            PrimaryPhoneNumberId: model.PrimaryPhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    InsurancePlan_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Address: Address_1.Address_Factory.CreateOutgoing(model.Address),
            AddressId: model.AddressId,
            AnnualCoverageEndDay: model.AnnualCoverageEndDay,
            AnnualCoverageEndMonth: model.AnnualCoverageEndMonth,
            AnnualCoverageStartDay: model.AnnualCoverageStartDay,
            AnnualCoverageStartMonth: model.AnnualCoverageStartMonth,
            ContactName: model.ContactName,
            COTClaimsNotRequired: model.COTClaimsNotRequired,
            Details: InsurancePlanDetail_1.InsurancePlanDetail_Factory.CreateOutgoing(model.Details),
            EligibilityWebsite: model.EligibilityWebsite,
            FamilyDeductible: model.FamilyDeductible,
            FamilyMaxAllowableBenefit: model.FamilyMaxAllowableBenefit,
            FrequencyCode: model.FrequencyCode,
            GroupIdName: model.GroupIdName,
            IndividualDeductible: model.IndividualDeductible,
            IndividualMaxAllowableBenefit: model.IndividualMaxAllowableBenefit,
            InheritContactFromPayer: model.InheritContactFromPayer,
            InsurancePayer: InsurancePayer_1.InsurancePayer_Factory.CreateOutgoing(model.InsurancePayer),
            InsurancePlanTypeCode: model.InsurancePlanTypeCode,
            IsAlternateProcedureCode: model.IsAlternateProcedureCode,
            IsContracted: model.IsContracted,
            IsCoverageMethodPercentage: model.IsCoverageMethodPercentage,
            IsPlanStatusActive: model.IsPlanStatusActive,
            MedicalInsuranceType: MedicalInsuranceType_1.MedicalInsuranceType_Factory.CreateOutgoing(model.MedicalInsuranceType),
            MedicalInsuranceTypeCode: model.MedicalInsuranceTypeCode,
            OrthoContractFrequency: OrthoContractFrequency_1.OrthoContractFrequency_Factory.CreateOutgoing(model.OrthoContractFrequency),
            PaperClaimFormatCode: model.PaperClaimFormatCode,
            PayerId: model.PayerId,
            PhoneNumber: PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(model.PhoneNumber),
            PhoneNumbers: model.PhoneNumbers && model.PhoneNumbers.map(function (i) { return PhoneNumber_1.PhoneNumber_Factory.CreateOutgoing(i); }),
            PlanName: model.PlanName,
            PrimaryPhoneNumberId: model.PrimaryPhoneNumberId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return InsurancePlan_Factory;
}());
exports.InsurancePlan_Factory = InsurancePlan_Factory;
