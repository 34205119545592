"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDefaultWorkingDay_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var UserWorkingDay_1 = require("./UserWorkingDay");
var Location_1 = require("./Location");
var UserDefaultWorkingDay_Factory = /** @class */ (function () {
    function UserDefaultWorkingDay_Factory() {
    }
    UserDefaultWorkingDay_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            FridayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(model.FridayWorkingDay),
            FridayWorkingDayId: model.FridayWorkingDayId,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            MondayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(model.MondayWorkingDay),
            MondayWorkingDayId: model.MondayWorkingDayId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            SaturdayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(model.SaturdayWorkingDay),
            SaturdayWorkingDayId: model.SaturdayWorkingDayId,
            SundayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(model.SundayWorkingDay),
            SundayWorkingDayId: model.SundayWorkingDayId,
            ThursdayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(model.ThursdayWorkingDay),
            ThursdayWorkingDayId: model.ThursdayWorkingDayId,
            TuesdayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(model.TuesdayWorkingDay),
            TuesdayWorkingDayId: model.TuesdayWorkingDayId,
            UserId: model.UserId,
            WednesdayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateIncoming(model.WednesdayWorkingDay),
            WednesdayWorkingDayId: model.WednesdayWorkingDayId,
        };
        return result;
    };
    UserDefaultWorkingDay_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            FridayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(model.FridayWorkingDay),
            FridayWorkingDayId: model.FridayWorkingDayId,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            MondayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(model.MondayWorkingDay),
            MondayWorkingDayId: model.MondayWorkingDayId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            SaturdayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(model.SaturdayWorkingDay),
            SaturdayWorkingDayId: model.SaturdayWorkingDayId,
            SundayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(model.SundayWorkingDay),
            SundayWorkingDayId: model.SundayWorkingDayId,
            ThursdayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(model.ThursdayWorkingDay),
            ThursdayWorkingDayId: model.ThursdayWorkingDayId,
            TuesdayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(model.TuesdayWorkingDay),
            TuesdayWorkingDayId: model.TuesdayWorkingDayId,
            UserId: model.UserId,
            WednesdayWorkingDay: UserWorkingDay_1.UserWorkingDay_Factory.CreateOutgoing(model.WednesdayWorkingDay),
            WednesdayWorkingDayId: model.WednesdayWorkingDayId,
        };
        return result;
    };
    return UserDefaultWorkingDay_Factory;
}());
exports.UserDefaultWorkingDay_Factory = UserDefaultWorkingDay_Factory;
