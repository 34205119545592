"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientStatus_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientStatus_Factory = /** @class */ (function () {
    function PatientStatus_Factory() {
    }
    PatientStatus_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            PatientStatus1: model.PatientStatus1,
            PatientStatusCode: model.PatientStatusCode,
        };
        return result;
    };
    PatientStatus_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            PatientStatus1: model.PatientStatus1,
            PatientStatusCode: model.PatientStatusCode,
        };
        return result;
    };
    return PatientStatus_Factory;
}());
exports.PatientStatus_Factory = PatientStatus_Factory;
