"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracCaseProduct_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracCase_1 = require("./LabtracCase");
var LabtracProduct_1 = require("./LabtracProduct");
var LabtracCaseProduct_Factory = /** @class */ (function () {
    function LabtracCaseProduct_Factory() {
    }
    LabtracCaseProduct_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            AppReference: model.AppReference,
            Discount: model.Discount,
            ExternalId: model.ExternalId,
            ExtPrice: model.ExtPrice,
            LabReference: model.LabReference,
            LabtracCase: LabtracCase_1.LabtracCase_Factory.CreateIncoming(model.LabtracCase),
            LabtracCaseId: model.LabtracCaseId,
            LabtracProduct: LabtracProduct_1.LabtracProduct_Factory.CreateIncoming(model.LabtracProduct),
            LabtracProductId: model.LabtracProductId,
            Name: model.Name,
            Qty: model.Qty,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Tax: model.Tax,
            UnitPrice: model.UnitPrice,
        };
        return result;
    };
    LabtracCaseProduct_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            AppReference: model.AppReference,
            Discount: model.Discount,
            ExternalId: model.ExternalId,
            ExtPrice: model.ExtPrice,
            LabReference: model.LabReference,
            LabtracCase: LabtracCase_1.LabtracCase_Factory.CreateOutgoing(model.LabtracCase),
            LabtracCaseId: model.LabtracCaseId,
            LabtracProduct: LabtracProduct_1.LabtracProduct_Factory.CreateOutgoing(model.LabtracProduct),
            LabtracProductId: model.LabtracProductId,
            Name: model.Name,
            Qty: model.Qty,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Tax: model.Tax,
            UnitPrice: model.UnitPrice,
        };
        return result;
    };
    return LabtracCaseProduct_Factory;
}());
exports.LabtracCaseProduct_Factory = LabtracCaseProduct_Factory;
