"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlNumber_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ControlNumber_Factory = /** @class */ (function () {
    function ControlNumber_Factory() {
    }
    ControlNumber_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            Id: model.Id,
        };
        return result;
    };
    ControlNumber_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            Id: model.Id,
        };
        return result;
    };
    return ControlNumber_Factory;
}());
exports.ControlNumber_Factory = ControlNumber_Factory;
