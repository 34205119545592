"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumber_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PhoneNumberType_1 = require("./PhoneNumberType");
var PhoneNumber_Factory = /** @class */ (function () {
    function PhoneNumber_Factory() {
    }
    PhoneNumber_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CountryPrefix: model.CountryPrefix,
            Description: model.Description,
            Extension: model.Extension,
            FormattedNumber: model.FormattedNumber,
            Number: model.Number,
            PhoneNumberType: PhoneNumberType_1.PhoneNumberType_Factory.CreateIncoming(model.PhoneNumberType),
            PhoneTypeCode: model.PhoneTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PhoneNumber_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CountryPrefix: model.CountryPrefix,
            Description: model.Description,
            Extension: model.Extension,
            FormattedNumber: model.FormattedNumber,
            Number: model.Number,
            PhoneNumberType: PhoneNumberType_1.PhoneNumberType_Factory.CreateOutgoing(model.PhoneNumberType),
            PhoneTypeCode: model.PhoneTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PhoneNumber_Factory;
}());
exports.PhoneNumber_Factory = PhoneNumber_Factory;
