"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewChargeProcedure_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ProcedureDiagnosisCode_1 = require("./ProcedureDiagnosisCode");
var NewChargeProcedure_Factory = /** @class */ (function () {
    function NewChargeProcedure_Factory() {
    }
    NewChargeProcedure_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            BillInsurance: model.BillInsurance,
            CPTModifierIds: model.CPTModifierIds && model.CPTModifierIds.map(function (i) { return i; }),
            DiagnosisCode: model.DiagnosisCode && model.DiagnosisCode.map(function (i) { return ProcedureDiagnosisCode_1.ProcedureDiagnosisCode_Factory.CreateIncoming(i); }),
            IsExternalServiceLocation: model.IsExternalServiceLocation,
            LocationId: model.LocationId,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            OriginalFee: model.OriginalFee,
            OverrideNotes: model.OverrideNotes,
            PatientProcedureId: model.PatientProcedureId,
            ProcedureId: model.ProcedureId,
            ProviderId: model.ProviderId,
            ServiceDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ServiceDate),
            ServiceLocationId: model.ServiceLocationId,
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothSurface: model.ToothSurface,
        };
        return result;
    };
    NewChargeProcedure_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            BillInsurance: model.BillInsurance,
            CPTModifierIds: model.CPTModifierIds && model.CPTModifierIds.map(function (i) { return i; }),
            DiagnosisCode: model.DiagnosisCode && model.DiagnosisCode.map(function (i) { return ProcedureDiagnosisCode_1.ProcedureDiagnosisCode_Factory.CreateOutgoing(i); }),
            IsExternalServiceLocation: model.IsExternalServiceLocation,
            LocationId: model.LocationId,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            OriginalFee: model.OriginalFee,
            OverrideNotes: model.OverrideNotes,
            PatientProcedureId: model.PatientProcedureId,
            ProcedureId: model.ProcedureId,
            ProviderId: model.ProviderId,
            ServiceDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ServiceDate),
            ServiceLocationId: model.ServiceLocationId,
            ToothCode: model.ToothCode,
            ToothGroupMask: model.ToothGroupMask,
            ToothSurface: model.ToothSurface,
        };
        return result;
    };
    return NewChargeProcedure_Factory;
}());
exports.NewChargeProcedure_Factory = NewChargeProcedure_Factory;
