import * as signals from "signals";

// Strongly-typed wrappers around `signals.Signal`.

export type SignalCallback<T> = (param: T) => void;
export type SignalCallback2<T1, T2> = (param1: T1, param2: T2) => void;
export type SignalCallback3<T1, T2, T3> = (param1: T1, param2: T2, param3: T3) => void;

// Has one type param
export interface ISignalDispatcher<T> {
  addListener(listener: SignalCallback<T>): void;
  removeListener(listener: SignalCallback<T>): void;
  dispatch: SignalCallback<T>;
  dispose: () => void;
}

// Has two type params
export interface ISignalDispatcher2<T1, T2> {
  addListener(listener: SignalCallback2<T1, T2>): void;
  removeListener(lisetner: SignalCallback2<T1, T2>): void;
  dispatch: SignalCallback2<T1, T2>;
  dispose: () => void;
}

// Has three type params
export interface ISignalDispatcher3<T1, T2, T3> {
  addListener(listener: SignalCallback3<T1, T2, T3>): void;
  removeListener(lisetner: SignalCallback3<T1, T2, T3>): void;
  dispatch: SignalCallback3<T1, T2, T3>;
  dispose: () => void;
}

// Private implementation
class SignalDispatcherImpl extends signals.Signal implements ISignalDispatcher<any> {
  addListener = super.add.bind(this);
  removeListener = super.remove.bind(this);
}

export function isSignalDispatcherInstance(obj: any): obj is SignalDispatcherImpl {
  return obj instanceof SignalDispatcherImpl;
}

// Strongly typed factory functions
export function createSignal<T>(): ISignalDispatcher<T>;
export function createSignal<T1, T2>(): ISignalDispatcher2<T1, T2>;
export function createSignal<T1, T2, T3>(): ISignalDispatcher3<T1, T2, T3>;
export function createSignal() {
  return new SignalDispatcherImpl();
}
