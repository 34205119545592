//import { IHubConnection } from "signalr-no-jquery";
import { ISignalDispatcher } from "../../utils/signalDispatcher";
import * as signalR from "@aspnet/signalr";

// These were moved out of `HubManager.ts` file in order
// to remove cyclic dependency between `BaseHub.ts` and `HubManager.ts`.
export const iid_HubManager = Symbol("Hub Manager");

export interface IHubManager {
  configure(): void;
  start(): void;
  Connecting: ISignalDispatcher<signalR.HubConnection>;
  Connected: ISignalDispatcher<signalR.HubConnection>;
  Disconnected: ISignalDispatcher<signalR.HubConnection>;
}
