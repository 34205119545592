"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalEventGroup_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEvent_1 = require("./ClinicalEvent");
var ClinicalEventGroup_Factory = /** @class */ (function () {
    function ClinicalEventGroup_Factory() {
    }
    ClinicalEventGroup_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AddedByUserId: model.AddedByUserId,
            ClinicalEventConditionCode: model.ClinicalEventConditionCode,
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            PatientProcedureGroupId: model.PatientProcedureGroupId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    ClinicalEventGroup_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AddedByUserId: model.AddedByUserId,
            ClinicalEventConditionCode: model.ClinicalEventConditionCode,
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            PatientProcedureGroupId: model.PatientProcedureGroupId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return ClinicalEventGroup_Factory;
}());
exports.ClinicalEventGroup_Factory = ClinicalEventGroup_Factory;
