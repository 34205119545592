"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerioExamSettings_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PerioExamSettings_Factory = /** @class */ (function () {
    function PerioExamSettings_Factory() {
    }
    PerioExamSettings_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AlwaysSameOrder: model.AlwaysSameOrder,
            ByArch: model.ByArch,
            LingualToFacial: model.LingualToFacial,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RightToLeft: model.RightToLeft,
            SkipCrowns: model.SkipCrowns,
            SkipImplants: model.SkipImplants,
            SkipMissing: model.SkipMissing,
            SkipPontics: model.SkipPontics,
            SkipPrimary: model.SkipPrimary,
            SkipUnerupted: model.SkipUnerupted,
            TopToBottom: model.TopToBottom,
            TransitionSameSide: model.TransitionSameSide,
            UserId: model.UserId,
        };
        return result;
    };
    PerioExamSettings_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AlwaysSameOrder: model.AlwaysSameOrder,
            ByArch: model.ByArch,
            LingualToFacial: model.LingualToFacial,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RightToLeft: model.RightToLeft,
            SkipCrowns: model.SkipCrowns,
            SkipImplants: model.SkipImplants,
            SkipMissing: model.SkipMissing,
            SkipPontics: model.SkipPontics,
            SkipPrimary: model.SkipPrimary,
            SkipUnerupted: model.SkipUnerupted,
            TopToBottom: model.TopToBottom,
            TransitionSameSide: model.TransitionSameSide,
            UserId: model.UserId,
        };
        return result;
    };
    return PerioExamSettings_Factory;
}());
exports.PerioExamSettings_Factory = PerioExamSettings_Factory;
