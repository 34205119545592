"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientConnectionTypeCodes_DisplayOrders = exports.PatientConnectionTypeCodes_Descriptions_English = exports.PatientConnectionTypeCodes_Descriptions = exports.PatientConnectionTypeCodes_translateDescriptionBack = exports.PatientConnectionTypeCodes_translateDescriptions = exports.PatientConnectionTypeCodes = void 0;
var PatientConnectionTypeCodes;
(function (PatientConnectionTypeCodes) {
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Unknown"] = 0] = "Unknown";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Self"] = 1] = "Self";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Parent"] = 2] = "Parent";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Guardian"] = 4] = "Guardian";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["GrandParent"] = 5] = "GrandParent";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["UncleOrAunt"] = 7] = "UncleOrAunt";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["StepParent"] = 9] = "StepParent";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Sibling"] = 11] = "Sibling";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Cousin"] = 13] = "Cousin";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["StepSibling"] = 14] = "StepSibling";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Spouse"] = 16] = "Spouse";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Child"] = 17] = "Child";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["StepChild"] = 19] = "StepChild";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["GrandChild"] = 21] = "GrandChild";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["NephewOrNiece"] = 23] = "NephewOrNiece";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Ward"] = 25] = "Ward";
    PatientConnectionTypeCodes[PatientConnectionTypeCodes["Other"] = 26] = "Other";
})(PatientConnectionTypeCodes = exports.PatientConnectionTypeCodes || (exports.PatientConnectionTypeCodes = {}));
function PatientConnectionTypeCodes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.PatientConnectionTypeCodes_Descriptions);
    allKeys.forEach(function (key) {
        exports.PatientConnectionTypeCodes_Descriptions[key] = translationFunction(exports.PatientConnectionTypeCodes_Descriptions[key]);
    });
}
exports.PatientConnectionTypeCodes_translateDescriptions = PatientConnectionTypeCodes_translateDescriptions;
function PatientConnectionTypeCodes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.PatientConnectionTypeCodes_Descriptions);
    allKeys.forEach(function (key) {
        exports.PatientConnectionTypeCodes_Descriptions[key] = exports.PatientConnectionTypeCodes_Descriptions_English[key];
    });
}
exports.PatientConnectionTypeCodes_translateDescriptionBack = PatientConnectionTypeCodes_translateDescriptionBack;
var _PatientConnectionTypeCodes_Descriptions = {};
_PatientConnectionTypeCodes_Descriptions.Unknown = _PatientConnectionTypeCodes_Descriptions[0] = "Unknown";
_PatientConnectionTypeCodes_Descriptions.Self = _PatientConnectionTypeCodes_Descriptions[1] = "Self";
_PatientConnectionTypeCodes_Descriptions.Parent = _PatientConnectionTypeCodes_Descriptions[2] = "Parent";
_PatientConnectionTypeCodes_Descriptions.Guardian = _PatientConnectionTypeCodes_Descriptions[4] = "Guardian";
_PatientConnectionTypeCodes_Descriptions.GrandParent = _PatientConnectionTypeCodes_Descriptions[5] = "Grandparent";
_PatientConnectionTypeCodes_Descriptions.UncleOrAunt = _PatientConnectionTypeCodes_Descriptions[7] = "Uncle/Aunt";
_PatientConnectionTypeCodes_Descriptions.StepParent = _PatientConnectionTypeCodes_Descriptions[9] = "Stepparent";
_PatientConnectionTypeCodes_Descriptions.Sibling = _PatientConnectionTypeCodes_Descriptions[11] = "Sibling";
_PatientConnectionTypeCodes_Descriptions.Cousin = _PatientConnectionTypeCodes_Descriptions[13] = "Cousin";
_PatientConnectionTypeCodes_Descriptions.StepSibling = _PatientConnectionTypeCodes_Descriptions[14] = "Stepsibling";
_PatientConnectionTypeCodes_Descriptions.Spouse = _PatientConnectionTypeCodes_Descriptions[16] = "Spouse";
_PatientConnectionTypeCodes_Descriptions.Child = _PatientConnectionTypeCodes_Descriptions[17] = "Child";
_PatientConnectionTypeCodes_Descriptions.StepChild = _PatientConnectionTypeCodes_Descriptions[19] = "Stepchild";
_PatientConnectionTypeCodes_Descriptions.GrandChild = _PatientConnectionTypeCodes_Descriptions[21] = "Grandchild";
_PatientConnectionTypeCodes_Descriptions.NephewOrNiece = _PatientConnectionTypeCodes_Descriptions[23] = "Nephew/Niece";
_PatientConnectionTypeCodes_Descriptions.Ward = _PatientConnectionTypeCodes_Descriptions[25] = "Ward";
_PatientConnectionTypeCodes_Descriptions.Other = _PatientConnectionTypeCodes_Descriptions[26] = "Other";
var _PatientConnectionTypeCodes_Descriptions_English = {};
_PatientConnectionTypeCodes_Descriptions_English.Unknown = _PatientConnectionTypeCodes_Descriptions_English[0] = "Unknown";
_PatientConnectionTypeCodes_Descriptions_English.Self = _PatientConnectionTypeCodes_Descriptions_English[1] = "Self";
_PatientConnectionTypeCodes_Descriptions_English.Parent = _PatientConnectionTypeCodes_Descriptions_English[2] = "Parent";
_PatientConnectionTypeCodes_Descriptions_English.Guardian = _PatientConnectionTypeCodes_Descriptions_English[4] = "Guardian";
_PatientConnectionTypeCodes_Descriptions_English.GrandParent = _PatientConnectionTypeCodes_Descriptions_English[5] = "Grandparent";
_PatientConnectionTypeCodes_Descriptions_English.UncleOrAunt = _PatientConnectionTypeCodes_Descriptions_English[7] = "Uncle/Aunt";
_PatientConnectionTypeCodes_Descriptions_English.StepParent = _PatientConnectionTypeCodes_Descriptions_English[9] = "Stepparent";
_PatientConnectionTypeCodes_Descriptions_English.Sibling = _PatientConnectionTypeCodes_Descriptions_English[11] = "Sibling";
_PatientConnectionTypeCodes_Descriptions_English.Cousin = _PatientConnectionTypeCodes_Descriptions_English[13] = "Cousin";
_PatientConnectionTypeCodes_Descriptions_English.StepSibling = _PatientConnectionTypeCodes_Descriptions_English[14] = "Stepsibling";
_PatientConnectionTypeCodes_Descriptions_English.Spouse = _PatientConnectionTypeCodes_Descriptions_English[16] = "Spouse";
_PatientConnectionTypeCodes_Descriptions_English.Child = _PatientConnectionTypeCodes_Descriptions_English[17] = "Child";
_PatientConnectionTypeCodes_Descriptions_English.StepChild = _PatientConnectionTypeCodes_Descriptions_English[19] = "Stepchild";
_PatientConnectionTypeCodes_Descriptions_English.GrandChild = _PatientConnectionTypeCodes_Descriptions_English[21] = "Grandchild";
_PatientConnectionTypeCodes_Descriptions_English.NephewOrNiece = _PatientConnectionTypeCodes_Descriptions_English[23] = "Nephew/Niece";
_PatientConnectionTypeCodes_Descriptions_English.Ward = _PatientConnectionTypeCodes_Descriptions_English[25] = "Ward";
_PatientConnectionTypeCodes_Descriptions_English.Other = _PatientConnectionTypeCodes_Descriptions_English[26] = "Other";
exports.PatientConnectionTypeCodes_Descriptions = _PatientConnectionTypeCodes_Descriptions;
exports.PatientConnectionTypeCodes_Descriptions_English = _PatientConnectionTypeCodes_Descriptions_English;
var _PatientConnectionTypeCodes_DisplayOrders = {};
_PatientConnectionTypeCodes_DisplayOrders.Unknown = _PatientConnectionTypeCodes_DisplayOrders[0] = 0;
_PatientConnectionTypeCodes_DisplayOrders.Self = _PatientConnectionTypeCodes_DisplayOrders[1] = 1;
_PatientConnectionTypeCodes_DisplayOrders.Parent = _PatientConnectionTypeCodes_DisplayOrders[2] = 2;
_PatientConnectionTypeCodes_DisplayOrders.Guardian = _PatientConnectionTypeCodes_DisplayOrders[4] = 4;
_PatientConnectionTypeCodes_DisplayOrders.GrandParent = _PatientConnectionTypeCodes_DisplayOrders[5] = 5;
_PatientConnectionTypeCodes_DisplayOrders.UncleOrAunt = _PatientConnectionTypeCodes_DisplayOrders[7] = 7;
_PatientConnectionTypeCodes_DisplayOrders.StepParent = _PatientConnectionTypeCodes_DisplayOrders[9] = 9;
_PatientConnectionTypeCodes_DisplayOrders.Sibling = _PatientConnectionTypeCodes_DisplayOrders[11] = 11;
_PatientConnectionTypeCodes_DisplayOrders.Cousin = _PatientConnectionTypeCodes_DisplayOrders[13] = 13;
_PatientConnectionTypeCodes_DisplayOrders.StepSibling = _PatientConnectionTypeCodes_DisplayOrders[14] = 14;
_PatientConnectionTypeCodes_DisplayOrders.Spouse = _PatientConnectionTypeCodes_DisplayOrders[16] = 16;
_PatientConnectionTypeCodes_DisplayOrders.Child = _PatientConnectionTypeCodes_DisplayOrders[17] = 17;
_PatientConnectionTypeCodes_DisplayOrders.StepChild = _PatientConnectionTypeCodes_DisplayOrders[19] = 19;
_PatientConnectionTypeCodes_DisplayOrders.GrandChild = _PatientConnectionTypeCodes_DisplayOrders[21] = 21;
_PatientConnectionTypeCodes_DisplayOrders.NephewOrNiece = _PatientConnectionTypeCodes_DisplayOrders[23] = 23;
_PatientConnectionTypeCodes_DisplayOrders.Ward = _PatientConnectionTypeCodes_DisplayOrders[25] = 25;
_PatientConnectionTypeCodes_DisplayOrders.Other = _PatientConnectionTypeCodes_DisplayOrders[26] = 26;
exports.PatientConnectionTypeCodes_DisplayOrders = _PatientConnectionTypeCodes_DisplayOrders;
