"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCourseChargesOutstanding_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var NHSCourseChargesOutstanding_Factory = /** @class */ (function () {
    function NHSCourseChargesOutstanding_Factory() {
    }
    NHSCourseChargesOutstanding_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ChargeDescription: model.ChargeDescription,
            NHSCourseDetailsId: model.NHSCourseDetailsId,
            NHSPerformer: User_1.User_Factory.CreateIncoming(model.NHSPerformer),
            NHSPerformerId: model.NHSPerformerId,
            OutstandingBalance: model.OutstandingBalance,
            PatientId: model.PatientId,
        };
        return result;
    };
    NHSCourseChargesOutstanding_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ChargeDescription: model.ChargeDescription,
            NHSCourseDetailsId: model.NHSCourseDetailsId,
            NHSPerformer: User_1.User_Factory.CreateOutgoing(model.NHSPerformer),
            NHSPerformerId: model.NHSPerformerId,
            OutstandingBalance: model.OutstandingBalance,
            PatientId: model.PatientId,
        };
        return result;
    };
    return NHSCourseChargesOutstanding_Factory;
}());
exports.NHSCourseChargesOutstanding_Factory = NHSCourseChargesOutstanding_Factory;
