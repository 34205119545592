"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrespondenceTemplateFormatType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CorrespondenceTemplateFormatType_Factory = /** @class */ (function () {
    function CorrespondenceTemplateFormatType_Factory() {
    }
    CorrespondenceTemplateFormatType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CorrespondenceTemplateFormatType1: model.CorrespondenceTemplateFormatType1,
            Description: model.Description,
        };
        return result;
    };
    CorrespondenceTemplateFormatType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CorrespondenceTemplateFormatType1: model.CorrespondenceTemplateFormatType1,
            Description: model.Description,
        };
        return result;
    };
    return CorrespondenceTemplateFormatType_Factory;
}());
exports.CorrespondenceTemplateFormatType_Factory = CorrespondenceTemplateFormatType_Factory;
