"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientWaitingListNote_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Note_1 = require("./Note");
var PatientWaitingList_1 = require("./PatientWaitingList");
var PatientWaitingListNoteType_1 = require("./PatientWaitingListNoteType");
var PatientWaitingListNote_Factory = /** @class */ (function () {
    function PatientWaitingListNote_Factory() {
    }
    PatientWaitingListNote_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateIncoming(model.NoteTakenOn),
            PatientWaitingList: PatientWaitingList_1.PatientWaitingList_Factory.CreateIncoming(model.PatientWaitingList),
            PatientWaitingListId: model.PatientWaitingListId,
            PatientWaitingListNoteType: model.PatientWaitingListNoteType,
            PatientWaitingListNoteType1: PatientWaitingListNoteType_1.PatientWaitingListNoteType_Factory.CreateIncoming(model.PatientWaitingListNoteType1),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PatientWaitingListNote_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.NoteTakenOn),
            PatientWaitingList: PatientWaitingList_1.PatientWaitingList_Factory.CreateOutgoing(model.PatientWaitingList),
            PatientWaitingListId: model.PatientWaitingListId,
            PatientWaitingListNoteType: model.PatientWaitingListNoteType,
            PatientWaitingListNoteType1: PatientWaitingListNoteType_1.PatientWaitingListNoteType_Factory.CreateOutgoing(model.PatientWaitingListNoteType1),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PatientWaitingListNote_Factory;
}());
exports.PatientWaitingListNote_Factory = PatientWaitingListNote_Factory;
