"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsurancePlanDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsurancePlanDetail_Factory = /** @class */ (function () {
    function InsurancePlanDetail_Factory() {
    }
    InsurancePlanDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AllowanceCount: model.AllowanceCount,
            PatientCount: model.PatientCount,
            RecordId: model.RecordId,
        };
        return result;
    };
    InsurancePlanDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AllowanceCount: model.AllowanceCount,
            PatientCount: model.PatientCount,
            RecordId: model.RecordId,
        };
        return result;
    };
    return InsurancePlanDetail_Factory;
}());
exports.InsurancePlanDetail_Factory = InsurancePlanDetail_Factory;
