"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClinicalEventWatchDetail_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEvent_1 = require("./ClinicalEvent");
var PatientWatch_1 = require("./PatientWatch");
var ClinicalEventWatchDetail_Factory = /** @class */ (function () {
    function ClinicalEventWatchDetail_Factory() {
    }
    ClinicalEventWatchDetail_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvent: ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(model.ClinicalEvent),
            ClinicalEventId: model.ClinicalEventId,
            Id: model.Id,
            IsCritical: model.IsCritical,
            PatientWatch: PatientWatch_1.PatientWatch_Factory.CreateIncoming(model.PatientWatch),
            PatientWatchId: model.PatientWatchId,
            WatchText: model.WatchText,
        };
        return result;
    };
    ClinicalEventWatchDetail_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvent: ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(model.ClinicalEvent),
            ClinicalEventId: model.ClinicalEventId,
            Id: model.Id,
            IsCritical: model.IsCritical,
            PatientWatch: PatientWatch_1.PatientWatch_Factory.CreateOutgoing(model.PatientWatch),
            PatientWatchId: model.PatientWatchId,
            WatchText: model.WatchText,
        };
        return result;
    };
    return ClinicalEventWatchDetail_Factory;
}());
exports.ClinicalEventWatchDetail_Factory = ClinicalEventWatchDetail_Factory;
