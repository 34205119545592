"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSProcessingError_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSClaim_1 = require("./NHSClaim");
var NHSProcessingError_Factory = /** @class */ (function () {
    function NHSProcessingError_Factory() {
    }
    NHSProcessingError_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimId: model.ClaimId,
            Error: model.Error,
            ErrorDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ErrorDate),
            NHSClaim: NHSClaim_1.NHSClaim_Factory.CreateIncoming(model.NHSClaim),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    NHSProcessingError_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClaimId: model.ClaimId,
            Error: model.Error,
            ErrorDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ErrorDate),
            NHSClaim: NHSClaim_1.NHSClaim_Factory.CreateOutgoing(model.NHSClaim),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return NHSProcessingError_Factory;
}());
exports.NHSProcessingError_Factory = NHSProcessingError_Factory;
