"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracMaterialGroup_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracLab_1 = require("./LabtracLab");
var LabtracMaterial_1 = require("./LabtracMaterial");
var LabtracMaterialGroup_Factory = /** @class */ (function () {
    function LabtracMaterialGroup_Factory() {
    }
    LabtracMaterialGroup_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ExternalId: model.ExternalId,
            LabReference: model.LabReference,
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateIncoming(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            LabtracMaterials: model.LabtracMaterials && model.LabtracMaterials.map(function (i) { return LabtracMaterial_1.LabtracMaterial_Factory.CreateIncoming(i); }),
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    LabtracMaterialGroup_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ExternalId: model.ExternalId,
            LabReference: model.LabReference,
            LabtracLab: LabtracLab_1.LabtracLab_Factory.CreateOutgoing(model.LabtracLab),
            LabtracLabId: model.LabtracLabId,
            LabtracMaterials: model.LabtracMaterials && model.LabtracMaterials.map(function (i) { return LabtracMaterial_1.LabtracMaterial_Factory.CreateOutgoing(i); }),
            Name: model.Name,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return LabtracMaterialGroup_Factory;
}());
exports.LabtracMaterialGroup_Factory = LabtracMaterialGroup_Factory;
