"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCredentialType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var UserCredentialType_Factory = /** @class */ (function () {
    function UserCredentialType_Factory() {
    }
    UserCredentialType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Type: model.Type,
            TypeCode: model.TypeCode,
        };
        return result;
    };
    UserCredentialType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Type: model.Type,
            TypeCode: model.TypeCode,
        };
        return result;
    };
    return UserCredentialType_Factory;
}());
exports.UserCredentialType_Factory = UserCredentialType_Factory;
