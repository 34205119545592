"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracCaseMaterial_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracCase_1 = require("./LabtracCase");
var LabtracMaterial_1 = require("./LabtracMaterial");
var LabtracCaseMaterial_Factory = /** @class */ (function () {
    function LabtracCaseMaterial_Factory() {
    }
    LabtracCaseMaterial_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppReference: model.AppReference,
            ExternalId: model.ExternalId,
            LabReference: model.LabReference,
            LabtracCase: LabtracCase_1.LabtracCase_Factory.CreateIncoming(model.LabtracCase),
            LabtracCaseId: model.LabtracCaseId,
            LabtracMaterial: LabtracMaterial_1.LabtracMaterial_Factory.CreateIncoming(model.LabtracMaterial),
            LabtracMaterialId: model.LabtracMaterialId,
            Name: model.Name,
            Qty: model.Qty,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    LabtracCaseMaterial_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppReference: model.AppReference,
            ExternalId: model.ExternalId,
            LabReference: model.LabReference,
            LabtracCase: LabtracCase_1.LabtracCase_Factory.CreateOutgoing(model.LabtracCase),
            LabtracCaseId: model.LabtracCaseId,
            LabtracMaterial: LabtracMaterial_1.LabtracMaterial_Factory.CreateOutgoing(model.LabtracMaterial),
            LabtracMaterialId: model.LabtracMaterialId,
            Name: model.Name,
            Qty: model.Qty,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return LabtracCaseMaterial_Factory;
}());
exports.LabtracCaseMaterial_Factory = LabtracCaseMaterial_Factory;
