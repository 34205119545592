"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnterpriseConsultationSubjectLink_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ClinicalEvent_1 = require("./ClinicalEvent");
var PatientProcedure_1 = require("./PatientProcedure");
var PerioExam_1 = require("./PerioExam");
var SoftTissueExam_1 = require("./SoftTissueExam");
var Tooth_1 = require("./Tooth");
var EnterpriseConsultationSubjectLink_Factory = /** @class */ (function () {
    function EnterpriseConsultationSubjectLink_Factory() {
    }
    EnterpriseConsultationSubjectLink_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateIncoming(i); }),
            ComputedMouthRegionDescription: model.ComputedMouthRegionDescription,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(i); }),
            PerioExams: model.PerioExams && model.PerioExams.map(function (i) { return PerioExam_1.PerioExam_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SoftTissueExams: model.SoftTissueExams && model.SoftTissueExams.map(function (i) { return SoftTissueExam_1.SoftTissueExam_Factory.CreateIncoming(i); }),
            SubjectTypeId: model.SubjectTypeId,
            Teeth: model.Teeth && model.Teeth.map(function (i) { return Tooth_1.Tooth_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    EnterpriseConsultationSubjectLink_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClinicalEvents: model.ClinicalEvents && model.ClinicalEvents.map(function (i) { return ClinicalEvent_1.ClinicalEvent_Factory.CreateOutgoing(i); }),
            ComputedMouthRegionDescription: model.ComputedMouthRegionDescription,
            MouthRegionGroupCode: model.MouthRegionGroupCode,
            MouthRegionIndex: model.MouthRegionIndex,
            PatientProcedures: model.PatientProcedures && model.PatientProcedures.map(function (i) { return PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(i); }),
            PerioExams: model.PerioExams && model.PerioExams.map(function (i) { return PerioExam_1.PerioExam_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SoftTissueExams: model.SoftTissueExams && model.SoftTissueExams.map(function (i) { return SoftTissueExam_1.SoftTissueExam_Factory.CreateOutgoing(i); }),
            SubjectTypeId: model.SubjectTypeId,
            Teeth: model.Teeth && model.Teeth.map(function (i) { return Tooth_1.Tooth_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return EnterpriseConsultationSubjectLink_Factory;
}());
exports.EnterpriseConsultationSubjectLink_Factory = EnterpriseConsultationSubjectLink_Factory;
