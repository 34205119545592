import React, { useMemo } from "react";
import Wait from "./Wait";
import usePrevious from "./usePrevious";


export interface ILazyComponentProps<C extends React.ComponentType<any>> {
  componentLoader: () => Promise<{ default: C }>;
  componentProps?: React.ComponentProps<C>;
}


const Waiting: React.FC = () =>
  <div className="centered"><Wait style={{padding: '8px 20px'}} /></div>

const LazyComponent = ({componentLoader, componentProps }: ILazyComponentProps<any>) => {
  const prevComponentLoader = usePrevious(componentLoader);
  if (prevComponentLoader && componentLoader !== prevComponentLoader) {
    console.warn("`props.ComponentLoader` has changed. This is unexpected. Consider using `useCallback` or a static function to ensure a stable reference.")
  }

  const Component = useMemo(() => React.lazy(componentLoader), [componentLoader]);
  return (
    <React.Suspense fallback={<Waiting />} >
      <Component {...componentProps} />
    </React.Suspense>)
};


export default LazyComponent;
