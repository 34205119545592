"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsInsurancePrefill_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsAddressPrefill_1 = require("./EFormsAddressPrefill");
var EFormsPersonPrefill_1 = require("./EFormsPersonPrefill");
var EFormsInsurancePrefill_Factory = /** @class */ (function () {
    function EFormsInsurancePrefill_Factory() {
    }
    EFormsInsurancePrefill_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Address: EFormsAddressPrefill_1.EFormsAddressPrefill_Factory.CreateIncoming(model.Address),
            GroupNum: model.GroupNum,
            Insured: EFormsPersonPrefill_1.EFormsPersonPrefill_Factory.CreateIncoming(model.Insured),
            InsuredId: model.InsuredId,
            PayerId: model.PayerId,
            PayerName: model.PayerName,
            PlanId: model.PlanId,
            PlanName: model.PlanName,
            PolicyId: model.PolicyId,
        };
        return result;
    };
    EFormsInsurancePrefill_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Address: EFormsAddressPrefill_1.EFormsAddressPrefill_Factory.CreateOutgoing(model.Address),
            GroupNum: model.GroupNum,
            Insured: EFormsPersonPrefill_1.EFormsPersonPrefill_Factory.CreateOutgoing(model.Insured),
            InsuredId: model.InsuredId,
            PayerId: model.PayerId,
            PayerName: model.PayerName,
            PlanId: model.PlanId,
            PlanName: model.PlanName,
            PolicyId: model.PolicyId,
        };
        return result;
    };
    return EFormsInsurancePrefill_Factory;
}());
exports.EFormsInsurancePrefill_Factory = EFormsInsurancePrefill_Factory;
