"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSRegion_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSRegion_Factory = /** @class */ (function () {
    function NHSRegion_Factory() {
    }
    NHSRegion_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CentralOptionValue: model.CentralOptionValue,
            NHSRegionID: model.NHSRegionID,
            RegionDescription: model.RegionDescription,
        };
        return result;
    };
    NHSRegion_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CentralOptionValue: model.CentralOptionValue,
            NHSRegionID: model.NHSRegionID,
            RegionDescription: model.RegionDescription,
        };
        return result;
    };
    return NHSRegion_Factory;
}());
exports.NHSRegion_Factory = NHSRegion_Factory;
