"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerioProblemThresholds_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PerioProblemThresholds_Factory = /** @class */ (function () {
    function PerioProblemThresholds_Factory() {
    }
    PerioProblemThresholds_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CAL: model.CAL,
            Enabled: model.Enabled,
            Furcation: model.Furcation,
            GingivalMargin: model.GingivalMargin,
            Mobility: model.Mobility,
            PocketDepth: model.PocketDepth,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    PerioProblemThresholds_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CAL: model.CAL,
            Enabled: model.Enabled,
            Furcation: model.Furcation,
            GingivalMargin: model.GingivalMargin,
            Mobility: model.Mobility,
            PocketDepth: model.PocketDepth,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    return PerioProblemThresholds_Factory;
}());
exports.PerioProblemThresholds_Factory = PerioProblemThresholds_Factory;
