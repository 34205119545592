"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CDTCode_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CDTCodeCategoryType_1 = require("./CDTCodeCategoryType");
var ProcedureCategoryType_1 = require("./ProcedureCategoryType");
var CDTCode_Factory = /** @class */ (function () {
    function CDTCode_Factory() {
    }
    CDTCode_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CategoryDescriptor: model.CategoryDescriptor,
            CategoryOfService: model.CategoryOfService,
            CategoryTypeCode: model.CategoryTypeCode,
            CDTCodeCategoryType: CDTCodeCategoryType_1.CDTCodeCategoryType_Factory.CreateIncoming(model.CDTCodeCategoryType),
            CDTCodeCategoryTypeCode: model.CDTCodeCategoryTypeCode,
            Class1: model.Class1,
            Class2: model.Class2,
            ClinicalTreatmentMaterial: model.ClinicalTreatmentMaterial,
            Descriptor: model.Descriptor,
            Nomenclature: model.Nomenclature,
            ProcedureCategoryType: ProcedureCategoryType_1.ProcedureCategoryType_Factory.CreateIncoming(model.ProcedureCategoryType),
            ProcedureCode: model.ProcedureCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Subcategory: model.Subcategory,
            SubcategoryDescriptor: model.SubcategoryDescriptor,
            SubSubcategory: model.SubSubcategory,
            SubSubcategoryDescriptor: model.SubSubcategoryDescriptor,
            TreatmentSubTypeId: model.TreatmentSubTypeId,
        };
        return result;
    };
    CDTCode_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CategoryDescriptor: model.CategoryDescriptor,
            CategoryOfService: model.CategoryOfService,
            CategoryTypeCode: model.CategoryTypeCode,
            CDTCodeCategoryType: CDTCodeCategoryType_1.CDTCodeCategoryType_Factory.CreateOutgoing(model.CDTCodeCategoryType),
            CDTCodeCategoryTypeCode: model.CDTCodeCategoryTypeCode,
            Class1: model.Class1,
            Class2: model.Class2,
            ClinicalTreatmentMaterial: model.ClinicalTreatmentMaterial,
            Descriptor: model.Descriptor,
            Nomenclature: model.Nomenclature,
            ProcedureCategoryType: ProcedureCategoryType_1.ProcedureCategoryType_Factory.CreateOutgoing(model.ProcedureCategoryType),
            ProcedureCode: model.ProcedureCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Subcategory: model.Subcategory,
            SubcategoryDescriptor: model.SubcategoryDescriptor,
            SubSubcategory: model.SubSubcategory,
            SubSubcategoryDescriptor: model.SubSubcategoryDescriptor,
            TreatmentSubTypeId: model.TreatmentSubTypeId,
        };
        return result;
    };
    return CDTCode_Factory;
}());
exports.CDTCode_Factory = CDTCode_Factory;
