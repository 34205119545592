"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientRecall_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Patient_1 = require("./Patient");
var PatientRecallPreference_1 = require("./PatientRecallPreference");
var PatientRecall_Factory = /** @class */ (function () {
    function PatientRecall_Factory() {
    }
    PatientRecall_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ContactAttempts: model.ContactAttempts,
            LastAppointmentDate: Date_Factory_1.Date_Factory.CreateIncoming(model.LastAppointmentDate),
            LastContactDate: Date_Factory_1.Date_Factory.CreateIncoming(model.LastContactDate),
            LastContactType: model.LastContactType,
            LastRecallAppointmentId: model.LastRecallAppointmentId,
            LastRecallDate: Date_Factory_1.Date_Factory.CreateIncoming(model.LastRecallDate, { ignoreTimePortion: true }),
            LastRecallTimeZone: model.LastRecallTimeZone,
            LocationName: model.LocationName,
            NextRecallDate: Date_Factory_1.Date_Factory.CreateIncoming(model.NextRecallDate),
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientRecallPreference: PatientRecallPreference_1.PatientRecallPreference_Factory.CreateIncoming(model.PatientRecallPreference),
            RecallDueDate: Date_Factory_1.Date_Factory.CreateIncoming(model.RecallDueDate),
        };
        return result;
    };
    PatientRecall_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ContactAttempts: model.ContactAttempts,
            LastAppointmentDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastAppointmentDate),
            LastContactDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastContactDate),
            LastContactType: model.LastContactType,
            LastRecallAppointmentId: model.LastRecallAppointmentId,
            LastRecallDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.LastRecallDate, { ignoreTimePortion: true }),
            LastRecallTimeZone: model.LastRecallTimeZone,
            LocationName: model.LocationName,
            NextRecallDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.NextRecallDate),
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientRecallPreference: PatientRecallPreference_1.PatientRecallPreference_Factory.CreateOutgoing(model.PatientRecallPreference),
            RecallDueDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecallDueDate),
        };
        return result;
    };
    return PatientRecall_Factory;
}());
exports.PatientRecall_Factory = PatientRecall_Factory;
