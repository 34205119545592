"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickbooksTransmissionHistory_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var QuickbooksTransmission_1 = require("./QuickbooksTransmission");
var QuickbooksTransmissionHistory_Factory = /** @class */ (function () {
    function QuickbooksTransmissionHistory_Factory() {
    }
    QuickbooksTransmissionHistory_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ActionedByUser: User_1.User_Factory.CreateIncoming(model.ActionedByUser),
            ActionedByUserId: model.ActionedByUserId,
            ActionedDateTime: Date_Factory_1.Date_Factory.CreateIncoming(model.ActionedDateTime),
            AppliedSettings: model.AppliedSettings,
            ErrorResult: model.ErrorResult,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Transmission: QuickbooksTransmission_1.QuickbooksTransmission_Factory.CreateIncoming(model.Transmission),
            TransmissionId: model.TransmissionId,
            TransmissionStatus: model.TransmissionStatus,
        };
        return result;
    };
    QuickbooksTransmissionHistory_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ActionedByUser: User_1.User_Factory.CreateOutgoing(model.ActionedByUser),
            ActionedByUserId: model.ActionedByUserId,
            ActionedDateTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.ActionedDateTime),
            AppliedSettings: model.AppliedSettings,
            ErrorResult: model.ErrorResult,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Transmission: QuickbooksTransmission_1.QuickbooksTransmission_Factory.CreateOutgoing(model.Transmission),
            TransmissionId: model.TransmissionId,
            TransmissionStatus: model.TransmissionStatus,
        };
        return result;
    };
    return QuickbooksTransmissionHistory_Factory;
}());
exports.QuickbooksTransmissionHistory_Factory = QuickbooksTransmissionHistory_Factory;
