"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnterpriseLocationDepartment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var EnterpriseLocation_1 = require("./EnterpriseLocation");
var EnterpriseLocationDepartment_Factory = /** @class */ (function () {
    function EnterpriseLocationDepartment_Factory() {
    }
    EnterpriseLocationDepartment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Departments: model.Departments && model.Departments.map(function (i) { return Location_1.Location_Factory.CreateIncoming(i); }),
            EnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateIncoming(model.EnterpriseLocation),
        };
        return result;
    };
    EnterpriseLocationDepartment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Departments: model.Departments && model.Departments.map(function (i) { return Location_1.Location_Factory.CreateOutgoing(i); }),
            EnterpriseLocation: EnterpriseLocation_1.EnterpriseLocation_Factory.CreateOutgoing(model.EnterpriseLocation),
        };
        return result;
    };
    return EnterpriseLocationDepartment_Factory;
}());
exports.EnterpriseLocationDepartment_Factory = EnterpriseLocationDepartment_Factory;
