"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSMailbox_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSMailbox_Factory = /** @class */ (function () {
    function NHSMailbox_Factory() {
    }
    NHSMailbox_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            InterchangeLimit: model.InterchangeLimit,
            IsTestSite: model.IsTestSite,
            LastClaimNo: model.LastClaimNo,
            LastInterchangeNo: model.LastInterchangeNo,
            PinProtectSchedule: model.PinProtectSchedule,
            PracticeSiteNo: model.PracticeSiteNo,
            Pswd: model.Pswd,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransmissionFormat: model.TransmissionFormat,
            UserName: model.UserName,
        };
        return result;
    };
    NHSMailbox_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            InterchangeLimit: model.InterchangeLimit,
            IsTestSite: model.IsTestSite,
            LastClaimNo: model.LastClaimNo,
            LastInterchangeNo: model.LastInterchangeNo,
            PinProtectSchedule: model.PinProtectSchedule,
            PracticeSiteNo: model.PracticeSiteNo,
            Pswd: model.Pswd,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransmissionFormat: model.TransmissionFormat,
            UserName: model.UserName,
        };
        return result;
    };
    return NHSMailbox_Factory;
}());
exports.NHSMailbox_Factory = NHSMailbox_Factory;
