"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrthoPatientTreatmentStatus_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var Patient_1 = require("./Patient");
var OrthoTreatmentStatus_1 = require("./OrthoTreatmentStatus");
var User_1 = require("./User");
var OrthoPatientTreatmentStatus_Factory = /** @class */ (function () {
    function OrthoPatientTreatmentStatus_Factory() {
    }
    OrthoPatientTreatmentStatus_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CreatedByUserId: model.CreatedByUserId,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            Note: model.Note,
            OrthoTreatmentStatusId: model.OrthoTreatmentStatusId,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            RecallDate: Date_Factory_1.Date_Factory.CreateIncoming(model.RecallDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordedAtLocation: model.RecordedAtLocation,
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TreatmentStatus: OrthoTreatmentStatus_1.OrthoTreatmentStatus_Factory.CreateIncoming(model.TreatmentStatus),
            User: User_1.User_Factory.CreateIncoming(model.User),
        };
        return result;
    };
    OrthoPatientTreatmentStatus_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CreatedByUserId: model.CreatedByUserId,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            Note: model.Note,
            OrthoTreatmentStatusId: model.OrthoTreatmentStatusId,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            RecallDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecallDate),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordedAtLocation: model.RecordedAtLocation,
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TreatmentStatus: OrthoTreatmentStatus_1.OrthoTreatmentStatus_Factory.CreateOutgoing(model.TreatmentStatus),
            User: User_1.User_Factory.CreateOutgoing(model.User),
        };
        return result;
    };
    return OrthoPatientTreatmentStatus_Factory;
}());
exports.OrthoPatientTreatmentStatus_Factory = OrthoPatientTreatmentStatus_Factory;
