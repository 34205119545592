import moment from "moment";
import { fromUtc, isSameDate } from "./dateUtils";
import { IPhoneNumber } from "csd.phoenix.models/PhoneNumber";
import { Moment } from "moment";
import { nullable } from "./objectUtils";
import { getLocale, isLocaleUS, isLocaleUK } from "../i18n";

export function standardizeNumber(value: string): number | null {
  if (value) {
    let temp = value.replace(/[^0-9.-]/g, "");
    if (temp) return Number(temp);
    return NaN;
  } else {
    return null;
  }
}

// export function currencySymbol() {
//   const formatted = formatCurrency(0)!;
//   const firstCharacter = formatted.substring(0, 1);
//   if (firstCharacter !== "0") return firstCharacter;

//   return formatted.substring(formatted.length - 1, formatted.length);
// }

// export function formatCurrency(
//   value: number | null | undefined,
//   minDecimalPlaces = 2,
//   maxDecimalPlaces = 2,
//   currency?: string
// ): string | null {
//   return formatNumber(value, minDecimalPlaces, "currency", maxDecimalPlaces, currency);
// }

// export function formatCurrencyFraction(
//   value: number | null | undefined,
//   decimalPlaces = 2
// ): string | null {
//   if (!value) return "0".repeat(decimalPlaces);

//   const fractional = Math.abs(
//     Math.round((value * Math.pow(10, decimalPlaces)) % Math.pow(10, decimalPlaces))
//   );

//   if (fractional == 0 || fractional >= Math.pow(10, decimalPlaces))
//     return "0".repeat(decimalPlaces);

//   return `${"0".repeat(decimalPlaces)}${fractional.toString()}`.slice(-decimalPlaces);
// }

// export function formatPercent(
//   value: number | null | undefined,
//   minDecimalPlaces = 0,
//   maxDecimalPlaces = 1,
//   outOfRangeDisplay: string = "N/A"
// ): string | null {
//   if (value && outOfRangeDisplay && !isFinite(value)) {
//     return outOfRangeDisplay;
//   }
//   return formatNumber(value, minDecimalPlaces, "percent", maxDecimalPlaces);
// }

// export type NumberStyle = "decimal" | "currency" | "percent";

// export function formatNumber(
//   value: number | null | undefined,
//   minDecimalPlaces?: number,
//   style: NumberStyle = "decimal",
//   maxDecimalPlaces = 2,
//   currency: string
// ) {
//   if (!value) {
//     return null;
//   } else {
//     let format = new Intl.NumberFormat({
//       minimumFractionDigits: minDecimalPlaces || 0,
//       maximumFractionDigits: maxDecimalPlaces,
//       useGrouping: true,
//       style: style,
//       currency: currency
//     });

//     return format.format(value);
//   }
// }

export function standardizePhoneNumber(value: string): string {
  if (value) {
    let temp = value.replace(/[^0-9]/g, "");
    //only remove the leading 1 if the number is 11 digits, starts with 1, and is a US locale
    if (temp.length === 11 && temp[0] === "1" && isLocaleUS()) {
      return temp.substring(1);
    } else {
      return temp;
    }
  } else {
    return value;
  }
}


export function formatPhoneNumber(value: IPhoneNumber, locale?: string): nullable<string>;
export function formatPhoneNumber(value: string, locale?: string, standardize?: boolean): nullable<string>;
export function formatPhoneNumber(
  value: string | IPhoneNumber,
  locale = getLocale(),
  standardize = true
): nullable<string> {

  if (!value) return null;

  if (typeof value === "string") {
    if (standardize) value = standardizePhoneNumber(value.toString());
    if (isLocaleUS(locale) && value.toString().length === 10) {
      return value.toString().replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else if (isLocaleUS(locale) && value.toString().length === 7) {
      return value.toString().replace(/(\d{3})(\d{4})/g, "$1-$2");
    } else if (isLocaleUK(locale) && value.toString().length === 11) {
      return value.toString().replace(/(\d{5})(\d{6})/, "$1 $2");
    }
    return value;
  } else {
    const number = formatPhoneNumber(value.Number, locale);
    if (value.Extension) {
      return `${number}x${value.Extension}`;
    } else {
      return number;
    }
  }
}

// Format a sms number for display. Ex: +17772223333 -> (777)222-3333
export function formatSmsNumber(number: string, locale = getLocale()) {
  if (!number) return "";

  if (number[0] !== "+") throw new Error("SMS number must start with `+`.");

  return formatPhoneNumber(number.substring(1), locale, true);
}

export function toSmsNumber(value: IPhoneNumber): string;
export function toSmsNumber(value: string, countryPrefix: string): string;
export function toSmsNumber(value: string | IPhoneNumber, countryPrefix?: string): string {
  if (!value) return "";

  if (typeof value !== "string") return toSmsNumber(value.Number, value.CountryPrefix);

  if (!countryPrefix) throw new Error("Country prefix is required to format SMS number.");

  const number = standardizePhoneNumber(value);
  switch (countryPrefix) {
    case "1": // US
      if (number.length !== 10) throw new Error(`Phone number (${number}) must contain 10 digits.`);
      return "+1" + number;

    case "44": // England, Scotland, Wales, Northern Ireland
      // Expecting 11 digit number that start with a zero.
      if (number.length !== 11) throw new Error(`Phone number (${number}) must contain 11 digits.`);
      if (number[0] !== "0")
        throw new Error(`Expecting phone number (${number}) to start with zero.`);
      return "+44" + number.substring(1);

    default:
      throw new Error("Unsupported country prefix: " + countryPrefix);
  }
}

// export function standardizeSsn(value: string) {
//   if (value) {
//     if (isLocaleUS()) {
//       return value.replace(/[^0-9]/g, "");
//     } else {
//       return value;
//     }
//   } else {
//     return value;
//   }
// }

// export function maskSsn(value: string) : string {
//   if(value) {
//     if (isLocaleUS() && value.length > 4) {
//       return "*".repeat(value.length - 4) + value.substring(value.length - 4);
//     }
//   }

//   return value;
// }

// export function formatSsn(value: string, standardize = true) {
//   if (value) {
//     if (standardize) {
//       value = standardizeSsn(value);
//     }

//     if (isLocaleUS() && value.length === 9)
//       return value.replace(/(\d{3})(\d{2})(\d{4})/g, "$1-$2-$3");
//     // [MG] The below logic disabled until we properly understand what this should be doing - introduction of the formatting results in the field lenth validation from failing in Business.tsx
//     //if (isLocaleUK() && value.length === 10) {
//     //  return value.replace(/(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3");
//     //}
//   }

//   return value;
// }

// export function standardizeZipCode(value: string) {
//   if (value) {
//     return value.replace(/[^0-9]/g, "");
//   } else {
//     return value;
//   }
// }

export function standardizePostalOrZipCode(value: string, locale = getLocale()) {
  if (!value) {
    return '';
  }

  if (isLocaleUS(locale)) {
    value = value.replace(/[^0-9]/g, "");
  }
  else if (isLocaleUK(locale)) {
    // UK Post codes are alphanumeric, and are variable in length: ranging from six to eight characters, including a space.
    // https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Overview
    value = value.replace(/[^0-9 a-zA-Z]/g, "");
  }

  return value.trim().toLocaleUpperCase(locale);
}

export function formatPostalOrZipCode(value: string, locale = getLocale()) {
  value = standardizePostalOrZipCode(value, locale);

  if (isLocaleUS(locale) && value.length === 9) {
    return value.replace(/(\d{5})(\d{4})/g, "$1-$2");
  }

  // UK postcodes ends with NAA
  if (isLocaleUK(locale) && /[0-9][A-Z]{2}$/.test(value)) {
    return value.replace(/^([0-9A-Z]{2,4}) ?([0-9][A-Z]{2})$/g, "$1 $2");
  }

  return value;
}


// export function standardizeUrl(value: string) {
//   if (value) {
//     if (!value.match(/^https?:\/\//i)) {
//       return `http://${value}`;
//     }
//   }
//   return value;
// }

// export function formatUrl(value: string) {
//   if (value) {
//     return value.replace(/^https?:\/\//, "");
//   }
//   return value;
// }

export function formatFileSize(size: number) {
  if (!size) return "";

  const i = Math.floor(Math.log(size) / Math.log(1024));

  return (
    `${(size / Math.pow(1024, i < 0 ? 0 : i)).toFixed(2)} ${
      ["B", "KB", "MB", "GB", "TB"][i < 0 ? 0 : i]
    }` || ""
  );
}

/**
 * Returns filename without the extension
 * @param fullFileName
 */
export function nameWithoutExtension(fullFileName: string) {
  return fullFileName.substr(0, fullFileName.lastIndexOf("."));
}

export interface IFileNameParts {
  name: string;
  extension: string;
}

export function getFileNameParts(fullFileName: string): IFileNameParts {
  let fileName = "";
  let fileExtension = "";

  if (fullFileName) {
    const split = fullFileName.lastIndexOf(".");
    if (split > 0) {
      fileName = fullFileName.substring(0, split);
      fileExtension = fullFileName.substring(split).toLowerCase();
    } else {
      fileName = fullFileName;
    }
  }

  return {
    name: fileName,
    extension: fileExtension
  };
}

export interface IMonthDay {
  month: number;
  day: number;
}

export function standardizeMonthDay(value: string): IMonthDay | null {
  if (value) {
    var match = value.match(/^(\d{2})[^\d]*(\d{2})$/);
    if (match) {
      return {
        month: parseInt(match[1]),
        day: parseInt(match[2])
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function areMonthDayEqual(a: IMonthDay | null, b: IMonthDay | null) {
  if (a != null && b != null) {
    return a.month === b.month && a.day === b.day;
  } else {
    return a === b;
  }
}

export function formatMonthDay(value: IMonthDay | null | undefined): string {
  if (value) {
    var month = (value.month < 10 ? "0" : "") + value.month.toString();
    var day = (value.day < 10 ? "0" : "") + value.day.toString();
    return `${month}/${day}`;
  } else {
    return "";
  }
}

export function formatDate(
  value: Date | null | undefined | Moment,
  locale = getLocale(),
  format?: string,
  allowDescriptiveText = true
): string {
  if (!value) return "";

  if (allowDescriptiveText) {
    if (isSameDate(moment(value).toDate(), new Date())) return "Today";
    if (
      isSameDate(
        moment(value)
          .add(1, "day")
          .toDate(),
        new Date()
      )
    )
      return "Yesterday";
    if (
      isSameDate(
        moment(value)
          .add(-1, "day")
          .toDate(),
        new Date()
      )
    )
      return "Tomorrow";
  }

  return moment(value).format(format || defaultDateFormatString(locale));
}

export function formatMonthYear(value: Date | null | undefined | Moment): string {
  if (value) {
    var date = moment(value);
    if (date.isSame(moment(), "month")) return "This Month";
    if (date.isSame(moment().add(1, "month"), "month")) return "Next Month";
    if (date.isSame(moment().add(-1, "month"), "month")) return "Last Month";
    return date.format("MMM, YYYY");
  } else {
    return "";
  }
}

// export function formatRelativeDate(
//   value: Date | null | undefined | Moment,
//   abbreviated: boolean = false
// ): string {
//   if (value) {
//     var date = moment(value);
//     var days = Math.ceil(date.diff(moment(today()), "day"));
//     var months = Math.round(date.diff(moment(today()), "month"));

//     var isFuture = days > 0;
//     days = Math.abs(days);
//     months = Math.abs(months);

//     var result = "";

//     if (isFuture) {
//       result += "in";
//     }

//     result += ` ${formatNumber(days)} ${abbreviated ? "d" : "days"}`;

//     if (months) {
//       result += ` (${formatNumber(months)} ${abbreviated ? "mo" : "months"})`;
//     }

//     if (!isFuture) {
//       result += " ago";
//     }
//     return result;
//   } else {
//     return "";
//   }
// }

export function formatDatePart(
  value: Date | null | undefined | Moment | string,
  timeZone: string | undefined
): string {
  if (!timeZone) throw new Error("You must specify a timezone when formatting date/times.");
  if (value) {
    value = moment(value).toDate();
    value = fromUtc(value, timeZone);

    return moment(value).format("ll");
  } else {
    return "";
  }
}

export function formatDateTime(
  value: Date | null | undefined | Moment | string,
  timeZone: string | undefined,
  format = "llll",
  includeTimeZone: boolean = false
): string {
  if (!timeZone) throw new Error("You must specify a timezone when formatting date/times.");
  if (value) {
    value = moment(value).toDate();
    value = fromUtc(value, timeZone);
    var result = moment(value).format(format);
    if (includeTimeZone) {
      return `${result} ${timeZone}`;
    }
    return result;
  } else {
    return "";
  }
}

export function formatTimePart(
  value: Date | null | undefined | Moment | string,
  timeZone: string | undefined
): string {
  if (!timeZone) throw new Error("You must specify a timezone when formatting date/times.");
  if (value) {
    value = moment(value).toDate();
    value = fromUtc(value, timeZone);
    return moment(value).format("hh:mm a");
  } else {
    return "";
  }
}

export function formatDateRange(value: Date[]): string {
  const standardDisplayFormat = "dddd ll";
  const shortMonthFormat = "MMM DD";
  const dayFormat = "DD";

  if (value.length === 1) {
    return moment(value[0]).format(standardDisplayFormat);
  } else if (value.length > 1) {
    let temp1 = moment(value[0]);
    let temp2 = moment(value[value.length - 1]);

    if (temp2.isSameOrAfter(temp1)) {
      if (temp2.month() === temp1.month()) {
        return `${temp1.format(shortMonthFormat)} - ${temp2.format(dayFormat)}`;
      }

      return `${temp1.format(shortMonthFormat)} - ${temp2.format(shortMonthFormat)}`;
    } else {
      if (temp2.month() === temp1.month()) {
        return `${temp2.format(shortMonthFormat)} - ${temp1.format(dayFormat)}`;
      }

      return `${temp2.format(shortMonthFormat)} - ${temp1.format(shortMonthFormat)}`;
    }
  } else {
    return "";
  }
}

// export function formatTime(time: ITime | null | undefined) {
//   if (time) {
//     var hour = time.hour;
//     var am = time.hour < 12 || time.hour == 0;
//     if (time.hour >= 13) hour -= 12;
//     if (time.hour == 0) hour += 12;
//     return `${hour}:${time.minute < 10 ? "0" : ""}${time.minute} ${am ? "AM" : "PM"}`;
//   } else {
//     return null;
//   }
// }

// export function formatHour(value: number | nullable<ITime>) {
//   if (!value) {
//     return null;
//   }

//   const hour = typeof value === "number" ? value : value.hour;
//   const h = hour % 12;
//   return (h === 0 ? "12" : h) + " " + (hour < 12 ? "AM" : "PM");
// }

// export function formatDuration(time: ITime | null | undefined) {
//   if (time) {
//     return `${time.hour}:${time.minute < 10 ? "0" : ""}${time.minute} hour${
//       time.hour == 1 && time.minute == 0 ? "" : "s"
//     }`;
//   } else {
//     return null;
//   }
// }

export function formatTimeFromDate(dateTime: Date | null | undefined) {
  if (dateTime) {
    let hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const am = hour < 12 || hour === 0;
    if (hour >= 13) hour -= 12;
    if (hour === 0) hour += 12;
    return `${hour}:${minutes < 10 ? "0" : ""}${minutes} ${am ? "AM" : "PM"}`;
  } else {
    return null;
  }
}

// export function formatTimeRange(
//   timeRange: ITimeRange | null | undefined,
//   separator: string = "-"
// ): string | null {
//   if (timeRange && timeRange.start && timeRange.end) {
//     let formattedStart = formatTime(timeRange.start);
//     let formattedEnd = formatTime(timeRange.end);
//     return `${formattedStart} ${separator} ${formattedEnd}`;
//   }

//   return null;
// }

// export function parseTime(value: string | null | undefined): ITime | null {
//   if (value) {
//     var parts = value.match(/^(\d\d?):(\d\d?) *([AP])?M?$/i);
//     if (parts) {
//       var hour = parseInt(parts[1]);
//       var minute = parseInt(parts[2]);
//       if (validateHour(hour, !parts[3]) && validateMinutes(minute)) {
//         if (parts[3]) {
//           var am = parts[3].toLowerCase() == "a";
//           if (am) {
//             if (hour == 12) hour -= 12;
//           } else if (hour != 12) {
//             hour += 12;
//           }
//         }
//         return {
//           hour: hour,
//           minute: minute
//         };
//       }
//     }
//   }
//   return null;
// }

// // Returns an `ITime` from an hour string value.
// // Allowed formats: 1, 12, 12pm, 12 pm, 12p, 12 PM, 12PM etc.
// export function parseHour(value: string | null, minutes = 0): ITime | null {
//   if (value) {
//     const [_, hourStr, amPmStr] = value.toLowerCase().match(/^(\d\d?)[ ]?([ap]m?)?$/) || [, , ,];
//     if (hourStr) {
//       let hour = parseInt(hourStr);
//       console.assert(!Number.isNaN(hour));
//       // Do we have an am/pm indicator?
//       let isPm;
//       if (amPmStr) {
//         isPm = amPmStr[0] === "p";
//         // 12 AM is a special case. I.e. '12 AM' = 0 hour.
//         if (!isPm && hour == 12) hour = 0;
//       } else {
//         isPm = hour > 11;
//       }

//       hour %= 12;
//       const hour24 = hour + Number(isPm) * 12;
//       return {
//         hour: hour24,
//         minute: minutes
//       };
//     }
//   }

//   return null;
// }

// export function setDateFromTime(
//   date: Date | null | undefined,
//   time: ITime | null | undefined
// ): Date | null {
//   return moment(date ? date : today())
//     .hour(time ? time.hour : 0)
//     .minute(time ? time.minute : 0)
//     .toDate();
// }

// function validateHour(value: number, is24Hour = false) {
//   if (is24Hour) {
//     return value >= 0 && value < 24;
//   } else {
//     return value >= 1 && value <= 12;
//   }
// }

// function validateMinutes(value: number) {
//   return value >= 0 && value < 60;
// }

export function defaultDateFormatString(locale = getLocale()) {
  // Return the default format string according to our location
  return isLocaleUS(locale) ? "MM/DD/YYYY" : "DD/MM/YYYY";
}

// //** Function to allow for format adjustments based on user-input */
// export function sanitizeParseInput(input: string, format: string): string {
//   //If input didn't use separators then remove them from parse format
//   const dateSeparators = ["/", "-", "."];
//   if (dateSeparators.every(sep => input.indexOf(sep) === -1)) {
//     //If they don't use separators then they have to use 2 digit day and month
//     dateSeparators.forEach(sep => {
//       format = format.replace(new RegExp(`\\${sep}`, "g"), "");
//     });
//   } else {
//     //Allow for single digit parsing of day/month
//     //Single characters work for 1 or 2 while 2 characters forces 2
//     format = removeDuplicateCharacters(format, ["D", "M"]);

//     //Find the separator used and make our format match
//     const usedSeparator = dateSeparators.filter(sep => input.indexOf(sep) >= 0);
//     //If they have mixed separators then we are lost
//     if (usedSeparator.length === 1) {
//       format = format.replace(new RegExp(`\\/`, "g"), usedSeparator[0]);
//     }
//   }

//   return format;
// }
