import { loadConfig } from "../../services/appConfigService";
import http from "../../services/api/http";
import { IGetEFormsPrefillDataResponse, GetEFormsPrefillDataResponse_Factory } from "csd.phoenix.models/GetEFormsPrefillDataResponse";


//#region Prefill Data

export interface IPublicPrefillData extends IGetEFormsPrefillDataResponse {
  // Mark `Locations` as required
  Locations: IGetEFormsPrefillDataResponse["Locations"];
}


export interface IPrefillData extends IPublicPrefillData {

}



export async function getPublicPrefillData(tenantInstallationId: string) {
  const { apiUrl } = await loadConfig();
  //console.info(`Loading template instances.`)
  const url = apiUrl
    .segment(`eforms/external/templates/publicPrefillData/${tenantInstallationId}`)
    .toString();

  const { data } = await http.get(url);
  const result = GetEFormsPrefillDataResponse_Factory.CreateIncoming(data)! as IPublicPrefillData;
  //console.log("Public prefill data:", result);
  return result;
}


export async function getPrefillData(patientId?: string) {
  const { apiUrl } = await loadConfig();
  //console.info(`Loading template instances.`)
  const url = apiUrl
  .segment(`eforms/templates/prefillData`)
    .param({patientId})
    .toString();

  const { data } = await http.get(url);
  const result = GetEFormsPrefillDataResponse_Factory.CreateIncoming(data)! as IPrefillData;
  return result;
}

//#endregion
