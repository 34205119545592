"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImplantDetailVersion_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ImplantBrand_1 = require("./ImplantBrand");
var ImplantBrandSize_1 = require("./ImplantBrandSize");
var ImplantManufacturer_1 = require("./ImplantManufacturer");
var PatientProcedure_1 = require("./PatientProcedure");
var Referral_1 = require("./Referral");
var ImplantDetailVersion_Factory = /** @class */ (function () {
    function ImplantDetailVersion_Factory() {
    }
    ImplantDetailVersion_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ActiveReferralId: model.ActiveReferralId,
            ImplantBrand: ImplantBrand_1.ImplantBrand_Factory.CreateIncoming(model.ImplantBrand),
            ImplantBrandId: model.ImplantBrandId,
            ImplantBrandSize: ImplantBrandSize_1.ImplantBrandSize_Factory.CreateIncoming(model.ImplantBrandSize),
            ImplantDetailCreatedAt: Date_Factory_1.Date_Factory.CreateIncoming(model.ImplantDetailCreatedAt),
            ImplantDetailId: model.ImplantDetailId,
            ImplantDetailUpdatedAt: Date_Factory_1.Date_Factory.CreateIncoming(model.ImplantDetailUpdatedAt),
            ImplantManufactureId: model.ImplantManufactureId,
            ImplantManufacturer: ImplantManufacturer_1.ImplantManufacturer_Factory.CreateIncoming(model.ImplantManufacturer),
            ImplantSizeId: model.ImplantSizeId,
            InsertDate: Date_Factory_1.Date_Factory.CreateIncoming(model.InsertDate),
            Lot: model.Lot,
            Note: model.Note,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateIncoming(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            RecordId: model.RecordId,
            RecordStatus: model.RecordStatus,
            Referral: Referral_1.Referral_Factory.CreateIncoming(model.Referral),
            RemovalDate: Date_Factory_1.Date_Factory.CreateIncoming(model.RemovalDate),
            UserId: model.UserId,
            VersionNumber: model.VersionNumber,
        };
        return result;
    };
    ImplantDetailVersion_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ActiveReferralId: model.ActiveReferralId,
            ImplantBrand: ImplantBrand_1.ImplantBrand_Factory.CreateOutgoing(model.ImplantBrand),
            ImplantBrandId: model.ImplantBrandId,
            ImplantBrandSize: ImplantBrandSize_1.ImplantBrandSize_Factory.CreateOutgoing(model.ImplantBrandSize),
            ImplantDetailCreatedAt: Date_Factory_1.Date_Factory.CreateOutgoing(model.ImplantDetailCreatedAt),
            ImplantDetailId: model.ImplantDetailId,
            ImplantDetailUpdatedAt: Date_Factory_1.Date_Factory.CreateOutgoing(model.ImplantDetailUpdatedAt),
            ImplantManufactureId: model.ImplantManufactureId,
            ImplantManufacturer: ImplantManufacturer_1.ImplantManufacturer_Factory.CreateOutgoing(model.ImplantManufacturer),
            ImplantSizeId: model.ImplantSizeId,
            InsertDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.InsertDate),
            Lot: model.Lot,
            Note: model.Note,
            PatientProcedure: PatientProcedure_1.PatientProcedure_Factory.CreateOutgoing(model.PatientProcedure),
            PatientProcedureId: model.PatientProcedureId,
            RecordId: model.RecordId,
            RecordStatus: model.RecordStatus,
            Referral: Referral_1.Referral_Factory.CreateOutgoing(model.Referral),
            RemovalDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.RemovalDate),
            UserId: model.UserId,
            VersionNumber: model.VersionNumber,
        };
        return result;
    };
    return ImplantDetailVersion_Factory;
}());
exports.ImplantDetailVersion_Factory = ImplantDetailVersion_Factory;
