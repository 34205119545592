"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsPersonPrefill_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsAddressPrefill_1 = require("./EFormsAddressPrefill");
var EFormsPhonePrefill_1 = require("./EFormsPhonePrefill");
var EFormsPersonPrefill_Factory = /** @class */ (function () {
    function EFormsPersonPrefill_Factory() {
    }
    EFormsPersonPrefill_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Birthdate: Date_Factory_1.Date_Factory.CreateIncoming(model.Birthdate),
            EmailAddress: model.EmailAddress,
            FirstName: model.FirstName,
            GenderCode: model.GenderCode,
            HomeAddress: EFormsAddressPrefill_1.EFormsAddressPrefill_Factory.CreateIncoming(model.HomeAddress),
            HomePhone: EFormsPhonePrefill_1.EFormsPhonePrefill_Factory.CreateIncoming(model.HomePhone),
            LastName: model.LastName,
            MiddleName: model.MiddleName,
            MobilePhone: EFormsPhonePrefill_1.EFormsPhonePrefill_Factory.CreateIncoming(model.MobilePhone),
            Nickname: model.Nickname,
            RecordId: model.RecordId,
            RelationshipCode: model.RelationshipCode,
            SalutationCode: model.SalutationCode,
            SSN: model.SSN,
            Title: model.Title,
            WorkPhone: EFormsPhonePrefill_1.EFormsPhonePrefill_Factory.CreateIncoming(model.WorkPhone),
        };
        return result;
    };
    EFormsPersonPrefill_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Birthdate: Date_Factory_1.Date_Factory.CreateOutgoing(model.Birthdate),
            EmailAddress: model.EmailAddress,
            FirstName: model.FirstName,
            GenderCode: model.GenderCode,
            HomeAddress: EFormsAddressPrefill_1.EFormsAddressPrefill_Factory.CreateOutgoing(model.HomeAddress),
            HomePhone: EFormsPhonePrefill_1.EFormsPhonePrefill_Factory.CreateOutgoing(model.HomePhone),
            LastName: model.LastName,
            MiddleName: model.MiddleName,
            MobilePhone: EFormsPhonePrefill_1.EFormsPhonePrefill_Factory.CreateOutgoing(model.MobilePhone),
            Nickname: model.Nickname,
            RecordId: model.RecordId,
            RelationshipCode: model.RelationshipCode,
            SalutationCode: model.SalutationCode,
            SSN: model.SSN,
            Title: model.Title,
            WorkPhone: EFormsPhonePrefill_1.EFormsPhonePrefill_Factory.CreateOutgoing(model.WorkPhone),
        };
        return result;
    };
    return EFormsPersonPrefill_Factory;
}());
exports.EFormsPersonPrefill_Factory = EFormsPersonPrefill_Factory;
