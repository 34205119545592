"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllowanceTable_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CDTCode_1 = require("./CDTCode");
var AllowanceTable_Factory = /** @class */ (function () {
    function AllowanceTable_Factory() {
    }
    AllowanceTable_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AgeRestrictedValue: model.AgeRestrictedValue,
            AllowedAmount: model.AllowedAmount,
            AppliesToDeductible: model.AppliesToDeductible,
            CDTCode: CDTCode_1.CDTCode_Factory.CreateIncoming(model.CDTCode),
            CDTCode1: CDTCode_1.CDTCode_Factory.CreateIncoming(model.CDTCode1),
            CDTCodeId: model.CDTCodeId,
            CopayAmount: model.CopayAmount,
            CoveredPercentage: model.CoveredPercentage,
            FrequencyLimitValue: model.FrequencyLimitValue,
            FrequencyLimitWithin: model.FrequencyLimitWithin,
            InsurancePlanId: model.InsurancePlanId,
            IsAgeRestricted: model.IsAgeRestricted,
            IsAgeRestrictedOver: model.IsAgeRestrictedOver,
            IsCovered: model.IsCovered,
            IsFrequencyLimit: model.IsFrequencyLimit,
            IsFrequencyLimitMonths: model.IsFrequencyLimitMonths,
            IsLEATRule: model.IsLEATRule,
            LEATCDTCode: model.LEATCDTCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    AllowanceTable_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AgeRestrictedValue: model.AgeRestrictedValue,
            AllowedAmount: model.AllowedAmount,
            AppliesToDeductible: model.AppliesToDeductible,
            CDTCode: CDTCode_1.CDTCode_Factory.CreateOutgoing(model.CDTCode),
            CDTCode1: CDTCode_1.CDTCode_Factory.CreateOutgoing(model.CDTCode1),
            CDTCodeId: model.CDTCodeId,
            CopayAmount: model.CopayAmount,
            CoveredPercentage: model.CoveredPercentage,
            FrequencyLimitValue: model.FrequencyLimitValue,
            FrequencyLimitWithin: model.FrequencyLimitWithin,
            InsurancePlanId: model.InsurancePlanId,
            IsAgeRestricted: model.IsAgeRestricted,
            IsAgeRestrictedOver: model.IsAgeRestrictedOver,
            IsCovered: model.IsCovered,
            IsFrequencyLimit: model.IsFrequencyLimit,
            IsFrequencyLimitMonths: model.IsFrequencyLimitMonths,
            IsLEATRule: model.IsLEATRule,
            LEATCDTCode: model.LEATCDTCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return AllowanceTable_Factory;
}());
exports.AllowanceTable_Factory = AllowanceTable_Factory;
