"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmsApptConfirmationCode_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SmsApptConfirmationCode_Factory = /** @class */ (function () {
    function SmsApptConfirmationCode_Factory() {
    }
    SmsApptConfirmationCode_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            ConfirmationCode: model.ConfirmationCode,
            PhoneNum: model.PhoneNum,
        };
        return result;
    };
    SmsApptConfirmationCode_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentId: model.AppointmentId,
            ConfirmationCode: model.ConfirmationCode,
            PhoneNum: model.PhoneNum,
        };
        return result;
    };
    return SmsApptConfirmationCode_Factory;
}());
exports.SmsApptConfirmationCode_Factory = SmsApptConfirmationCode_Factory;
