"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EthnicityType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EthnicityType_Factory = /** @class */ (function () {
    function EthnicityType_Factory() {
    }
    EthnicityType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DisplayOrder: model.DisplayOrder,
            EthnicityType1: model.EthnicityType1,
            EthnicityTypeCode: model.EthnicityTypeCode,
        };
        return result;
    };
    EthnicityType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DisplayOrder: model.DisplayOrder,
            EthnicityType1: model.EthnicityType1,
            EthnicityTypeCode: model.EthnicityTypeCode,
        };
        return result;
    };
    return EthnicityType_Factory;
}());
exports.EthnicityType_Factory = EthnicityType_Factory;
