"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalQuestionnaireQuestion_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var MedicalAlertType_1 = require("./MedicalAlertType");
var MedicalQuestionnaireQuestionGroup_1 = require("./MedicalQuestionnaireQuestionGroup");
var MedicalQuestionnaireQuestion_Factory = /** @class */ (function () {
    function MedicalQuestionnaireQuestion_Factory() {
    }
    MedicalQuestionnaireQuestion_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CreatesMedicalAlert: model.CreatesMedicalAlert,
            GroupId: model.GroupId,
            LegacyKey: model.LegacyKey,
            MedicalAlertAnswer: model.MedicalAlertAnswer,
            MedicalAlertType: MedicalAlertType_1.MedicalAlertType_Factory.CreateIncoming(model.MedicalAlertType),
            MedicalAlertTypeCode: model.MedicalAlertTypeCode,
            MedicalQuestionnaireId: model.MedicalQuestionnaireId,
            MedicalQuestionnaireQuestionGroup: MedicalQuestionnaireQuestionGroup_1.MedicalQuestionnaireQuestionGroup_Factory.CreateIncoming(model.MedicalQuestionnaireQuestionGroup),
            QuestionType: model.QuestionType,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SystemId: model.SystemId,
            Text: model.Text,
        };
        return result;
    };
    MedicalQuestionnaireQuestion_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CreatesMedicalAlert: model.CreatesMedicalAlert,
            GroupId: model.GroupId,
            LegacyKey: model.LegacyKey,
            MedicalAlertAnswer: model.MedicalAlertAnswer,
            MedicalAlertType: MedicalAlertType_1.MedicalAlertType_Factory.CreateOutgoing(model.MedicalAlertType),
            MedicalAlertTypeCode: model.MedicalAlertTypeCode,
            MedicalQuestionnaireId: model.MedicalQuestionnaireId,
            MedicalQuestionnaireQuestionGroup: MedicalQuestionnaireQuestionGroup_1.MedicalQuestionnaireQuestionGroup_Factory.CreateOutgoing(model.MedicalQuestionnaireQuestionGroup),
            QuestionType: model.QuestionType,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SystemId: model.SystemId,
            Text: model.Text,
        };
        return result;
    };
    return MedicalQuestionnaireQuestion_Factory;
}());
exports.MedicalQuestionnaireQuestion_Factory = MedicalQuestionnaireQuestion_Factory;
