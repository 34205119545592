import { BaseHub, IBaseHub } from "./BaseHub";
import { ISignalDispatcher, createSignal } from "../../utils/signalDispatcher";
import { EFormsStatusCodes } from "csd.phoenix.models";

export var iid_FormsHub = Symbol("FormsHub");

export interface IFormsMessageBody {
  FormId: string;
  StatusCode?: EFormsStatusCodes;
  LocationId: string;
}

export interface IFormsHub extends IBaseHub {
  FormUpdated: ISignalDispatcher<IFormsMessageBody>;
}

export class FormsHub extends BaseHub implements IFormsHub {
  constructor() {
    super("FormsHub");
  }
  public FormUpdated = createSignal<IFormsMessageBody>();
}
