"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSAcornAssessment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSAcornAssessment_Factory = /** @class */ (function () {
    function NHSAcornAssessment_Factory() {
    }
    NHSAcornAssessment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Decay: model.Decay,
            DecayCountDeciduous: model.DecayCountDeciduous,
            DecayCountPermanent: model.DecayCountPermanent,
            Dental: model.Dental,
            Medical: model.Medical,
            Other: model.Other,
            PatientId: model.PatientId,
            Perio: model.Perio,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Social: model.Social,
            ToothCount: model.ToothCount,
            Urgent: model.Urgent,
        };
        return result;
    };
    NHSAcornAssessment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Decay: model.Decay,
            DecayCountDeciduous: model.DecayCountDeciduous,
            DecayCountPermanent: model.DecayCountPermanent,
            Dental: model.Dental,
            Medical: model.Medical,
            Other: model.Other,
            PatientId: model.PatientId,
            Perio: model.Perio,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Social: model.Social,
            ToothCount: model.ToothCount,
            Urgent: model.Urgent,
        };
        return result;
    };
    return NHSAcornAssessment_Factory;
}());
exports.NHSAcornAssessment_Factory = NHSAcornAssessment_Factory;
