"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostOpEvaluation_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var VitalsRecord_1 = require("./VitalsRecord");
var PostOpEvaluation_Factory = /** @class */ (function () {
    function PostOpEvaluation_Factory() {
    }
    PostOpEvaluation_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AldreteActivityLevel: model.AldreteActivityLevel,
            AldreteCirculation: model.AldreteCirculation,
            AldreteConsciousness: model.AldreteConsciousness,
            AldreteOxygenSaturation: model.AldreteOxygenSaturation,
            AldreteRespiration: model.AldreteRespiration,
            AmbulatingTypeCode: model.AmbulatingTypeCode,
            DischargeStaffId: model.DischargeStaffId,
            DischargeTime: Date_Factory_1.Date_Factory.CreateIncoming(model.DischargeTime),
            EscortDescription: model.EscortDescription,
            EscortTypeCode: model.EscortTypeCode,
            InstructionsGivenToCode: model.InstructionsGivenToCode,
            InstructionsTypesGiven: model.InstructionsTypesGiven,
            NoComplications: model.NoComplications,
            Notes: model.Notes,
            PostOpMedications: model.PostOpMedications,
            PostOpVitalsRecord: VitalsRecord_1.VitalsRecord_Factory.CreateIncoming(model.PostOpVitalsRecord),
            PostOpVitalsRecordId: model.PostOpVitalsRecordId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    PostOpEvaluation_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AldreteActivityLevel: model.AldreteActivityLevel,
            AldreteCirculation: model.AldreteCirculation,
            AldreteConsciousness: model.AldreteConsciousness,
            AldreteOxygenSaturation: model.AldreteOxygenSaturation,
            AldreteRespiration: model.AldreteRespiration,
            AmbulatingTypeCode: model.AmbulatingTypeCode,
            DischargeStaffId: model.DischargeStaffId,
            DischargeTime: Date_Factory_1.Date_Factory.CreateOutgoing(model.DischargeTime),
            EscortDescription: model.EscortDescription,
            EscortTypeCode: model.EscortTypeCode,
            InstructionsGivenToCode: model.InstructionsGivenToCode,
            InstructionsTypesGiven: model.InstructionsTypesGiven,
            NoComplications: model.NoComplications,
            Notes: model.Notes,
            PostOpMedications: model.PostOpMedications,
            PostOpVitalsRecord: VitalsRecord_1.VitalsRecord_Factory.CreateOutgoing(model.PostOpVitalsRecord),
            PostOpVitalsRecordId: model.PostOpVitalsRecordId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return PostOpEvaluation_Factory;
}());
exports.PostOpEvaluation_Factory = PostOpEvaluation_Factory;
