"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSLocation_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var NHSMailbox_1 = require("./NHSMailbox");
var NHSLocation_Factory = /** @class */ (function () {
    function NHSLocation_Factory() {
    }
    NHSLocation_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            LocationNo: model.LocationNo,
            Locations: model.Locations && model.Locations.map(function (i) { return Location_1.Location_Factory.CreateIncoming(i); }),
            MailboxId: model.MailboxId,
            NHSMailbox: NHSMailbox_1.NHSMailbox_Factory.CreateIncoming(model.NHSMailbox),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SiteApprovalNo: model.SiteApprovalNo,
            TestIndicator: model.TestIndicator,
        };
        return result;
    };
    NHSLocation_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            LocationNo: model.LocationNo,
            Locations: model.Locations && model.Locations.map(function (i) { return Location_1.Location_Factory.CreateOutgoing(i); }),
            MailboxId: model.MailboxId,
            NHSMailbox: NHSMailbox_1.NHSMailbox_Factory.CreateOutgoing(model.NHSMailbox),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SiteApprovalNo: model.SiteApprovalNo,
            TestIndicator: model.TestIndicator,
        };
        return result;
    };
    return NHSLocation_Factory;
}());
exports.NHSLocation_Factory = NHSLocation_Factory;
