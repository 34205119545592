"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsurancePayerType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsurancePayer_1 = require("./InsurancePayer");
var InsurancePayerType_Factory = /** @class */ (function () {
    function InsurancePayerType_Factory() {
    }
    InsurancePayerType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            InsurancePayers: model.InsurancePayers && model.InsurancePayers.map(function (i) { return InsurancePayer_1.InsurancePayer_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    InsurancePayerType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Description: model.Description,
            InsurancePayers: model.InsurancePayers && model.InsurancePayers.map(function (i) { return InsurancePayer_1.InsurancePayer_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return InsurancePayerType_Factory;
}());
exports.InsurancePayerType_Factory = InsurancePayerType_Factory;
