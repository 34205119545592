"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LegacyIdentifier_1 = require("./LegacyIdentifier");
var EntityType_Factory = /** @class */ (function () {
    function EntityType_Factory() {
    }
    EntityType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EntityDescription: model.EntityDescription,
            EntityTypeCode: model.EntityTypeCode,
            LegacyIdentifiers: model.LegacyIdentifiers && model.LegacyIdentifiers.map(function (i) { return LegacyIdentifier_1.LegacyIdentifier_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    EntityType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EntityDescription: model.EntityDescription,
            EntityTypeCode: model.EntityTypeCode,
            LegacyIdentifiers: model.LegacyIdentifiers && model.LegacyIdentifiers.map(function (i) { return LegacyIdentifier_1.LegacyIdentifier_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return EntityType_Factory;
}());
exports.EntityType_Factory = EntityType_Factory;
