"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmsApptReminderCreationReason_DisplayOrders = exports.SmsApptReminderCreationReason_Descriptions_English = exports.SmsApptReminderCreationReason_Descriptions = exports.SmsApptReminderCreationReason_translateDescriptionBack = exports.SmsApptReminderCreationReason_translateDescriptions = exports.SmsApptReminderCreationReason = void 0;
var SmsApptReminderCreationReason;
(function (SmsApptReminderCreationReason) {
    SmsApptReminderCreationReason[SmsApptReminderCreationReason["Unknown"] = 0] = "Unknown";
    SmsApptReminderCreationReason[SmsApptReminderCreationReason["UserAction"] = 1] = "UserAction";
    SmsApptReminderCreationReason[SmsApptReminderCreationReason["Confirmation"] = 2] = "Confirmation";
    SmsApptReminderCreationReason[SmsApptReminderCreationReason["NewlyScheduled"] = 3] = "NewlyScheduled";
    SmsApptReminderCreationReason[SmsApptReminderCreationReason["BatchProcess"] = 4] = "BatchProcess";
})(SmsApptReminderCreationReason = exports.SmsApptReminderCreationReason || (exports.SmsApptReminderCreationReason = {}));
function SmsApptReminderCreationReason_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.SmsApptReminderCreationReason_Descriptions);
    allKeys.forEach(function (key) {
        exports.SmsApptReminderCreationReason_Descriptions[key] = translationFunction(exports.SmsApptReminderCreationReason_Descriptions[key]);
    });
}
exports.SmsApptReminderCreationReason_translateDescriptions = SmsApptReminderCreationReason_translateDescriptions;
function SmsApptReminderCreationReason_translateDescriptionBack() {
    var allKeys = Object.keys(exports.SmsApptReminderCreationReason_Descriptions);
    allKeys.forEach(function (key) {
        exports.SmsApptReminderCreationReason_Descriptions[key] = exports.SmsApptReminderCreationReason_Descriptions_English[key];
    });
}
exports.SmsApptReminderCreationReason_translateDescriptionBack = SmsApptReminderCreationReason_translateDescriptionBack;
var _SmsApptReminderCreationReason_Descriptions = {};
_SmsApptReminderCreationReason_Descriptions.Unknown = _SmsApptReminderCreationReason_Descriptions[0] = "Unknown";
_SmsApptReminderCreationReason_Descriptions.UserAction = _SmsApptReminderCreationReason_Descriptions[1] = "UserAction";
_SmsApptReminderCreationReason_Descriptions.Confirmation = _SmsApptReminderCreationReason_Descriptions[2] = "Confirmation";
_SmsApptReminderCreationReason_Descriptions.NewlyScheduled = _SmsApptReminderCreationReason_Descriptions[3] = "NewlyScheduled";
_SmsApptReminderCreationReason_Descriptions.BatchProcess = _SmsApptReminderCreationReason_Descriptions[4] = "BatchProcess";
var _SmsApptReminderCreationReason_Descriptions_English = {};
_SmsApptReminderCreationReason_Descriptions_English.Unknown = _SmsApptReminderCreationReason_Descriptions_English[0] = "Unknown";
_SmsApptReminderCreationReason_Descriptions_English.UserAction = _SmsApptReminderCreationReason_Descriptions_English[1] = "UserAction";
_SmsApptReminderCreationReason_Descriptions_English.Confirmation = _SmsApptReminderCreationReason_Descriptions_English[2] = "Confirmation";
_SmsApptReminderCreationReason_Descriptions_English.NewlyScheduled = _SmsApptReminderCreationReason_Descriptions_English[3] = "NewlyScheduled";
_SmsApptReminderCreationReason_Descriptions_English.BatchProcess = _SmsApptReminderCreationReason_Descriptions_English[4] = "BatchProcess";
exports.SmsApptReminderCreationReason_Descriptions = _SmsApptReminderCreationReason_Descriptions;
exports.SmsApptReminderCreationReason_Descriptions_English = _SmsApptReminderCreationReason_Descriptions_English;
var _SmsApptReminderCreationReason_DisplayOrders = {};
_SmsApptReminderCreationReason_DisplayOrders.Unknown = _SmsApptReminderCreationReason_DisplayOrders[0] = 0;
_SmsApptReminderCreationReason_DisplayOrders.UserAction = _SmsApptReminderCreationReason_DisplayOrders[1] = 1;
_SmsApptReminderCreationReason_DisplayOrders.Confirmation = _SmsApptReminderCreationReason_DisplayOrders[2] = 2;
_SmsApptReminderCreationReason_DisplayOrders.NewlyScheduled = _SmsApptReminderCreationReason_DisplayOrders[3] = 3;
_SmsApptReminderCreationReason_DisplayOrders.BatchProcess = _SmsApptReminderCreationReason_DisplayOrders[4] = 4;
exports.SmsApptReminderCreationReason_DisplayOrders = _SmsApptReminderCreationReason_DisplayOrders;
