"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CPTModifier_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CPTModifier_Factory = /** @class */ (function () {
    function CPTModifier_Factory() {
    }
    CPTModifier_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            ModifierCode: model.ModifierCode,
            ModifierId: model.ModifierId,
        };
        return result;
    };
    CPTModifier_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            ModifierCode: model.ModifierCode,
            ModifierId: model.ModifierId,
        };
        return result;
    };
    return CPTModifier_Factory;
}());
exports.CPTModifier_Factory = CPTModifier_Factory;
