"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedDevice_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ConnectedMonitor_1 = require("./ConnectedMonitor");
var ConnectedDevice_Factory = /** @class */ (function () {
    function ConnectedDevice_Factory() {
    }
    ConnectedDevice_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ConnectedMonitors: model.ConnectedMonitors && model.ConnectedMonitors.map(function (i) { return ConnectedMonitor_1.ConnectedMonitor_Factory.CreateIncoming(i); }),
            ConnectionStatus: model.ConnectionStatus,
            Description: model.Description,
            FriendlyName: model.FriendlyName,
            LocationId: model.LocationId,
            PrimaryKey: model.PrimaryKey,
            PublicKey: model.PublicKey,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RegistrationId: model.RegistrationId,
        };
        return result;
    };
    ConnectedDevice_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ConnectedMonitors: model.ConnectedMonitors && model.ConnectedMonitors.map(function (i) { return ConnectedMonitor_1.ConnectedMonitor_Factory.CreateOutgoing(i); }),
            ConnectionStatus: model.ConnectionStatus,
            Description: model.Description,
            FriendlyName: model.FriendlyName,
            LocationId: model.LocationId,
            PrimaryKey: model.PrimaryKey,
            PublicKey: model.PublicKey,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RegistrationId: model.RegistrationId,
        };
        return result;
    };
    return ConnectedDevice_Factory;
}());
exports.ConnectedDevice_Factory = ConnectedDevice_Factory;
