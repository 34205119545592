"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSExemptCode_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSExemptCode_Factory = /** @class */ (function () {
    function NHSExemptCode_Factory() {
    }
    NHSExemptCode_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AmountPrompt: model.AmountPrompt,
            Auto: model.Auto,
            CertPrompt: model.CertPrompt,
            DatePrompt: model.DatePrompt,
            Description: model.Description,
            EDINotSeen: model.EDINotSeen,
            EDISeen: model.EDISeen,
            EncodeText: model.EncodeText,
            EvidenceCheck: model.EvidenceCheck,
            ExemptCode: model.ExemptCode,
            ExemptStatus: model.ExemptStatus,
            ExpiryMessage: model.ExpiryMessage,
            ExpiryNewCode: model.ExpiryNewCode,
            MaxAge: model.MaxAge,
            MinAge: model.MinAge,
            Notes: model.Notes,
            RegionMask: model.RegionMask,
            Sex: model.Sex,
            TextPrompt: model.TextPrompt,
            ValidationID: model.ValidationID,
            ValidFrom: Date_Factory_1.Date_Factory.CreateIncoming(model.ValidFrom),
            ValidTo: Date_Factory_1.Date_Factory.CreateIncoming(model.ValidTo),
        };
        return result;
    };
    NHSExemptCode_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AmountPrompt: model.AmountPrompt,
            Auto: model.Auto,
            CertPrompt: model.CertPrompt,
            DatePrompt: model.DatePrompt,
            Description: model.Description,
            EDINotSeen: model.EDINotSeen,
            EDISeen: model.EDISeen,
            EncodeText: model.EncodeText,
            EvidenceCheck: model.EvidenceCheck,
            ExemptCode: model.ExemptCode,
            ExemptStatus: model.ExemptStatus,
            ExpiryMessage: model.ExpiryMessage,
            ExpiryNewCode: model.ExpiryNewCode,
            MaxAge: model.MaxAge,
            MinAge: model.MinAge,
            Notes: model.Notes,
            RegionMask: model.RegionMask,
            Sex: model.Sex,
            TextPrompt: model.TextPrompt,
            ValidationID: model.ValidationID,
            ValidFrom: Date_Factory_1.Date_Factory.CreateOutgoing(model.ValidFrom),
            ValidTo: Date_Factory_1.Date_Factory.CreateOutgoing(model.ValidTo),
        };
        return result;
    };
    return NHSExemptCode_Factory;
}());
exports.NHSExemptCode_Factory = NHSExemptCode_Factory;
