"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlignerTrackingPlan_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AlignerTrayPlan_1 = require("./AlignerTrayPlan");
var AlignerTrackingPlan_Factory = /** @class */ (function () {
    function AlignerTrackingPlan_Factory() {
    }
    AlignerTrackingPlan_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AlignerTrayPlans: model.AlignerTrayPlans && model.AlignerTrayPlans.map(function (i) { return AlignerTrayPlan_1.AlignerTrayPlan_Factory.CreateIncoming(i); }),
            LowerTrayPlan: AlignerTrayPlan_1.AlignerTrayPlan_Factory.CreateIncoming(model.LowerTrayPlan),
            Name: model.Name,
            OriginalPlanId: model.OriginalPlanId,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RefinementNum: model.RefinementNum,
            RefinementPlans: model.RefinementPlans && model.RefinementPlans.map(function (i) { return AlignerTrackingPlan_Factory.CreateIncoming(i); }),
            UpperTrayPlan: AlignerTrayPlan_1.AlignerTrayPlan_Factory.CreateIncoming(model.UpperTrayPlan),
        };
        return result;
    };
    AlignerTrackingPlan_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AlignerTrayPlans: model.AlignerTrayPlans && model.AlignerTrayPlans.map(function (i) { return AlignerTrayPlan_1.AlignerTrayPlan_Factory.CreateOutgoing(i); }),
            LowerTrayPlan: AlignerTrayPlan_1.AlignerTrayPlan_Factory.CreateOutgoing(model.LowerTrayPlan),
            Name: model.Name,
            OriginalPlanId: model.OriginalPlanId,
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RefinementNum: model.RefinementNum,
            RefinementPlans: model.RefinementPlans && model.RefinementPlans.map(function (i) { return AlignerTrackingPlan_Factory.CreateOutgoing(i); }),
            UpperTrayPlan: AlignerTrayPlan_1.AlignerTrayPlan_Factory.CreateOutgoing(model.UpperTrayPlan),
        };
        return result;
    };
    return AlignerTrackingPlan_Factory;
}());
exports.AlignerTrackingPlan_Factory = AlignerTrackingPlan_Factory;
