"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserWorkingDayChair_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var UserWorkingDayChair_Factory = /** @class */ (function () {
    function UserWorkingDayChair_Factory() {
    }
    UserWorkingDayChair_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ChairId: model.ChairId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserWorkingDayId: model.UserWorkingDayId,
        };
        return result;
    };
    UserWorkingDayChair_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ChairId: model.ChairId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserWorkingDayId: model.UserWorkingDayId,
        };
        return result;
    };
    return UserWorkingDayChair_Factory;
}());
exports.UserWorkingDayChair_Factory = UserWorkingDayChair_Factory;
