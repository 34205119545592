"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientRecallCycleUnitType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var PatientRecallCycleUnitType_Factory = /** @class */ (function () {
    function PatientRecallCycleUnitType_Factory() {
    }
    PatientRecallCycleUnitType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Descriptipn: model.Descriptipn,
        };
        return result;
    };
    PatientRecallCycleUnitType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Code: model.Code,
            Descriptipn: model.Descriptipn,
        };
        return result;
    };
    return PatientRecallCycleUnitType_Factory;
}());
exports.PatientRecallCycleUnitType_Factory = PatientRecallCycleUnitType_Factory;
