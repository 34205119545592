"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionAllocation_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Transaction_1 = require("./Transaction");
var User_1 = require("./User");
var CollectionAllocation_Factory = /** @class */ (function () {
    function CollectionAllocation_Factory() {
    }
    CollectionAllocation_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            ComputedProviderId: model.ComputedProviderId,
            CreditId: model.CreditId,
            Debit: Transaction_1.Transaction_Factory.CreateIncoming(model.Debit),
            DebitId: model.DebitId,
            DebitProviderId: model.DebitProviderId,
            PrePaymentPaid: model.PrePaymentPaid,
            Provider: User_1.User_Factory.CreateIncoming(model.Provider),
            ProviderId: model.ProviderId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    CollectionAllocation_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Amount: model.Amount,
            ComputedProviderId: model.ComputedProviderId,
            CreditId: model.CreditId,
            Debit: Transaction_1.Transaction_Factory.CreateOutgoing(model.Debit),
            DebitId: model.DebitId,
            DebitProviderId: model.DebitProviderId,
            PrePaymentPaid: model.PrePaymentPaid,
            Provider: User_1.User_Factory.CreateOutgoing(model.Provider),
            ProviderId: model.ProviderId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return CollectionAllocation_Factory;
}());
exports.CollectionAllocation_Factory = CollectionAllocation_Factory;
