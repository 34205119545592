import UrlAssembler from "url-assembler";
import { AxiosRequestConfig } from "axios";
import { ODataQueryParams, IApiQueryOptions, IApiQueryResponse, IODataSuccessResponse, KeyValuePairs, ODataActionResponse } from "./models";
import { assertNotEmpty } from "../../../utils/objectUtils";
import Resource from "./Resource";
import http from "../http";



export const activeRecordsFilter: ODataQueryParams = {filter: "RecordStatus eq 0"}

type HttpRequestWithoutUrl = Omit<AxiosRequestConfig, "url">;
type PostOperationQueryParams = Pick<ODataQueryParams, "expand" | "select">;


export default class ODataApi {
  private baseUrl: UrlAssembler;
  private entitySetName: string;

  constructor(baseUrl: UrlAssembler, entitySetName: string) {
    this.baseUrl = assertNotEmpty(baseUrl);
    this.entitySetName = assertNotEmpty(entitySetName);
  }


  private async baseRunQuery<T>(httpRequest: AxiosRequestConfig): Promise<IApiQueryResponse<T>> {
    try {
      const { data } = await http.request<IODataSuccessResponse<T>>(httpRequest);
      //const data = _data as IODataSuccessResponse<T>;
      return {
        count: data["@odata.count"],
        value: data.value
      };
    }
    catch(_e) {

      // else if (error.response && error.response.status === 400) {
  //   //Handle BadRequest ModelState Error
  //   const response = (error.response.data as any)["error"];
  //   return Promise.reject(response && response["innererror"]||error);
  // }

      //console.log(_e);
      //const axiosError = _e as AxiosError<IODataErrorResponse>;
      // let errorMsg;
      // // Handle "400 - Bad Data" error.
      // if (axiosError.response && axiosError.response.status === 400) {
      //   const { data } = axiosError.response;
      //   errorMsg = data;
      // }
      // else
      //   errorMsg = _e.message;

      //const { error: { message: errorMsg } } = _e as IODataErrorResponse;
      const message = `OData ${httpRequest.method} call to "${this.entitySetName}" failed. ${_e.message}`;
      console.error(message, _e);
      throw _e;
    }
  }


  runSetQuery<T>(
    httpRequest: HttpRequestWithoutUrl,
    queryParams?: ODataQueryParams,
    pageOptions?: IApiQueryOptions,
  ): Promise<IApiQueryResponse<T[]>> {

    const url = new Resource(this.baseUrl.toString())
        .entitySet(this.entitySetName)
        .queryOptions(queryParams)
        .applyApiQueryOptions(pageOptions)
        .asString();

    const requestConfig = {...httpRequest, url }
    return this.baseRunQuery<T[]>(requestConfig);
  }


  async runEntityQuery<T>(
    entityId: string,
    httpRequest: HttpRequestWithoutUrl,
    queryParams?: ODataQueryParams,
    pageOptions?: IApiQueryOptions,
  ): Promise<T> {

    const url = new Resource(this.baseUrl.toString())
        .entity(this.entitySetName, entityId)
        .queryOptions(queryParams)
        .applyApiQueryOptions(pageOptions)
        .asString();

    const requestConfig = {...httpRequest, url }
    const result = await this.baseRunQuery<T[]>(requestConfig);
    if (result.value.length === 0) {
      throw new Error(`Can't find ${this.entitySetName} with id: ${entityId}`);
    }

    console.assert(result.value.length === 1);
    return result.value[0];
  }


  private async baseActionQuery<TReq, TRsp>(entityId: string, actionName: string, data: TReq): Promise<TRsp> {
    let urlBuilder = new Resource(this.baseUrl.toString());
    urlBuilder = entityId ? urlBuilder.entity(this.entitySetName, entityId) : urlBuilder.entitySet(this.entitySetName)

    const url = urlBuilder.action(actionName).asString();

    const httpRequest: AxiosRequestConfig = {
      url,
      method: "POST",
      data
    };

    try {
      const { data } = await http.request<ODataActionResponse<TRsp>>(httpRequest);
      const {"@odata.context": _, ...entity} = data;
      //console.log("entity", entity)

      return entity as any as TRsp;
    }
    catch(_e) {
      const message = `OData action ${actionName} call to "${this.entitySetName}" failed. ${_e.message}`;
      console.error(message, _e);
      throw _e;
    }
  }


  runEntityDeepUpdate<TReq extends {RecordId: string}>(data: TReq): Promise<TReq> {
    const model = { object: data  }
    return this.baseActionQuery<typeof model, TReq>(data.RecordId, "DeepUpdate", model);
  }


  runCollectionFunction<T>(
    httpRequest: HttpRequestWithoutUrl,
    functionName: string,
    functionParams?: KeyValuePairs,
    queryParams?: PostOperationQueryParams
  ): Promise<IApiQueryResponse<T>> {

    const url = new Resource(this.baseUrl.toString())
        .entitySet(this.entitySetName)
        .entityFunction("Action." + functionName, functionParams)
        .queryOptions(queryParams)
        .asString();

    const requestConfig = {...httpRequest, url }
    return this.baseRunQuery<T>(requestConfig);
  }


  runCollectionAction<T>(
    httpRequest: HttpRequestWithoutUrl,
    actionName: string,
    queryParams?: PostOperationQueryParams
  ): Promise<IApiQueryResponse<T>> {

    const url = new Resource(this.baseUrl.toString())
        .entitySet(this.entitySetName)
        .action(actionName)
        .queryOptions(queryParams)
        .asString();

    const requestConfig = {...httpRequest, url }
    return this.baseRunQuery<T>(requestConfig);
  }

  runCollectionActionWithData<TReq>(entityId: string, action: string, data: any): Promise<TReq> {
    return this.baseActionQuery<typeof data, TReq>(entityId, action, data);
  }
}


