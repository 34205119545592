"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsuranceClaimNote_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var InsuranceClaim_1 = require("./InsuranceClaim");
var InsuranceClaimNoteType_1 = require("./InsuranceClaimNoteType");
var Note_1 = require("./Note");
var InsuranceClaimNote_Factory = /** @class */ (function () {
    function InsuranceClaimNote_Factory() {
    }
    InsuranceClaimNote_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            InsuranceClaim: InsuranceClaim_1.InsuranceClaim_Factory.CreateIncoming(model.InsuranceClaim),
            InsuranceClaimId: model.InsuranceClaimId,
            InsuranceClaimNoteType1: InsuranceClaimNoteType_1.InsuranceClaimNoteType_Factory.CreateIncoming(model.InsuranceClaimNoteType1),
            Note: Note_1.Note_Factory.CreateIncoming(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateIncoming(model.NoteTakenOn),
            NoteTypeCode: model.NoteTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    InsuranceClaimNote_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            InsuranceClaim: InsuranceClaim_1.InsuranceClaim_Factory.CreateOutgoing(model.InsuranceClaim),
            InsuranceClaimId: model.InsuranceClaimId,
            InsuranceClaimNoteType1: InsuranceClaimNoteType_1.InsuranceClaimNoteType_Factory.CreateOutgoing(model.InsuranceClaimNoteType1),
            Note: Note_1.Note_Factory.CreateOutgoing(model.Note),
            NoteId: model.NoteId,
            NoteTakenOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.NoteTakenOn),
            NoteTypeCode: model.NoteTypeCode,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return InsuranceClaimNote_Factory;
}());
exports.InsuranceClaimNote_Factory = InsuranceClaimNote_Factory;
