"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompletedMedicalQuestionnaireAnswer_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CompletedMedicalQuestionnaire_1 = require("./CompletedMedicalQuestionnaire");
var CompletedMedicalQuestionnaireAnswer_Factory = /** @class */ (function () {
    function CompletedMedicalQuestionnaireAnswer_Factory() {
    }
    CompletedMedicalQuestionnaireAnswer_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Answer: model.Answer,
            CompletedMedicalQuestionnaire: CompletedMedicalQuestionnaire_1.CompletedMedicalQuestionnaire_Factory.CreateIncoming(model.CompletedMedicalQuestionnaire),
            CompletedMedicalQuestionnaireId: model.CompletedMedicalQuestionnaireId,
            FreeTextAnswer: model.FreeTextAnswer,
            MedicalQuestionnaireQuestionId: model.MedicalQuestionnaireQuestionId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    CompletedMedicalQuestionnaireAnswer_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Answer: model.Answer,
            CompletedMedicalQuestionnaire: CompletedMedicalQuestionnaire_1.CompletedMedicalQuestionnaire_Factory.CreateOutgoing(model.CompletedMedicalQuestionnaire),
            CompletedMedicalQuestionnaireId: model.CompletedMedicalQuestionnaireId,
            FreeTextAnswer: model.FreeTextAnswer,
            MedicalQuestionnaireQuestionId: model.MedicalQuestionnaireQuestionId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return CompletedMedicalQuestionnaireAnswer_Factory;
}());
exports.CompletedMedicalQuestionnaireAnswer_Factory = CompletedMedicalQuestionnaireAnswer_Factory;
