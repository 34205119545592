"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCaseMixRecording_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCaseMixRecordedCriteria_1 = require("./NHSCaseMixRecordedCriteria");
var NHSCourseDetail_1 = require("./NHSCourseDetail");
var NHSCaseMixRecording_Factory = /** @class */ (function () {
    function NHSCaseMixRecording_Factory() {
    }
    NHSCaseMixRecording_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CaseMixRecordedCriteria: model.CaseMixRecordedCriteria && model.CaseMixRecordedCriteria.map(function (i) { return NHSCaseMixRecordedCriteria_1.NHSCaseMixRecordedCriteria_Factory.CreateIncoming(i); }),
            NHSCourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateIncoming(model.NHSCourseDetail),
            NHSCourseDetailId: model.NHSCourseDetailId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordedByUserId: model.RecordedByUserId,
            RecordedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordedDate, { ignoreTimePortion: true }),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    NHSCaseMixRecording_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CaseMixRecordedCriteria: model.CaseMixRecordedCriteria && model.CaseMixRecordedCriteria.map(function (i) { return NHSCaseMixRecordedCriteria_1.NHSCaseMixRecordedCriteria_Factory.CreateOutgoing(i); }),
            NHSCourseDetail: NHSCourseDetail_1.NHSCourseDetail_Factory.CreateOutgoing(model.NHSCourseDetail),
            NHSCourseDetailId: model.NHSCourseDetailId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordedByUserId: model.RecordedByUserId,
            RecordedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordedDate, { ignoreTimePortion: true }),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return NHSCaseMixRecording_Factory;
}());
exports.NHSCaseMixRecording_Factory = NHSCaseMixRecording_Factory;
