"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsStatus_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsStatusTracking_1 = require("./EFormsStatusTracking");
var EFormsStatus_Factory = /** @class */ (function () {
    function EFormsStatus_Factory() {
    }
    EFormsStatus_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            Name: model.Name,
            StatusCode: model.StatusCode,
            StatusTrackings: model.StatusTrackings && model.StatusTrackings.map(function (i) { return EFormsStatusTracking_1.EFormsStatusTracking_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    EFormsStatus_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            Name: model.Name,
            StatusCode: model.StatusCode,
            StatusTrackings: model.StatusTrackings && model.StatusTrackings.map(function (i) { return EFormsStatusTracking_1.EFormsStatusTracking_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return EFormsStatus_Factory;
}());
exports.EFormsStatus_Factory = EFormsStatus_Factory;
