"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestedReport_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var RequestedReportStatus_1 = require("./RequestedReportStatus");
var ReportGeneratorReportType_1 = require("./ReportGeneratorReportType");
var RequestedReport_Factory = /** @class */ (function () {
    function RequestedReport_Factory() {
    }
    RequestedReport_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CodeVersion: model.CodeVersion,
            CompletedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.CompletedDate),
            CreatedBy: model.CreatedBy,
            CreatedByUser: User_1.User_Factory.CreateIncoming(model.CreatedByUser),
            CreatedDate: Date_Factory_1.Date_Factory.CreateIncoming(model.CreatedDate),
            Diagnostics: model.Diagnostics,
            FileId: model.FileId,
            FunctionalityFlag: model.FunctionalityFlag,
            PdfFileId: model.PdfFileId,
            RecordId: model.RecordId,
            ReportDefinition: model.ReportDefinition,
            ReportStatus: RequestedReportStatus_1.RequestedReportStatus_Factory.CreateIncoming(model.ReportStatus),
            ReportType: model.ReportType,
            RequestedReportType: ReportGeneratorReportType_1.ReportGeneratorReportType_Factory.CreateIncoming(model.RequestedReportType),
            Status: model.Status,
        };
        return result;
    };
    RequestedReport_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CodeVersion: model.CodeVersion,
            CompletedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.CompletedDate),
            CreatedBy: model.CreatedBy,
            CreatedByUser: User_1.User_Factory.CreateOutgoing(model.CreatedByUser),
            CreatedDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.CreatedDate),
            Diagnostics: model.Diagnostics,
            FileId: model.FileId,
            FunctionalityFlag: model.FunctionalityFlag,
            PdfFileId: model.PdfFileId,
            RecordId: model.RecordId,
            ReportDefinition: model.ReportDefinition,
            ReportStatus: RequestedReportStatus_1.RequestedReportStatus_Factory.CreateOutgoing(model.ReportStatus),
            ReportType: model.ReportType,
            RequestedReportType: ReportGeneratorReportType_1.ReportGeneratorReportType_Factory.CreateOutgoing(model.RequestedReportType),
            Status: model.Status,
        };
        return result;
    };
    return RequestedReport_Factory;
}());
exports.RequestedReport_Factory = RequestedReport_Factory;
