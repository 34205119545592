"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoftTissueExam_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EnterpriseConsultationSubjectLink_1 = require("./EnterpriseConsultationSubjectLink");
var SoftTissueExamFinding_1 = require("./SoftTissueExamFinding");
var Note_1 = require("./Note");
var SoftTissueExam_Factory = /** @class */ (function () {
    function SoftTissueExam_Factory() {
    }
    SoftTissueExam_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateIncoming(i); }),
            EnterpriseLocationId: model.EnterpriseLocationId,
            Findings: model.Findings && model.Findings.map(function (i) { return SoftTissueExamFinding_1.SoftTissueExamFinding_Factory.CreateIncoming(i); }),
            LocationId: model.LocationId,
            Notes: model.Notes && model.Notes.map(function (i) { return Note_1.Note_Factory.CreateIncoming(i); }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    SoftTissueExam_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseConsultationSubjectLinks: model.EnterpriseConsultationSubjectLinks && model.EnterpriseConsultationSubjectLinks.map(function (i) { return EnterpriseConsultationSubjectLink_1.EnterpriseConsultationSubjectLink_Factory.CreateOutgoing(i); }),
            EnterpriseLocationId: model.EnterpriseLocationId,
            Findings: model.Findings && model.Findings.map(function (i) { return SoftTissueExamFinding_1.SoftTissueExamFinding_Factory.CreateOutgoing(i); }),
            LocationId: model.LocationId,
            Notes: model.Notes && model.Notes.map(function (i) { return Note_1.Note_Factory.CreateOutgoing(i); }),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            UserId: model.UserId,
        };
        return result;
    };
    return SoftTissueExam_Factory;
}());
exports.SoftTissueExam_Factory = SoftTissueExam_Factory;
