"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsLocationPrefill_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EFormsPhonePrefill_1 = require("./EFormsPhonePrefill");
var EFormsAddressPrefill_1 = require("./EFormsAddressPrefill");
var EFormsLocationPrefill_Factory = /** @class */ (function () {
    function EFormsLocationPrefill_Factory() {
    }
    EFormsLocationPrefill_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptingForms: model.AcceptingForms,
            BusinessName: model.BusinessName,
            DisplayName: model.DisplayName,
            LocationId: model.LocationId,
            MainPhone: EFormsPhonePrefill_1.EFormsPhonePrefill_Factory.CreateIncoming(model.MainPhone),
            NHSLocationNumber: model.NHSLocationNumber,
            PhysicalAddress: EFormsAddressPrefill_1.EFormsAddressPrefill_Factory.CreateIncoming(model.PhysicalAddress),
        };
        return result;
    };
    EFormsLocationPrefill_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AcceptingForms: model.AcceptingForms,
            BusinessName: model.BusinessName,
            DisplayName: model.DisplayName,
            LocationId: model.LocationId,
            MainPhone: EFormsPhonePrefill_1.EFormsPhonePrefill_Factory.CreateOutgoing(model.MainPhone),
            NHSLocationNumber: model.NHSLocationNumber,
            PhysicalAddress: EFormsAddressPrefill_1.EFormsAddressPrefill_Factory.CreateOutgoing(model.PhysicalAddress),
        };
        return result;
    };
    return EFormsLocationPrefill_Factory;
}());
exports.EFormsLocationPrefill_Factory = EFormsLocationPrefill_Factory;
