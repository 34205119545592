"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurgeryDrugRecording_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var SurgeryDrug_1 = require("./SurgeryDrug");
var SurgeryDrugDosageType_1 = require("./SurgeryDrugDosageType");
var SurgeryDrugRecording_Factory = /** @class */ (function () {
    function SurgeryDrugRecording_Factory() {
    }
    SurgeryDrugRecording_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Dose: model.Dose,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SurgeryDrug: SurgeryDrug_1.SurgeryDrug_Factory.CreateIncoming(model.SurgeryDrug),
            SurgeryDrugDosageType: SurgeryDrugDosageType_1.SurgeryDrugDosageType_Factory.CreateIncoming(model.SurgeryDrugDosageType),
            SurgeryDrugDosageTypeId: model.SurgeryDrugDosageTypeId,
            SurgeryDrugId: model.SurgeryDrugId,
            Units: model.Units,
        };
        return result;
    };
    SurgeryDrugRecording_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Dose: model.Dose,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SurgeryDrug: SurgeryDrug_1.SurgeryDrug_Factory.CreateOutgoing(model.SurgeryDrug),
            SurgeryDrugDosageType: SurgeryDrugDosageType_1.SurgeryDrugDosageType_Factory.CreateOutgoing(model.SurgeryDrugDosageType),
            SurgeryDrugDosageTypeId: model.SurgeryDrugDosageTypeId,
            SurgeryDrugId: model.SurgeryDrugId,
            Units: model.Units,
        };
        return result;
    };
    return SurgeryDrugRecording_Factory;
}());
exports.SurgeryDrugRecording_Factory = SurgeryDrugRecording_Factory;
