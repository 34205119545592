"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NHSCaseMixScoreWeight_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var NHSCaseMixScoreWeight_Factory = /** @class */ (function () {
    function NHSCaseMixScoreWeight_Factory() {
    }
    NHSCaseMixScoreWeight_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CriterionCode: model.CriterionCode,
            ScoreCode: model.ScoreCode,
            Weight: model.Weight,
        };
        return result;
    };
    NHSCaseMixScoreWeight_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CriterionCode: model.CriterionCode,
            ScoreCode: model.ScoreCode,
            Weight: model.Weight,
        };
        return result;
    };
    return NHSCaseMixScoreWeight_Factory;
}());
exports.NHSCaseMixScoreWeight_Factory = NHSCaseMixScoreWeight_Factory;
