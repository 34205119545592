import { Container, interfaces } from "inversify";
import getDecorators from "inversify-inject-decorators";

const container = new Container();
export default container;

// Property injection decorators
// https://github.com/inversify/InversifyJS/blob/master/wiki/property_injection.md
export const { lazyInject, lazyMultiInject, lazyInjectNamed, lazyInjectTagged } = getDecorators(
  container
);

export function bindSingleton<T, U extends T>(
  serviceId: interfaces.ServiceIdentifier<T>,
  serviceType: interfaces.Newable<U>
) {
  container.bind(serviceId).to(serviceType).inSingletonScope();
}

export function bindConstant<T, U extends T>(serviceId: interfaces.ServiceIdentifier<T>, value: U) {
  container.bind<T>(serviceId).toConstantValue(value);
}

/** Return value only and not setter */
export function useInject<T>(serviceId: interfaces.ServiceIdentifier<T>): T {
  return container.get<T>(serviceId);
}
