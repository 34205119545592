"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracCaseEnclosure_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracCase_1 = require("./LabtracCase");
var LabtracEnclosure_1 = require("./LabtracEnclosure");
var LabtracCaseEnclosure_Factory = /** @class */ (function () {
    function LabtracCaseEnclosure_Factory() {
    }
    LabtracCaseEnclosure_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppReference: model.AppReference,
            ExternalId: model.ExternalId,
            LabReference: model.LabReference,
            LabtracCase: LabtracCase_1.LabtracCase_Factory.CreateIncoming(model.LabtracCase),
            LabtracCaseId: model.LabtracCaseId,
            LabtracEnclosure: LabtracEnclosure_1.LabtracEnclosure_Factory.CreateIncoming(model.LabtracEnclosure),
            LabtracEnclosureId: model.LabtracEnclosureId,
            Name: model.Name,
            Qty: model.Qty,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    LabtracCaseEnclosure_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppReference: model.AppReference,
            ExternalId: model.ExternalId,
            LabReference: model.LabReference,
            LabtracCase: LabtracCase_1.LabtracCase_Factory.CreateOutgoing(model.LabtracCase),
            LabtracCaseId: model.LabtracCaseId,
            LabtracEnclosure: LabtracEnclosure_1.LabtracEnclosure_Factory.CreateOutgoing(model.LabtracEnclosure),
            LabtracEnclosureId: model.LabtracEnclosureId,
            Name: model.Name,
            Qty: model.Qty,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return LabtracCaseEnclosure_Factory;
}());
exports.LabtracCaseEnclosure_Factory = LabtracCaseEnclosure_Factory;
