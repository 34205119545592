"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickbooksDefaultMapping_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var QuickbooksDefaultMapping_Factory = /** @class */ (function () {
    function QuickbooksDefaultMapping_Factory() {
    }
    QuickbooksDefaultMapping_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DepositToAccount: model.DepositToAccount,
            DeposLineItemDetails: model.DeposLineItemDetails,
            ItemizeByPatient: model.ItemizeByPatient,
            QBOClass: model.QBOClass,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransactionsFrom: Date_Factory_1.Date_Factory.CreateIncoming(model.TransactionsFrom),
        };
        return result;
    };
    QuickbooksDefaultMapping_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DepositToAccount: model.DepositToAccount,
            DeposLineItemDetails: model.DeposLineItemDetails,
            ItemizeByPatient: model.ItemizeByPatient,
            QBOClass: model.QBOClass,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            TransactionsFrom: Date_Factory_1.Date_Factory.CreateOutgoing(model.TransactionsFrom),
        };
        return result;
    };
    return QuickbooksDefaultMapping_Factory;
}());
exports.QuickbooksDefaultMapping_Factory = QuickbooksDefaultMapping_Factory;
