"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportGeneratorReportType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var ReportGeneratorReportCategory_1 = require("./ReportGeneratorReportCategory");
var ReportGeneratorColumn_1 = require("./ReportGeneratorColumn");
var ReportGeneratorReportType_Factory = /** @class */ (function () {
    function ReportGeneratorReportType_Factory() {
    }
    ReportGeneratorReportType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CurrentCodeVersion: model.CurrentCodeVersion,
            Id: model.Id,
            Name: model.Name,
            ReportCategory: ReportGeneratorReportCategory_1.ReportGeneratorReportCategory_Factory.CreateIncoming(model.ReportCategory),
            ReportCategoryCode: model.ReportCategoryCode,
            ReportColumns: model.ReportColumns && model.ReportColumns.map(function (i) { return ReportGeneratorColumn_1.ReportGeneratorColumn_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    ReportGeneratorReportType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CurrentCodeVersion: model.CurrentCodeVersion,
            Id: model.Id,
            Name: model.Name,
            ReportCategory: ReportGeneratorReportCategory_1.ReportGeneratorReportCategory_Factory.CreateOutgoing(model.ReportCategory),
            ReportCategoryCode: model.ReportCategoryCode,
            ReportColumns: model.ReportColumns && model.ReportColumns.map(function (i) { return ReportGeneratorColumn_1.ReportGeneratorColumn_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return ReportGeneratorReportType_Factory;
}());
exports.ReportGeneratorReportType_Factory = ReportGeneratorReportType_Factory;
