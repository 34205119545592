"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitingList_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var PatientWaitingList_1 = require("./PatientWaitingList");
var WaitingList_Factory = /** @class */ (function () {
    function WaitingList_Factory() {
    }
    WaitingList_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            ClockDurationUnit: model.ClockDurationUnit,
            ClockDurationValue: model.ClockDurationValue,
            ClockStopsOn: model.ClockStopsOn,
            Description: model.Description,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            Name: model.Name,
            PatientWaitingLists: model.PatientWaitingLists && model.PatientWaitingLists.map(function (i) { return PatientWaitingList_1.PatientWaitingList_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    WaitingList_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            ClockDurationUnit: model.ClockDurationUnit,
            ClockDurationValue: model.ClockDurationValue,
            ClockStopsOn: model.ClockStopsOn,
            Description: model.Description,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            Name: model.Name,
            PatientWaitingLists: model.PatientWaitingLists && model.PatientWaitingLists.map(function (i) { return PatientWaitingList_1.PatientWaitingList_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return WaitingList_Factory;
}());
exports.WaitingList_Factory = WaitingList_Factory;
