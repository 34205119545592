//import { isDate } from "lodash";
import get from "lodash.get";


export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

// Note: TypeScript user-defined type-guard
export function notNil<T>(value: T | null | undefined): value is T {
  // Note: Calling this function with an undeclared variable will throw
  // a ReferenceError exception -- which is a "Good Thing (TM)".
  // (See associated unit tests for an example.)
  return value != null && (typeof value !== "number" || !isNaN(value));
}

export function _dangerouslyIgnoreNil<T>(value: T | null | undefined) {
  return value!;
}

export function isNil(value: any) {
  return value == null || (typeof value === "number" && isNaN(value));
}

export function notEmpty<T>(value: T): value is NonNullable<T> {
  return notNil(value) && (typeof value !== 'string' || value !== "");
}

export function assertNonNil<T>(value: T | null | undefined, message?: string) {
  if (notNil(value)) return value;

  const error = "Invariant failed. Supplied value is `null` or `undefined`.";
  throw new Error(error + (message ? ` (${message})` : ""));
}

export function assertNotEmpty<T>(value: T, message?: string): NonNullable<T> {
  if (notEmpty(value)) return value;
  const error = 'Invariant failed. Supplied value is `null`, `undefined` or "".';
  throw new Error(error + (message ? ` (${message})` : ""));
}


export function noop(): void {}

export function identity<T>(value: T) {
  return value;
}

export type optional<T> = T | null | undefined;
export type nullable<T> = T | null;

/**
 * This is a safer way to set defaults so as not to fall foul of unexpected falsy defaults (i.e. 0)
 * Uses isNil to also protect against NaN
 * @param value The value to check to see if it is undefined | null
 * @param defaultValue The value to use if it is
 */
export function setDefault<T>(value: T | null | undefined, defaultValue: T): T {
  return isNil(value) ? defaultValue : assertNonNil(value);
}

type EnumType = { [s: string]: number } | { [n: number]: string };

// Note: This will NOT work on `const enum`s.
export function getEnumValues(enumType: EnumType): number[] {
  return Object.values(enumType).filter(v => typeof v === "number");
}

/**
 * Stolen from Blueprintjs
 * Safely invoke the function with the given arguments, if it is indeed a
 * function, and return its value. Otherwise, return undefined.
 */
export function invokeIfFunction<R>(func: (() => R) | undefined): R | undefined;
export function invokeIfFunction<A, R>(func: ((arg1: A) => R) | undefined, arg1: A): R | undefined;
export function invokeIfFunction<A, B, R>(func: ((arg1: A, arg2: B) => R) | undefined, arg1: A, arg2: B): R | undefined;
export function invokeIfFunction<A, B, C, R>(func: ((arg1: A, arg2: B, arg3: C) => R) | undefined, arg1: A, arg2: B, arg3: C): R | undefined;
export function invokeIfFunction<A, B, C, D, R>(
  func: ((arg1: A, arg2: B, arg3: C, arg4: D) => R) | undefined,
  arg1: A,
  arg2: B,
  arg3: C,
  arg4: D
): R | undefined;
// tslint:disable-next-line:ban-types
export function invokeIfFunction(func: Function | undefined, ...args: any[]) {
  if (isFunction(func)) {
    return func(...args);
  }
  return undefined;
}

export function isFunction(value: any): value is Function {
  return typeof value === "function";
}

// Genrate a random int within the given range
export function randInt(max: number, min = 0) {
  return Math.round(Math.random() * (max - min)) + min;
}

// // Replace object property values that match the supplied value
// export function replaceValues(obj: object, valueToMatch: any, valueToReplaceWith: any): any {
//   for (let [key, value] of Object.entries(obj)) {
//     if (value === valueToMatch || (isDate(value) && isDate(valueToMatch) && value.valueOf() === valueToMatch.valueOf())) {
//       (obj as any)[key] = valueToReplaceWith;
//     }
//   }

//   return obj;
// }

// Remove specified members from a type.
// Ex:
//  type t1 = { a: number, b: string };
//  Omit<t1, "a"> gives you: {b: string};
//export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

/**
 * Convert any Iterable to a Map<K,T>
 * @param items Iterable to convert to Map
 * @param keySelector lambda to return Map.key from T
 */
export function toMap<K, T>(items: Iterable<T>, keySelector: (item: T) => K): Map<K, T> {
  return new Map<K, T>([...items].map(i => [keySelector(i), i] as [K, T]));
}

/**
 * Converts number to it's bit version
 * @param value number you need converting
 * @param offset used to baseline enums that are not 1-based (lowest enum value - 1)
 */
export function bitShiftValue(value: number, offset: number = 0) {
  return 1 << (value - offset - 1);
}

/**
 * Convert array of enum values into a bitmask
 * enums must be 1-based and sequential
 * @param values array of enum values (enum must start at 1)
 * @param offset used to baseline enums that are not 1-based (lowest enum value - 1)
 */
export function enumValuesToBitMask(values: number[], offset: number = 0) {
  return values
    .map(s => bitShiftValue(s, offset))
    .reduce((mask, s) => {
      return s | mask;
    }, 0);
}

/**
 * Get array of values from bitmask
 * enums must be 1-based and sequential
 * @param values array of enum values (enum must start at 1)
 * @param mask bitmask of enum values
 * @param offset used to baseline enums that are not 1-based (lowest enum value - 1)
 */
export function enumValuesFromBitMask(values: number[], mask: number = 0, offset: number = 0) {
  return values.filter(s => bitShiftValue(s, offset) & mask);
}


// Perform string interpolation similar to ES6 template string literals.
// However, since there's an existing eslint rule to warn about template params in non-template strings,
// we use `{{prop}}` instead of ${prop}` for replacable parapeters.
// Ex: `formatString("Hello {{patient.name.first}.", {patient: {name: {first: "John"}}})` return "Hello John."
const stringInterpolationRegEx = /{{[_a-zA-Z][_a-zA-Z0-9-\]]*(\.[_a-zA-Z][_a-zA-Z0-9-[\]]*)*}}/g
export function formatString(str: string = '', params: object = {}) {
  return str.replace(stringInterpolationRegEx, (match) => {
    const propPath = match.substr(2, match.length - 4);
    return get(params, propPath) || match;
  });
}

export function getStackTrace() {
  const o: { stack?: string } = {};
  Error.captureStackTrace(o);
  return o.stack;
}


// Converts "nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn" to "nnnnnnnn-nnnn-nnnn-nnnn-nnnnnnnnnnnn"
export function formatAsGuid(value: string) {
  return `${value.substr(0, 8)}-${value.substr(8, 4)}-${value.substr(12, 4)}-${value.substr(16, 4)}-${value.substr(20)}`;
}

