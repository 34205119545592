"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultRecallSetting_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var AppointmentType_1 = require("./AppointmentType");
var CorrespondenceTemplate_1 = require("./CorrespondenceTemplate");
var PatientRecallCycleUnitType_1 = require("./PatientRecallCycleUnitType");
var Procedure_1 = require("./Procedure");
var RecallDateGroupingsType_1 = require("./RecallDateGroupingsType");
var DefaultRecallSetting_Factory = /** @class */ (function () {
    function DefaultRecallSetting_Factory() {
    }
    DefaultRecallSetting_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateIncoming(model.AppointmentType),
            CorrespondenceTemplate: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateIncoming(model.CorrespondenceTemplate),
            CorrespondenceTemplate1: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateIncoming(model.CorrespondenceTemplate1),
            CorrespondenceTemplate2: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateIncoming(model.CorrespondenceTemplate2),
            CorrespondenceTemplate3: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateIncoming(model.CorrespondenceTemplate3),
            DefaultAdultProcedureId: model.DefaultAdultProcedureId,
            DefaultApptTypeId: model.DefaultApptTypeId,
            DefaultChildProcedureId: model.DefaultChildProcedureId,
            EmailReminderFutureId: model.EmailReminderFutureId,
            EmailReminderOverdueId: model.EmailReminderOverdueId,
            MailReminderFutureId: model.MailReminderFutureId,
            MailReminderOverdueId: model.MailReminderOverdueId,
            NewPatientsOnRecall: model.NewPatientsOnRecall,
            PatientRecallCycleUnitType: PatientRecallCycleUnitType_1.PatientRecallCycleUnitType_Factory.CreateIncoming(model.PatientRecallCycleUnitType),
            Procedure: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure),
            Procedure1: Procedure_1.Procedure_Factory.CreateIncoming(model.Procedure1),
            RecallBasisId: model.RecallBasisId,
            RecallCyclePeriod: model.RecallCyclePeriod,
            RecallCycleUnitTypeCode: model.RecallCycleUnitTypeCode,
            RecallDateGroupingsType: RecallDateGroupingsType_1.RecallDateGroupingsType_Factory.CreateIncoming(model.RecallDateGroupingsType),
            RecallDateGroupingsType1: RecallDateGroupingsType_1.RecallDateGroupingsType_Factory.CreateIncoming(model.RecallDateGroupingsType1),
            RecallDateGroupingsType2: RecallDateGroupingsType_1.RecallDateGroupingsType_Factory.CreateIncoming(model.RecallDateGroupingsType2),
            RecallDateGroupingsTypeCode1: model.RecallDateGroupingsTypeCode1,
            RecallDateGroupingsTypeCode2: model.RecallDateGroupingsTypeCode2,
            RecallDateGroupingsTypeCode3: model.RecallDateGroupingsTypeCode3,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    DefaultRecallSetting_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppointmentType: AppointmentType_1.AppointmentType_Factory.CreateOutgoing(model.AppointmentType),
            CorrespondenceTemplate: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateOutgoing(model.CorrespondenceTemplate),
            CorrespondenceTemplate1: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateOutgoing(model.CorrespondenceTemplate1),
            CorrespondenceTemplate2: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateOutgoing(model.CorrespondenceTemplate2),
            CorrespondenceTemplate3: CorrespondenceTemplate_1.CorrespondenceTemplate_Factory.CreateOutgoing(model.CorrespondenceTemplate3),
            DefaultAdultProcedureId: model.DefaultAdultProcedureId,
            DefaultApptTypeId: model.DefaultApptTypeId,
            DefaultChildProcedureId: model.DefaultChildProcedureId,
            EmailReminderFutureId: model.EmailReminderFutureId,
            EmailReminderOverdueId: model.EmailReminderOverdueId,
            MailReminderFutureId: model.MailReminderFutureId,
            MailReminderOverdueId: model.MailReminderOverdueId,
            NewPatientsOnRecall: model.NewPatientsOnRecall,
            PatientRecallCycleUnitType: PatientRecallCycleUnitType_1.PatientRecallCycleUnitType_Factory.CreateOutgoing(model.PatientRecallCycleUnitType),
            Procedure: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure),
            Procedure1: Procedure_1.Procedure_Factory.CreateOutgoing(model.Procedure1),
            RecallBasisId: model.RecallBasisId,
            RecallCyclePeriod: model.RecallCyclePeriod,
            RecallCycleUnitTypeCode: model.RecallCycleUnitTypeCode,
            RecallDateGroupingsType: RecallDateGroupingsType_1.RecallDateGroupingsType_Factory.CreateOutgoing(model.RecallDateGroupingsType),
            RecallDateGroupingsType1: RecallDateGroupingsType_1.RecallDateGroupingsType_Factory.CreateOutgoing(model.RecallDateGroupingsType1),
            RecallDateGroupingsType2: RecallDateGroupingsType_1.RecallDateGroupingsType_Factory.CreateOutgoing(model.RecallDateGroupingsType2),
            RecallDateGroupingsTypeCode1: model.RecallDateGroupingsTypeCode1,
            RecallDateGroupingsTypeCode2: model.RecallDateGroupingsTypeCode2,
            RecallDateGroupingsTypeCode3: model.RecallDateGroupingsTypeCode3,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
        };
        return result;
    };
    return DefaultRecallSetting_Factory;
}());
exports.DefaultRecallSetting_Factory = DefaultRecallSetting_Factory;
