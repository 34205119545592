"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormsSourceTypes_DisplayOrders = exports.EFormsSourceTypes_Descriptions_English = exports.EFormsSourceTypes_Descriptions = exports.EFormsSourceTypes_translateDescriptionBack = exports.EFormsSourceTypes_translateDescriptions = exports.EFormsSourceTypes = void 0;
var EFormsSourceTypes;
(function (EFormsSourceTypes) {
    EFormsSourceTypes[EFormsSourceTypes["Office"] = 1] = "Office";
    EFormsSourceTypes[EFormsSourceTypes["Public"] = 2] = "Public";
    EFormsSourceTypes[EFormsSourceTypes["System"] = 3] = "System";
})(EFormsSourceTypes = exports.EFormsSourceTypes || (exports.EFormsSourceTypes = {}));
function EFormsSourceTypes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EFormsSourceTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.EFormsSourceTypes_Descriptions[key] = translationFunction(exports.EFormsSourceTypes_Descriptions[key]);
    });
}
exports.EFormsSourceTypes_translateDescriptions = EFormsSourceTypes_translateDescriptions;
function EFormsSourceTypes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EFormsSourceTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.EFormsSourceTypes_Descriptions[key] = exports.EFormsSourceTypes_Descriptions_English[key];
    });
}
exports.EFormsSourceTypes_translateDescriptionBack = EFormsSourceTypes_translateDescriptionBack;
var _EFormsSourceTypes_Descriptions = {};
_EFormsSourceTypes_Descriptions.Office = _EFormsSourceTypes_Descriptions[1] = "Office";
_EFormsSourceTypes_Descriptions.Public = _EFormsSourceTypes_Descriptions[2] = "Public";
_EFormsSourceTypes_Descriptions.System = _EFormsSourceTypes_Descriptions[3] = "System";
var _EFormsSourceTypes_Descriptions_English = {};
_EFormsSourceTypes_Descriptions_English.Office = _EFormsSourceTypes_Descriptions_English[1] = "Office";
_EFormsSourceTypes_Descriptions_English.Public = _EFormsSourceTypes_Descriptions_English[2] = "Public";
_EFormsSourceTypes_Descriptions_English.System = _EFormsSourceTypes_Descriptions_English[3] = "System";
exports.EFormsSourceTypes_Descriptions = _EFormsSourceTypes_Descriptions;
exports.EFormsSourceTypes_Descriptions_English = _EFormsSourceTypes_Descriptions_English;
var _EFormsSourceTypes_DisplayOrders = {};
_EFormsSourceTypes_DisplayOrders.Office = _EFormsSourceTypes_DisplayOrders[1] = 1;
_EFormsSourceTypes_DisplayOrders.Public = _EFormsSourceTypes_DisplayOrders[2] = 2;
_EFormsSourceTypes_DisplayOrders.System = _EFormsSourceTypes_DisplayOrders[3] = 3;
exports.EFormsSourceTypes_DisplayOrders = _EFormsSourceTypes_DisplayOrders;
