"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientProcedureCPTModifiers_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CPTModifier_1 = require("./CPTModifier");
var PatientProcedureCPTModifiers_Factory = /** @class */ (function () {
    function PatientProcedureCPTModifiers_Factory() {
    }
    PatientProcedureCPTModifiers_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Modifier1: CPTModifier_1.CPTModifier_Factory.CreateIncoming(model.Modifier1),
            Modifier1Id: model.Modifier1Id,
            Modifier2: CPTModifier_1.CPTModifier_Factory.CreateIncoming(model.Modifier2),
            Modifier2Id: model.Modifier2Id,
            Modifier3: CPTModifier_1.CPTModifier_Factory.CreateIncoming(model.Modifier3),
            Modifier3Id: model.Modifier3Id,
            Modifier4: CPTModifier_1.CPTModifier_Factory.CreateIncoming(model.Modifier4),
            Modifier4Id: model.Modifier4Id,
            RecordId: model.RecordId,
        };
        return result;
    };
    PatientProcedureCPTModifiers_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Modifier1: CPTModifier_1.CPTModifier_Factory.CreateOutgoing(model.Modifier1),
            Modifier1Id: model.Modifier1Id,
            Modifier2: CPTModifier_1.CPTModifier_Factory.CreateOutgoing(model.Modifier2),
            Modifier2Id: model.Modifier2Id,
            Modifier3: CPTModifier_1.CPTModifier_Factory.CreateOutgoing(model.Modifier3),
            Modifier3Id: model.Modifier3Id,
            Modifier4: CPTModifier_1.CPTModifier_Factory.CreateOutgoing(model.Modifier4),
            Modifier4Id: model.Modifier4Id,
            RecordId: model.RecordId,
        };
        return result;
    };
    return PatientProcedureCPTModifiers_Factory;
}());
exports.PatientProcedureCPTModifiers_Factory = PatientProcedureCPTModifiers_Factory;
