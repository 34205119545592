"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockBookingTemplateDate_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BlockBookingTemplateDate_Factory = /** @class */ (function () {
    function BlockBookingTemplateDate_Factory() {
    }
    BlockBookingTemplateDate_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            BlockBookingTemplateId: model.BlockBookingTemplateId,
            Date: Date_Factory_1.Date_Factory.CreateIncoming(model.Date),
            RecordId: model.RecordId,
        };
        return result;
    };
    BlockBookingTemplateDate_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            BlockBookingTemplateId: model.BlockBookingTemplateId,
            Date: Date_Factory_1.Date_Factory.CreateOutgoing(model.Date),
            RecordId: model.RecordId,
        };
        return result;
    };
    return BlockBookingTemplateDate_Factory;
}());
exports.BlockBookingTemplateDate_Factory = BlockBookingTemplateDate_Factory;
