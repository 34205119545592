"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EServicesReportStatuses_DisplayOrders = exports.EServicesReportStatuses_Descriptions_English = exports.EServicesReportStatuses_Descriptions = exports.EServicesReportStatuses_translateDescriptionBack = exports.EServicesReportStatuses_translateDescriptions = exports.EServicesReportStatuses = void 0;
var EServicesReportStatuses;
(function (EServicesReportStatuses) {
    EServicesReportStatuses[EServicesReportStatuses["New"] = 0] = "New";
    EServicesReportStatuses[EServicesReportStatuses["Read"] = 1] = "Read";
    EServicesReportStatuses[EServicesReportStatuses["Archived"] = 2] = "Archived";
})(EServicesReportStatuses = exports.EServicesReportStatuses || (exports.EServicesReportStatuses = {}));
function EServicesReportStatuses_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EServicesReportStatuses_Descriptions);
    allKeys.forEach(function (key) {
        exports.EServicesReportStatuses_Descriptions[key] = translationFunction(exports.EServicesReportStatuses_Descriptions[key]);
    });
}
exports.EServicesReportStatuses_translateDescriptions = EServicesReportStatuses_translateDescriptions;
function EServicesReportStatuses_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EServicesReportStatuses_Descriptions);
    allKeys.forEach(function (key) {
        exports.EServicesReportStatuses_Descriptions[key] = exports.EServicesReportStatuses_Descriptions_English[key];
    });
}
exports.EServicesReportStatuses_translateDescriptionBack = EServicesReportStatuses_translateDescriptionBack;
var _EServicesReportStatuses_Descriptions = {};
_EServicesReportStatuses_Descriptions.New = _EServicesReportStatuses_Descriptions[0] = "New";
_EServicesReportStatuses_Descriptions.Read = _EServicesReportStatuses_Descriptions[1] = "Read";
_EServicesReportStatuses_Descriptions.Archived = _EServicesReportStatuses_Descriptions[2] = "Archived";
var _EServicesReportStatuses_Descriptions_English = {};
_EServicesReportStatuses_Descriptions_English.New = _EServicesReportStatuses_Descriptions_English[0] = "New";
_EServicesReportStatuses_Descriptions_English.Read = _EServicesReportStatuses_Descriptions_English[1] = "Read";
_EServicesReportStatuses_Descriptions_English.Archived = _EServicesReportStatuses_Descriptions_English[2] = "Archived";
exports.EServicesReportStatuses_Descriptions = _EServicesReportStatuses_Descriptions;
exports.EServicesReportStatuses_Descriptions_English = _EServicesReportStatuses_Descriptions_English;
var _EServicesReportStatuses_DisplayOrders = {};
_EServicesReportStatuses_DisplayOrders.New = _EServicesReportStatuses_DisplayOrders[0] = 0;
_EServicesReportStatuses_DisplayOrders.Read = _EServicesReportStatuses_DisplayOrders[1] = 1;
_EServicesReportStatuses_DisplayOrders.Archived = _EServicesReportStatuses_DisplayOrders[2] = 2;
exports.EServicesReportStatuses_DisplayOrders = _EServicesReportStatuses_DisplayOrders;
