"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrespondenceMergeFieldGroup_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CorrespondenceMergeField_1 = require("./CorrespondenceMergeField");
var CorrespondenceMergeFieldGroup_Factory = /** @class */ (function () {
    function CorrespondenceMergeFieldGroup_Factory() {
    }
    CorrespondenceMergeFieldGroup_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CorrespondenceMergeFields: model.CorrespondenceMergeFields && model.CorrespondenceMergeFields.map(function (i) { return CorrespondenceMergeField_1.CorrespondenceMergeField_Factory.CreateIncoming(i); }),
            DisplayName: model.DisplayName,
            Id: model.Id,
            Name: model.Name,
        };
        return result;
    };
    CorrespondenceMergeFieldGroup_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CorrespondenceMergeFields: model.CorrespondenceMergeFields && model.CorrespondenceMergeFields.map(function (i) { return CorrespondenceMergeField_1.CorrespondenceMergeField_Factory.CreateOutgoing(i); }),
            DisplayName: model.DisplayName,
            Id: model.Id,
            Name: model.Name,
        };
        return result;
    };
    return CorrespondenceMergeFieldGroup_Factory;
}());
exports.CorrespondenceMergeFieldGroup_Factory = CorrespondenceMergeFieldGroup_Factory;
