"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabtracMessage_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var LabtracCase_1 = require("./LabtracCase");
var LabtracMessage_Factory = /** @class */ (function () {
    function LabtracMessage_Factory() {
    }
    LabtracMessage_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AppReference: model.AppReference,
            ExternalId: model.ExternalId,
            LabtracCase: LabtracCase_1.LabtracCase_Factory.CreateIncoming(model.LabtracCase),
            LabtracCaseId: model.LabtracCaseId,
            LabtracCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.LabtracCreated),
            LabtracDoctorId: model.LabtracDoctorId,
            LinkObjectId: model.LinkObjectId,
            LinkObjectType: model.LinkObjectType,
            MessageText: model.MessageText,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SenderType: model.SenderType,
            SenderUserName: model.SenderUserName,
        };
        return result;
    };
    LabtracMessage_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AppReference: model.AppReference,
            ExternalId: model.ExternalId,
            LabtracCase: LabtracCase_1.LabtracCase_Factory.CreateOutgoing(model.LabtracCase),
            LabtracCaseId: model.LabtracCaseId,
            LabtracCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.LabtracCreated),
            LabtracDoctorId: model.LabtracDoctorId,
            LinkObjectId: model.LinkObjectId,
            LinkObjectType: model.LinkObjectType,
            MessageText: model.MessageText,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SenderType: model.SenderType,
            SenderUserName: model.SenderUserName,
        };
        return result;
    };
    return LabtracMessage_Factory;
}());
exports.LabtracMessage_Factory = LabtracMessage_Factory;
